<template>
  <div class="mt-20 ml-20">
    <h3>Preview {{ filename }}</h3>
    <div v-if="assetURL">
      <div v-if="assetExtension === 'pdf'" class="wrapper">
        <pdf v-for="i in numPages" :key="i" class="preview h-full" :src="src" :page="i" />
      </div>
      <div v-if="assetExtension === 'image'" class="wrapper">
        <img class="preview" :src="assetURL" alt="" />
      </div>
      <div v-if="assetExtension === 'video'" class="wrapper">
        <video class="preview" controls>
          <source :src="assetURL" type="video/mp4" />
        </video>
      </div>
      <div v-if="assetExtension != 'pdf' && assetExtension != 'image' && assetExtension != 'video'">
        Sorry, {{ assetExtension }} currently not supported in Preview.
      </div>
    </div>
  </div>
</template>

<script>
import pdf from '@jbtje/vite-vue3pdf';
import { mapState } from 'vuex';

export default {
  name: 'FilePreview',

  components: {
    pdf,
  },

  props: {
    partid: { type: String, default: '' },
    filename: { type: String, default: '' },
  },

  data() {
    return {
      showPreview: '',
      showAccessDeniedError: false, // avoid 403-popup on input-page load
      assetURL: '',
      assetExtension: '',
      numPages: undefined,
      src: '',
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
  },

  watch: {
    filename: {
      handler(newVal) {
        if (newVal == '') {
          this.assetURL = '';
        } else {
          this.previewAsset(this.partid, this.filename);
        }
      },
    },

    partid(newVal) {
      if (newVal == '') {
        this.assetURL = '';
      }
    },
  },

  methods: {
    async previewAsset(partId, fileName) {
      if (this.showPreview === fileName || fileName === '' || fileName === undefined) {
        return;
      }
      this.showPreview = fileName;
      this.assetExtension = fileName.split('.').pop().toLowerCase(); // Get file extension, e.g. pdf, png, jpg
      this.checkAssetType();
      this.axiosInstance
        .get(`/api/v1/part/${partId}/assets/`, {
          responseType: 'blob',
          params: { basename: fileName },
        })
        .then(response => {
          // Create non-persitent URL
          const urlCreator = window.URL || window.webkitURL;
          console.log(urlCreator);
          this.assetURL = urlCreator.createObjectURL(response.data);
          if (this.assetExtension === 'pdf') {
            this.loadingTask = pdf.createLoadingTask(this.assetURL);
            this.src = this.loadingTask;
            this.numPages = 1;
            //this.src.promise.then((pdf) => {
            //  this.numPages = pdf.numPages;
            //});
          }
        })
        .catch(error => {
          if (this.showError(error)) {
            this.$root.notifyError('Preview Asset', error);
          }
        })
        .finally(() => {
          // As soon as the preview on input-page load has finished, every 403 error should have a pop-up
          this.showAccessDeniedError = true;
        });
    },

    checkAssetType() {
      let imageExtensions = ['png', 'jpg', 'jpeg', 'svg'];
      let videoExtensions = ['mp4', 'mpg', 'mpeg'];
      if (imageExtensions.includes(this.assetExtension)) {
        this.assetExtension = 'image';
      }
      if (videoExtensions.includes(this.assetExtension)) {
        this.assetExtension = 'video';
      }
    },

    showError(error) {
      if (error.response.status == 403) {
        return this.showAccessDeniedError;
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.preview {
  width: 100%;
  background-size: cover;
  object-fit: cover;
  border: 1px solid grey;
  background-position: 50% 50%;
}

.wrapper {
  overflow-y: auto;
  max-height: 500px;
  margin-top: 10px;
}
</style>
