<template>
  <div class="flex mx-20"><CostCard /></div>
</template>

<script>
import CostCard from './CostCard.vue';

export default {
  name: 'FeasibilityPopup',

  components: { CostCard },
};
</script>
