<template>
  <div>
    <div class="flex items-center">
      <SparkForm class="w-200 ml-10">
        <SparkInput v-model="inputText" name="input" :label="file.title" :default="file.default" disabled />
      </SparkForm>

      <DownloadFile
        class="text-11 ml-2"
        data-type="Asset"
        :part-id="file.part_id"
        :new-file-name="file.basename"
        :disabled="file.basename === ''"
      />

      <DeleteAsset
        v-if="file.basename"
        class="text-11 ml-2"
        :cad-asset="file.type === 'cad'"
        :file="file"
        :file-type="fileType"
      />

      <SparkButton v-if="file.basename" variant="outlined" @click="showPreview()">
        <div class="flex items-center">
          <SparkButtonIcon custom="mt-2 pr-8" icon="fas fa-search" title="Preview File" />
          Preview
        </div>
      </SparkButton>
    </div>
    <div
      v-if="file.basename !== '' && file.type == 'drawing' && !drawingAnalysisExisting && !stat"
      class="text-11 pl-12"
    >
      <img class="h-20" src="@/assets/img/loading.gif" />
      Searching for material in drawing
    </div>
    <div v-if="file.type == 'drawing' && drawingAnalysisExisting" class="text-11 pl-12">
      Material detected. Click on the found text to add to current material.
      <div v-for="text in drawingAnalysisText" :key="text" >
        <div class="mb-8" @click="setToCurrentMaterial(text)">
          <Badge :title="text" selected-style="darkblue" class="mb-8"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import SparkButton from '../../SparkComponents/SparkButton.vue';
import SparkButtonIcon from '../../SparkComponents/SparkButtonIcon.vue';
import SparkForm from '../../SparkComponents/SparkForm.vue';
import SparkInput from '../../SparkComponents/SparkInput.vue';

import DeleteAsset from './DeleteAsset.vue';

import Badge from '@/components/Reusable/Badge.vue';
import DownloadFile from '@/components/Reusable/DownloadFile.vue';

export default {
  name: 'FileRowAsset',

  components: {
    Badge,
    DownloadFile,
    DeleteAsset,
    SparkForm,
    SparkInput,
    SparkButtonIcon,
    SparkButton,
  },

  props: {
    file: {
      type: Object,
      default() {
        return {
          title: '',
          default: '',
          type: '',
          part_id: '',
        };
      },
    },

    fileType: {
      type: String,
      default: 'asset',
    },
  },

  emits: ['show-preview'],

  computed: {
    ...mapState(['part']),

    stat() {
      return this.part?.assets[this.file?.uid]?.asset_stat?.choices?.image_analysis.finished;
    },

    inputText() {
      if (!this.file.basename) {
        return this.file.default;
      } else {
        return this.file.basename;
      }
    },

    drawingAnalysisExisting() {
      let existing = false;
      if (this.file.uid != '' || this.file.uid != undefined) {
        if (this.part?.drawing_analysis[this.file.uid] != undefined) {
          existing = true;
        }
      }
      return existing;
    },

    drawingAnalysisText() {
      return this.part?.drawing_analysis[this.file?.uid]?.text;
    },
  },

  methods: {
    ...mapMutations(['setCurrentMat']),

    setToCurrentMaterial(text) {
      this.setCurrentMat(text);
    },

    showPreview() {
      this.$emit('show-preview');
    },
  },
};
</script>
