<template>
  <div>
    <!-- Nesting Dimension Setting -->
    <div class="spark-new-input-group">
      <select v-model="nestingDimension" required class="spark-new-select-input" :disabled="disabledSetting">
        <option v-for="(label, key) in possibleNestingDimension" :key="key" :value="key">
          {{ label }}
        </option>
      </select>
      <label class="spark-new-select-label">Nesting Dimension</label>
    </div>
    <div class="grey-subinfo ml-16 mb-16">
      {{ nestingDimensionInfo[nestingDimension] }}
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  name: 'NestingDimensionSetting',

  props: {
    chain: {
      type: Object,
      default() {
        return {};
      },
    },

    disabledSetting: Boolean,
  },

  emits: ['setting-selected'],

  data() {
    return {
      possibleNestingDimension: {
        2: '2D',
        3: '3D',
      },

      nestingDimensionInfo: {
        2: 'Parts are arranged on the build plate.',
        3: 'Parts are stacked and placed freely in the build envelope.',
      },
    };
  },

  computed: {
    nestingDimension: {
      get() {
        return this.chain?.nst_dim;
      },

      set(value) {
        this.updateNstDimension({ uid: this.chain.process_chain_id, setting: value });
        let formData = { nst_dim: value };
        this.$emit('setting-selected', formData);
      },
    },
  },

  methods: {
    ...mapMutations(['updateNstDimension']),
  },
};
</script>
