<template>
  <div class="flex mx-20"><CarbonFootprintCard /></div>
</template>

<script>
import CarbonFootprintCard from './CarbonFootprintCard.vue';

export default {
  name: 'CarbonPopup',

  components: { CarbonFootprintCard },
};
</script>
