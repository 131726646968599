import { Euler, Matrix4, Vector3, MathUtils, Box3 } from 'three';

import { kardanToEulerRad } from '../Converters.js';

function setupRotationMatrixFromKardan(rotRadX, rotRadY, rotRadZ) {
  let rotMatX = new Matrix4();
  rotMatX.makeRotationX(rotRadX);

  let rotMatY = new Matrix4();
  rotMatY.makeRotationY(rotRadY);

  let rotMatZ = new Matrix4();
  rotMatZ.makeRotationZ(rotRadZ);

  let rotMat = new Matrix4();
  rotMat.multiply(rotMatX);
  rotMat.multiply(rotMatY);
  rotMat.multiply(rotMatZ);
  return rotMat;
}

function applyRotToVectorArray(rotMat, vecArray) {
  let numberVectors = vecArray.count;
  let rotatedVectors = new Array(numberVectors * 3);

  for (let cntVec = 0; cntVec < numberVectors; cntVec++) {
    let rotatedVector = new Vector3(vecArray.getX(cntVec), vecArray.getY(cntVec), vecArray.getZ(cntVec));
    rotatedVector.applyMatrix4(rotMat);

    let startInd = cntVec * 3;
    rotatedVectors[startInd] = rotatedVector.x;
    rotatedVectors[startInd + 1] = rotatedVector.y;
    rotatedVectors[startInd + 2] = rotatedVector.z;
  }
  return rotatedVectors;
}

function getNormalToPlaneAnglesRad(normal) {
  function angleToNegativeZAxis(normalInPlane) {
    let rotatedNormal = [-normalInPlane[1], normalInPlane[0]];
    let angle = Math.atan2(rotatedNormal[1], rotatedNormal[0]);
    return angle;
  }

  let normalArray = [normal.x, normal.y, normal.z];

  let normalProjectedYZ = [normalArray[1], normalArray[2]];
  let rotXRad = angleToNegativeZAxis(normalProjectedYZ);
  rotXRad = -1 * rotXRad;

  let euler = new Euler(rotXRad, 0, 0, 'XYZ');
  let Rx = new Matrix4().makeRotationFromEuler(euler);

  let normalRotX = normal.clone().applyMatrix4(Rx);

  let normalProjectedXZ = [normalRotX.x, normalRotX.z];
  let rotYRad = angleToNegativeZAxis(normalProjectedXZ);

  const rotXDegPositive = convertToPositiveDegree(rotXRad);
  const rotYDegPositive = convertToPositiveDegree(rotYRad);
  return [rotXDegPositive, rotYDegPositive, 0];
}

function convertToPositiveDegree(angleRad) {
  const angleDeg = MathUtils.radToDeg(angleRad);
  const angleDegPositive = MathUtils.euclideanModulo(angleDeg, 360);
  const angleDegPositiveRounded = Math.round(angleDegPositive); // avoids wonky movement after picking face for orientation in canvas
  return angleDegPositiveRounded;
}

function centerOfBB(x_min, x_max) {
  return x_min + (x_max - x_min) / 2;
}

function getXYZ(ind, array) {
  const xyzOfArray = [array[ind * 3], array[ind * 3 + 1], array[ind * 3 + 2]];
  return xyzOfArray;
}

class MeshRotater {
  constructor(scene = null) {
    this.scene = scene;

    this.mesh = null;
    this.meshBackside = null;
    this.convexHull = null;

    this.rotatedVertexPos = null;
    this.partToPlateTranslationWithoutScale = null;
    this._initialTransToCenterPartToOrigin = null;
    this._plateOffsetUnscaled = null;
    this.orientedBBOfOrigPosArray = null;
  }

  fetchMeshes() {
    if (this.scene !== null) {
      for (let cntChild = 0; cntChild < this.scene.children.length; cntChild++) {
        let mesh = this.scene.children[cntChild];
        if (mesh.name === 'Frontside') {
          this.mesh = mesh;
        } else if (mesh.name === 'Backside') {
          this.meshBackside = mesh;
        } else if (mesh.name === 'ConvexHull') {
          this.convexHullMesh = mesh;
        }
      }
    }
  }

  writeFaceNormalsToUserdata() {
    // As of 10/2023 it was not possible to write the face normals from the python
    // trimesh object directly to the GLB file and then use it in frontend.
    // Therefor the face normals must be recomputed here.

    const vertexPos = this.mesh.geometry.attributes.position.array;
    const indices = this.mesh.geometry.index.array;

    let faceNormals = [];
    const numberFaces = indices.length;
    for (let cntFace = 0; cntFace < numberFaces; cntFace += 3) {
      let posOfVerticesWithinFace = [];
      for (let cntXYZ = 0; cntXYZ < 3; cntXYZ++) {
        const indVertexInFace = indices[cntFace + cntXYZ];
        let vertexPosVec = new Vector3(...getXYZ(indVertexInFace, vertexPos));
        posOfVerticesWithinFace.push(vertexPosVec);
      }

      let faceNormal = this._computeFaceNormalFromItsVertices(...posOfVerticesWithinFace);
      this._repairFaceNormalIfRequired(faceNormal, cntFace);
      faceNormals.push(faceNormal.x, faceNormal.y, faceNormal.z);
    }

    this.mesh.userData.faceNormals = faceNormals;
  }

  _computeFaceNormalFromItsVertices(vertexPos1, vertexPos2, vertexPos3) {
    let faceNormal = new Vector3();
    faceNormal.crossVectors(vertexPos2.clone().sub(vertexPos1), vertexPos3.clone().sub(vertexPos1));
    faceNormal.normalize();
    return faceNormal;
  }

  _repairFaceNormalIfRequired(faceNormal, faceInd) {
    const indices = this.mesh.geometry.index.array;
    const vertexNormals = this.mesh.geometry.attributes.normal.array;

    let vertexNormal = new Vector3(...getXYZ(indices[faceInd], vertexNormals));
    const faceNormalPointsInCorrectDir = faceNormal.dot(vertexNormal) > 0;
    if (!faceNormalPointsInCorrectDir) {
      faceNormal.negate();
    }
  }

  getPlateOffsetInZ() {
    // apparently THREEJS lookAt() can not be utilized to center the view on the part.
    // Therefor the initial view always looks at origin --> we need to offset the part in z-direction s.t. the
    // part is properly in center of view.

    let vertexPos = this.mesh.geometry.attributes.position.array;
    let bb = this._getBoundingBox(vertexPos);
    let centerBB = centerOfBB(bb.min.z, bb.max.z);
    this._plateOffsetUnscaled = -(centerBB - bb.min.z);
    return this._plateOffsetUnscaled;
  }

  getVerticesPositionsOfCurrentRotation() {
    return [this.rotatedVertexPos, this.orientedBBOfOrigPosArray];
  }

  /**
   * Set the euler rotation of mesh and backside mesh. Is centered by default.
   *
   * The function is called like this:
   * @param [rotXRad=null] - Rotation around the X axis in radians
   * @param [rotYRad=null] - Rotation around the Y axis in radians.
   * @param [rotZRad=null] - Rotation around the Z axis in radians.
   * @param [centered=true] - If true, the mesh will be centered on the origin (0,0,0).
   */
  setEulerRotationRad(eulerRotXRad = null, eulerRotYRad = null, eulerRotZRad = null, centered = true) {
    if (eulerRotXRad !== null) {
      this.mesh.rotation.x = eulerRotXRad;
    }

    if (eulerRotYRad !== null) {
      this.mesh.rotation.y = eulerRotYRad;
    }

    if (eulerRotZRad !== null) {
      this.mesh.rotation.z = eulerRotZRad;
    }

    if (centered) {
      this._recenterMeshToPlate();
    }

    this.meshBackside.rotation.set(...this.mesh.rotation.toArray());
    this.meshBackside.position.set(...this.mesh.position.toArray());

    this.convexHullMesh.scale.set(...this.mesh.scale.toArray());
    this.convexHullMesh.rotation.set(...this.mesh.rotation.toArray());
    this.convexHullMesh.position.set(...this.mesh.position.toArray());
  }

  _recenterMeshToPlate() {
    let transInitCenter = this._compTranslationToCenterOnOrigin();
    let transRot = this._compTranslationDueToRotation(transInitCenter);

    let trans = new Vector3();
    trans.add(transInitCenter);
    trans.add(transRot);
    this.partToPlateTranslationWithoutScale = trans.clone(); // make a shallow copy to not get overwritten below
    trans.multiply(this.mesh.scale);

    this._translateMesh(trans);
  }

  _compTranslationToCenterOnOrigin() {
    if (this._initialTransToCenterPartToOrigin == null) {
      // just compute once to make UX more fluent
      let vertexPos = this.mesh.geometry.attributes.position.array;
      let bb = this._getBoundingBox(vertexPos);

      this._initialTransToCenterPartToOrigin = new Vector3(
        -centerOfBB(bb.min.x, bb.max.x),
        -centerOfBB(bb.min.y, bb.max.y),
        -centerOfBB(bb.min.z, bb.max.z)
      );
    }
    return this._initialTransToCenterPartToOrigin;
  }

  _compTranslationDueToRotation(transAppliedToPosArrayBeforeRot) {
    let rotMat = this._setupRotationMatrixFromEuler();
    let posRotated = applyRotToVectorArray(rotMat, this.mesh.geometry.attributes.position);
    let orientedBB = this._getBoundingBox(posRotated);
    this.orientedBBOfOrigPosArray = orientedBB.clone();

    // apply initial translation BEFORE computing translation due to rotation --> to avoid DOUBLE the translation than needed
    orientedBB.min.add(transAppliedToPosArrayBeforeRot);
    orientedBB.max.add(transAppliedToPosArrayBeforeRot);

    let translation = new Vector3(
      -centerOfBB(orientedBB.min.x, orientedBB.max.x),
      -centerOfBB(orientedBB.min.y, orientedBB.max.y),
      -(orientedBB.min.z - this._plateOffsetUnscaled)
    );

    // assign for usage outside this module
    this.rotatedVertexPos = posRotated;

    return translation;
  }

  _setupRotationMatrixFromEuler() {
    let euler = new Euler(this.mesh.rotation.x, this.mesh.rotation.y, this.mesh.rotation.z);
    let rotMat = new Matrix4();
    rotMat.makeRotationFromEuler(euler);
    return rotMat;
  }

  _getBoundingBox(array) {
    let arrayMin = new Array(3);
    let arrayMax = new Array(3);
    [0, 1, 2].forEach(coordInd => {
      let slicedArray = this._getEvery3rdElementOf(array, coordInd);
      arrayMin[coordInd] = Math.min(...slicedArray);
      arrayMax[coordInd] = Math.max(...slicedArray);
    });

    let bb = new Box3(new Vector3(...arrayMin), new Vector3(...arrayMax));
    return bb;
  }

  _getEvery3rdElementOf(array, startInd) {
    let slice = new Array(array.length / 3);
    for (let index = 0; index < slice.length; index++) {
      slice[index] = array[startInd + index * 3];
    }
    return slice;
  }

  _translateMesh(transVec) {
    // this is quasi a reimplementation of this.mesh.geometry.translate() BUT factor 1000 faster

    this._resetMeshPosition();
    var transMat = new Matrix4();
    transMat.makeTranslation(transVec.x, transVec.y, transVec.z);
    this.mesh.applyMatrix4(transMat);
  }

  _resetMeshPosition() {
    ['mesh', 'meshBackside'].forEach(mesh => {
      ['x', 'y', 'z'].forEach(coordinates => {
        this[mesh].position[coordinates] = 0;
      });
    });
  }

  /**
   * Convert degree angles to radians and call setRotationRad.
   * @param [rotXDeg=null] - The rotation around the X axis in degrees.
   * @param [rotYDeg=null] - The rotation around the Y axis in degrees.
   * @param [rotZDeg=null] - The rotation around the Z axis in degrees.
   * @param [centered=true] - If true, the rotation will be centered around the center of the object.
   */
  setEulerRotationDeg(eulerRotXDeg = null, eulerRotYDeg = null, eulerRotZDeg = null, centered = true) {
    let eulerRotXRad = null;
    let eulerRotYRad = null;
    let eulerRotZRad = null;

    if (eulerRotXDeg !== null) {
      eulerRotXRad = MathUtils.degToRad(eulerRotXDeg);
    }

    if (eulerRotYDeg !== null) {
      eulerRotYRad = MathUtils.degToRad(eulerRotYDeg);
    }

    if (eulerRotZDeg !== null) {
      eulerRotZRad = MathUtils.degToRad(eulerRotZDeg);
    }

    this.setEulerRotationRad(eulerRotXRad, eulerRotYRad, eulerRotZRad, centered);
  }

  setKardanRotationRad(kardanRotXRad, kardanRotYRad, kardanRotZRad) {
    let eulerRotXRad = null;
    let eulerRotYRad = null;
    let eulerRotZRad = null;

    eulerRotXRad, eulerRotYRad, (eulerRotZRad = kardanToEulerRad(kardanRotXRad, kardanRotYRad, kardanRotZRad));
    this.setEulerRotationRad(eulerRotXRad, eulerRotYRad, eulerRotZRad);
  }

  setKardanRotationDeg(kardanRotXDeg, kardanRotYDeg, kardanRotZDeg) {
    let kardanRotXRad = MathUtils.degToRad(kardanRotXDeg);
    let kardanRotYRad = MathUtils.degToRad(kardanRotYDeg);
    let kardanRotZRad = MathUtils.degToRad(kardanRotZDeg);

    let angles = kardanToEulerRad(kardanRotXRad, kardanRotYRad, kardanRotZRad);

    let eulerRotXRad = angles[0];
    let eulerRotYRad = angles[1];
    let eulerRotZRad = angles[2];
    this.setEulerRotationRad(eulerRotXRad, eulerRotYRad, eulerRotZRad);
  }
}

export { setupRotationMatrixFromKardan, applyRotToVectorArray, centerOfBB, getNormalToPlaneAnglesRad, MeshRotater };
