<template>
  <div class="toggle">
    <div>
      <label v-for="(label, key) in possibleClasses" :key="label">
        <input
          type="checkbox"
          name="checkbox"
          :class="[selectedClass[key] === label ? 'checked' : '']"
          @click.prevent="changeClass(key, label)"
        />
        <span>{{ label }}</span>
      </label>
    </div>
  </div>
</template>

<script>
// Examples for Props

// selectedClass: {
//         _pp: "Part",
//       },

//  possibleClasses:{
//         _pp: "Part",
//         _pl: "Lot",
//         _pj: "Job",
//       };

export default {
  name: 'Toggle',

  props: {
    possibleClasses: Object,
    selectedClass: Object,
  },

  emits: ['selected'],

  methods: {
    icChecked(key, label) {
      return this.selectedClass[key] == label;
    },

    changeClass(key, label) {
      this.$emit('selected', { [key]: label });
    },
  },
};
</script>

<style lang="scss" scoped>
:focus {
  outline: 0;
  border-color: #2260ff;
  box-shadow: 0 0 0 4px #b5c9fc;
}

.toggle div {
  display: flex;
  flex-wrap: wrap;
  font-size: var(--10px);
}

.toggle input[type='checkbox'] {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.checked + span {
  box-shadow: 0 0 0 0.0625em #0043ed;
  background-color: #dee7ff;
  z-index: 1;
  color: #0043ed;
}

label span {
  display: block;
  cursor: pointer;
  background-color: #fff;
  padding: 0.375em 0.75em;
  position: relative;
  margin-left: 0.0625em;
  box-shadow: 0 0 0 0.0625em #b5bfd9;
  letter-spacing: 0.05em;
  color: #3e4963;
  text-align: center;
  transition: background-color 0.5s ease;
}

label:first-child span {
  border-radius: 0.375em 0 0 0.375em;
}

label:last-child span {
  border-radius: 0 0.375em 0.375em 0;
}
</style>
