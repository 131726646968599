<template>
  <th
    :title="hoverTitle"
    class="hover: cursor-pointer rounded-8 border-l border-b border-gray-50 whitespace-nowrap px-4"
    @click="$emit('sort', sortby)"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div v-if="sortby != ''" class="w-8 inline-block mr-4">
      <Sorting v-show="hover || enabled" :enabled="enabled" :reverse="reverse" />
    </div>
    {{ title }}
  </th>
</template>

<script>
import Sorting from '@/components/Reusable/Sorting.vue';

export default {
  name: 'InvestigationTitle',

  components: { Sorting },

  props: {
    title: {
      type: String,
      default: '',
    },

    hoverTitle: {
      type: String,
      default: '',
    },

    sortby: { type: String, default: '' },
    activeSorting: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  emits: ['sort'],

  data() {
    return {
      hover: false,
    };
  },

  computed: {
    enabled() {
      if (Object.prototype.hasOwnProperty.call(this.activeSorting, 'enabled')) {
        return this.activeSorting.enabled;
      } else {
        return false;
      }
    },

    reverse() {
      if (Object.prototype.hasOwnProperty.call(this.activeSorting, 'reverse')) {
        return this.activeSorting.reverse;
      } else {
        return false;
      }
    },
  },
};
</script>
