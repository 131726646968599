<template>
  <div style="margin: 20px">
    <div class="chart-container">
      <h5 class="crop" style="width: 100%; display: block">{{ title }}</h5>
      <div>
        <p v-if="unit == 'days'">Total: {{ days }} {{ weeks }}</p>
        <p v-else>Total: {{ formattedValue(sum) }}</p>
        <BarChart :data="data" />
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from './BarChart.vue';

export default {
  name: 'Chart',

  components: {
    BarChart,
  },

  props: {
    chartData: Object,
    title: String,
    sum: Number,
    unit: String,
  },

  data() {
    return {
      data: [[], []],
    };
  },

  computed: {
    weeks() {
      if (this.unit == 'days' && this.sum >= 14) {
        return '/ ' + this.formattedValue(this.sum / 7) + ' weeks';
      }
      return '';
    },

    days() {
      return this.formattedValue(this.sum) + ' ' + this.unit;
    },
  },

  watch: {
    chartData: {
      handler(newVal) {
        this.data = [Object.keys(newVal), Object.values(newVal)];
      },

      immediate: true,
    },
  },

  methods: {
    formattedValue(value) {
      if (value >= 100000) {
        return String(this.$formatZeroDecimalPlaces(value / 1000)).concat(' k');
      } else {
        return this.$formatZeroDecimalPlaces(value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.margin-30 {
  margin-top: 30px;
}
.chart-container {
  position: relative;
}
.loading-container {
  position: relative;
  top: 000px;
  left: 0;
  right: 0;
  align-self: start;
}

p {
  font-size: var(--12px);
}
</style>
