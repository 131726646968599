<template>
  <ErrorPage
    title="404 - Page not found"
    description="We cant find the page you are looking for."
    back-to="/start"
    back-to-title="Back home"
    :image-name="errorImage"
    image-description="404 - Page not found"
  />
</template>

<script>
import ErrorImage from '@/assets/img/error_pages/404.png';
import ErrorPage from '@/components/Reusable/ErrorPage.vue';

export default {
  name: 'NotFound',

  components: { ErrorPage },

  computed: {
    errorImage() {
      return ErrorImage;
    },
  },
};
</script>
