<template>
  <div>
    <h2>Pricing Rule Order</h2>
    <summary>{{ numberOfPricings }} Pricing Rules</summary>
    <div v-if="pricingList.length > 0">
      <draggable :list="pricingList" item-key="order_index" @end="reindexPricings">
        <template #item="{ element }">
          <div
            class="draggable-item flex w-full"
            :class="[{ 'my-0 mx-4 border-2 border-primary-500': element.order_index === activePricing }]"
          >
            <div class="cursor-move mr-10">&#x2630;</div>

            <div class="h-40 flex items-center space-x-2 w-full">
              <div v-if="editingIndex === element.order_index" class="flex-grow">
                <SparkInput v-model="tempRule" name="rule-edit" type="text" />
              </div>
              <SparkInput v-else v-model="element.rule" custom="!text-black" class="flex-grow" name="rule" disabled />
              <SparkButton
                v-if="editingIndex === element.order_index"
                small
                class="mr-4 flex-shrink-0"
                title="cancel"
                variant="outlined"
                @click="editingIndex = null"
              >
                <i class="fas fa-xmark" />
              </SparkButton>
              <SparkButton
                small
                class="mr-4 flex-shrink-0"
                title="delete pricing"
                variant="outlined"
                @click="deletePricing(element.uid)"
              >
                <i class="fas fa-trash" />
              </SparkButton>

              <SparkButton
                v-if="editingIndex === element.order_index"
                small
                title="save"
                variant="outlined"
                class="mr-4 flex-shrink-0"
                @click="saveEdit(element.order_index)"
              >
                <i class="fas fa-save" />
              </SparkButton>

              <SparkButton v-else small variant="outlined" @click="editPricing(element, element.order_index)">
                <div class="fas fa-edit" />
              </SparkButton>
            </div>
          </div>
        </template>
      </draggable>
    </div>
    <div v-else>
      <p>No pricings available.</p>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapState } from 'vuex';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';

export default {
  name: 'RuleContainer',

  components: { draggable, SparkButton, SparkInput },

  props: {
    pricingList: { type: Array, required: true },
    selectedPricingOptionUid: { type: String, required: true },
  },

  emits: ['edit-pricing', 'delete-pricing', 'save-edit'],

  data() {
    return {
      editingIndex: null,
      tempRule: '',
      activePricing: null,
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),

    numberOfPricings() {
      return this.pricingList.length;
    },
  },

  methods: {
    editPricing(pricing, index) {
      this.editingIndex = index;
      this.tempRule = pricing.rule ?? 'No rule defined';
    },

    deletePricing(pricingUid) {
      this.$emit('delete-pricing', pricingUid);
    },

    saveEdit(index) {
      this.$emit('save-edit', { index, rule: this.tempRule });
      this.editingIndex = null;
    },

    reindexPricings() {
      let formData = {
        pricing_order: {},
      };
      this.pricingList.forEach((pricing, index) => {
        pricing.order_index = index;
        formData.pricing_order[index] = pricing.uid;
      });

      this.axiosInstance.put(`api/v1/pricing-list/${this.selectedPricingOptionUid}/`, formData);
      this.activePricing = null;
    },
  },
};
</script>

<style lang="scss">
.active-pricing {
  padding: 0 5px;
  border: 3px solid #97ab13;
}

.draggable-item td {
  min-width: 200px;
}

.draggable-item {
  display: flex;
  align-items: center; /* Align icon and template details horizontally */
  margin-top: 10px;
}

.draggable-item td {
  min-width: 200px;
}
</style>
