<template>
  <SparkForm class="w-200 ml-10">
    <SparkInput
      v-model.lazy="lotSize"
      custom="!w-200"
      name="lot_size"
      label="Lot Size *"
      type="number"
      :disabled="lockedForUser"
      :title="lockedForUser ? lockedTitle : ''"
      :rules="fieldSchema"
      @keyup="limitValue"
    />
  </SparkForm>
</template>

<script>
import { toTypedSchema } from '@vee-validate/zod';
import { mapState, mapGetters, mapMutations } from 'vuex';
import * as zod from 'zod';

import SparkForm from '../../SparkComponents/SparkForm.vue';
import SparkInput from '../../SparkComponents/SparkInput.vue';
export default {
  name: 'Quantity',

  components: {
    SparkInput,
    SparkForm,
  },

  emits: ['lot-size-changed'],

  computed: {
    ...mapState(['part', 'popup']),
    ...mapState('application', ['lockedTitle']),
    ...mapGetters(['lockedForUser']),
    lotSize: {
      get() {
        const size = this.part.lot_size;
        if (size === null || size === 0 || size === undefined) {
          return 1;
        } else {
          return this.part.lot_size;
        }
      },

      set(value) {
        value = parseInt(value);
        let lotSize = Math.ceil(value);
        lotSize == 0 ? (lotSize = 1) : '';
        if (lotSize > 1000000) {
          lotSize = 1000000;
          this.$root.notify(
            'warning',
            'Quantity limit reached',
            'You have reached the quantity limit of 1.000.000.',
            3000
          );
        }

        if (this.checkProcessChainLotsizes()) {
          this.$emit('lot-size-changed', { lot_size: lotSize });
        } else {
          this.triggerPopup({
            key: this.$options.name,
            show: true,
            title: 'Lot Size Change',
            buttons: true,
            message: 'You are about to change the lot sizes for all process chains. Are you sure to proceed?',
            additionalData: lotSize,
            buttonContent: [
              { text: 'Cancel', type: 'outlined', value: 'cancel' },
              { text: 'Ok', type: 'secondary', value: 'ok' },
            ],
          });
        }
      },
    },

    fieldSchema() {
      return toTypedSchema(zod.number().positive('Lot Size must be at least 1').int('Must be a number'));
    },
  },

  watch: {
    popup: {
      handler(popup) {
        if (popup?.key == this.$options.name) {
          if (this.popup?.clicked == 'ok') {
            this.$emit('lot-size-changed', { lot_size: this.popup.additionalData });
            this.triggerPopup('');
          } else if (this.popup?.clicked == 'cancel' || this.popup?.clicked == 'close') {
            this.triggerPopup('');
          }
        }
      },
    },
  },

  methods: {
    ...mapMutations(['triggerPopup']),
    limitValue() {
      if (parseInt(this.value) > 1000000) {
        this.value = 1000000;
        return false;
      }
    },

    checkProcessChainLotsizes() {
      let lotSizeArray = [];
      if (this.part.process_chains) {
        Object.keys(this.part.process_chains).forEach(key => {
          lotSizeArray.push(this.part.process_chains[key].lot_size);
        });
      }
      return lotSizeArray.every((val, i, arr) => val === arr[0]);
    },
  },
};
</script>
