export const conditionals = {
  title: 'Conditionals',
  items: [
    { displayName: '>', variableName: '>', variableType: 'conditional', id: 11, value: null },
    { displayName: '<', variableName: '<', variableType: 'conditional', id: 12, value: null },
    {
      displayName: '>=',
      variableName: '>=',
      variableType: 'conditional',
      id: 13,
      value: null,
    },
    { displayName: '<=', variableName: '<=', variableType: 'conditional', id: 14, value: null },
    { displayName: '==', variableName: '==', variableType: 'conditional', id: 15, value: null },
    { displayName: '!=', variableName: '!=', variableType: 'conditional', id: 16, value: null },
    { displayName: '&', variableName: '&', variableType: 'conditional', id: 17, value: null },
    { displayName: '|', variableName: '|', variableType: 'conditional', id: 18, value: null },
  ],
};

export const operators = {
  title: 'Operators',
  items: [
    { displayName: '+', variableName: '+', variableType: 'operator', id: 4, value: null },
    { displayName: '-', variableName: '-', variableType: 'operator', id: 5, value: null },
    { displayName: '*', variableName: '*', variableType: 'operator', id: 6, value: null },
    { displayName: '/', variableName: '/', variableType: 'operator', id: 7, value: null },
    { displayName: '(', variableName: '(', variableType: 'operator', id: 8, value: null },
    { displayName: ')', variableName: ')', variableType: 'operator', id: 9, value: null },
    { displayName: '^', variableName: '^', variableType: 'operator', id: 10, value: null },
  ],
};

export const operands = {
  title: 'Operands',
  items: [
    { displayName: 'Cost per Part', variableName: 'cost_pp', variableType: 'variable', id: 0, value: 0 },
    { displayName: 'Lot Size', variableName: 'lot_size', variableType: 'variable', id: 1, value: 0 },
    { displayName: 'Margin', variableName: 'margin', variableType: 'variable', id: 2, value: 0 },
    { displayName: 'Overhead', variableName: 'overhead', variableType: 'variable', id: 3, value: 0 },
  ],
};
