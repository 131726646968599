<template>
  <tr>
    <td colspan="5">
      <button
        type="button"
        class="spark-btn"
        :disabled="!cadUploaded || noProcessChainSelected"
        @click="requestOffer()"
      >
        Request Offer
      </button>
    </td>
  </tr>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'ExternalButton',

  data() {
    return {
      orderStatusKey: '',
      orderStatusSelectedKey: '',
    };
  },

  computed: {
    ...mapState(['part', 'popup']),
    ...mapState('application', ['axiosInstance']),

    partId() {
      return this.part.part_id;
    },

    comboCategories() {
      return this.part.categories.combo_categories;
    },

    cadUploaded() {
      return this.part.basename != '';
    },

    popup() {
      return this.$store.state.popup;
    },

    primaryProcessChainUid() {
      return this.part.primary_process_chain_id;
    },

    noProcessChainSelected() {
      return this.primaryProcessChainUid == null || this.primaryProcessChainUid == '';
    },
  },

  watch: {
    comboCategories: {
      handler(comboCategories) {
        this.getOrderStatus(comboCategories);
      },

      immediate: true,
    },

    popup(popup) {
      if (popup?.key == this.$options.name) {
        if (popup?.clicked == 'ok') {
          this.triggerPopup('');
          this.resetPart();
          this.updateActiveId(0);
          this.setPartCategories();
          this.updateReloadCanvas(true);
          this.resetCanvas();
        } else if (popup?.clicked == 'cancel' || popup?.clicked == 'close') {
          this.triggerPopup('');
          this.$router.push({
            name: 'part-library',
          });
        }
      }
    },
  },

  methods: {
    ...mapMutations(['triggerPopup', 'resetPart', 'updateActiveId', 'setPartCategories']),
    ...mapMutations('canvas', ['resetCanvas', 'updateReloadCanvas']),

    getOrderStatus(comboCategories) {
      if (comboCategories != undefined) {
        Object.keys(comboCategories).forEach(key => {
          if (comboCategories[key].label === 'Order Status') {
            this.orderStatusKey = key;
            Object.keys(comboCategories[key].items).forEach(key2 => {
              if (comboCategories[key].items[key2] === 'Offer Requested') {
                this.orderStatusSelectedKey = key2;
              }
            });
          }
        });
      }
    },

    requestOffer() {
      this.triggerPopup({
        key: this.$options.name,
        show: true,
        title: 'Success',
        message: 'An offer has been requested. Do you want to upload another component, or go back to the library?',
        selectOptions: this.machineLibraryColumns,
        buttons: true,
        buttonContent: [
          { text: 'Back to library', type: 'outlined', value: 'cancel' },
          { text: 'Upload', type: 'secondary', value: 'ok' },
        ],
      });

      // Set Order Status to "Offer Requested"
      let comboCats = { combo_categories: { [this.orderStatusKey]: this.orderStatusSelectedKey } };
      this.savePart(comboCats);
    },

    async savePart(dataToBeSaved) {
      // This function will parse the current 'part' instance to the database.
      let formData = dataToBeSaved;
      this.axiosInstance
        .put(`/api/v1/part/${this.partId}/`, formData)
        .then(() => {})
        .catch(error => {
          console.log(JSON.stringify(error));
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.spark-btn {
  float: right;
}
</style>
