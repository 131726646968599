<template>
  <div class="flex-container">
    <!-- <div v-if="title" class="bar-title">{{ title }}</div> -->
    <h5 v-if="title">{{ title }}</h5>
    <div class="rectangle">
      <!-- suitability not computed yet -->
      <div v-if="value === null" class="valuebar">
        <div class="text-left">Not Available</div>
      </div>

      <!-- standard case aka. value between 0% - 100% -->
      <div v-else class="valuebar" :style="{ width: value + '%', background: barColor }" :title="value + ' %'">
        <div v-show="value > 7" class="text-right">{{ text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeasibilityBar',

  props: {
    value: Number,
    title: String,
  },

  computed: {
    text() {
      return String(this.$formatZeroDecimalPlaces(this.value)).concat(' %');
    },

    barColor() {
      if (this.value >= 88) {
        return ' #5dca5b';
      }
      if (this.value >= 80 && this.value < 88) {
        return '#69cc50';
      }
      if (this.value >= 72 && this.value < 80) {
        return '#7ace46';
      }
      if (this.value >= 64 && this.value < 72) {
        return '#90d03a';
      }
      if (this.value >= 56 && this.value < 64) {
        return '#abd32f';
      }
      if (this.value >= 48 && this.value < 56) {
        return '#c8d127';
      }
      if (this.value >= 40 && this.value < 48) {
        return '#ceb722';
      }
      if (this.value >= 32 && this.value < 40) {
        return '#ca931d';
      }
      if (this.value >= 24 && this.value < 32) {
        return '#c66f18';
      }
      if (this.value >= 16 && this.value < 24) {
        return '#c24a13';
      }
      if (this.value >= 8 && this.value < 16) {
        return ' #bd250f';
      } else {
        return '#b80b15';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bar-title {
  font-size: var(--10px);
}

.rectangle {
  min-width: 200px;
  flex-grow: 10;
  height: 15px;
  background: #e3dddd;
  border-radius: 15px;
}

.valuebar {
  height: 15px;
  border-radius: 15px;
  width: 0px;
  background-color: grey;
}

.flex-container {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  align-content: center;
}

.text-right {
  color: white;
  font-size: var(--10px);
  white-space: nowrap;
  padding-right: 3px;
  float: right;
}

.text-left {
  font-size: var(--10px);
  white-space: nowrap;
  padding-left: 3px;
  float: left;
}
</style>
