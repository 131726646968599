<template>
  <div>
    <!-- Process Chain -->
    <div class="container" :class="pdfView ? 'pdf-container' : ''">
      <ProcessStepGroup
        v-if="preProcessSteps"
        :steps="preProcessSteps"
        title="Pre-Process"
        :level="selectedLevel"
        :color="color['Pre-Process']"
        show-button
        @add="showDialog($event)"
      />
      <ProcessStepGroup
        :steps="mainProcessStep"
        title="In-Process"
        :level="selectedLevel"
        :color="color['In-Process']"
        :show-button="false"
      />
      <ProcessStepGroup
        v-if="postProcessSteps"
        :steps="postProcessSteps"
        title="Post-Process"
        :level="selectedLevel"
        :color="color['Post-Process']"
        show-button
        @add="showDialog($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import ProcessStepDialog from '@/components/PartWorkflow/ProcessChain/ProcessStepDialog.vue';
import ProcessStepGroup from '@/components/PartWorkflow/ProcessChain/ProcessStepGroup.vue';

export default {
  name: 'ProcessChain',

  components: {
    ProcessStepGroup,
  },

  props: {
    chain: {
      type: Object,
      default() {
        return {};
      },
    },

    pdfView: { type: Boolean },

    selectedLevel: {
      type: Object,
      default() {
        return { _pp: 'Part' };
      },
    },
  },

  data() {
    return {
      preProcessSteps: null,
      mainProcessStep: null,
      postProcessSteps: null,
      chartData: null,
      show: false,
      filterDialog: '',
      color: {
        'Pre-Process': '#50a443',
        'In-Process': '#035e7b',
        'Post-Process': '#00867e',
      },
    };
  },

  computed: {
    ...mapState(['part', 'popup']),

    showIfTechnologyAM() {
      if (this.chain?.tech === 'additive_manufacturing') {
        return true;
      } else {
        return false;
      }
    },
  },

  watch: {
    chain: function () {
      this.calculateChartData();

      this.preProcessSteps = this.calculateProcessStepGroup(['pre', 'pre_custom']);
      this.postProcessSteps = this.calculateProcessStepGroup(['post', 'post_custom']);
      this.mainProcessStep = this.calculateProcessStepGroup(['main']);
    },

    popup(popup) {
      if (popup?.key == this.$options.name) {
        if (popup?.clicked == 'ok' || popup?.clicked == 'close') {
          this.triggerPopup('');
        }
      }
    },
  },

  mounted() {
    this.calculateChartData();
    this.show = this.showIfTechnologyAM;

    this.preProcessSteps = this.calculateProcessStepGroup(['pre', 'pre_custom']);
    this.postProcessSteps = this.calculateProcessStepGroup(['post', 'post_custom']);
    this.mainProcessStep = this.calculateProcessStepGroup(['main']);
  },

  methods: {
    ...mapMutations(['triggerPopup']),

    showDialog(event) {
      this.dialogColor = this.color[event];
      this.filterDialog = event;
      this.triggerPopup({
        key: this.$options.name,
        show: true,
        title: 'Add ' + this.filterDialog + ' Step',
        type: 'component',
        buttons: true,
        buttonContent: [{ text: 'Ok', type: 'secondary', value: 'cancel' }],
        data: ProcessStepDialog,
        additionalData: {
          chain: this.chain,
          filterDialog: this.filterDialog,
          color: this.dialogColor,
        },
      });
    },

    calculateProcessStepGroup(stepTypes) {
      if (this.chain?.process_steps !== undefined) {
        return Object.fromEntries(
          Object.entries(this.chain?.process_steps).filter(
            ([, step]) => stepTypes.includes(step.step_type) && (this.chain?.tech == step.tech || step.tech == null)
          )
        );
      }
    },

    calculateChartData() {
      let a = [];
      for (let [, value] of Object.entries(this.chain?.process_steps)) {
        a.push({
          name: value.name,
          data: [value.cost.cost_pp / this.chain.cost.cost_pp],
        });
      }
      this.chartData = a;
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  z-index: 10;
  padding: unset;
  margin-top: 5px;
  overflow-x: scroll;
}

.pdf-container {
  flex-wrap: wrap;
}

.small {
  font-size: var(--11px);
  margin-top: 0px;
}
</style>
