<template>
  <div>
    <!-- Shared Build Jobs Setting -->
    <div class="spark-new-input-group">
      <select v-model="buildJobStyle" required class="spark-new-select-input" :disabled="disabledSetting">
        <option v-for="(label, key) in possibleBuildJobStyles" :key="key" :value="key">{{ label }}</option>
      </select>
      <label class="spark-new-select-label">Shared Build Jobs</label>
    </div>
    <div class="grey-subinfo ml-16 mb-16">
      {{ buildJobStyleInfo[buildJobStyle] }}
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  name: 'SharedBuildJobSetting',

  props: {
    disabledSetting: Boolean,
    chain: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  emits: ['setting-selected'],

  data() {
    return {
      possibleBuildJobStyles: {
        true: 'Lot Only',
        false: 'Shared',
      },

      buildJobStyleInfo: {
        false:
          "Potentially available free space in the build job is filled with other parts. This is typical for 3D printing service providers, unless 'Lot Only' is explicitly requested.",

        true: 'Potentially available free space in the build job is not filled with other parts. This can be a requirement e.g. for quality or regulatory reasons but increases part cost.',
      },
    };
  },

  computed: {
    buildJobStyle: {
      get() {
        return this.chain?.lot_only;
      },

      set(value) {
        let settingConvertedToBool = null;
        if (typeof value == 'boolean') {
          settingConvertedToBool = value;
        } else {
          settingConvertedToBool = value.toLowerCase() === 'true';
        }
        this.updateLotOnly({ uid: this.chain.process_chain_id, setting: settingConvertedToBool });
        let formData = { lot_only: settingConvertedToBool };
        this.$emit('setting-selected', formData);
      },
    },
  },

  mounted() {
    this.selectedBuildJobStyle = this.chain?.lot_only;
  },

  methods: {
    ...mapMutations(['updateLotOnly']),
  },
};
</script>
