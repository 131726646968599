<template>
  <div />
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import useCopyObject from '@/composables/copyObject.js';
import getCurrentDateWithTime from '@/composables/dateFormatting.js';

export default {
  name: 'CustomMachine',

  props: {
    machinedata: {
      type: Object,
      required: true,
    },

    type: {
      type: String,
      default: '',
    },
  },

  emits: ['get-machines', 'highlight-machine'],

  computed: {
    ...mapState(['popup', 'user']),
    ...mapState('application', ['axiosInstance']),

    formData() {
      return {
        0: {
          Name: {
            db_name: 'mac_name',
            field: 'input-text',
            options: '',
            unit: '',
            required: false,
            prc: '',
          },
        },

        1: {
          'OEM Name': {
            db_name: 'oem_name',
            field: 'input-text',
            options: '',
            unit: '',
            required: true,
            prc: '',
          },
        },

        2: {
          Process: {
            db_name: 'prc_uid',
            field: 'select',
            options: this.processes,
            unit: '',
            required: true,
            prc: '',
          },
        },

        3: {
          'Machine Hourly Rate': {
            db_name: 'mac_cost_ph',
            field: 'input-number',
            options: [0.1, 100000],
            unit: '€/h',
            required: true,
            prc: '',
          },
        },

        4: {
          'Build envelope x-direction': {
            db_name: 'bld_size_x',
            field: 'input-number',
            options: [0.1, 100000],
            unit: 'mm',
            required: true,
            prc: '',
          },
        },

        5: {
          'Build envelope y-direction': {
            db_name: 'bld_size_y',
            field: 'input-number',
            options: [0.1, 100000],
            unit: 'mm',
            required: true,
            prc: '',
          },
        },

        6: {
          'Build envelope z-direction': {
            db_name: 'bld_size_z',
            field: 'input-number',
            options: [0.1, 100000],
            unit: 'mm',
            required: true,
            prc: '',
          },
        },

        7: {
          'Process time per layer': {
            db_name: 'mac_prc_t_plyr',
            field: 'input-number',
            options: [0, 100000],
            unit: 's',
            required: false,
            prc: '',
          },
        },

        8: {
          'Fixed machine cost per job, e.g. consumables': {
            db_name: 'fix_mac_cost_pj',
            field: 'input-number',
            options: [0, 1000000],
            unit: '€',
            required: false,
            prc: '',
          },
        },

        9: {
          'Time required to manually setup the machine.': {
            db_name: 'setup_time',
            field: 'input-number',
            options: [0, 1000000],
            unit: 'h',
            required: false,
            prc: '',
          },
        },

        10: {
          'Time required to manually unload the machine till the next setup cycle can be started.': {
            db_name: 'unloading_time',
            field: 'input-number',
            options: [0, 1000000],
            unit: 'h',
            required: false,
            prc: '',
          },
        },

        11: {
          'Machine idle time, e.g. preheating and cooling.': {
            db_name: 'mac_idl_t_pj',
            field: 'input-number',
            options: [0, 1000000],
            unit: 'h',
            required: false,
            prc: '',
          },
        },

        12: {
          'Layer Height': {
            db_name: 'lyr_h',
            field: 'input-number',
            options: [0, 1000000],
            unit: 'mm',
            required: false,
            prc: '',
          },
        },

        13: {
          'Percentage of the process time the machine has to be supervised by personell.': {
            db_name: 'staff_time_factor_percent',
            field: 'input-number',
            options: [0, 100],
            unit: '%',
            required: false,
            prc: '',
          },
        },

        14: {
          Access: {
            db_name: 'restriction',
            field: 'select',
            options: this.restrictions,
            unit: '',
            required: false,
            prc: '',
          },
        },

        15: {
          'Average Power Consumption': {
            db_name: 'power_consumption',
            field: 'input-number',
            options: [0, 10000],
            unit: 'kW',
            required: false,
            prc: '',
          },
        },
      };
    },

    processes() {
      let actualProcesses = useCopyObject(this.user.organization.process_synonyms);
      // only use AM because no other materials should be created
      if (actualProcesses != undefined) {
        Object.keys(actualProcesses).forEach(key => {
          let listToRemove = [
            'EBM',
            'DOD',
            'VDC',
            'UIM',
            'TWIM',
            'SMW LC',
            'HAND LAMINATION',
            'SDC',
            'SAND CAST',
            'RIM',
            'PVC',
            'MIM',
            'LSR',
            'LP CAST',
            'LAMINATION',
            'INC CAST',
            'GIM',
            'G CAST',
            'FORM MIL',
            'FOAM CAST',
            'FACE MIL',
            'DM',
            'CTF CAST',
            'CMT',
          ];
          if (listToRemove.includes(actualProcesses[key].prc_acronym)) {
            delete actualProcesses[key];
          }
        });
      }

      return Object.values(actualProcesses).map(prc => ({
        uid: prc.uid,
        value: prc.uid,
        text: prc.prc_acronym,
      }));
    },

    restrictions() {
      return {
        0: { key: 0, value: 'owner', text: 'Owner' },
        1: { key: 1, value: 'organization', text: 'My Organization' },
      };
    },
  },

  watch: {
    machinedata: {
      handler(machine) {
        if (Object.keys(machine).length) {
          if (this.type == 'view') {
            this.triggerPopup({
              key: this.$options.name,
              show: true,
              title: 'View Machine',
              type: 'table',
              buttons: true,
              buttonContent: [{ text: 'Close', type: 'secondary', value: 'ok' }],
              tableInput: this.createViewDataTable(),
            });
          } else if (this.type == 'add') {
            this.triggerPopup({
              key: this.$options.name,
              show: true,
              title: 'Add custom Machine',
              type: 'form',
              buttons: true,
              data: this.machinedata,
              link: '#custom-machine',
              formType: 'add',
              formData: this.formData,
              buttonContent: [
                { text: 'Cancel', type: 'outlined', value: 'cancel' },
                { text: 'Add Machine', type: 'secondary', value: 'ok' },
              ],
            });
          } else if (this.type == 'edit') {
            this.triggerPopup({
              key: this.$options.name,
              show: true,
              title: 'Edit Machine',
              type: 'form',
              buttons: true,
              data: this.machinedata,
              link: '#custom-machine',
              formData: this.formData,
              formType: 'edit',
              buttonContent: [
                { text: 'Cancel', type: 'outlined', value: 'cancel' },
                { text: 'Save', type: 'secondary', value: 'ok' },
              ],
            });
          }
        }
      },

      deep: true,
    },

    popup: {
      handler(popup) {
        if (popup?.key == this.$options.name) {
          if (popup?.title == 'View Machine') {
            if (popup?.clicked == 'ok' || popup?.clicked == 'cancel' || popup?.clicked == 'close') {
              this.triggerPopup('');
            }
          } else if (popup?.title == 'Edit Machine') {
            if (popup?.clicked == 'ok') {
              this.triggerPopup('');
              let formData = popup?.filled_form_data;
              this.editMachine(formData);
            } else if (popup?.clicked == 'cancel' || popup?.clicked == 'close') {
              this.triggerPopup('');
            }
          } else if (popup?.title == 'Add custom Machine') {
            if (popup?.clicked == 'ok') {
              let formData = popup?.filled_form_data;
              formData = this.generateMacNameIfNotGiven(formData);
              this.addMachine(formData);
              this.triggerPopup('');
            } else if (popup?.clicked == 'cancel' || popup?.clicked == 'close') {
              this.triggerPopup('');
            }
          }
        }
      },

      deep: true,
    },
  },

  methods: {
    ...mapMutations(['triggerPopup']),
    createViewDataTable() {
      let viewTableData = [];
      Object.keys(this.formData).forEach(key => {
        let title = Object.keys(this.formData[key])[0];
        let db_name = this.formData[key][title]['db_name'];
        let value = '-';
        if (db_name == 'prc_uid') {
          let uid = this.machinedata[db_name];
          value = this.user.organization.process_synonyms[uid].prc_acronym;
        } else {
          value = this.machinedata[db_name];
        }
        let unit = this.formData[key][title]['unit'];
        if (value == undefined) {
          value = '-';
        }
        viewTableData.push([title, value, unit]);
      });
      viewTableData = Array.from(new Set(viewTableData));
      return viewTableData;
    },

    generateMacNameIfNotGiven(formData) {
      if (formData == undefined) return formData;
      if (formData.mac_name == undefined) {
        let dateString = getCurrentDateWithTime().dateWithTime;
        formData.mac_name = this.user.first_name + ' ' + this.user.last_name + ' ' + dateString;
      }
      return formData;
    },

    addMachine(formData) {
      this.axiosInstance
        .post('/api/v1/machine/', formData)
        .then(response => {
          this.$root.notify('success', 'Custom Machine ' + response.data.mac_name + ' created', 3000);
          this.$emit('highlight-machine', response.data.mac_id);
          this.$emit('get-machines');
          this.$store.commit('triggerPopup', '');
        })
        .catch(error => {
          this.$root.notify('error', error.response.data.error_message, 3000);
        });
    },

    editMachine(formData) {
      this.axiosInstance
        .put(`/api/v1/machine/${formData.mac_id}/`, formData)
        .then(response => {
          this.$root.notify('success', 'Machine ' + response.data.mac_name + ' edited', 3000);
          this.$emit('get-machines');
        })
        .catch(error => {
          this.$root.notify('error', error.response.data.error_message, 3000);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.spark-btn {
  float: right;
  font-size: var(--12px);
}
</style>
