<template>
  <div class="overflow-y-scroll h-[600px] overflow-x-hidden">
    <div v-if="optionsEmpty" class="notification-element">No Notifications available.</div>
    <div v-for="(value, key) in elementOptions" :key="key">
      <div class="notification-element">
        <div class="notification-title ellipsis">
          <i :class="getElementIcon(value.theme)" :style="getElementStyle(value.theme)" /> &nbsp;
          <p>{{ value.title }}</p>
        </div>
        <div class="notification-close" title="delete" @click="deleteNotification(key)">
          <i class="fas fa-times do-not-close-dropdown-on-click" />
        </div>
        <div class="notification-body">
          <div class="notification-timestamp">{{ key }}</div>
          <div>{{ value.message }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  name: 'DropdownMenuNotifications',

  props: {
    elementOptions: Object,
  },

  emits: ['lastElementDeleted'],

  data() {
    return {};
  },

  computed: {
    optionsEmpty() {
      if (Object.keys(this.elementOptions).length === 0) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    ...mapMutations(['deleteUserNotification']),

    getElementStyle(elementTheme) {
      let style = '';
      if (elementTheme == 'success') {
        style = 'color: var(--spark-check-green)';
      } else if (elementTheme == 'info') {
        style = 'color: #FFFFCC';
      } else if (elementTheme == 'warning') {
        style = 'color: #F9821A';
      } else if (elementTheme == 'error') {
        style = 'color: #C34943';
      }
      return style;
    },

    getElementIcon(elementTheme) {
      let icon = '';
      if (elementTheme == 'success') {
        icon = 'fas fa-check';
      } else if (elementTheme == 'info') {
        icon = 'fas fa-info';
      } else if (elementTheme == 'warning') {
        icon = 'fas fa-exclamation';
      } else if (elementTheme == 'error') {
        icon = 'fas fa-times';
      }
      return icon;
    },

    deleteNotification(key) {
      this.deleteUserNotification(key);
      if (this.optionsEmpty) {
        this.$emit('lastElementDeleted');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-element {
  cursor: auto;
  position: relative;
  --border-radius: 0.2rem;
  padding: 1rem;
  margin: 5px;
  border-radius: var(--border-radius);
  width: 100%;
  color: black;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 10%);
}

.notification-close {
  transition: all 0.3s ease-out;
  font-size: 1.2em;
  position: absolute;
  cursor: pointer;
  right: 10px;
  top: 13px;

  &:hover {
    color: var(--spark-red);
  }
}

.notification-title {
  white-space: break-spaces;
  word-wrap: break-word;
  font-weight: bold;
  width: 160px;
}

.notification-timestamp {
  margin-bottom: 10px;
  color: grey;
  text-align: right;
}

.notification-body {
  white-space: break-spaces;
  word-wrap: break-word;
}
</style>
