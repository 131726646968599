<template>
  <div class="flex flex-col bg-white border border-gray-100 rounded-8 p-8">
    <div class="p-20">
      <div>Properties included in optimization</div>
      <div class="text-11 text-gray-400 mb-16">
        The following properties of the part are used to fit a prediction of the price. Currently the parameters are
        fixed and not selectable.
      </div>
      <div class="flex items-center text-13"><SparkCheckbox name="volume" disabled />Part Volume</div>
      <div class="flex items-center text-13"><SparkCheckbox name="area" disabled />Part Area</div>
      <div class="flex items-center text-13"><SparkCheckbox name="bounding_box" disabled />Bounding Box Volume</div>
    </div>
  </div>
</template>

<script>
import SparkCheckbox from '@/components/SparkComponents/SparkCheckbox.vue';

export default {
  name: 'PricingProperties',

  components: {
    SparkCheckbox,
  },
};
</script>
