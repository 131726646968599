<template>
  <SparkModal :model-value="show" @update:model-value="close">
    <template #header>
      <div class="text-19 font-bold" v-text="'Change Roles'" />
    </template>

    <template #default>
      <div class="flex items-center justify-between mb-20">
        <div class="font-bold text-15" v-text="'Role*'" />
        <SparkSelect
          v-if="roleOptions.length > 0"
          v-model="selectedRole"
          custom="!w-200 !mx-auto my-16"
          :options="roleOptions"
          name="role"
        />
      </div>
      <div class="flex justify-end gap-16">
        <SparkButton variant="outlined" @click="close">
          <div v-text="'Cancel'" />
        </SparkButton>
        <SparkButton variant="secondary" @click="updateRole">
          <div v-text="'Change'" />
        </SparkButton>
      </div>
    </template>
  </SparkModal>
</template>

<script>
import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';
import SparkSelect from '@/components/SparkComponents/SparkSelect.vue';

export default {
  name: 'ChangeRoleModal',

  components: { SparkModal, SparkSelect, SparkButton },

  props: {
    show: { type: Boolean },
    roleOptions: { type: Array, required: true },
    currentRole: { type: String, default: '' },
  },

  emits: ['close', 'update-role'],

  data() {
    return {
      selectedRole: '',
    };
  },

  watch: {
    show(value) {
      if (value) {
        this.selectedRole = this.currentRole;
      }
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },

    updateRole() {
      this.$emit('update-role', this.selectedRole);
      this.close();
    },
  },
};
</script>
