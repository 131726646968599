<template>
  <div class="flex flex-col w-full flex-grow">
    <div class="flex bg-white h-56 text-19 p-16 font-bold">{{ showTitle }}</div>

    <div v-if="actualPage !== 'CalibrationResults'" class="flex justify-center my-56 flex-grow">
      <CalibrationMode v-if="actualPage === 'CalibrationMode'" class="w-1/3" />
      <SelectTemplate v-if="actualPage === 'SelectTemplate'" />
      <MachineMaterialLimits v-if="actualPage === 'MachineMaterialLimits'" class="w-2/3 mt-24" />
      <PricingProperties v-if="actualPage === 'PricingProperties'" class="w-2/3 mt-24" />
      <CalibrationRunning v-if="actualPage === 'CalibrationRunning'" />
    </div>
    <div v-else class="flex items-start justify-start flex-grow">
      <CalibrationResults v-if="actualPage === 'CalibrationResults'" class="w-full" :save="save" />
    </div>

    <div class="bg-white h-80 text-19 font-bold bottom-0 sticky">
      <FooterButtons
        @save="saveResults()"
        @create-calibration-config="createCalibrationConfig"
        @add-parts-calibration-config="createOrPutCalibrationInstance"
      />
    </div>
  </div>

  <SaveResultsModal :show="showSaveModal" @close="closeSaveModal()" />
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import CalibrationMode from './components/CalibrationMode.vue';
import CalibrationResults from './components/CalibrationResults.vue';
import CalibrationRunning from './components/CalibrationRunning.vue';
import FooterButtons from './components/FooterButtons.vue';
import MachineMaterialLimits from './components/MachineMaterialLimits.vue';
import PricingProperties from './components/PricingProperties.vue';
import SaveResultsModal from './components/SaveResultsModal.vue';
import SelectTemplate from './components/SelectTemplate.vue';

export default {
  name: 'Calibration',

  components: {
    CalibrationMode,
    FooterButtons,
    SelectTemplate,
    MachineMaterialLimits,
    CalibrationResults,
    CalibrationRunning,
    SaveResultsModal,
    PricingProperties,
  },

  // props: {
  //   calibration_id: {
  //     type: String, // or Number, depending on the type you're expecting
  //     required: false,
  //     default: '0',
  //   },
  // },

  data() {
    return {
      title: {
        unset: 'Calibration',
        machine: 'Material and machine combination calibration',
        price: 'Target pricing calibration',
      },

      save: 0,
      showSaveModal: false,
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
    ...mapState('calibration', [
      'selectedMode',
      'actualPage',
      'transformedPartsData',
      'calibrationConfigUid',
      'instancesCreated',
    ]),

    showTitle() {
      return this.title[this.selectedMode];
    },
  },

  unmounted() {
    this.resetCalibrationState();
  },

  methods: {
    ...mapMutations('calibration', [
      'setActualPage',
      'setCalibrationConfigUid',
      'resetCalibrationState',
      'setInstancesCreated',
    ]),

    saveResults() {
      this.showSaveModal = true;
    },

    closeSaveModal() {
      this.showSaveModal = false;
    },

    async createCalibrationConfig() {
      const data = {
        calibration_config_name: this.calibrationConfigName,
        calibration_config_type: this.selectedMode,
        parameters: [{}],
      };

      await this.axiosInstance
        .post(`api/v1/calibration-config-list/`, data)
        .then(response => {
          this.setCalibrationConfigUid(response.data.uid);
          // this.$router.replace({ name: 'calibration', params: { calibration_id: response.data.uid } });
        })
        .catch(error => {
          console.error(error);
        });
    },

    async createOrPutCalibrationInstance() {
      let data = { part_ids: [], values: [], lot_sizes: [] };
      Object.keys(this.transformedPartsData).forEach(key => {
        data['part_ids'].push(this.transformedPartsData[key].uid);
        data['values'].push(this.transformedPartsData[key].value);
        data['lot_sizes'].push(this.transformedPartsData[key].lot_size);
      });
      if (this.instancesCreated) {
        await this.axiosInstance
          .put(`api/v1/calibration-list/${this.calibrationConfigUid}`, data)
          .then(response => {
            this.setInstancesCreated(true);
          })
          .catch(error => {
            console.error(error);
          });
      } else {
        await this.axiosInstance
          .post(`api/v1/calibration-list/${this.calibrationConfigUid}`, data)
          .then(response => {
            this.setInstancesCreated(true);
          })
          .catch(error => {
            console.error(error);
          });
      }
    },
  },
};
</script>
