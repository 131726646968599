<template>
  <div class="background">
    <div class="title">
      Break Even Chart
      <i class="icon-button fas fa-times" @click="hideChart()" />
    </div>
    <div v-if="processChainsAnalysisFinished" id="chart" class="chart">
      <apexchart type="line" height="auto" :options="chartOptions" :series="series" />
    </div>
    <div v-else><p class="small">All process chains must be analyzed.</p></div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

export default {
  name: 'BreakEvenChart',

  props: {
    showToolbar: Boolean,
  },

  data() {
    return {
      chartOptions: {
        chart: {
          height: 500,
          type: 'area',
          stacked: false,
          zoom: {
            enabled: true,
            type: 'xy',
            //autoScaleYaxis: true,
          },

          fontFamily: 'Poppins, Arial, sans-serif',
          toolbar: {
            show: this.showToolbar,
          },
        },

        legend: {
          position: 'bottom',
        },

        // colors: ["#035e7b", "#009766", "#8E0045"],
        noData: {
          text: 'Please reanalyze',
          align: 'center',
          verticalAlign: 'middle',
          offsetY: 0,
        },

        stroke: {
          width: [2, 2, 2],
          curve: 'straight',
        },

        plotOptions: {
          bar: {
            columnWidth: '50%',
          },
        },

        fill: {
          type: 'gradient',
          opacity: [0.9, 0.9, 0.9],
          gradient: {
            shade: 'light',
            shadeIntensity: 0,
            opacityFrom: 0.1,
            opacityTo: 0.4,
            stops: [0, 5, 100],
            type: 'vertical',
          },
        },

        markers: {
          size: 0,
        },

        xaxis: {
          type: 'numeric',
          min: 0,
          title: {
            text: 'Parts',
          },

          decimalsInFloat: 0,
        },

        yaxis: {
          title: {
            text: 'Cost per Part [€]',
          },

          min: 0,
          decimalsInFloat: 0,
        },

        tooltip: {
          shared: true,
          intersect: false,
          fixed: {
            enabled: true,
            position: 'bottomRight',
            offsetY: 150,
          },

          y: {
            formatter: function (y) {
              if (typeof y !== 'undefined') {
                return y.toFixed(0) + ' €';
              }
            },
          },

          x: {
            formatter: function (x) {
              return x + ' Parts';
            },
          },
        },

        annotations: {
          xaxis: {},
        },
      },
    };
  },

  computed: {
    ...mapState(['part']),
    ...mapGetters(['processChainsAnalysisFinished']),

    boolProcessChainsExist() {
      if (this.part.process_chains !== undefined) {
        for (const value of Object.values(this.part.process_chains)) {
          if (value.break_even !== undefined) {
            if (value.break_even.y_values !== undefined) {
              return true;
            } else return false;
          } else return false;
        }
      }
      return false;
    },

    processChains() {
      if (this.boolProcessChainsExist) {
        return this.part.process_chains;
      }
      return undefined;
    },

    series() {
      let dataArray = [];
      if (this.boolProcessChainsExist && this.processChainsAnalysisFinished) {
        for (let value of Object.values(this.processChains)) {
          if (value.break_even !== undefined) {
            if (value.break_even.y_values && value.break_even.x_values) {
              let y_values = Object.values(value.break_even.y_values);
              let x_values = Object.values(value.break_even.x_values);
              let data = x_values.map(function (x, idx) {
                return [x, y_values[idx]];
              });
              let dataObject = {
                name: value.prc + '|' + value.material_name + '|' + value.machine_name,
                type: 'area',
                data: data,
              };
              dataArray.push(dataObject);
            }
          }
        }
      }
      return dataArray;
    },
  },

  mounted() {
    if (this.processChainsAnalysisFinished) {
      this.addIntersections();
    }
  },

  methods: {
    ...mapMutations(['changeDisplayInfo']),

    hideChart() {
      this.changeDisplayInfo('');
    },

    addIntersections() {
      let intersections = [];
      let maxIntersection = 0;
      if (this.processChains != undefined) {
        for (let value of Object.values(this.processChains)) {
          let name = value.prc + '|' + value.material_name + '|' + value.machine_name;
          let firstChainName = name.substring(0, 15) + '...';
          for (let [intKey, intValue] of Object.entries(value.break_even.intersections)) {
            let name =
              this.processChains[intKey].prc +
              '|' +
              '|' +
              this.processChains[intKey].material_name +
              '|' +
              '|' +
              this.processChains[intKey].machine_name;
            let secondChainName = name.substring(0, 15) + '...';
            let intersection = {};
            if (intValue[0] != null && intValue[1] != null) {
              if (maxIntersection < intValue[0]) {
                maxIntersection = intValue[0];
              }
              intersection = {
                x: intValue[0],
                x2: null,
                strokeDashArray: 0,
                borderColor: '#383e46',
                label: {
                  style: {
                    fontSize: '9px',
                  },

                  text: firstChainName + ' | ' + secondChainName + ' (' + intValue[0] + ' Parts)',
                },
              };
              intersections.push(intersection);
            }
          }
        }
      }
      let x_max = 0;
      if (maxIntersection != 0) {
        x_max = maxIntersection * 1.2;
      } else {
        x_max = 200;
      }

      this.chartOptions.xaxis.max = x_max;

      this.chartOptions.annotations.xaxis = intersections;
    },
  },
};
</script>

<style lang="scss" scoped>
.chart {
  margin-top: 1px;
  border: 1px solid #e4e5e8;
  border-radius: 3px;
}

.title {
  margin-top: 10px;
  padding-left: 10px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.small {
  margin-left: 10px;
  font-size: var(--11px);
  padding-bottom: 10px;
}

.background {
  background-color: white;
}
</style>
