<template>
  <div />
</template>

<script>
import { markRaw } from 'vue';
import { mapMutations, mapState } from 'vuex';

import CreateProcessChain from './CreateProcessChain.vue';

export default {
  name: 'CreateProcessChainPopup',

  props: {
    triggerCreateProcessChainPopup: { type: Number, default: 0 },
  },

  computed: {
    ...mapState(['popup']),
  },

  watch: {
    triggerCreateProcessChainPopup(newVal) {
      this.triggerPopup({
        key: this.$options.name,
        show: true,
        title: 'Add a process chain',
        type: 'component',
        buttons: true,
        buttonContent: [{ text: 'Close', type: 'outlined', value: 'cancel' }],
        data: markRaw(CreateProcessChain),
      });
    },

    popup(popup) {
      if (popup?.key == this.$options.name) {
        if (popup?.clicked == 'ok') {
          this.triggerPopup('');
        } else if (popup?.clicked == 'cancel' || popup?.clicked == 'close') {
          this.triggerPopup('');
        }
      }
    },
  },

  methods: {
    ...mapMutations(['triggerPopup']),
  },
};
</script>
