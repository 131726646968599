<template>
  <!-- <div class="overview-frame">{{ processChain }}</div> -->
  <div>
    <DownloadPdf :chain="processChain" />
    <RefinerDialog
      :form-id="formId"
      :show="showRefiner"
      message-identifier="if_you_could_add_one_thing_what_would_it_be"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import DownloadPdf from '@/components/PartWorkflow/Overview/DownloadPdf.vue';
import RefinerDialog from '@/components/Reusable/RefinerDialog.vue';

export default {
  name: 'OverviewFrame',

  components: { DownloadPdf, RefinerDialog },

  data() {
    return {
      formId: 'e0821070-ecdb-11ee-97fd-4158e8798bac',
      showRefiner: 1,
    };
  },

  computed: {
    ...mapState(['part']),

    processChain() {
      let uid = this.$route.params.process_chain_id;
      return this.part.process_chains[uid];
    },
  },

  mounted() {
    this.showRefiner = this.showRefiner + 1;
  },
};
</script>

<style lang="scss" scoped>
.overview-frame {
  margin: 0px var(--grid-outer-whitespace);
}
</style>
