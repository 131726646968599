<template>
  <div>
    <div class="flex justify-between">
      <h2 style="margin: 14px">Filter</h2>
      <div class="flex gap-8">
        <SparkButton variant="secondary" small :disabled="buttonDisabled" @click="filtering()">
          <div v-text="'Apply Filter'" />
        </SparkButton>

        <Reset :disabled="buttonDisabled" @resetting="resetDashboard()" />
      </div>
    </div>
    <Separator />

    <div v-for="(component, label) in enabledDashboardFilters" :key="label" class="filter-field">
      <component
        :is="component"
        :component="component"
        :label="label"
        :reset="reset"
        parent="dashboard"
        @selected="addFilter($event)"
      />
    </div>
    <Separator />
    <div class="flex justify-end gap-8">
      <SparkButton variant="secondary" small :disabled="buttonDisabled" @click="filtering()">
        <div v-text="'Apply Filter'" />
      </SparkButton>

      <Reset :disabled="buttonDisabled" @resetting="resetDashboard()" />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import SparkButton from '../../../components/SparkComponents/SparkButton.vue';

import PipelineStatusFilter from '@/components/Reusable/Filter/PipelineStatusFilter.vue';
import ComboCategoryFilter from '@/components/Reusable/Filter/ComboCategoryFilter.vue';
import DateFilter from '@/components/Reusable/Filter/DateFilter.vue';
import MaterialFilter from '@/components/Reusable/Filter/MaterialFilter.vue';
import OwnFilter from '@/components/Reusable/Filter/OwnFilter.vue';
import Reset from '@/components/Reusable/Filter/Reset.vue';
import ValidationFilter from '@/components/Reusable/Filter/ValidationFilter.vue';
import Separator from '@/components/Reusable/Separator.vue';

export default {
  name: 'DashboardFilter',

  components: {
    DateFilter,
    ValidationFilter,
    MaterialFilter,
    OwnFilter,
    Reset,
    ComboCategoryFilter,
    Separator,
    SparkButton,
    PipelineStatusFilter,
  },

  emits: ['filtering'],

  data() {
    return {
      reset: 0,
      buttonDisabled: false,
      filterSettings: {
        query_type: 'organization',
        part_stat: 'validated',
        materials: [],
      },

      enabledDashboardFilters: {
        Validation: 'ValidationFilter',
        'Own Parts': 'OwnFilter',
        Material: 'MaterialFilter',
        Date: 'DateFilter',
        PipelineStatus: 'PipelineStatusFilter',
      },
    };
  },

  computed: {
    ...mapState(['user']),
    ...mapState('application', ['axiosInstance']),
  },

  watch: {
    user: {
      handler() {
        this.getFilters();
      },
    },
  },

  created() {
    this.getFilters();
  },

  methods: {
    ...mapMutations(['updateFilterDashboard', 'resetAllDashboardFilters']),
    addFilter(event) {
      this.filterSettings = { ...this.filterSettings, ...event };
      this.updateFilterDashboard(this.filterSettings);
    },

    resetDashboard() {
      this.resetAllDashboardFilters(true);
      this.reset += 1;
    },

    getFilters() {
      if (this.user?.organization?.combo_categories) {
        Object.values(this.user?.organization?.combo_categories).forEach(cat => {
          this.enabledDashboardFilters = {
            ...this.enabledDashboardFilters,
            ...{ [cat.label]: 'ComboCategoryFilter' },
          };
        });
      }
    },

    filtering() {
      this.$emit('filtering');
      this.buttonDisabled = true;
      setTimeout(() => {
        this.buttonDisabled = false;
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-field {
  display: inline-block;
  margin: 10px;
  font-size: var(--13px);
  vertical-align: middle;
}

select {
  width: 100%;
}

.filter-btn {
  margin-left: auto;
  margin-right: 0;
  font-size: 12px;
  cursor: pointer;
  box-shadow: unset;
  &:hover {
    background-color: var(--spark-darkgreen);
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #ccc;
    color: #888;
    border-color: #888;
  }
}
</style>
