<template>
  <div class="w-60 h-60 mr-5 ml-8 border-solid border border-gray-100 rounded-4">
    <img v-if="thumbnail" :src="thumbnail" alt="" @error="imgError" />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import defaultImage from '@/assets/img/Logo_Transparent.png';

export default {
  name: 'PartThumbnail',

  data() {
    return {
      defaultImage,
      localThumbnail: null,
    };
  },

  computed: {
    ...mapState(['part', 'activeId']),
    ...mapState('application', ['axiosInstance']),

    thumbnail() {
      return this.localThumbnail || this.part?.thumbnail_file || this.defaultImage;
    },
  },

  watch: {
    'part.thumbnail_file': {
      immediate: true,
      handler(newVal) {
        this.fetchThumbnail();
      },
    },

    activeId(newVal) {
      if (newVal === 0 || newVal === '') {
        this.localThumbnail = this.defaultImage;
      }
    },
  },

  methods: {
    ...mapMutations(['updatePart']),

    async fetchThumbnail() {
      if (this.part.part_id) {
        try {
          const response = await this.axiosInstance.get(`api/v1/part-thumbnail/${this.part.part_id}/`, {
            responseType: 'blob',
          });
          const fileReader = new FileReader();
          fileReader.readAsDataURL(response.data);
          fileReader.onload = e => {
            this.localThumbnail = e.target.result || this.defaultImage;
          };
        } catch (error) {
          console.log('Error fetching thumbnail:', error);
          this.localThumbnail = this.defaultImage;
        }
      }
    },

    imgError() {
      this.localThumbnail = this.defaultImage;
    },
  },
};
</script>
