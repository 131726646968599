<template>
  <div class="inline-block ml-8">
    <SparkInput
      v-model="filterMaterialValue"
      name="text"
      label=" Search in all columns"
      animate-label
      :suffix-icon="filterMaterialValue ? 'fas fa-xmark' : 'fas fa-search'"
      @suffix-clicked="clear"
    />
  </div>
</template>

<script>
import SparkInput from '../../SparkComponents/SparkInput.vue';

export default {
  name: 'ComparisonPageMaterialLibraryFilter',

  components: { SparkInput },

  emits: ['filter'],

  data() {
    return {
      filterMaterialValue: '',
    };
  },

  watch: {
    filterMaterialValue(newVal) {
      this.$emit('filter', newVal);
    },
  },

  methods: {
    clear() {
      this.filterMaterialValue = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.width-200 {
  max-width: 200px;
}
</style>
