<template>
  <div class="filter-element">
    <div class="filter-label">Organizations</div>
    <div class="filtering">
      <VueMultiselect
        v-model.lazy="selectedOrganizations"
        :options="organizationOptions"
        multiple
        :close-on-select="false"
        :limit="2"
        :limit-text="limitText"
        placeholder="Pick some"
        label="name"
        track-by="uid"
      />
    </div>
    <div v-if="selectedOrganizations.length != 0" class="clear-filtering" @click="clear()">Clear</div>
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'OrganizationFilter',

  components: {
    VueMultiselect,
  },

  props: {
    reset: { type: Number, required: true },
  },

  emits: ['selected'],

  data() {
    return {
      selectedOrganizations: [],
      organizationOptions: [],
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
  },

  watch: {
    selectedOrganizations(newVal) {
      this.$emit('selected', { organizations: newVal });
    },

    reset: function () {
      this.selectedOrganizations = [];
    },
  },

  mounted() {
    this.getOrganizations();
  },

  methods: {
    ...mapMutations(['resetFilter']),

    async getOrganizations() {
      await this.axiosInstance
        .get(`api/v1/organizations-dropdown/`)
        .then(response => {
          this.organizationOptions = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    },

    clear() {
      this.resetFilter({ organizations: 'organizations' });
      this.selectedOrganizations = [];
    },

    limitText(count) {
      return `...`;
    },
  },
};
</script>

<style scoped lang="scss">
.multiselect {
  font-size: var(--12px);
}

.multiselect__tags {
  padding: 5px 40px 0px 3px;
}

.multiselect__tag {
  padding: 0px;
  font-size: var(--9px);
}

.multiselect__input {
  font-size: var(--9px);
}
</style>
