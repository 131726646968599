<template>
  <component :is="component" :additional-data="additionalData" class="pt-20 pb-20" />
</template>

<script>
import { markRaw } from 'vue';
export default {
  name: 'PopupGeneralComponent',

  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },

    additionalData: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  computed: {
    component() {
      return markRaw(this.data);
    },
  },
};
</script>
