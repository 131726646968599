import applicationActions from './actions';
import applicationGetters from './getters';
import applicationMutations from './mutations';
import applicationState from './state';
const applicationModule = {
  state: applicationState,
  getters: applicationGetters,
  mutations: applicationMutations,
  actions: applicationActions,
  namespaced: true,
};
export default applicationModule;
