<template>
    <div v-if="feasibilityChks !== null">  
    <div class="name-header">
      <img
        v-if="feasibilityChks === true"
        class="feasibility-check"
        src="@/assets/icons/comparison/traffic_light_check.svg"
        alt="Feasibility Check"
        height="25"
        width="25"
        style="margin-bottom: 8px; padding-left: 2px"
      />
      <img
      v-if="feasibilityChks === false"
        class="feasibility-check"
        src="@/assets/icons/comparison/traffic_light_exclamation_mark.svg"
        alt="Feasibility Check"
        height="25"
        width="25"
        style="margin-bottom: 8px; padding-right: 2px"
      />
      <span style="margin-top: 7px">{{ name }}</span>
    </div>
    <div style="font-size: var(--10px); margin-left: 25px; color: grey; margin-bottom: 2px">{{ additional }}</div>
    <span
      v-if="name == 'Gap Size' && feasibility?.min?.val == 100000"
      style="
        margin-left: 25px;
        background-color: var(--spark-check-green);
        margin-right: auto;
        margin-bottom: 20px;
        padding: 2px 5px;
        border-radius: var(--border-radius);
        color: white;
      "
    >
      No Gaps Detected
    </span>
    <div v-if="!name != 'Gap Size' && feasibility?.min?.val != 100000" class="bar">
      <FeasibilityMarkedBarApex
        :show-bar="showBar"
        :feasibility="feasibility"
        :redesign-suggestion="redesignSuggestion"
      />
    </div>
  </div>
</template>

<script>
import FeasibilityMarkedBarApex from './FeasibilityMarkedBarApex.vue';

import { totalFeasFromValues } from '@/helpers.js';

export default {
  name: 'FeasibilityEntry',

  components: { FeasibilityMarkedBarApex },

  props: { name: String, showBar: Boolean, feasibility: Object, additional: String },

  data() {
    return {
      redesignSuggestions: {
        'Fits Machine': { max: 'The part is too big for the selected manufacturing machine.', min: '' },
        'Fits Process': {
          max: 'The part is too big for the selected manufacturing process.',
          min: 'The part is too small for the selected manufacturing process.',
        },

        'Gap Size': {
          min: 'The part has gaps that fall below the minimum acceptable gap size of the process.',
          max: '',
        },

        'Support Removability': {
          min: 'There are faces that need support and are badly accessible.',
          max: '',
        },

        'Wall Thickness': {
          min: 'The part has walls that fall below the minimum acceptable wall thickness of the process.',
          max: '',
        },
      },
    };
  },

  computed: {
    feasibilityChks() {
      if (this.feasibility === undefined) {
        return null;
      } else {
        return totalFeasFromValues(this.feasibility.max?.chk, this.feasibility.min?.chk);
      }
    },

    redesignSuggestion() {
      return this.redesignSuggestions[this.name];
    },
  },
};
</script>

<style lang="scss" scoped>
.name-header {
  display: flex;
  align-items: start;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: var(--8px);
  color: grey;
}

.descriptive-text {
  display: flex;
  flex-direction: row;
  padding-left: 33px;
  margin-bottom: 10px;
}

.bar {
  margin-top: -20px;
  margin-bottom: -15px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
</style>
