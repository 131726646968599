<template>
  <div v-if="data">
    <apexchart type="bar" :options="options" :series="series" width="100%" height="400"/>
  </div>
</template>

<script>
import { floatFormat } from '@/helpers.js';

export default {
  name: 'BarChart',

  props: {
    data: Array,
    detail: String,
    reload: Number,
  },

  data() {
    return {
      series: [],
      options: {
        labels: [],
        chart: {
          type: 'bar',
          animations: {
            enabled: true,
          },

          fontFamily: 'Poppins, Arial, sans-serif',
          toolbar: {
            show: true,
            tools: {
              download: '<i class="fas fa-download"></i>',
            },
          },
        },

        tooltip: {
          custom: function ({  seriesIndex, dataPointIndex, w }) {
            var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            return (
              '<div style="margin:3px;"><p style="margin-bottom:2px;"><b>' +
              data.x +
              '</b>&nbsp;' +
              data.y +
              '</p>' +
              '<p style="margin-bottom:2px;" ><b>Percentage</b>&nbsp;' +
              floatFormat(data.percentage, 0) +
              '&nbsp;%</p></div>'
            );
          },
        },

        colors: [
          function ({  dataPointIndex, w }) {
            if (w.globals.initialSeries[0].data[dataPointIndex] != undefined)
              return w.globals.initialSeries[0].data[dataPointIndex]['color'];
          },
        ],

        dataLabels: {
          enabled: false,
        },

        //   formatter: function (value) {
        //     return parseFloat(value).toFixed(0);
        //   },
        //   offsetX: 10,
        //   textAnchor: "end",
        //   style: {
        //     fontSize: "10px",
        //     fontFamily: "Poppins",
        //     fontWeight: 300,
        //   },
        //   // colors: ["#00293c", "#005364", "#007f7b", "#36ac7e", "#96d575", "#fef671"],
        // },
        legend: {
          show: false,
          position: 'bottom',
        },

        noData: {
          text: 'Data not found',
        },

        plotOptions: {
          bar: {
            borderRadius: 2,
            distributed: true,
          },
        },
      },
    };
  },

  watch: {
    data: {
      handler(newVal) {
        if (newVal !== null) {
          this.series = [{ name: this.detail, data: newVal[1] }];
        }
      },

      immediate: true,
    },
  },
};
</script>
