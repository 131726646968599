<template>
  <div class="cursor-pointer">
    <i v-if="supplierChecked" class="far fa-check-square text-15" />
    <i v-else class="far fa-square text-15" />
  </div>
</template>

<script>
export default {
  name: 'ActionCheckbox',

  props: {
    checkedSuppliers: {
      type: Object,
      default() {
        return {};
      },
    },

    supplierId: { type: String, default: '' },
  },

  data() {
    return {
      supplierChecked: false,
    };
  },

  watch: {
    checkedSuppliers: {
      handler() {
        this.getCheckboxState();
      },

      deep: true,
      immediate: true,
    },
  },

  methods: {
    getCheckboxState() {
      if (Object.prototype.hasOwnProperty.call(this.checkedSuppliers, this.supplierId)) {
        this.supplierChecked = true;
      } else {
        this.supplierChecked = false;
      }
    },
  },
};
</script>
