const supplierMutations = {
  setUsers(state, users) {
    state.users = users;
  },

  setIsSupplier(state, isSupplier) {
    state.isSupplier = isSupplier;
  },

  setSelectedSalesRep(state, salesRep) {
    state.selectedSalesRep = salesRep;
  },

  setPublicSuppliers(state, suppliers) {
    state.publicSuppliers = suppliers;
  },

  setCustomersSuppliers(state, suppliers) {
    state.customersSuppliers = suppliers;
  },

  setSelectedSuppliers(state, suppliers) {
    state.selectedSuppliers = suppliers;
  },
};
export default supplierMutations;
