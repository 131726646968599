<template>
  <div class="m-16 overflow-scroll max-h-[400px]">
    <table v-if="part.process_proposal != undefined" class="text-11 w-full">
      <tbody>
        <tr v-for="(result, prcUid) in proposalsFilteredSorted" :key="prcUid" class="h-24 even:bg-gray-50">
          <td class="pl-8">{{ user.organization.process_synonyms[prcUid].prc_acronym }}</td>
          <td>{{ user.organization.process_synonyms[prcUid].prc_name }}</td>
          <td><TrafficLightBox :value="result.score" /></td>
        </tr>
      </tbody>
    </table>
    <div v-else>Proposals for this part are not calculated.</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import TrafficLightBox from '@/components/Reusable/TrafficLightBox.vue';

export default {
  name: 'TechnologyCard',

  components: {
    TrafficLightBox,
  },

  data() {
    return {};
  },

  computed: {
    ...mapState(['part', 'user']),
    proposalsFilteredSorted() {
      let filteredProcesses = Object.keys(this.part.process_proposal)
        .filter(key => this.part.process_proposal[key].score !== null)
        .reduce((result, key) => {
          result[key] = this.part.process_proposal[key];
          return result;
        }, {});

      let sortedKeys = Object.keys(filteredProcesses).sort((a, b) => {
        return filteredProcesses[b].score - filteredProcesses[a].score;
      });

      let sortedData = sortedKeys.reduce((result, key) => {
        result[key] = filteredProcesses[key];
        return result;
      }, {});

      return sortedData;
    },
  },
};
</script>

<style lang="scss" scoped></style>
