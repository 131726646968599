<template>
  <SparkButton variant="secondary" class="spark-btn" @click="addNewBatch"><i class="fas fa-plus" /> Add</SparkButton>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import SparkButton from '../../../../components/SparkComponents/SparkButton.vue';

import BatchFileDrop from './BatchFileDrop.vue';

export default {
  name: 'AddBatchButton',

  components: { SparkButton },

  computed: {
    ...mapState(['part', 'popup']),
    ...mapState('application', ['axiosInstance']),
  },

  watch: {
    popup(popup) {
      if (popup?.key === this.$options.name) {
        if (popup?.clicked === 'ok') {
          this.triggerPopup('');
        } else if (popup?.clicked === 'cancel' || popup?.clicked === 'close') {
          this.triggerPopup('');
        }
      }
    },
  },

  methods: {
    ...mapMutations(['triggerPopup']),

    addNewBatch() {
      this.triggerPopup({
        key: this.$options.name,
        show: true,
        title: '[BETA] Add New Group ',
        type: 'component',
        buttons: true,
        buttonContent: [
          { text: 'Cancel', type: 'plain', value: 'cancel' },
          { text: 'Ok', type: 'secondary', value: 'ok' },
        ],
        data: BatchFileDrop,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.spark-btn {
  width: 100px;
  align-self: flex-end;
  margin-right: unset;
  &:hover {
    cursor: pointer;
  }
}
</style>
