<template>
  <div class="bg-white ml-10 rounded-8 border border-gray-50">
    <table class="w-full">
      <thead>
        <tr class="text-gray-500 text-11 border-b border-gray-100 align-middle h-48">
          <th class="pl-8">
            <span class="cursor-pointer" @click.stop="toggleMasterSelect()">
              <i v-if="allSelected" class="far fa-check-square text-15" />
              <i v-else class="far fa-square text-15" />
            </span>
          </th>
          <th>&nbsp;</th>
          <th>Supplier</th>
          <th>E-Mail</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="supplier in selectedSuppliers" :key="supplier">
          <tr
            class="text-11 align-middle h-40 border-b border-gray-100 hover:bg-gray-50"
            :class="{ 'bg-gray-50': supplier.expanded, 'cursor-pointer': supplier.priceInfo?.length }"
            @click="toggleSupplierExpansion(supplier)"
          >
            <td class="pl-8">
              <ActionCheckbox
                :checked-suppliers="checkedSuppliers"
                :supplier-id="supplier.uid"
                @click.stop="toggleCheckSupplier(supplier.uid)"
              />
            </td>
            <td>
              <img class="supplier-logo" :src="supplier.logoUrl" alt="supplier-logo" />
            </td>
            <td>{{ supplier.name }}</td>
            <td>{{ supplier.sales_contact?.email }}</td>
            <td>
              <SparkButtonIcon icon="fa-regular fa-paper-plane" @click.stop="shareSingleRFQViaMail(supplier.uid)" />
            </td>
          </tr>
          <tr v-if="supplier.expanded">
            <td colspan="5">
              <table class="w-full mx-32">
                <thead>
                  <tr class="text-gray-500 text-11 border-b border-gray-100 align-middle h-48">
                    <th>Process</th>
                    <th>Total Price Estimate</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="price in supplier.priceInfo"
                    :key="price.process_chain_template_uid"
                    class="text-gray-500 text-11 border-b border-gray-100 align-middle h-32"
                  >
                    <td>{{ price.process_chain_template_name }}</td>
                    <td
                      v-if="
                        $formatTwoDecimalPlaces(price.target_price_min) ===
                        $formatTwoDecimalPlaces(price.target_price_max)
                      "
                      class="font-bold"
                      v-text="`€ ${$formatTwoDecimalPlaces(price.target_price_min)}`"
                    />
                    <td
                      v-else
                      class="font-bold"
                      v-text="
                        `€ ${$formatTwoDecimalPlaces(price.target_price_min)} - € ${$formatTwoDecimalPlaces(
                          price.target_price_max
                        )}`
                      "
                    />
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import SparkButtonIcon from '../../SparkComponents/SparkButtonIcon.vue';

import ActionCheckbox from './components/ActionCheckbox.vue';

export default {
  name: 'SupplierPanelFrame',

  components: {
    SparkButtonIcon,
    ActionCheckbox,
  },

  data() {
    return {
      suppliers: [],
      allSelected: false,
      checkedSuppliers: {},
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance', 'axiosInstanceFileUpload']),
    ...mapState(['part', 'triggerRFQ']),
    ...mapState('supplier', ['selectedSuppliers']),
  },

  watch: {
    allParts: {
      handler() {
        const updatedCheckedParts = {};
        for (let partId in this.checkedParts) {
          const part = this.allParts.find(part => part.part_id === partId);
          if (part) {
            updatedCheckedParts[partId] = true;
          }
        }

        setTimeout(() => {
          this.checkedParts = updatedCheckedParts;
        }, 300);
      },
    },

    checkedParts: {
      handler() {
        if (Object.keys(this.checkedParts).length === 0) {
          this.allSelected = false;
        }
      },

      deep: true,
    },

    triggerRFQ(newVal) {
      if (newVal != 0) {
        this.shareRFQViaMail();
      }
    },
  },

  async mounted() {
    await this.fetchSelectedSuppliers();
  },

  methods: {
    ...mapActions('supplier', ['fetchSelectedSuppliers', 'updateSupplierExpansion']),

    toggleMasterSelect() {
      this.allSelected = !this.allSelected;
      if (this.allSelected) {
        this.selectedSuppliers.map(supplier => {
          this.checkedSuppliers[supplier.uid] = true;
        });
      } else {
        this.checkedSuppliers = {};
      }
    },

    toggleCheckSupplier(uid) {
      if (uid in this.checkedSuppliers) {
        delete this.checkedSuppliers[uid];
      } else {
        this.checkedSuppliers[uid] = true;
      }
    },

    shareSingleRFQViaMail(uid) {
      this.checkedSuppliers[uid] = true;
      this.shareRFQViaMail();
    },

    async shareRFQViaMail() {
      this.$root.notify('success', 'RFQ Mail', 'Generating content', 3000);

      let formData = { uids: Object.keys(this.checkedSuppliers) };
      this.axiosInstance
        .post('/api/v1/part/' + this.part.part_id + '/mail-request-for-quotation/', formData)
        .then(response => {
          // take MailTo content from response data and put it in a fresh mail
          window.open(response.data);
        })
        .catch(error => {
          this.$root.notifyError('RFQ Mail', error);
        });

      this.checkedSuppliers = {};
      this.allSelected = false;
    },

    toggleSupplierExpansion(supplier) {
      if (!supplier.priceInfo?.length) return;
      this.updateSupplierExpansion(supplier.uid);
    },
  },
};
</script>

<style>
.supplier-logo {
  height: 30px;
}
</style>
