<template>
  <SparkModal :model-value="show" @update:model-value="close">
    <template #header>
      <div class="text-19 font-bold" v-text="'Add scalar value'" />
    </template>

    <template #default>
      <div class="flex items-center justify-between mb-20">
        <div class="font-bold text-15" v-text="'Value'" />
        <SparkInput v-model="scalarValue" type="number" custom="!w-200 !mx-auto my-16" name="scalar-value" />
      </div>
      <div class="flex justify-end gap-16">
        <SparkButton variant="outlined" @click="close">
          <div v-text="'Cancel'" />
        </SparkButton>
        <SparkButton custom="w-80" variant="secondary" @click="addScalarValue">
          <div v-text="'Add'" />
        </SparkButton>
      </div>
    </template>
  </SparkModal>
</template>

<script>
import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';

export default {
  name: 'ChangeRoleModal',

  components: { SparkModal, SparkButton, SparkInput },

  props: {
    show: { type: Boolean },
    type: { type: String, required: true },
  },

  emits: ['close', 'value'],

  data() {
    return {
      scalarValue: null,
    };
  },

  methods: {
    close() {
      this.$emit('close');
    },

    addScalarValue() {
      this.$emit('value', { type: this.type, value: this.scalarValue });
      this.close();
    },
  },
};
</script>
