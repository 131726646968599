<template>
  <div v-if="pipelineStatusesExist">
    <div v-if="diabledState" class="disabled-state">{{ part.pipeline_status_name }}</div>
    <!--
 <div v-else>
      <SparkBadge>{{ part.pipeline_status_name }}</SparkBadge>
    </div>
     -->
    <div v-else :title="lockedForUser ? lockedTitle : ''">
      <DropdownMenu
        close-click-style="always"
        :list-options="!lockedForUser && !externalUser ? pipelineList : []"
        custom
        @update-option="choosePipelineStatus($event)"
      >
        <SparkBadge>{{ part.pipeline_status_name }}<i class="fas fa-caret-down pl-8" /></SparkBadge>
      </DropdownMenu>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import SparkBadge from '../../SparkComponents/SparkBadge.vue';

import DropdownMenu from '@/components/Reusable/DropdownMenu.vue';

export default {
  name: 'Status',

  components: {
    DropdownMenu,
    SparkBadge,
  },

  computed: {
    ...mapState(['part', 'pipeline', 'lockedForUser', 'user']),
    ...mapState('application', ['lockedTitle', 'axiosInstance']),

    diabledState() {
      return this.user.is_external || (this.userIsPowerUserOrUser && this.part.creator !== this.user.username);
    },

    userIsPowerUserOrUser() {
      return this.user.user_role === 'poweruser' || this.user.user_role === 'user';
    },

    pipelineStatusesExist() {
      return this.pipelineList != undefined || this.pipelineList?.length != 0;
    },

    externalUser() {
      return this.user.is_external;
    },

    pipelineList() {
      let pipelineList = [];
      if (this.pipeline !== undefined) {
        Object.keys(this.pipeline).forEach(key => {
          pipelineList.push({
            text: this.pipeline[key].name,
            value: this.pipeline[key].uid,
            disabled: false,
          });
        });
        return pipelineList;
      } else {
        return undefined;
      }
    },
  },

  methods: {
    ...mapMutations(['updatePart', 'setPartPipelineStatus']),

    choosePipelineStatus(event) {
      this.setPartPipelineStatus(event.text);
      let formData = { pipeline_status: event.value };
      this.axiosInstance.put(`/api/v1/part/${this.part.part_id}/`, formData).catch(error => {
        console.log(error);
      });
    },
  },
};
</script>
