<template>
  <div class="range-slider">
    <div class="slider-track" />
    <input
      v-model.number="minValue"
      type="range"
      :min="min"
      :max="max"
      :step="step"
      class="min-slider"
      @input="updateValues"
    />
    <input
      v-model.number="maxValue"
      type="range"
      :min="min"
      :max="max"
      :step="step"
      class="max-slider"
      @input="updateValues"
    />

    <div class="slider-labels">
      <span class="min-label" :style="{ left: `${((minValue - min) / (max - min)) * 100}%` }">
        {{ minValue }}
      </span>
      <span class="max-label" :style="{ left: `${((maxValue - min) / (max - min)) * 100}%` }">
        {{ maxValue }}
      </span>
    </div>

    <div class="range-values">
      <span>{{ min }}</span>
      <span>{{ max }}</span>
    </div>
    <!--
 <div class="text-13">
      <span>Selected Range: </span>
      <span>{{ minValue }}&nbsp; - &nbsp;</span>
      <span>{{ maxValue }}</span>
    </div> 
-->
  </div>
</template>

<script>
export default {
  name: 'SparkSlider',

  props: {
    min: {
      type: Number,
      default: 0,
    },

    max: {
      type: Number,
      default: 100,
    },

    step: {
      type: Number,
      default: 1,
    },

    defaultMin: {
      type: Number,
      default: 25,
    },

    defaultMax: {
      type: Number,
      default: 75,
    },

    type: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      minValue: this.defaultMin,
      maxValue: this.defaultMax,
    };
  },

  watch: {
    minValue: 'updateValues',
    maxValue: 'updateValues',
  },

  methods: {
    updateValues() {
      if (this.minValue > this.maxValue) {
        const temp = this.minValue;
        this.minValue = this.maxValue;
        this.maxValue = temp;
      }
      this.$emit('update:modelValue', [this.type, this.minValue, this.maxValue]);
    },
  },
};
</script>

<style scoped>
.range-slider {
  position: relative;
  width: 400px;
  height: 40px;
  padding: 20px 0;
  margin-bottom: 70px;
}

.slider-track {
  position: absolute;
  top: 63%;
  transform: translateY(-50%);
  width: 100%;
  height: 8px;
  background-color: #ddd;
}

input[type='range'] {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  -webkit-appearance: none;
  z-index: 2;
  background: none;
}

input[type='range']::-webkit-slider-thumb {
  pointer-events: auto;
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #b8ce45;
  cursor: pointer;
}

input[type='range']::-moz-range-thumb {
  pointer-events: auto;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #b8ce45;
  cursor: pointer;
}

.range-values {
  display: flex;
  justify-content: space-between;
  margin-top: -20px;
  font-size: 13px;
  color: gray;
}

.slider-labels {
  position: absolute;
  width: 100%;
  top: 100%;
}

.min-label,
.max-label {
  position: absolute;
  transform: translateX(-50%);
  background-color: #b8ce45;
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
}
</style>
