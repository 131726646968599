<template>
  <div class="flex-col">
    <h4>Customizable Reports</h4>
    <table>
      <tr>
        <td class="align-top">
          <div class="flex-col justify-start">
            <div class="mb-20">Download default template</div>

            <SparkButton class="mb-10" variant="outlined" @click="downloadDefaultTemplateDocx('part')">
              <slot
                ><i class="fas fa-cube mr-4" />
                <p class="text-9">Part template</p></slot
              >
            </SparkButton>

            <SparkButton variant="outlined" @click="downloadDefaultTemplateDocx('part_list')">
              <slot
                ><i class="fas fa-cubes mr-4" />
                <p class="text-9">Part List template</p></slot
              >
            </SparkButton>
          </div>
        </td>
        <td class="align-top">Edit the template to your needs.</td>
        <td class="align-top">
          <div>
            <div class="mb-20">Upload the edited template</div>
            <div class="spark-new-input-group">
              <SparkInput v-model="templatePostName" custom="!my-16" name="report-name" label="Template Name" />
            </div>

            <div class="spark-new-input-group">
              <SparkInput
                v-model="templatePostDefaultFileName"
                name="template-file-name"
                label="Template File Name"
                readonly
              />
            </div>
            <SparkSelect v-model="documentType" name="report-type" :options="options" label="Select a document type" />
            <input id="fileinput" type="file" accept=".docx" @change="updateCurrentUploadTemplateFile" />
            <SparkButton class="mt-8 !w-[200px]" small variant="secondary" @click="createTemplate()">
              <div v-text="'Upload'" />
            </SparkButton>
          </div>
        </td>
      </tr>
    </table>

    <div v-if="noTemplatesExisting === null">
      <img class="h-20 w-20" src="@/assets/img/loading.gif" />
    </div>

    <div v-if="noTemplatesExisting === false">
      <table class="spark-table">
        <tr>
          <th>Document Type</th>
          <th>Name</th>
          <th>File Name</th>
          <th>User</th>
          <th>&nbsp;</th>
        </tr>
        <tr v-for="template in templates" :key="template">
          <td>
            <div v-if="template.doc_type === 'part'"><i class="fas fa-cube mr-4" /> Part</div>
            <div v-if="template.doc_type === 'part_list'"><i class="fas fa-cubes mr-4" />Part List</div>
          </td>
          <td>{{ template.name }}</td>
          <td>{{ template.default_file_name }}</td>
          <td>{{ template.creator_name }}</td>
          <td>
            <i class="table-button fas fa-file-download" @click="downloadSpecificTemplateDocx(template)" />
            <!-- <i class="table-button far fa-edit" @click="updateSpecificTemplate(template)"/> -->
            <i class="table-button far fa-trash-alt" @click="deleteSpecificTemplateDocx(template)" />
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
import { mapState } from 'vuex';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import SparkSelect from '@/components/SparkComponents/SparkSelect.vue';

export default {
  name: 'CustomizableReportSettings',

  components: { SparkSelect, SparkInput, SparkButton },

  data() {
    return {
      templatePostName: '',
      currentUploadTemplateFile: null,
      templates: null,
      noTemplatesExisting: null,
      documentType: 'part',
      options: [
        { value: 'part', label: 'Part' },
        { value: 'part_list', label: 'Part List' },
      ],
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance', 'axiosInstanceFileUpload']),

    templatePostDefaultFileName() {
      return this.templatePostName.replace(/ /g, '_').toLowerCase();
    },
  },

  watch: {
    documentType() {
      // console.log(this.documentType);
    },
  },

  mounted() {
    this.getTemplateDocxList();
  },

  methods: {
    // Download Default Template File
    async downloadDefaultTemplateDocx(type) {
      this.documentType = type;
      this.axiosInstance
        .get(`api/v1/doc-default-template/${type}/`, {
          responseType: 'blob',
        })
        .then(response => {
          if (type === 'part') {
            saveAs(response.data, 'spark_template_v4.docx');
          } else if (type === 'part_list') {
            saveAs(response.data, 'spark_list_template_v0.docx');
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

    // POST TemplateApi
    async createTemplate() {
      if (!this.currentUploadTemplateFile) {
        this.$root.notify('error', 'No file selected', 3000);
        return;
      }
      if (!this.templatePostName) {
        this.$root.notify('error', 'Please enter a Template Name', 3000);
        return;
      }
      if (!this.templatePostDefaultFileName) {
        this.$root.notify('error', 'Please Enter a Default File Name', 3000);
        return;
      }
      let formData = {
        file: this.currentUploadTemplateFile,
        name: this.templatePostName,
        default_file_name: this.templatePostDefaultFileName,
      };
      this.axiosInstanceFileUpload.post(`api/v1/doc-template-upload/${this.documentType}/`, formData).then(() => {
        this.templatePostName = '';
        this.templatePostDefaultFileName = '';
        this.currentUploadTemplateFile = null;
        document.getElementById('fileinput').value = '';
        this.getTemplateDocxList();
      });
    },

    async updateCurrentUploadTemplateFile(e) {
      this.currentUploadTemplateFile = e.target.files[0];
    },

    // Get Template List
    async getTemplateDocxList() {
      this.axiosInstanceFileUpload.get('api/v1/doc-template-list/').then(response => {
        this.templates = response.data;
        if (Object.keys(this.templates).length === 0) {
          this.noTemplatesExisting = true;
        } else {
          this.noTemplatesExisting = false;
        }
      });
    },

    // Download Specific Template File
    async downloadSpecificTemplateDocx(template) {
      this.axiosInstance
        .get(`api/v1/doc-template-file/${template.uid}/`, {
          responseType: 'blob',
        })
        .then(response => {
          saveAs(response.data, template.default_file_name);
        })
        .catch(error => {
          console.log(error);
        });
    },

    // DELETE Specific Template
    async deleteSpecificTemplateDocx(template) {
      this.axiosInstanceFileUpload.delete(`api/v1/doc-template/${template.uid}/`).then(() => {
        this.getTemplateDocxList();
        this.$root.notify('success', 'Template deleted', 3000);
      });
    },

    // GET Specific Template
    //    async getSpecificTemplateDocx() {
    //      this.axiosInstanceFileUpload.get(`api/v1/doc-template/${this.templateGetUid}/`).then(response => {
    //      console.log(response.data);
    //   });
    // },

    // PUT Specific Template
    // async updateSpecificTemplate(template) {
    //   // was genau soll man hier ändern können, man müsste das file ändern? oder nur den
    //   let formData = {
    //     name: 'sfnlf',
    //     default_file_name: 'file.docx',
    //   };
    //   this.axiosInstanceFileUpload.put(`api/v1/doc-template/${template.uid}/`, formData).then(response => {
    //     console.log(response.data);
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.flex-col {
  display: flex;
  flex-direction: column;
  font-size: var(--11px);
}
.flex-row {
  display: flex;
  flex-direction: row;
}

.link {
  color: Var(--spark-darkgreen);
  text-decoration: underline;
  cursor: pointer;
  margin-right: 10px;
}
h4 {
  margin-bottom: 15px;
  font-size: var(--15px);
}

.table-column {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wizard {
  border: 1px solid var(--light-color);
  padding: 10px;
  border-radius: 5px;
}

.spark-table {
  margin-top: 20px;
}
</style>
