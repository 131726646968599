<template>
  <div class="materials">
    <MaterialTable />
  </div>
</template>

<script>
import MaterialTable from './components/MaterialTable.vue';

export default {
  name: 'Materials',

  components: { MaterialTable },
};
</script>

<style lang="scss" scoped>
.materials {
  max-width: 100%;
  overflow: hidden;
  overflow-x: auto;
  height: calc(100vh + 300px);
  background-color: white;
}
</style>
