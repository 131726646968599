<template>
  <ErrorPage
    title="Oooops"
    description="Something went wrong with your request. Please contact us, if this happens again."
    back-to="/start"
    back-to-title="Back home"
    :image-name="errorImage"
    image-description="Something Went Wrong"
  />
</template>

<script>
import ErrorImage from '@/assets/img/error_pages/wrong.png';
import ErrorPage from '@/components/Reusable/ErrorPage.vue';

export default {
  name: 'SomethingWentWrong',

  components: { ErrorPage },

  computed: {
    errorImage() {
      return ErrorImage;
    },
  },
};
</script>
