<template>
  <div class="organization-settings">
    <div>
      <h3 class="card-title">Organization Settings</h3>
      <p class="warning">
        These settings will affect your entire Organization. Please be careful with editing and deleting. There may be
        information loss.
      </p>
    </div>
    <div class="grid-container">
      <div class="card box11">
        <CostSettings />
      </div>
      <div class="card">
        <Categories />
      </div>
      <div class="card">
        <EnergySettings />
      </div>
      <div class="card"><SalesRepresentative /></div>
      <div class="card">
        <OrganizationGoalSettings />
      </div>

      <div v-show="boolWhiteLabel" class="card">
        <LogoSettings @make-color-settings="makeColorSettings" />
      </div>
      <div v-show="boolWhiteLabel" class="card">
        <ColorSettings :bool-make-color-settings="boolMakeColorSettings" />
      </div>
      <div class="card"><CustomizableReportSettings /></div>
      <div class="card"><PipelineSettings /></div>
      <div class="card"><ProcessSynonyms /></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Categories from './Categories.vue';
import ColorSettings from './ColorSettings.vue';
import CostSettings from './CostSettings.vue';
import CustomizableReportSettings from './CustomizableReportSettings.vue';
import EnergySettings from './EnergySettings.vue';
import LogoSettings from './LogoSettings.vue';
import OrganizationGoalSettings from './OrganizationGoalSettings.vue';
import PipelineSettings from './PipelineSettings.vue';
import ProcessSynonyms from './ProcessSynonyms.vue';
import SalesRepresentative from './SalesRepresentative.vue';

export default {
  name: 'OrganizationSettings',

  components: {
    CostSettings,
    Categories,
    ColorSettings,
    LogoSettings,
    EnergySettings,
    ProcessSynonyms,
    OrganizationGoalSettings,
    CustomizableReportSettings,
    PipelineSettings,
    SalesRepresentative,
  },

  beforeRouteLeave() {
    this.$getUser(this.$keycloak, this.axiosInstance, this.$store);
  },

  data() {
    return {
      boolMakeColorSettings: false,
    };
  },

  computed: {
    ...mapState(['user']),
    ...mapState('application', ['axiosInstance']),

    boolWhiteLabel() {
      return this.user.organization.allow_white_label;
    },

    axiosInstance() {
      return this.axiosInstance;
    },
  },

  methods: {
    makeColorSettings() {
      this.boolMakeColorSettings = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-container {
  width: 100%;
  overflow-y: scroll;
  display: grid;
  grid-gap: 4px;
}

@media only screen and (min-width: 981px) {
  .grid-container {
    grid-template-columns: 1fr 1fr;
  }
}

:deep(.app) {
  padding-bottom: 0 !important;
}

.warning {
  background: var(--spark-delete);
  border-radius: 3px;
  padding: 5px;
}

.card {
  width: 100%;
}
</style>
