<template>
  <div class="flex items-baseline justify-between">
    <div v-if="fieldName !== ''" :colspan="fieldType == 'validator' ? 2 : 1">
      <div class="inline-block text-11" :style="[fieldStyle, fieldType === 'validator' ? 'padding-right: -20px' : '']">
        {{ fieldName }}
      </div>
    </div>
    <div v-if="fieldType == 'validator'">
      <FeedbackValueValidatorField
        :feedback-value="feedbackValue"
        :field-unit="fieldUnit"
        :no-heading="noHeading"
        :field-style="fieldStyle"
        :field-name="fieldName"
        @feedback-value-changed="feedbackValueChanged($event)"
        @input-focus-active="inputFocusActive($event)"
      />
    </div>
    <!--
      <td v-else-if="fieldType == 'hoursMinutes'" colspan="3" class="text-right">
        <FeedbackValueHoursMinutes
        :feedback-value="feedbackValue"
        :field-unit="fieldUnit"
        @feedback-value-changed="feedbackValueChanged($event)"
        @input-focus-active="inputFocusActive($event)"
        />
      </td> 
      -->
    <div v-else-if="fieldType == 'normal'" :colspan="colspan" class="text-right">
      <FeedbackValueNormal
        :feedback-value="feedbackValue"
        :field-unit="fieldUnit"
        :field-style="fieldStyle"
        :field-label="fieldLabel"
        @feedback-value-changed="feedbackValueChanged($event)"
        @input-focus-active="inputFocusActive($event)"
      />
    </div>
  </div>
</template>

<script>
// import FeedbackValueHoursMinutes from '@/components/Reusable/FeedbackValues/FeedbackValueHoursMinutes.vue';
import FeedbackValueNormal from '@/components/Reusable/FeedbackValues/FeedbackValueNormal.vue';
import FeedbackValueValidatorField from '@/components/Reusable/FeedbackValues/FeedbackValueValidatorField.vue';

export default {
  name: 'FeedbackValue',

  components: {
    FeedbackValueValidatorField,
    // FeedbackValueHoursMinutes,
    FeedbackValueNormal,
  },

  props: {
    feedbackValue: { type: [Object, String, Number], required: true }, // an object with .user & .calculated entries or a plain value if no validator field (e.g. for time)
    fieldName: { type: String, default: '' },
    fieldLabel: { type: String, default: '' },
    fieldUnit: { type: String, default: '' },
    fieldStyle: { type: String, default: '' },
    fieldType: { type: String, default: 'validator' }, // Validator fields are visible feedback fields, whereas non-validator fields are simple "edit fields" from a users perspective, while they are handeled the same way in the backend
    indentType: { type: Number, default: 1 },
    colspan: { type: Number, default: 1 },
    noHeading: { type: Boolean },
  },

  emits: ['input-focus-active', 'feedback-value-changed'],

  data() {
    return {};
  },

  methods: {
    feedbackValueChanged(newFeedbackValue) {
      this.$emit('feedback-value-changed', newFeedbackValue);
    },

    inputFocusActive(event) {
      this.$emit('input-focus-active', event);
    },
  },
};
</script>
