<template>
  <div />
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'StylingColors',

  data() {
    return {
      colorDefault: {
        primary_color_default: '#383e46',
        primary_text_color_default: '#ffffff',
        accent_color_default: '#97ab13',
        accent_text_color_default: '#000000',
      },

      primaryColor: '#383e46',
      primaryTextColor: '#ffffff',
      accentColor: '#97ab13',
      accentTextColor: '#000000',
    };
  },

  computed: {
    ...mapState(['user']),
    organizationColor() {
      // Set organization color, if user and organization exist and white labeling is allowed
      if (this.user !== undefined) {
        if (this.user.organization !== undefined) {
          if (this.user.organization.allow_white_label) {
            return this.user.organization.color;
          }
        }
      }
      return this.colorDefault;
    },
  },

  watch: {
    organizationColor: {
      handler() {
        this.setNewColors();
      },

      immediate: true,
    },
  },

  mounted() {
    this.setNewColors();
  },

  methods: {
    setNewColors() {
      this.setDefaultOrOrganizationColor();
      this.setGlobalStyle();
    },

    setDefaultOrOrganizationColor() {
      Object.entries(this.organizationColor).forEach(entry => {
        let [key, value] = entry;
        let setColor = this.getDefaultOrOrganizationColor(key, value);

        switch (key) {
          case 'primary_color':
            if (setColor === undefined) setColor = this.colorDefault.primary_color_default;
            this.setPrimaryColor(setColor);
            break;
          case 'primary_text_color':
            if (setColor === undefined) setColor = this.colorDefault.primary_text_color_default;
            this.setPrimaryTextColor(setColor);
            break;
          case 'accent_color':
            if (setColor === undefined) setColor = this.colorDefault.accent_color_default;
            this.setAccentColor(setColor);
            break;
          case 'accent_text_color':
            if (setColor === undefined) setColor = this.colorDefault.accent_text_color_default;
            this.setAccentTextColor(setColor);
            break;
          // default:
        }
      });
    },

    getDefaultOrOrganizationColor(key, value) {
      if (value === null || value === undefined || !this.validHexColor(value)) {
        return this.colorDefault[key];
      } else {
        value = value.replace(/\s/g, '');
        return value;
      }
    },

    validHexColor(color) {
      // Checking if the color is a valid hex color.
      return /^#[0-9A-F]{6}$/i.test(color);
    },

    setGlobalStyle() {
      // Sets the currently active color scheme to the document itself, so that it is available as a global variable.
      document.documentElement.style.setProperty('--primary-color', this.primaryColor);
      document.documentElement.style.setProperty(
        '--primary-color-light',
        this.applySaturationToHexColor(
          this.ligthenColor(this.primaryColor, 30),
          10,
          this.ligthenColor(this.primaryColor, 30)
        )
      );
      document.documentElement.style.setProperty(
        '--primary-color-dark',
        this.applySaturationToHexColor(
          this.ligthenColor(this.primaryColor, -30),
          100,
          this.ligthenColor(this.primaryColor, 30)
        )
      );
      document.documentElement.style.setProperty('--primary-text-color', this.primaryTextColor);
      document.documentElement.style.setProperty('--accent-color', this.accentColor);
      document.documentElement.style.setProperty('--accent-text-color', this.accentTextColor);
    },

    ligthenColor(hexColor, magnitude) {
      // A function that takes a hex color value and lightens/darkens it by magnitude.
      hexColor = hexColor.replace(`#`, ``);
      if (hexColor.length === 6) {
        const decimalColor = parseInt(hexColor, 16);
        let r = (decimalColor >> 16) + magnitude;
        r > 255 ? (r = 255) : '';
        r < 0 ? (r = 0) : '';
        let g = (decimalColor & 0x0000ff) + magnitude;
        g > 255 ? (g = 255) : '';
        g < 0 ? (g = 0) : '';
        let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
        b > 255 ? (b = 255) : '';
        b < 0 ? (b = 0) : '';
        return this.rgbToHex(r, g, b);
      } else {
        return hexColor;
      }
    },

    rgbToHexString(c) {
      // Converting a number to a hexadecimal string.
      let hex = c.toString(16);
      return hex.length == 1 ? '0' + hex : hex;
    },

    rgbToHex(r, g, b) {
      return '#' + this.rgbToHexString(r) + this.rgbToHexString(g) + this.rgbToHexString(b);
    },

    hexToRgb(hexColor) {
      // Converting a hex color to rgb.
      let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
    },

    applySaturationToHexColor(hex, saturationPercent, hexDefault) {
      // A function that takes a hex color value and changes it saturation by percent (<50 decrease / >50 increase).
      try {
        if (!/^#([0-9a-f]{6})$/i.test(hex)) {
          throw 'Unexpected color format';
        }

        if (saturationPercent < 0 || saturationPercent > 100) {
          throw 'Unexpected color format';
        }
      } catch (error) {
        console.log(error);
        return hexDefault;
      }

      var saturationFloat = saturationPercent / 100,
        rgbIntensityFloat = [
          parseInt(hex.substr(1, 2), 16) / 255,
          parseInt(hex.substr(3, 2), 16) / 255,
          parseInt(hex.substr(5, 2), 16) / 255,
        ];

      var rgbIntensityFloatSorted = rgbIntensityFloat.slice(0).sort(function (a, b) {
          return a - b;
        }),
        maxIntensityFloat = rgbIntensityFloatSorted[2],
        mediumIntensityFloat = rgbIntensityFloatSorted[1],
        minIntensityFloat = rgbIntensityFloatSorted[0];

      if (maxIntensityFloat == minIntensityFloat) {
        // All colors have same intensity, which means
        // the original color is gray, so we can't change saturation.
        return hex;
      }

      // New color max intensity wont change. Lets find medium and weak intensities.
      var newMediumIntensityFloat,
        newMinIntensityFloat = maxIntensityFloat * (1 - saturationFloat);

      if (mediumIntensityFloat == minIntensityFloat) {
        // Weak colors have equal intensity.
        newMediumIntensityFloat = newMinIntensityFloat;
      } else {
        // Calculate medium intensity with respect to original intensity proportion.
        var intensityProportion =
          (maxIntensityFloat - mediumIntensityFloat) / (mediumIntensityFloat - minIntensityFloat);
        newMediumIntensityFloat =
          (intensityProportion * newMinIntensityFloat + maxIntensityFloat) / (intensityProportion + 1);
      }

      var newRgbIntensityFloat = [],
        newRgbIntensityFloatSorted = [newMinIntensityFloat, newMediumIntensityFloat, maxIntensityFloat];

      // We've found new intensities, but we have then sorted from min to max.
      // Now we have to restore original order.
      rgbIntensityFloat.forEach(function (originalRgb) {
        var rgbSortedIndex = rgbIntensityFloatSorted.indexOf(originalRgb);
        newRgbIntensityFloat.push(newRgbIntensityFloatSorted[rgbSortedIndex]);
      });

      var floatToHex = function (val) {
          return ('0' + Math.round(val * 255).toString(16)).substr(-2);
        },
        rgb2hex = function (rgb) {
          return '#' + floatToHex(rgb[0]) + floatToHex(rgb[1]) + floatToHex(rgb[2]);
        };

      var newHex = rgb2hex(newRgbIntensityFloat);

      return newHex;
    },

    setPrimaryColor(newColor) {
      this.primaryColor = newColor;
    },

    setPrimaryTextColor(newColor) {
      this.primaryTextColor = newColor;
    },

    setAccentColor(newColor) {
      this.accentColor = newColor;
    },

    setAccentTextColor(newColor) {
      this.accentTextColor = newColor;
    },
  },
};
</script>

<style lang="scss"></style>
