<template>
  <div>
    <h5>Categories with text <i class="fas fa-plus-square" @click="showDialogNewComboCategory()" /></h5>
    <div
      v-for="(category, id) in textCategories"
      :key="category.id"
      class="category-row"
      :class="categoryEditMode === id ? 'show-highlight' : ''"
    >
      <input
        id="category"
        v-model="category.label"
        :class="categoryEditMode === id ? 'input-edit-mode' : 'input-view-mode'"
        class="name-input"
        type="text"
        name="name"
        :disabled="categoryEditMode !== id"
        placeholder="Category Name"
      />

      <div v-if="id === categoryEditMode" class="flex-row">
        <SparkCheckbox v-model="category.required" name="required">
          <div class="text-13" v-text="'Required'" />
        </SparkCheckbox>
      </div>

      <div v-else class="flex-row">
        <div class="flex-row">
          <i v-if="category.required" class="fas fa-check category-checked" />
          <i v-else class="fas fa-xmark category-unchecked" />
          <span>required</span>
        </div>
      </div>

      <div v-if="id != categoryEditMode" class="flex-row icons-end">
        <i class="far fa-edit icon-button" @click="categoryInEditMode(id)" />
        <i class="far fa-trash-alt icon-button" @click="deleteCategoryCheck(id, category.label)" />
      </div>
      <div v-else class="flex-row icons-end">
        <i class="fas fa-check icon-button" @click="updateCategory(id, category)" />
      </div>
    </div>

    <div v-if="newCategoryEditMode" class="category-row">
      <input
        id="category"
        v-model="newCategory.category_label"
        class="name-input input-edit-mode"
        type="text"
        name="name"
        placeholder="Category Name"
      />
      <SparkCheckbox v-model="newCategory.required" label="Required" name="required-new" />

      <div class="flex-row icons-end">
        <i class="fas fa-check icon-button" @click="addNewTextCategory" />
        <i class="fas fa-times icon-button" @click="closeNewCategory" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import SparkCheckbox from '@/components/SparkComponents/SparkCheckbox.vue';

export default {
  name: 'PRPTextCategories',

  components: { SparkCheckbox },

  data() {
    return {
      newCategoryEditMode: false,
      categoryEditMode: '',
      newCategory: {
        category_label: '',
        required: false,
      },
    };
  },

  computed: {
    ...mapState(['user', 'popup']),
    ...mapState('application', ['axiosInstance']),
    textCategories() {
      return this.user.organization.prp_text_categories;
    },
  },

  watch: {
    popup(popup) {
      if (popup?.clicked == 'ok') {
        if (popup?.key == this.$options.name) {
          this.deleteCategory(popup.additionalData);
          this.triggerPopup('');
        }
      } else if (popup?.clicked == 'cancel' || popup?.clicked == 'close') {
        this.triggerPopup('');
      }
    },
  },

  methods: {
    ...mapMutations(['triggerPopup']),
    showDialogNewComboCategory() {
      this.newCategoryEditMode = true;
    },

    categoryInEditMode(uid) {
      this.categoryEditMode = uid;
    },

    deleteCategoryCheck(categoryUid, categoryLabel) {
      this.triggerPopup({
        key: this.$options.name,
        show: true,
        title: 'Delete a category',
        message:
          'Do you really want to delete ' +
          categoryLabel +
          ' ? If there is data related to a part. This will be also deleted.',
        additionalData: categoryUid,
        buttons: true,
        buttonContent: [
          { text: 'Cancel', type: 'outlined', value: 'cancel' },
          { text: 'Ok', type: 'secondary', value: 'ok' },
        ],
      });
    },

    closeNewCategory() {
      this.newCategory = {
        category_label: '',
        required: false,
      };
      this.newCategoryEditMode = false;
    },

    deleteCategory(categoryUid) {
      this.axiosInstance.delete(`/api/v1/prp-text-category/${categoryUid}`).then(() => {
        this.$getUser(this.$keycloak, this.axiosInstance, this.$store);
      });
    },

    addNewTextCategory() {
      this.axiosInstance
        .post('api/v1/prp-text-category/', this.newCategory)
        .then(() => {
          this.newCategory = {
            category_label: '',
            required: false,
          };
          this.newCategoryEditMode = false;
          this.$getUser(this.$keycloak, this.axiosInstance, this.$store);
        })
        .catch(() => {});
    },

    updateCategory(uid, category) {
      this.axiosInstance.put(`/api/v1/prp-text-category/${uid}/`, category).then(() => {
        this.$getUser(this.$keycloak, this.axiosInstance, this.$store);
        this.categoryEditMode = '';
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.category-row {
  display: flex;
  font-size: var(--11px);
  align-items: center;
  padding: 5px;
}
.show-highlight {
  padding: 4px;
  border: 1px solid var(--accent-color);
  border-radius: 5px;
}
input {
  border: 1px solid white;
  width: 20%;
  height: 2em;
  margin: 5px 3px 5px 0px;
}
.input-edit-mode {
  border: 1px solid var(--light-color);
}
.input-view-mode {
  border: 1px solid white;
  background-color: white;
  font-weight: bold;
}
.flex-row {
  display: flex;
  flex-wrap: nowrap;
}
.items {
  display: flex;
  flex-wrap: wrap;
}
.name-input {
  min-width: 100px;
}
.item {
  display: flex;
  margin: 2px;
  background-color: #dbd3d8;
  border-radius: 10px;
  padding: 0px 5px;
  white-space: nowrap;
}
.category-checked {
  color: var(--accent-color);
  padding-left: 10px;
}
.category-unchecked {
  color: red;
  padding-left: 10px;
}
.checkbox-item {
  display: flex;
  font-size: var(--10px);
  align-items: center;
  margin-right: 5px;
}
input[type='checkbox'] {
  height: 15px;
  &:hover {
    cursor: pointer;
  }
}
i {
  cursor: pointer;
  margin: 0px 5px;
}
.icons-end {
  justify-self: end;
  margin-left: auto;
  margin-right: 0;
}
span {
  cursor: default;
}
</style>
