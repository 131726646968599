<template>
  <div class="mx-16 z-10">
    <div>Select process chains to be added to comparison.</div>
    <div class="flex my-12 border rounded-8 border-gray-100 p-4">
      <SparkButton
        small
        variant="outlined"
        custom="border-transparent ml-4"
        :style="selectedTab === 'template' ? 'color: white; background-color: gray' : ''"
        @click="switchTabs('template')"
        >From template</SparkButton
      >
      <SparkButton
        custom="border-transparent ml-4"
        small
        variant="outlined"
        :style="selectedTab === 'material' ? 'color: white; background-color: gray' : ''"
        @click="switchTabs('material')"
        >From material</SparkButton
      >
    </div>
    <SuggestionTable v-if="selectedTab === 'template'" />
    <div v-else>
      <ComparisonPageMaterialTable @mat-id="addProcessChain($event)" />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import SparkButton from '../../../SparkComponents/SparkButton.vue';
import ComparisonPageMaterialTable from '../../Material/ComparisonPageMaterialTable.vue';

import SuggestionTable from './SuggestionTable.vue';

export default {
  name: 'CreateProcessChain',

  components: { SuggestionTable, SparkButton, ComparisonPageMaterialTable },

  data() {
    return { selectedTab: 'template' };
  },

  computed: {
    ...mapState(['part']),
    ...mapState('application', ['axiosInstance']),
  },

  methods: {
    ...mapMutations({
      updateStorePart: 'updatePart',
    }),

    switchTabs(selection) {
      this.selectedTab = selection;
    },

    addProcessChain(formData) {
      this.axiosInstance
        .put(`/api/v1/part/${this.part.part_id}/`, formData)
        .then(response => {
          this.updateStorePart(response.data);
        })
        .catch(error => {
          console.log(error);
          this.boolProhibitRapidMaterialChange = false;
          this.$root.notify(
            'warning',
            'Material Selection Error',
            'An unexpected error occured with the material you selected. Please try again or try a different material. Please inform 3D Spark support if the error is persistent.',
            4000
          );
        });
    },
  },
};
</script>
