<template>
  <SparkButtonIcon small icon="" title="Delete File" custom="hover:!bg-primary-100 mr-4" @click="confirmDelete()">
    <img src="@/assets/icons/delete.svg" />
  </SparkButtonIcon>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import SparkButtonIcon from '../../SparkComponents/SparkButtonIcon.vue';

export default {
  name: 'DeleteFile',

  components: {
    SparkButtonIcon,
  },

  props: {
    file: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  emits: ['assetDel'],

  computed: {
    ...mapState(['popup']),
    ...mapState('application', ['axiosInstance']),
  },

  watch: {
    popup(popup) {
      if (popup?.key == this.$options.name) {
        if (popup?.clicked == 'ok') {
          this.deleteFile(popup?.data);
          this.triggerPopup('');
        } else if (popup?.clicked == 'cancel' || popup?.clicked == 'close') {
          this.triggerPopup('');
        }
      }
    },
  },

  methods: {
    ...mapMutations(['triggerPopup', 'updatePart']),
    ...mapMutations('canvas', ['updateReloadCanvas']),

    confirmDelete() {
      this.triggerPopup({
        key: this.$options.name,
        show: true,
        title: 'Delete File',
        message: 'The file ' + this.file.basename + ' will be deleted. Are you sure?',
        buttons: true,
        data: this.file,
        buttonContent: [
          { text: 'Cancel', type: 'outlined', value: 'cancel' },
          { text: 'Delete', type: 'secondary', value: 'ok' },
        ],
      });
    },

    async deleteFile(data) {
      /* delete files from the active part if it is a cad file use different API than for other assets
        update part accordingly*/
      let err = '';
      await this.axiosInstance
        .delete(`/api/v1/part/${data.part_id}/upload-file/`)
        .then(() => {
          this.updatePart({
            basename: '',
            part_vol: null,
            bb_x: 0,
            bb_y: 0,
            bb_z: 0,
            visualization_file: '',
            cad_stat: null,
            cad_upload_progress: null,
          });
          this.updateReloadCanvas(true);
          this.$root.notify('success', 'File deleted', 'The file was successfully deleted.', 3000);
        })
        .catch(error => {
          this.$root.notifyError('Deleting CAD', error);
        });
    },
  },
};
</script>
