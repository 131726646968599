<template>
  <div>
    <div class="inline-block">
      <SparkInput
        v-model.lazy="storedValue"
        type="number"
        name="cost"
        placeholder="0"
        :title="lockedForUser ? lockedTitle : ''"
        :disabled="lockedForUser"
        :label="fieldLabel"
        :custom="'border-white hover:border-gray-100 text-right !h-24 w-120 rounded-4 !appearance-none ' + fieldStyle"
        @change="setNewFeedbackValue()"
        @focus="inputFocusActive(true)"
        @blur="inputFocusActive(false)"
      />
    </div>
    <div class="inline-block" :style="{ unitFieldStyle }">
      {{ fieldUnit }}
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import SparkInput from '../../SparkComponents/SparkInput.vue';
export default {
  name: 'FeedbackValueNormal',

  components: {
    SparkInput,
  },

  props: {
    feedbackValue: [Object, String, Number],
    fieldStyle: { type: String, default: '' },
    fieldUnit: { type: String, default: '' },
    fieldLabel: { type: String, default: '' },
  },

  emits: ['feedback-value-changed', 'input-focus-active'],

  data() {
    return {
      storedValue: '',
      unitFieldStyle: '',
    };
  },

  computed: {
    ...mapState('application', ['lockedTitle']),
    ...mapGetters(['lockedForUser']),
  },

  watch: {
    feedbackValue: {
      handler(feedbackValue) {
        this.setProppedFeedbackValue(feedbackValue);
      },

      immediate: true,
    },

    fieldStyle: {
      handler(fieldStyle) {
        this.setUnitFieldStyle(fieldStyle);
      },
    },
  },

  methods: {
    setProppedFeedbackValue(feedbackValue) {
      if (feedbackValue != undefined) {
        this.storedValue = feedbackValue;
      }
    },

    setUnitFieldStyle(fieldStyle) {
      if (fieldStyle.includes('font-weight: bold')) {
        this.unitFieldStyle = 'font-weight: bold';
      }
    },

    setNewFeedbackValue() {
      this.$emit('feedback-value-changed', this.storedValue);
    },

    inputFocusActive(value) {
      this.$emit('input-focus-active', value);
    },
  },
};
</script>
