<template>
  <div>
    <SparkButton
      title="Saves all process chains as a set of templates. Created profiles can be accessed from the list below."
      :disabled="processChainsNotExist"
      variant="outlined"
      small
      @click="create()"
    >
      <div class="flex text-13 items-center"><i class="far fa-save mr-4" /> Save as Profile</div>
    </SparkButton>
    <AddEditProfileModal
      :show="showAddProfileModal"
      :external-profile="anotherProfileUidIsExternal"
      :profile="formData"
      @save="createAnalysisProfile"
      @close="closeAddProfileModal"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import SparkButton from '../../../SparkComponents/SparkButton.vue';
import AddEditProfileModal from '../modals/AddEditProfileModal.vue';

export default {
  name: 'Profile',

  components: { SparkButton, AddEditProfileModal },

  data() {
    return {
      anotherProfileUidIsExternal: '',
      showAddProfileModal: false,
    };
  },

  computed: {
    ...mapState(['part', 'user', 'analysisProfiles']),
    ...mapState('application', ['axiosInstance']),
    processChainsNotExist() {
      return Object.keys(this.part.process_chains).length == 0;
    },

    formData() {
      return {
        0: {
          db_name: 'name',
          label: 'Name',
          field: 'input-text',
          options: '',
          unit: '',
          required: true,
          value: '',
        },

        1: {
          db_name: 'restriction',
          label: 'Restriction',
          field: 'select',
          options: this.restrictions,
          unit: '',
          required: true,
          value: 'owner',
          validityCheck: { external: '' },
        },
      };
    },

    restrictions() {
      if (this.user.user_role === 'manager' && !this.user.is_external) {
        return [
          { label: 'Owner', value: 'owner' },
          { label: 'PRP', value: 'external' },
          { label: 'Organization', value: 'organization' },
        ];
      } else {
        return [{ label: 'Owner', value: 'owner' }];
      }
    },
  },

  methods: {
    ...mapMutations(['updateTemplatesAndProfiles']),

    async create() {
      this.formData[0].value = '';
      await this.validityCheckExternalProfileText();

      this.showAddProfileModal = true;
    },

    closeAddProfileModal() {
      this.showAddProfileModal = false;
    },

    async validityCheckExternalProfileText() {
      let text = '';

      if (this.analysisProfiles && Object.keys(this.analysisProfiles).length > 0) {
        Object.keys(this.analysisProfiles).forEach(key => {
          if (this.analysisProfiles[key].restriction === 'external') {
            this.anotherProfileUidIsExternal = this.analysisProfiles[key];
            text = "⚠️ Current PRP profile '" + this.analysisProfiles[key].name + "' will be unset.";
          }
        });
      } else {
        this.anotherProfileUidIsExternal = '';
      }

      return text;
    },

    async createAnalysisProfile(profile) {
      let profileData = {};
      Object.keys(this.formData).forEach(key => {
        profileData[profile[key]['db_name']] = profile[key]['value'];
      });

      profileData['process_chain_ids'] = Object.keys(this.part.process_chains);
      this.axiosInstance
        .post(`/api/v1/analysis-profile/`, profileData)
        .then(response => {
          this.profileId = response.data.uid;
          this.deleteProfileId = response.data.uid;
          this.duplicateProfileId = response.data.uid;
          this.defaultProfileId = response.data.uid;
          this.updateTemplatesAndProfiles(new Date());
          if (response.status == 201) {
            this.$root.notify(
              'success',
              'Profile Created',
              `Profile '${response.data.name}' successfully created.`,
              6000
            );
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 422) {
            this.$root.notify('warning', 'Could not create profile.', error.response.data.error_message, 6000);
          } else if (error.response.status === 403) {
            this.$root.notify('error', 'Error', error.response.data.error_message, 6000);
          }
        });
      if (profileData.restriction == 'external') {
        this.removeProfileAsExternal();
      }
      profileData = {};
    },

    async removeProfileAsExternal() {
      if (this.anotherProfileUidIsExternal != '') {
        this.anotherProfileUidIsExternal.restriction = 'owner';

        this.axiosInstance
          .put(`/api/v1/analysis-profile/${this.anotherProfileUidIsExternal.uid}/`, this.anotherProfileUidIsExternal)
          .then(response => {
            this.updateTemplatesAndProfiles(new Date());
            this.anotherProfileUidIsExternal = '';
            if (response.status == 201) {
              this.$root.notify(
                'success',
                'Profile Created',
                `Profile '${response.data.name}' successfully created.`,
                6000
              );
            }
          });
      }
    },
  },
};
</script>
