<template>
  <SparkModal dismissable :model-value="show" @update:model-value="close">
    <template #header>Add selected parts to group</template>
    <template #default>
      <div class="flex flex-row">
        <div class="spark-new-input-group">
          <select v-model="selectedBatch" class="spark-new-select-input">
            <option v-for="batch in batches" :key="batch" :value="batch">
              {{ batch.batch_name }}
            </option>
          </select>
          <label class="spark-new-select-label">Group</label>
        </div>
        <SparkButton variant="secondary" class="ml-8" @click="addToGroup()"
          >Add {{ numberCheckedParts }} parts</SparkButton
        >
      </div>
    </template>
  </SparkModal>
</template>

<script>
import { mapState } from 'vuex';

import SparkButton from '../SparkComponents/SparkButton.vue';
import SparkModal from '../SparkComponents/SparkModal.vue';

export default {
  name: 'AddToGroupModal',

  components: { SparkModal, SparkButton },

  props: {
    show: { type: Boolean },
    numberCheckedParts: { type: Number, default: 0 },
    checkedParts: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  emits: ['close'],

  data() {
    return {
      selectedBatch: '',
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
    ...mapState(['batches']),
  },

  methods: {
    close() {
      this.$emit('close');
    },

    async addToGroup() {
      if (this.selectedBatch && this.numberCheckedParts > 0) {
        await this.axiosInstance
          .put('/api/v1/add-parts-to-batch/', {
            parts: this.checkedParts,
            batch: this.selectedBatch,
          })
          .catch(error => {
            this.$root.notifyError('Adding parts to group', error);
          });
      }
      this.selectedBatch = '';
      this.$emit('close');
    },
  },
};
</script>
