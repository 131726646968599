<template>
  <div>
    <apexchart ref="costChart" :options="options" :series="series" height="300px" />
  </div>
</template>

<script>
import { useRoute } from 'vue-router';

import usePriceFormatting from '@/composables/priceFormatting.js';

export default {
  name: 'CostChart',

  props: {
    chain: Object,
    label: Object,
  },

  data: function () {
    return {
      options: {
        labels: ['Pre-Process', 'In-Process', 'Post-Process', 'Material'],
        chart: {
          type: 'bar',
          height: 'auto',
          stacked: true,
          customScale: 0.8,
          animations: {
            enabled: true,
          },

          fontFamily: 'Poppins, Arial, sans-serif',
        },

        dataLabels: {
          enabled: false,
        },

        xaxis: {
          categories: ['Pre-Process', 'In-Process', 'Post-Process', 'Material'],
        },

        yaxis: {
          labels: {
            formatter: function (value) {
              return usePriceFormatting(value).price;
            },
          },
        },

        tooltip: {
          enabled: true,
          y: {
            formatter: function (value) {
              return usePriceFormatting(value).price;
            },
          },
        },

        legend: {
          show: true,
          position: 'bottom',
        },
      },

      theme: {
        monochrome: {
          enabled: true,
          color: '#97ab13',
        },
      },
    };
  },

  computed: {
    series() {
      let dataArray = [];
      let cost = this.chain?.cost_breakdown;
      let sortedXCategories = ['pre_process', 'in_process', 'post_process', 'material'];
      let sortedYCategories = ['labor', 'machine', 'other', 'build_material', 'support_material', 'waste_material'];
      sortedYCategories.forEach(caty => {
        let data = [];
        sortedXCategories.forEach(catx => {
          if (cost.hasOwnProperty(catx) && cost[catx].hasOwnProperty(caty)) {
            data.push(cost[catx][caty]);
          } else {
            data.push(0);
          }
        });
        let obj = {
          name: this.label[caty],
          data: data,
        };
        dataArray.push(obj);
      });
      return dataArray;
    },
  },
};
</script>

<style lang="scss"></style>
