<template>
  <div :id="id">{{ count }}</div>
</template>
<script>
export default {
  name: 'NumberAnimation',
  props: {
    id: String,
    count: Number,
  },
  mounted() {
    this.animateValue(this.$el, 0, this.count, 3000);
  },
  methods: {
    animateValue(obj, start, end, duration) {
      let startTimestamp = null;
      const step = timestamp => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        obj.innerHTML = Math.floor(progress * (end - start) + start);
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    },
  },
};
</script>
<style lang="scss" scoped></style>
