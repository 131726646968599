<template>
  <table id="info">
    <tr v-if="part.bb_x != null">
      <td>Bounding Box</td>
      <td>
        {{ $formatOneDecimalPlace(part.bb_x) }} x {{ $formatOneDecimalPlace(part.bb_y) }} x
        {{ $formatOneDecimalPlace(part.bb_z) }} &nbsp;mm
      </td>
    </tr>
    <tr v-if="boolScaling && highlightedProcessChain.has_scaling != null">
      <td class="description">Scaled Bounding Box</td>
      <td class="number">
        {{ $formatOneDecimalPlace(highlightedProcessChain.scaled_bounding_box[0]) }} x
        {{ $formatOneDecimalPlace(highlightedProcessChain.scaled_bounding_box[1]) }} x
        {{ $formatOneDecimalPlace(highlightedProcessChain.scaled_bounding_box[2]) }}&nbsp;mm
      </td>
    </tr>
    <tr v-if="part.part_vol != null">
      <td class="description">Part Volume</td>
      <td class="number">{{ $formatOneDecimalPlace(partVolume.value) }}&nbsp;{{ partVolume.unit }}</td>
    </tr>

    <tr v-if="boolScaling && highlightedProcessChain.has_scaling != null">
      <td class="description">Scaled Part Volume</td>
      <td class="number">{{ $formatOneDecimalPlace(scaledPartVolume.value) }}&nbsp;{{ scaledPartVolume.unit }}</td>
    </tr>
    <tr v-if="part.part_srf_area != null">
      <td class="description">Part Surface Area</td>
      <td class="number">{{ $formatOneDecimalPlace(partSurfaceArea.value) }}&nbsp;{{ partSurfaceArea.unit }}</td>
    </tr>
    <tr v-if="meshChecksExisting" class="action" @click="showMeshChecks()">
      <td class="description" title="Click To See detailed Mesh Checks">Mesh Checks</td>
      <td v-if="meshChecks" class="number"><i class="fas fa-check" style="color: var(--spark-check-green)" /></td>
      <td v-else-if="!meshChecks" class="number">
        <i class="fas fa-exclamation" style="color: var(--spark-warning)" />
      </td>
      <td v-else class="number">-</td>
    </tr>
  </table>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'CanvasInformation',

  computed: {
    ...mapState(['part', 'investigationDetails']),

    boolScaling() {
      if (this.highlightedProcessChain == null) return false;
      if (this.highlightedProcessChain.scale == null) return false;
      if (
        this.highlightedProcessChain.scale[0] === 1 &&
        this.highlightedProcessChain.scale[1] === 1 &&
        this.highlightedProcessChain.scale[2] === 1
      ) {
        return false;
      } else return true;
    },

    partVolume() {
      return this.getVolumeWithUnit(this.part.part_vol);
    },

    scaledPartVolume() {
      return this.getVolumeWithUnit(this.highlightedProcessChain.scaled_part_volume);
    },

    partSurfaceArea() {
      return this.getAreaWithUnit(this.part.part_srf_area);
    },

    highlightedProcessChainUid() {
      return this.investigationDetails.uid;
    },

    highlightedProcessChain() {
      if (this.highlightedProcessChainUid != null || this.highlightedProcessChainUid != '') {
        return this.processChains[this.highlightedProcessChainUid];
      }
    },

    processChains() {
      return this.part.process_chains;
    },

    meshChecksExisting() {
      let meshChecks = this.part.mesh_checks;
      if (meshChecks != null) {
        return Object.keys(meshChecks).length != 0;
      } else {
        return false;
      }
    },

    meshChecks() {
      let meshChecks = this.part?.mesh_checks;
      let result = true;
      Object.values(meshChecks).forEach(checkGroup => {
        Object.values(checkGroup).forEach(check => {
          if (check.check_passed == false) {
            result = false;
            this.changeDisplayInfo('meshChecks');
          }
        });
      });
      return result;
    },
  },

  methods: {
    ...mapMutations(['changeDisplayInfo']),

    getVolumeWithUnit(volume) {
      if (volume < 1) {
        return { value: volume * 1000, unit: 'mm\u{00B3}' };
      } else {
        return { value: volume, unit: 'cm\u{00B3}' };
      }
    },

    getAreaWithUnit(area) {
      if (area < 1) {
        return { value: area * 100, unit: 'mm\u{00B2}' };
      } else {
        return { value: area, unit: 'cm\u{00B2}' };
      }
    },

    showMeshChecks() {
      this.changeDisplayInfo('meshChecks');
    },
  },
};
</script>

<style lang="scss" scoped>
#info {
  margin: 5px;
  padding: 5px;
  font-size: var(--10px);
  bottom: 6px;
  z-index: 100;
  display: block;
  border-radius: 3px;
  background-color: white;
  opacity: 0.5;
  &:hover {
    opacity: unset;
  }
}

.number {
  text-align: right;
}

.description {
  text-align: left;
}
.action:hover {
  cursor: pointer;
  font-weight: bold;
}
</style>
