import supplierActions from './actions';
import supplierGetters from './getters';
import supplierMutations from './mutations';
import supplierState from './state';
const supplierModule = {
  state: supplierState,
  getters: supplierGetters,
  mutations: supplierMutations,
  actions: supplierActions,
  namespaced: true,
};
export default supplierModule;
