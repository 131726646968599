import { Vector3, Matrix4 } from 'three';

import { MeshColorer } from '@/assets/js/3d_viewer/SparkModules/MeshColor.js';
import { setupRotationMatrixFromKardan } from '@/assets/js/3d_viewer/SparkModules/MeshRotation.js';

class NestingsHandler {
  constructor(activeProcessChain, scene, refMeshRotater) {
    this.activeProcessChain = activeProcessChain;
    this.scene = scene;
    this.refMeshRotater = refMeshRotater;
  }

  addAdditionalNestingMeshesToSceneIfRequired(showNesting, physicalPlateOffsetProcChain) {
    let nestings_pos_rot = this.activeProcessChain?.nestings_pos_rot;
    if (showNesting && typeof nestings_pos_rot == 'object') {
      this.deleteNestingMeshesFromScene();
      this.addMeshesToScene(nestings_pos_rot, physicalPlateOffsetProcChain);
      this.setVisibilityOfAllMeshes(true);
    } else {
      this.setVisibilityOfAllMeshes(false);
    }
  }

  deleteNestingMeshesFromScene() {
    this.listOfNestedMeshes().forEach(nestedMesh => {
      nestedMesh.geometry.dispose();
      nestedMesh.material.dispose();
      this.scene.scene.remove(nestedMesh);
    });
  }

  listOfNestedMeshes() {
    var list = [];
    for (let cntChild = this.scene.scene.children.length - 1; cntChild >= 0; cntChild--) {
      let child = this.scene.scene.children[cntChild];
      if (child.type === 'Mesh' && child.name === 'NestingClone') {
        list.push(child);
      }
    }
    return list;
  }

  addMeshesToScene(nestingsPosAndRot, physicalPlateOffsetProcChain) {
    for (let cntNest = 0; cntNest < nestingsPosAndRot.length; cntNest++) {
      let translation = new Vector3(...nestingsPosAndRot[cntNest][0]);
      translation.z -= physicalPlateOffsetProcChain; // plate offset is handled in canvas seperatly --> avoid having double plate offset

      let rotation = new Vector3(...nestingsPosAndRot[cntNest][1]);
      this.addSingleMeshCloneToScene(translation, rotation);
    }
  }

  addSingleMeshCloneToScene(initTranslationVec, rotation) {
    let rotMat = setupRotationMatrixFromKardan(...rotation);

    let trans = new Vector3(...initTranslationVec);
    trans.multiply(this.refMeshRotater.mesh.scale);
    let transMat = new Matrix4();
    transMat.makeTranslation(trans.x, trans.y, trans.z);

    let clonedMesh = this.refMeshRotater.mesh.clone();
    clonedMesh.name = 'NestingClone';
    clonedMesh.applyMatrix4(rotMat);
    clonedMesh.applyMatrix4(transMat);

    this.scene.scene.add(clonedMesh);
  }

  setVisibilityOfAllMeshes(showNested = false) {
    const showOriginalMesh = !showNested;
    this.refMeshRotater.mesh.visible = showOriginalMesh;
    this.refMeshRotater.meshBackside.visible = showOriginalMesh;

    this.setVisibilityAndTransparencyOfNestedMeshes(showNested);
  }

  setVisibilityAndTransparencyOfNestedMeshes(showNested) {
    let cntNestedPart = 0;
    this.listOfNestedMeshes().forEach(nestedMesh => {
      cntNestedPart++;
      this.updateVisibility(nestedMesh, showNested);

      if (showNested) {
        this.cloneMaterial(nestedMesh);

        const parts_pj = this.activeProcessChain?.parts_pj;
        const lot_size = this.activeProcessChain?.lot_size;
        const nestedMeshIsTransparent = cntNestedPart > parts_pj || cntNestedPart > lot_size;
        let nestedMeshColorer = new MeshColorer(nestedMesh);
        nestedMeshColorer.setTransparencyOfWholeMesh(nestedMeshIsTransparent);
      }
    });
  }

  updateVisibility(nestedMesh, showNested) {
    // as nested meshes are clones of original mesh, their visibility gets changed as well when
    // original mesh changes visibility --> special care is required AFTER ORIG MESH IS CHANGED
    nestedMesh.visible = showNested;
  }

  cloneMaterial(nestedMesh) {
    // cloning is important, as material of all meshes are initially just a reference to the SAME
    // material instance. --> Without clone() the modification of the last iteration mesh will
    // apply to all other meshes as well.
    nestedMesh.material = nestedMesh.material.clone();
  }
}

export { NestingsHandler };
