<template>
  <div>
    <!--
 <div class="topic-field" :style="topics[news.topic]['style']" style="font-size: var(--10px)">
      {{ topics[news.topic]["name"] }}
    </div> 
-->
    <Badge :title="topics[news.topic]['name']" :selected-style="topics[news.topic]['style']" />
    <div class="card2-date" style="font-size: var(--10px)">{{ convertToDate(news.created_date) }}</div>
    <div class="card2-menu" style="font-size: var(--14px)" title="delete" @click="deleteNews(news.uid)">
      <i class="fas fa-times" />
    </div>
    <div class="card2-title" style="font-size: 15px">
      <a v-if="newsUrl != undefined" rel="noopener noreferrer" target="_blank" :href="newsUrl">
        {{ news.title }}
      </a>
      <div v-else>
        {{ news.title }}
      </div>
    </div>
    <div rel="noopener noreferrer" target="_blank" class="card2-text" style="font-size: 15px">
      <a v-if="newsUrl != undefined" :href="newsUrl">
        {{ news.content }}
      </a>
      <div v-else>
        {{ news.content }}
      </div>
      <img v-if="assetURL != ''" :src="assetURL" alt="News Image" class="news-image" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Badge from '@/components/Reusable/Badge.vue';

export default {
  name: 'News',

  components: { Badge },

  props: { news: Object, newsUrl: String, imageUrl: String },

  emits: ['deleteNews'],

  data() {
    return {
      topics: {
        general: { name: 'General', style: 'mint' },
        release_note: { name: 'Release Note', style: 'greenyellow' },
        service: { name: 'Service Annoucement', style: 'orange' },
        important_change: { name: 'Important Change', style: 'purple' },
        user: { name: 'User Info', style: 'brownred' },
        feature: { name: 'Feature', style: 'brown' },
        bugfix: { name: 'Bugfix', style: 'darkred' },
        blog_entry: { name: 'Blog Entry', style: 'darkpurple' },
        press_release: { name: 'Press Release', style: 'darkblue' },
        social_post: { name: 'Social Media Post', style: 'greyblue' },
      },

      assetURL: '',
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
  },

  mounted() {
    if (this.news.image != undefined) {
      this.getImage(this.news.image);
    }
  },

  methods: {
    convertToDate(dateTime) {
      let onlyDate = dateTime.split(' ')[0];
      return onlyDate;
    },

    getImage(path) {
      this.axiosInstance
        .get(`/api/v1/news/image/${this.news.uid}/`, {
          responseType: 'blob',
        })
        .then(response => {
          const urlCreator = window.URL || window.webkitURL;
          this.assetURL = urlCreator.createObjectURL(response.data);
        });
    },

    deleteNews(uid) {
      this.$emit('deleteNews', uid);
    },
  },
};
</script>

<style lang="scss" scoped>
.card2-date {
  color: var(--spark-lightgrey);
  display: inline-block;
  margin-left: 50px;
}

.card2-menu {
  cursor: pointer;
  float: right;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.news-image {
  max-height: 300px;
  width: 100%;
  object-fit: cover;
}
.card2-title {
  margin-top: 10px;
  margin-bottom: 25px;
  font-weight: bold;
}

.card2-text {
  font-size: var(--12px);
  margin-bottom: 20px;
}

a {
  cursor: pointer;
}
</style>
