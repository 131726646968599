<template>
  <div class="filter-element">
    <div>
      <div class="filter-label">Start Date</div>
      <div class="filtering">
        <Datepicker
          v-model="start_date"
          format="dd.MM.y"
          auto-apply
          show-now-button
          text-input
          :text-input-options="textInputOptions"
          :enable-time-picker="false"
          :clearable="false"
          :max-date="maxDate"
          :state="validationState"
        />
      </div>
      <ul>
        <li v-for="error in errors" :key="error" class="error">{{ error }}</li>
      </ul>
    </div>
    <div>
      <div class="filter-label">End Date</div>
      <div class="filtering">
        <Datepicker
          v-model="end_date"
          format="dd.MM.y"
          auto-apply
          show-now-button
          text-input
          :text-input-options="textInputOptions"
          :enable-time-picker="false"
          :clearable="false"
          :max-date="maxDate"
          :state="validationState"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import { enGB } from 'date-fns/locale';
import { ref } from 'vue';
import { mapState, mapMutations } from 'vuex';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: 'DateFilter',

  components: { Datepicker },

  props: {
    reset: { type: Number },
    label: { type: String },
    component: { type: String },
    parent: { type: String },
  },

  emits: ['selected'],

  data() {
    return {
      en: enGB,
      errors: [],
      textInputOptions: ref({
        enterSubmit: true,
        format: 'dd.MM.yyyy',
      }),

      end_date: this.$dayjs(),
      start_date: this.$dayjs().subtract(365, 'days'),
      validationState: null,
    };
  },

  computed: {
    ...mapState(['user', 'partLibraryFilters', 'dashboardFilters']),

    maxDate() {
      return this.$dayjs().format('dd.MM.yyyy');
    },
  },

  watch: {
    start_date: {
      handler(newVal) {
        this.checkForm();
        this.$emit('selected', {
          start_date: this.$dayjs(newVal).utc().format(),
        });
      },
    },

    end_date: {
      handler(newVal) {
        this.checkForm();
        this.$emit('selected', {
          end_date: this.$dayjs(newVal).utc().format(),
        });
      },
    },

    reset: {
      handler() {
        if (this.reset) {
          this.remove();
          (this.end_date = this.$dayjs()), (this.start_date = this.$dayjs().subtract(365, 'days'));
        }
      },
    },
  },

  mounted() {
    if (this.parent === 'library') {
      const filter = JSON.parse(sessionStorage.getItem('libraryFilter'));
      const startDate = filter?.start_date;
      const endDate = filter?.end_date;
      if (startDate) {
        this.start_date = startDate;
        this.$emit('selected', {
          start_date: this.$dayjs(this.start_date).utc().format(),
        });
      }
      if (endDate) {
        this.end_date = endDate;
        this.$emit('selected', {
          end_date: this.$dayjs(this.end_date).utc().format(),
        });
      }
    }
    if (this.parent === 'dashboard') {
      this.loadPreviouslySelectedDatesInDashboard();
    }
    this.$emit('selected', {
      start_date: this.$dayjs(this.start_date).utc().format(),
    });
    this.$emit('selected', {
      end_date: this.$dayjs(this.end_date).utc().format(),
    });
  },

  methods: {
    ...mapMutations(['resetFilter', 'disablePartLibraryFilter']),

    loadPreviouslySelectedDatesInDashboard() {
      if (this.dashboardFilters.start_date == undefined) {
        this.$emit('selected', {
          start_date: this.$dayjs(this.start_date).utc().format(),
        });
      } else {
        this.start_date = this.dashboardFilters.start_date;
      }

      if (this.dashboardFilters.end_date == undefined) {
        this.$emit('selected', {
          end_date: this.$dayjs(this.end_date).utc().format(),
        });
      } else {
        this.end_date = this.dashboardFilters.end_date;
      }
    },

    checkForm() {
      this.errors = [];
      if (this.$dayjs(this.start_date) > this.$dayjs(this.end_date)) {
        this.errors.push('Start Date must be before End Date');
        this.validationState = false;
      }
      if (this.start_date === this.end_date) {
        this.errors.push('Start Date can not be equal to End Date.');
        this.validationState = false;
      }
      if (!this.errors.length) {
        this.validationState = null;
        return true;
      }
    },

    remove() {
      this.resetFilter({ date_filter: 'start_date' });
      this.resetFilter({ date_filter: 'end_date' });
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-element {
  width: 100%;
}

.filter-element {
  flex-direction: row !important;
}

.error {
  color: red;
  font-size: var(--10px);
}

li {
  list-style-type: none;
  padding-left: 0px;
  display: flex;
}

ul {
  padding-left: 1px;
  margin-bottom: 1px;
  padding-top: 0px;
}

.dp__theme_light {
  --dp-background-color: #ffffff;
  --dp-text-color: #212121;
  --dp-hover-color: #f3f3f3;
  --dp-hover-text-color: #212121;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: var(--spark-darkgreen);
  --dp-primary-text-color: #f8f5f5;
  --dp-secondary-color: #c0c4cc;
  --dp-border-color: #ddd;
  --dp-menu-border-color: #ddd;
  --dp-border-color-hover: #aaaeb7;
  --dp-disabled-color: #f6f6f6;
  --dp-scroll-bar-background: #f3f3f3;
  --dp-scroll-bar-color: #959595;
  --dp-success-color: #76d275;
  --dp-success-color-disabled: #a3d9b1;
  --dp-icon-color: #959595;
  --dp-danger-color: #ff6f60;
}

$dp__font_family: 'Poppins' !default; // Font size for the menu
</style>
