<template>
  <div>
    <div class="spark-new-input-group">
      <select v-model="calculationStyle" required class="spark-new-select-input" :disabled="disabledSetting">
        <option v-for="(label, key) in calculationStyles" :key="key" :value="key">
          {{ label }}
        </option>
      </select>
      <label class="spark-new-select-label">Calculation Style</label>
    </div>
    <div class="grey-subinfo ml-16 mb-16">
      {{ calculationStyleInfo[calculationStyle] }}
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  name: 'CalculationStyleSetting',

  props: {
    disabledSetting: Boolean,
    chain: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  emits: ['setting-selected'],

  data() {
    return {
      calculationStyles: {
        geometry: 'Geometry',
        bounding_box: 'Bounding Box',
      },

      calculationStyleInfo: {
        geometry: 'Using geometric features from the CAD file.',
        bounding_box: 'Estimate from the part bounding box and material price.',
      },
    };
  },

  computed: {
    calculationStyle: {
      get() {
        return this.chain?.calc_style;
      },

      set(value) {
        let data = { uid: this.chain.process_chain_id, setting: value };
        this.updateCalculationStyle(data);
        let formData = { calc_style: value };
        this.$emit('setting-selected', formData);
      },
    },
  },

  methods: {
    ...mapMutations(['updateCalculationStyle']),
  },
};
</script>
