<template>
  <div>
    <div class="flex flex-row align-baseline mr-16">
      <SparkInput
        v-model.lazy="userValue"
        :label="axisLabel"
        custom="text-11"
        type="number"
        step="30"
        min="0"
        max="360"
        name="axis"
        @change="pushToBackend()"
      />
      <input
        v-model="userValue"
        class="ml-3 slider"
        type="range"
        min="0"
        max="360"
        step="5"
        @change="pushToBackend()"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import SparkInput from '../../SparkComponents/SparkInput.vue';
export default {
  name: 'CustomAxisRotation',

  components: {
    SparkInput,
  },

  props: {
    axis: String('x'),
    reset: Number(0),
  },

  data() {
    return {
      userValue: 0,
    };
  },

  computed: {
    ...mapState(['investigationDetails', 'part']),
    activeInvestigationDetails() {
      return this.investigationDetails;
    },

    rotationCost() {
      if (this.activeInvestigationDetails != '' || this.activeInvestigationDetails?.uid != undefined) {
        let axisDimension = 'rot_' + this.axis + '_cost';
        if (this.part?.process_chains[this.activeInvestigationDetails.uid] != undefined) {
          return this.part?.process_chains[this.activeInvestigationDetails.uid][axisDimension];
        }
        return '';
      } else {
        return '';
      }
    },

    axisLabel() {
      return this.axis + '-axis';
    },
  },

  watch: {
    userValue: {
      handler(newVal) {
        this.commitUserRotation(newVal, false);
      },
    },

    reset() {
      this.userValue = 0;
      this.pushToBackend();
    },

    rotationCost: {
      handler(newVal) {
        this.userValue = newVal;
      },

      immediate: true,
    },
  },

  mounted() {
    this.updateRotStat('rot_user');
  },

  methods: {
    ...mapMutations(['rotUserChangedSingleAxis']),
    ...mapMutations('canvas', ['updateRotStat']),

    pushToBackend() {
      this.commitUserRotation(this.userValue, true);
    },

    commitUserRotation(userValue, pushToBackend) {
      let dict = {
        uid: this.activeInvestigationDetails.uid,
        axis: this.axis,
        value: parseInt(userValue),
        pushToBackend: pushToBackend,
      };
      this.rotUserChangedSingleAxis(dict);
    },
  },
};
</script>
