<template>
  <div class="checkbox-container" title="Please delete the cad file to enable this option.">
    <div class="checkbox-title">No CAD File</div>
    <div class="checkbox-group">
      <SparkCheckbox v-model="isCadless" :disabled="cadFileUploaded" name="isCadless">
        <div v-text="'I do not have a CAD File'" />
      </SparkCheckbox>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import SparkCheckbox from '../../SparkComponents/SparkCheckbox.vue';

export default {
  name: 'EnableCadLessCalculation',

  components: { SparkCheckbox },

  computed: {
    ...mapState(['part', 'axiosInstance']),

    partId() {
      return this.part.part_id;
    },

    isCadless: {
      get() {
        return this.part.is_cadless;
      },

      set(value) {
        this.setIsCadless(value);
      },
    },

    cadFileUploaded() {
      return this.part.basename !== '';
    },
  },

  watch: {
    partId() {
      this.is_cadless = this.part.is_cadless;
    },
  },

  mounted() {
    this.is_cadless = this.part.is_cadless;
  },

  methods: {
    ...mapMutations(['updatePart', 'setIsCadless']),
  },
};
</script>

<style lang="scss" scoped>
.checkbox-label {
  font-size: var(--11px);
}

.checkbox-title {
  color: var(--spark-lightgrey);
  font-size: var(--12px);
}

.checkbox-container {
  margin-bottom: 5px;
}

.checkbox-group {
  display: flex;
  align-items: center;
}

input {
  width: auto;
  margin: 5px 5px 5px 0px;
}
</style>
