<template>
  <div>
    <h4>Color Settings</h4>
    <div style="width: 60%; display: inline-block; vertical-align: top">
      <table class="spark-table">
        <tbody>
          <tr :class="currentCanvas === 'primary' && boolColorPicker ? 'active-row' : ''">
            <td style="width: 40%; font-weight: bold">Primary Color:</td>
            <td style="width: 40%; margin: 1px" class="primary-color">{{ currentPrimaryColor }}</td>
            <td v-if="boolEditColors" style="width: 7%" title="Set Primary Color." @click="openColorCanvas('primary')">
              <i class="fas fa-palette" />
            </td>
            <td v-else style="width: 7%" />
          </tr>
          <tr v-show="boolEditColors">
            <td>Default:</td>
            <td style="margin: 1px; color: white; background-color: #383e46; border: 2px solid white">#383e46</td>
            <td />
            <td />
          </tr>
          <tr>
            <td style="width: 40%; font-weight: bold">Accent Color:</td>
            <td style="width: 40%" class="accent-color">{{ currentAccentColor }}</td>
            <td v-if="boolEditColors" style="width: 7%" title="Set Accent Color." @click="openColorCanvas('accent')">
              <i class="fas fa-palette" />
            </td>
            <td v-else style="width: 7%" />
          </tr>
          <tr v-show="boolEditColors">
            <td>Default:</td>
            <td style="margin: 1px; color: black; background-color: #97ab13; border: 2px solid white">#97ab13</td>
            <td />
            <td />
          </tr>
        </tbody>
      </table>
    </div>
    <div style="width: 40%; display: inline-block">
      <div
        v-if="!boolEditColors"
        style="display: inline-flex; cursor: pointer; margin: 2px"
        @click="editSettings(true)"
      >
        <i class="far fa-edit" />
      </div>
      <div v-if="boolEditColors" style="display: inline-flex; cursor: pointer; margin: 2px" @click="closeSettings()">
        <i class="fas fa-times" />
      </div>
      <div
        v-if="boolEditColors"
        style="display: inline-flex; cursor: pointer; margin: 2px"
        @click="makeColorSettings()"
      >
        <i class="fas fa-check" />
      </div>
    </div>
    <transition name="fade">
      <div v-if="boolColorPicker" style="width: 40%; display: inline-block">
        <ColorPicker
          theme="light"
          :color="color"
          :sucker-hide="true"
          :sucker-canvas="suckerCanvas"
          :sucker-area="suckerArea"
          @changeColor="changeColor"
          @openSucker="openSucker"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { ColorPicker } from 'vue-color-kit';
import { mapState, mapMutations } from 'vuex';
import 'vue-color-kit/dist/vue-color-kit.css';

export default {
  name: 'ColorSettings',

  components: {
    ColorPicker,
  },

  props: { boolMakeColorSettings: Boolean },

  data() {
    return {
      storedSettings: {},
      color: '#383e46',
      currentCanvas: 'primary',
      currentPrimaryColor: '#383e46',
      currentAccentColor: '#97ab13',
      suckerCanvas: null,
      suckerArea: [],
      isSucking: false,
      boolColorPicker: false,
      boolEditColors: false,
    };
  },

  computed: {
    ...mapState(['user']),
    ...mapState('application', ['color_default', 'axiosInstance']),

    colorDefault() {
      return this.color_default;
    },

    colorOrganization() {
      return this.user.organization.color;
    },

    primaryColor() {
      if (this.colorOrganization.primary_color !== null && this.colorOrganization.primary_color !== undefined) {
        return this.colorOrganization.primary_color;
      } else {
        return this.colorDefault.primary_color_default;
      }
    },

    primaryTextColor() {
      if (
        this.colorOrganization.primary_text_color !== null &&
        this.colorOrganization.primary_text_color !== undefined
      ) {
        return this.colorOrganization.primary_text_color;
      } else {
        return this.colorDefault.primary_text_color_default;
      }
    },

    accentColor() {
      if (this.colorOrganization.accent_color !== null && this.colorOrganization.accent_color !== undefined) {
        return this.colorOrganization.accent_color;
      } else {
        return this.colorDefault.accent_color_default;
      }
    },

    accentTextColor() {
      if (this.colorOrganization.accent_text_color !== null && this.colorOrganization.accent_text_color !== undefined) {
        return this.colorOrganization.accent_text_color;
      } else {
        return this.colorDefault.accent_text_color_default;
      }
    },
  },

  watch: {
    primaryColor: {
      handler(newColor) {
        this.setPrimaryColor(newColor);
        this.setGlobalColors();
      },

      immediate: true,
    },

    accentColor: {
      handler(newColor) {
        this.setAccentColor(newColor);
        this.setGlobalColors();
      },

      immediate: true,
    },

    boolMakeColorSettings: {
      handler(newBool) {
        if (newBool) {
          this.makeColorSettings();
        }
      },

      immediate: true,
    },
  },

  mounted() {
    this.setGlobalColors();
  },

  methods: {
    ...mapMutations(['updateColorObject', 'updateColor']),

    setGlobalColors() {
      if (this.colorOrganization === {} || this.colorOrganization === undefined) {
        this.setPrimaryColor(this.colorDefault.primary_color_default);
        this.setAccentColor(this.colorDefault.accent_color_default);
      } else {
        this.colorOrganization.primary_color === null || this.colorOrganization.primary_color === undefined
          ? this.setPrimaryColor(this.colorDefault.primary_color_default)
          : this.setPrimaryColor(this.colorOrganization.primary_color);
        this.colorOrganization.accent_color === null || this.colorOrganization.primary_color === undefined
          ? this.setAccentColor(this.colorDefault.accent_color_default)
          : this.setAccentColor(this.colorOrganization.accent_color);
      }
    },

    setPrimaryColor(newColor) {
      this.currentPrimaryColor = newColor;
    },

    setAccentColor(newColor) {
      this.currentAccentColor = newColor;
    },

    editSettings(boolShowSettings = false) {
      this.storedSettings = JSON.stringify(this.colorOrganization);
      if (this.isPrimary()) {
        this.colorPickerColor = this.currentPrimaryColor;
      } else {
        this.colorPickerColor = this.currentAccentColor;
      }
      this.boolEditColors = boolShowSettings;
      this.boolColorPicker = false;
    },

    closeSettings() {
      this.updateColorObject(JSON.parse(this.storedSettings));
      this.boolEditColors = false;
      this.boolColorPicker = false;
      this.setGlobalColors();
    },

    changeColor(color) {
      // check if color is valid
      if (!this.validHexColor(color.hex)) {
        // try to remove whitespaces
        color.hex = color.hex.replace(/\s/g, '');
        if (!this.validHexColor(color.hex)) {
          this.$root.notify(
            'warning',
            'Invalid Color',
            'Invalid color detected. Please try to use a valid hex code or use the color picker to set a color.',
            5000
          );
          return;
        }
      }
      let textColor = this.setTextBrigthness(color);
      this.updateColor({ color: color.hex, canvas: this.currentCanvas, textcolor: textColor });
    },

    validHexColor(color) {
      // Checking if the color is a valid hex color.
      return /^#[0-9A-F]{6}$/i.test(color);
    },

    makeColorSettings() {
      this.editSettings();
      // create form data to send
      let formData = new FormData();
      let color = {
        primary_color: this.primaryColor,
        primary_text_color: this.primaryTextColor,
        accent_color: this.accentColor,
        accent_text_color: this.accentTextColor,
      };
      formData.append('color', JSON.stringify(color));

      this.axiosInstance
        .post(`api/v1/styling-config/`, formData)
        .then(response => {
          this.updateColorObject(response.data.color);
          this.$root.notify('success', 'Color settings', 'Color Settings successfully applied.', 3000);
        })
        .catch(error => {
          console.log(error.response.data);
        });
    },

    setTextBrigthness(color) {
      // A function that returns the color of the text based on the color of the background.
      let c = color.hex.substring(1); // strip #
      let rgb = parseInt(c, 16); // convert rrggbb to decimal
      let r = (rgb >> 16) & 0xff; // extract red
      let g = (rgb >> 8) & 0xff; // extract green
      let b = (rgb >> 0) & 0xff; // extract blue

      let luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

      if (luma < 255 / 2 + 25) {
        return '#ffffff';
      } else {
        return '#000000';
      }
    },

    isPrimary() {
      if (this.currentCanvas === 'primary') {
        return true;
      } else {
        return false;
      }
    },

    openColorCanvas(canvas) {
      if (this.currentCanvas === canvas) {
        this.boolColorPicker = !this.boolColorPicker;
      } else {
        this.currentCanvas = canvas;
      }
    },

    openSucker(isOpen) {},
  },
};
</script>

<style lang="scss" scoped>
.primary-color {
  border: 2px solid white;
  background-color: v-bind(currentPrimaryColor);
  color: v-bind(primaryTextColor);
}

.accent-color {
  border: 2px solid white;
  background-color: v-bind(currentAccentColor);
  color: v-bind(accentTextColor);
}

.fa-palette {
  font-size: 1.2em;
  cursor: pointer;
  color: v-bind(currentPrimaryColor);
  transition: all 0.3s ease-out;

  &:hover {
    color: v-bind(currentAccentColor);
    transition: all 0.3s ease-out;
  }
}
</style>

<style lang="scss">
.hu-color-picker {
  padding: 10px;
  background: #1d2024;
  border-radius: 4px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
  z-index: 1;
}
.hu-color-picker.light {
  background: #f7f8f9;
}
.hu-color-picker.light .color-show .sucker {
  background: #eceef0;
}
.hu-color-picker.light .color-type .name {
  background: #e7e8e9;
}
.hu-color-picker.light .color-type .value {
  color: #666;
  background: #eceef0;
}
.hu-color-picker.light .colors.history {
  border-top: 1px solid #eee;
}
.hu-color-picker canvas {
  vertical-align: top;
}
.hu-color-picker .color-set {
  display: flex;
}
.hu-color-picker .color-show {
  border: solid 1.5px white;
  margin-left: -1px;
  display: flex;
  margin-top: 8px;
}
.saturation {
  position: relative;
  cursor: pointer;
}
.saturation .slide {
  position: absolute;
  left: 100px;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #fff;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.3);
  pointer-events: none;
}
.color-alpha {
  display: none; // Hide transparency option since it is unused
  position: relative;
  margin-left: 8px;
  cursor: pointer;
}
.color-alpha .slide {
  position: absolute;
  left: -10%;
  top: 100px;
  width: 120%;
  height: 4px;
  background: rgb(0, 0, 0);
  border: 1px solid #fff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);
  pointer-events: none;
}
.sucker {
  fill: #9099a4;
  background: #2e333a;
  cursor: pointer;
  transition: all 0.3s;
}
.sucker.active,
.sucker:hover {
  fill: #1593ff;
}
.hue {
  position: relative;
  margin-left: 12px;
  cursor: pointer;
}
.hue .slide {
  position: absolute;
  left: -10%;
  top: 100px;
  width: 120%;
  height: 4px;
  border: 1px solid #fff;
  background: rgb(0, 0, 0);
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);
  pointer-events: none;
}
.colors {
  padding: 0;
  margin: 0;
}
.colors.history {
  margin-top: 10px;
  border-top: 1px solid #2e333a;
}
.colors .item {
  position: relative;
  width: 16px;
  height: 16px;
  margin: 10px 0 0 10px;
  border-radius: 3px;
  vertical-align: top;
  display: inline-block;
  transition: all 0.1s;
  cursor: pointer;
}
// .colors .item:nth-child(8n + 1) {
//   margin-left: 0;
// }
.colors .item:hover {
  transform: scale(1.4);
}
.colors .item .alpha {
  height: 100%;
  border-radius: 4px;
}
.colors .item .color {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
}
.color-type {
  display: flex;
  margin-top: 8px;
  font-size: 12px;
}
.color-type .name {
  width: 60px;
  height: 30px;
  margin-top: 5px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
  background: #252930;
}
.color-type .value {
  flex: 1;
  height: 30px;
  min-width: 100px;
  padding: 0 12px;
  border: 0;
  color: #fff;
  background: #2e333a;
}
</style>
