<template>
  <div>
    <table class="spark-table">
      <tbody>
        <tr v-for="(infos, name) in formData" :key="name">
          <td class="width-50" :class="infos.required ? 'required' : ''">
            {{ infos.label }}<span v-if="infos.required">&nbsp;*</span>
          </td>
          <td v-if="infos.field == 'input-text'" class="width-40">
            <input v-model="infos.value" type="text" maxlength="250" />
          </td>
          <td v-if="infos.field == 'input-number'" class="width-40">
            <input v-model="infos.value" type="number" :min="infos.options[0]" :max="infos.options[1]" /> />
          </td>
          <td v-if="infos.field == 'select'" class="width-40">
            <select v-model="infos.value">
              <option v-for="(option, key) in infos.options" :key="key" :value="option.value">
                {{ option.text }}
              </option>
            </select>
            <div v-if="infos?.validityCheck">
              {{ infos?.validityCheck[infos.value] }}
            </div>
          </td>
          <td v-if="infos.field == 'checkbox'">
            <SparkCheckbox v-model="infos.value" name="'check-box'"><div v-text="''" /></SparkCheckbox>
          </td>
          <td class="width-10">{{ infos.unit }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import SparkCheckbox from '../SparkComponents/SparkCheckbox.vue';

export default {
  name: 'PopupGeneralForm',

  components: {
    SparkCheckbox,
  },

  props: {
    formData: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  watch: {
    filledFormData() {
      this.$store.commit('triggerPopup', { filled_form_data: this.filledFormData });
    },
  },
};
</script>

<style lang="scss" scoped>
.required {
  font-weight: bold;
}

.help {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 10px;
  border-radius: 50%;
  color: black;
  &:hover {
    background-color: var(--accent-color);
    color: white;
  }
}

.no-hover:hover {
  background-color: white;
}

.width-50 {
  width: 50%;
}
.width-40 {
  width: 40%;
}
.width-10 {
  width: 10%;
}
</style>
