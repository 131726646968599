<template>
  <div class="input-frame-container">
    <FileDrop />
    <details open>
      <summary>Required Inputs for Analysis</summary>
      <div class="double-column">
        <div class="flex-item">
          <FileRowCad :key="cadFile" :file="cadFile" file-type="mainCad" />
          <Units @change-unit="updatePartNewPart($event)" />
          <AssemblyRipper />
        </div>
        <div class="flex-item">
          <EnableCadLessCalculation />
          <CADLessInput />
        </div>
      </div>
      <div class="double-column">
        <Quantity class="flex-item" @lot-size-changed="updatePartNewPart($event)" />
      </div>
    </details>

    <details open>
      <summary>Infos</summary>
      <!-- <CategoryInput required-categories @category="updatePartNewPart($event)" /> -->
      <CategoryInput @category="updatePartNewPart($event)" />
      <Description v-if="!user.is_external" @info="updatePartNewPart($event)" />
    </details>

    <details open>
      <summary>Technical Drawing and Current Material</summary>
      <div class="double-column">
        <div class="flex-item">
          <div v-for="drawing in technicalDrawings" :key="drawing">
            <FileRowAsset
              v-if="!user.is_external"
              :file="drawing"
              :class="[drawing.basename !== '' ? 'clickable' : '']"
              :style="previewFileName === drawing.basename && drawing.basename !== '' ? 'background-color:#ededed' : ''"
              @show-preview="showFile(drawing.basename)"
            />
          </div>
          <CurrentMaterialSelection
            v-if="!user.is_external"
            class="flex-item"
            @material-change="updatePartNewPart($event)"
          />
        </div>
      </div>
    </details>

    <details open>
      <summary>Files</summary>
      <div v-for="asset in assets" :key="asset" class="margin-top-20">
        <FileRowAsset
          v-if="asset.type !== 'analysis' && !user.is_external"
          :file="asset"
          file-type="asset"
          :class="[asset.basename !== '' ? 'clickable' : '']"
          :style="previewFileName === asset.basename && asset.basename !== '' ? 'background-color:#ededed' : ''"
          @show-preview="showFile(asset.basename)"
        />
      </div>
    </details>
    <details open>
      <summary>Preview</summary>
      <FilePreview v-if="assetAmount > 0" :partid="part.part_id" :filename="previewFileName" />
    </details>
    <router-view />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import AssemblyRipper from './AssemblyRipper.vue';
import CADLessInput from './CADLessInput.vue';
import CategoryInput from './CategoryInput.vue';
import CurrentMaterialSelection from './CurrentMaterialSelection.vue';
import Description from './Description.vue';
import EnableCadLessCalculation from './EnableCadLessCalculation.vue';
import FileDrop from './FileDrop.vue';
import FilePreview from './FilePreview.vue';
import FileRowAsset from './FileRowAsset.vue';
import FileRowCad from './FileRowCad.vue';
import Quantity from './Quantity.vue';
import Units from './Units.vue';

export default {
  name: 'InputFrame',

  components: {
    FileDrop,
    FileRowCad,
    FileRowAsset,
    CategoryInput,
    Quantity,
    Units,
    FilePreview,
    Description,
    AssemblyRipper,
    CurrentMaterialSelection,
    CADLessInput,
    EnableCadLessCalculation,
  },

  data() {
    return {
      previewFileName: '',
      assetsAndDrawingsLoaded: false,
    };
  },

  computed: {
    ...mapState(['part', 'assetTypes', 'user', 'assetAmount']),
    ...mapState('application', ['axiosInstance']),
    ...mapGetters(['processChainsAnalysisFinished', 'cadStatus', 'assetAmount']),

    cadFile() {
      let fileObject = {
        title: 'CAD File *',
        default: 'max. 250 MB (.3mf, .obj, .ply, .stl, .step)',
        type: 'main_cad',
        part_id: '',
      };
      if (this.part.basename) {
        fileObject.part_id = this.part.part_id;
        fileObject.basename = this.part.basename;
        fileObject.date = this.part.created_date;
      }
      return fileObject;
    },

    technicalDrawings() {
      let drawings = [];
      if (this.part.assets !== undefined) {
        for (const [key, value] of Object.entries(this.part.assets)) {
          if (value.asset_type == 'drawing') {
            drawings.push({
              title: this.getAssetType(value.asset_type),
              type: value.asset_type,
              basename: value.basename,
              date: value.updated.split(' ')[0],
              part_id: this.part.part_id,
              uid: key,
            });
            this.setToLoaded();
          }
        }
        let drawingsEmpty = drawings.length === 0;
        if (drawingsEmpty) {
          drawings.push({
            title: 'Technical Drawing',
            default: 'Upload to extract information',
            type: 'drawing',
            basename: '',
          });
        }
      }
      return drawings;
    },

    assets() {
      let assets = [];
      if (this.part.assets !== undefined) {
        for (const [key, value] of Object.entries(this.part.assets)) {
          if (value.asset_type != 'drawing') {
            assets.push({
              title: this.getAssetType(value.asset_type),
              type: value.asset_type,
              basename: value.basename,
              date: value.updated.split(' ')[0],
              part_id: this.part.part_id,
              uid: key,
            });
          }
          this.setToLoaded();
        }
        let assetsEmpty = assets.length === 0;
        if (assetsEmpty) {
          assets.push({
            title: 'Additional Files',
            default: 'max. 250 MB (jpg, png, pdf, ...)',
            basename: '',
            part_id: '',
          });
        }
      }
      return assets;
    },
  },

  watch: {
    assetsAndDrawingsLoaded() {
      if (Object.keys(this.technicalDrawings).length != 0) {
        this.previewFileName = this.technicalDrawings[0].basename;
      } else if (Object.keys(this.assets).length != 0) {
        this.previewFileName = this.assets[0].basename;
      }
    },

    popup(popup) {
      if (popup?.key == this.$options.name) {
        if (popup?.clicked == 'ok') {
          this.triggerPopup('');
          this.setMachineUserSettings();
          this.updateMachineUserSettings(this.machineLibraryColumns);
        } else if (popup?.clicked == 'cancel' || popup?.clicked == 'close') {
          this.triggerPopup('');
        }
      }
    },
  },

  methods: {
    ...mapMutations([
      'updatePart',
      'updateComboCategories',
      'updateTextCategories',
      'updateUnsavedChanges',
      'triggerPopup',
    ]),

    setToLoaded() {
      this.assetsAndDrawingsLoaded = true;
    },

    getAssetType(type) {
      let obj = Object.values(this.assetTypes).find(value => value.value === type);
      if (obj === undefined) return 'Attachment';
      return obj.text;
    },

    updatePartNewPart(formData) {
      if (Object.prototype.hasOwnProperty.call(formData, 'combo_categories')) {
        this.updateComboCategories(formData['combo_categories']);
        // change data structure for backend
        for (const [key, value] of Object.entries(formData['combo_categories'])) {
          formData['combo_categories'][key] = value.uid;
        }
      } else if (Object.prototype.hasOwnProperty.call(formData, 'text_categories')) {
        // pass
      } else {
        this.updatePart(formData);
      }

      this.setOutdatedIfNeeded(formData);

      if (this.part.part_id != undefined && this.part.part_id != '' && this.part.part_id != 0) {
        this.savePart(formData);
      } else {
        // save unsaved changes in the store
        this.updateUnsavedChanges(formData);
      }
    },

    setOutdatedIfNeeded(formData) {
      if (
        Object.prototype.hasOwnProperty.call(formData, 'lot_size') ||
        Object.prototype.hasOwnProperty.call(formData, 'upload_file_units')
      ) {
        if (this.analysisFinished) {
          // this.saveProcessChainStat();
        }
      }
    },

    showFile(event) {
      this.previewFileName = event;
    },

    savePart(formData) {
      // This function will parse the current 'part' instance to the database.
      this.axiosInstance
        .put(`/api/v1/part/${this.part.part_id}/`, formData)
        .then(response => {
          this.updatePart(response.data);
        })
        .catch(error => {
          console.log(JSON.stringify(error));
        });
    },
  },
};
</script>

<style scoped lang="scss">
details {
  margin: 20px 0px 20px 10px;
  border: 1px solid var(--lighter-color);
  border-radius: 5px 5px 0px 0px;
}

summary {
  background-color: var(--lighter-color);
  padding: 10px;
  border-radius: 5px 5px 0px 0px;
}

.flex-item {
  width: 50%;
}

.column {
  display: flex;
  flex-direction: column;
}

.input-frame-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  border-radius: 3px;
}

.clickable {
  cursor: pointer;
}

.input-heading {
  margin-top: 10px;
  white-space: nowrap;
  font-size: var(--13px);
}

.double-column {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.flex-row {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.margin-top-20 {
  margin-top: 20px;
}
</style>
