<template>
  <ErrorPage
    title="Sorry, your account has not been activated yet."
    description="Please contact support@3dspark.de"
    back-to="/login"
    back-to-title="Back home"
    :image-name="errorImage"
    image-description="Not activated"
  />
</template>

<script>
import ErrorImage from '@/assets/img/error_pages/not-activated.png';
import ErrorPage from '@/components/Reusable/ErrorPage.vue';

export default {
  name: 'NotFound',

  components: { ErrorPage },

  computed: {
    errorImage() {
      return ErrorImage;
    },
  },
};
</script>

<style lang="scss" scoped>
.circle {
  border-radius: 50px;
  height: 100px;
  width: 100px;
  position: absolute;
  bottom: 85%;
  left: 42%;
  background: rgb(3, 94, 112);
  background: linear-gradient(29deg, rgba(3, 94, 112, 1) 0%, rgba(0, 151, 102, 1) 70%, rgba(151, 171, 19, 1) 100%);
}
.box {
  text-align: center;
  margin: 50px;
  display: inline-block;
  padding: 100px;
  position: relative;
  border-radius: 5px;
}
h5 {
  color: var(--primary-color);
}

.fas {
  height: 100px;
  line-height: 100px;
  vertical-align: middle;
  text-align: center;
  font-size: 3em;
  color: var(--lighter-color);
}
</style>
