export function getCanvasState() {
  return {
    canvas: {
      rot_stat: 'rot_init', // canvas setting:  Which orientation should be applied? states: 'rot_init', 'rot_cost', 'rot_qual', 'rot_time'
      centered: true, // Whether a part is displayed centered or not. states: true/false
      transparency: false,
      showNesting: false,
      showHull: false,
      showGrid: true,
    },
    reloadCanvas: false,
    feasibilityToggle: 'geometry',
  };
}
export default getCanvasState();
