const applicationMutations = {
  setAxiosInstances(state, axiosInstances) {
    state.axiosInstance = axiosInstances.axiosInstance;
    state.axiosInstanceFileUpload = axiosInstances.axiosInstanceFileUpload;
  },
  setHeartbeatRate(state, rate) {
    state.heartbeatRate = rate;
  },
  setInitializationStatus(state, status) {
    state.initializationStatus = status;
  },
  setWebsocketDisconnect(state, disconnectBool) {
    state.websocketDisconnect = disconnectBool;
  },
  setWebsocketFailure(state, failureBool) {
    state.websocketFailure = failureBool;
  },
  togglePreviewer(state) {
    state.togglePreview = !state.togglePreview;
  },
  setToggleDebugMode(state) {
    state.toggleDebugMode = !state.toggleDebugMode;
  },
  cacheThumbnail(state, { part_id, thumbnail }) {
    state.cachedThumbnails[part_id] = thumbnail;
  },
  updateLogo(state, logo) {
    state.logo = logo;
  },
  togglePrpSidebar(state) {
    state.showPrpSidebar = !state.showPrpSidebar;
  },
  toggleSidebar(state) {
    state.showSidebar = !state.showSidebar;
  },
};
export default applicationMutations;
