<template>
  <div class="flex flex-col mx-4" @click="trigger()">
    <EstimationAndBenchmark
      :mouse-hover="mouseHover === 'leadtime'"
      type="leadtime"
      :chain="chain"
      :process-chains="chains"
      unit="d"
      @click="setToActive()"
    />
    <OpenDetails :show="mouseHover === 'leadtime'" />
  </div>
</template>

<script>
import { markRaw } from 'vue';
import { mapMutations } from 'vuex';

import EstimationAndBenchmark from '../../components/EstimationAndBenchmark.vue';
import OpenDetails from '../../components/OpenDetails.vue';

import LeadtimePopup from './components/LeadtimePopup.vue';

export default {
  name: 'Leadtime',

  components: {
    EstimationAndBenchmark,
    OpenDetails,
  },

  props: {
    chain: {
      type: Object,
      default() {
        return {};
      },
    },

    chains: {
      type: Object,
      default() {
        return {};
      },
    },

    mouseHover: { type: String, default: '' },
  },

  methods: {
    ...mapMutations(['triggerPopup', 'changeInvestigationDetails']),

    setToActive() {
      this.changeInvestigationDetails({ uid: this.chain.process_chain_id });
    },

    trigger() {
      this.changeInvestigationDetails({ uid: this.chain.process_chain_id });
      this.triggerPopup({
        key: this.$options.name,
        show: true,
        title: 'Leadtime',
        type: 'component',
        buttons: true,
        buttonContent: [{ text: 'Close', type: 'outlined', value: 'cancel' }],
        data: markRaw(LeadtimePopup),
      });
    },
  },
};
</script>
