import canvasActions from './actions';
import canvasGetters from './getters';
import canvasMutations from './mutations';
import canvasState from './state';
const canvasModule = {
  state: canvasState,
  getters: canvasGetters,
  mutations: canvasMutations,
  actions: canvasActions,
  namespaced: true,
};
export default canvasModule;
