<template>
  <div>
    <teleport v-if="isMounted" to="#sub-navigation__action-buttons">
      <SparkButton class="w-200 mr-0 ml-auto" variant="secondary" @click="openModal()">
        <div v-text="'Create Supplier'"
      /></SparkButton>
    </teleport>

    <SupplierList
      class="bg-white flex flex-col border border-gray-50 rounded-8 mb-16"
      :suppliers="publicSuppliers"
      supplier-restriction="public"
      title="3D Spark Network"
      @update="fetchPublicSuppliers()"
    />
    <SupplierList
      class="bg-white flex flex-col border border-gray-50 rounded-8"
      :suppliers="customersSuppliers"
      supplier-restriction="customer"
      title="Self Created"
      @update="fetchCustomersSuppliers()"
    />
    <CreateSupplierModal :show="showModal" @close="closeModal()" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import SparkButton from '../../../../components/SparkComponents/SparkButton.vue';

import CreateSupplierModal from './components/CreateSupplierModal.vue';
import SupplierList from './components/SupplierList.vue';
export default {
  name: 'SupplierSettings',

  components: { SupplierList, CreateSupplierModal, SparkButton },

  data() {
    return {
      showModal: false,
      isMounted: false,
    };
  },

  computed: {
    ...mapState('supplier', ['publicSuppliers', 'customersSuppliers']),
  },

  async mounted() {
    await this.fetchSelectedSuppliers(); // Get all supplier
    await this.fetchPublicSuppliers(); // Get the selected suppliers from backend
    await this.fetchCustomersSuppliers(); // Get the selected suppliers from backend
    this.isMounted = true;
  },

  methods: {
    ...mapActions('supplier', ['fetchSelectedSuppliers', 'fetchPublicSuppliers', 'fetchCustomersSuppliers']),

    openModal() {
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
    },
  },
};
</script>
