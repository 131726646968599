<template>
  <div class="w-full text-13 px-8">
    <div v-for="(option, heading) in elementOptions" :key="heading">
      <div class="text-gray-500 pb-4 pt-8">
        {{ heading }}
      </div>

      <div
        v-for="(value, key) in option"
        :key="key"
        :class="value.disabled ? 'text-gray-500' : ''"
        @click="clicked(value)"
      >
        <div
          v-if="value.icon != 'button'"
          class="flex flex-row justify-between p-2 rounded-4"
          :class="value.disabled ? '' : 'hover:bg-gray-200 hover:cursor-pointer'"
        >
          <i :class="value.icon" class="pr-4" />
          <div class="w-full" :class="value.warning ? 'text-red-500' : ''">{{ value.text }}</div>

          <div class="justify-end">
            <div class="relative">
              <img class="h-full" :src="value.image" />
              <div v-if="value.warning" class="circle"><img src="@/assets/icons/canvas/alert-circle.svg" /></div>
            </div>
          </div>
        </div>
        <div v-else class="hover:bg-white">
          <SparkButton small variant="outlined"><div v-text="value.text" /></SparkButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

import SparkButton from '../SparkComponents/SparkButton.vue';

export default {
  name: 'DropdownMenuNotifications',

  components: {
    SparkButton,
  },

  props: {
    elementOptions: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  emits: ['clicked-value'],

  data() {
    return {};
  },

  methods: {
    ...mapMutations(['deleteUserNotification']),

    clicked(event) {
      this.$emit('clicked-value', event);
    },
  },
};
</script>

<style lang="scss" scoped>
.circle {
  position: absolute;
  top: -4px;
  right: -4px;
  width: 13px;
}
</style>
