import prpActions from './actions';
import prpGetters from './getters';
import prpMutations from './mutations';
import prpState from './state';
const prpModule = {
  state: prpState,
  getters: prpGetters,
  mutations: prpMutations,
  actions: prpActions,
  namespaced: true,
};
export default prpModule;
