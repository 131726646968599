<template>
  <div class="filter-element">
    <div class="filter-label">{{ label }}</div>
    <div class="filtering">
      <input v-model.lazy="filterValue" />
    </div>
    <div :style="{ visibility: filterValue ? 'visible' : 'hidden' }" class="clear-filtering" @click="clear()">
      Clear
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'TextCategoryFilter',

  props: {
    reset: Number,
    label: String,
    component: String,
    parent: String,
  },

  emits: ['selected'],

  data() {
    return {
      filterValue: '',
    };
  },

  watch: {
    filterValue(newVal) {
      let uid = this.getCategoryUid(this.label);
      this.$emit('selected', { text_categories: { [uid]: newVal } });
      if (newVal === '') {
        this.resetFilter({
          text_categories: uid,
        });
      }
    },

    reset: {
      handler(reset) {
        if (reset) {
          this.remove();
          this.selectedCategories = [];
        }
      },
    },
  },

  mounted() {
    if (this.parent === 'library') {
      let value = this.partLibraryFilters.text_categories[this.getCategoryUid(this.label)];
      if (value != undefined) {
        this.filterValue = value;
      }
    }
  },

  computed: {
    ...mapState(['partLibraryFilters', 'user']),

    textCategories() {
      return this.user.organization.text_categories;
    },
  },

  methods: {
    ...mapMutations(['resetFilter', 'disablePartLibraryFilter']),

    remove() {
      this.disablePartLibraryFilter({
        [this.label]: this.component,
      });
      this.clear();
    },

    getCategoryUid(categoryName) {
      let categoryUid = '';
      Object.keys(this.textCategories).forEach(uid => {
        if (this.textCategories[uid].label === categoryName) {
          categoryUid = uid;
        }
      });
      return categoryUid;
    },

    clear() {
      this.resetFilter({
        text_categories: this.getCategoryUid(this.label),
      });
      this.filterValue = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-element {
  width: 100%;
}
</style>
