<template>
  <div class="machines">
    <MachineTable />
  </div>
</template>

<script>
import MachineTable from './components/MachineTable.vue';
export default {
  name: 'Machines',

  components: { MachineTable },
};
</script>

<style lang="scss" scoped>
.machines {
  max-width: 100%;
  overflow: hidden;
  overflow-x: scroll;
  height: calc(100vh + 300px);
  background-color: white;
}
</style>
