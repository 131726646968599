import { Float32BufferAttribute, FrontSide, DoubleSide } from 'three';

export const backSideMeshOpacity = 0.9;
export const frontSideMeshOpacity = 0.2;
export const hullMeshOpacity = 0.6;

export class MeshColorer {
  constructor(mesh, backsideMesh) {
    this.mesh = mesh;
    this.backsideMesh = backsideMesh;
  }

  initColorAttribute() {
    let colors = [];
    for (let cntVertex = 0; cntVertex < this.mesh.geometry.attributes.position.count; cntVertex++) {
      colors.push(1, 1, 1, 1);
    }
    this.mesh.geometry.attributes.color = new Float32BufferAttribute(colors, 4);
  }

  setTransparencyOfWholeMesh(transparent = false) {
    const opacity = transparent ? frontSideMeshOpacity : 1;
    this.mesh.material.opacity = opacity;
  }

  defaultColoring(transparent = false) {
    const alpha = this._getAlpha(transparent);
    // eslint-disable-next-line no-unused-vars
    const sameColorAllVertices = (transparent, mapVal) => [1, 1, 1, alpha];
    const trueMap = new Array(this.mesh.geometry.attributes.position.count).fill(true);
    this._setVertexColors(trueMap, transparent, sameColorAllVertices);
  }

  feasColoring(map, transparent = false) {
    /* Colors each triangle of the mesh regarding the selected attribute and its corresponding feasibility check. */

    this.setTransparencyOfWholeMesh(false); // create clean slate of material opacity

    // cannot pass this._getRgbaFeas directly to _setVertexColors() for some reason.
    // Therefor the workaround using lambda notation.
    const rgbaEvalFunc = (transparent, mapVal) => this._getRgbaFeas(transparent, mapVal);
    this._setVertexColors(map, transparent, rgbaEvalFunc);
  }

  _setVertexColors(map, transparent, colorFunc) {
    let colors = this.mesh.geometry.attributes.color;
    this._prepareTransparency(transparent);

    // set the color for each vertex from the gltf attribute
    for (let cntVertex = 0; cntVertex < map.length; cntVertex++) {
      let [r, g, b, alpha] = colorFunc(transparent, map[cntVertex]);
      colors.setXYZW(cntVertex, r, g, b, alpha);
    }
    colors.needsUpdate = true; // needed to finally display the color in the canvas
  }

  _prepareTransparency(transparent) {
    var frontSideMaterial = this.mesh.material;
    frontSideMaterial.transparent = true;

    var backSideOpacity;
    if (transparent) {
      frontSideMaterial.side = DoubleSide;

      // See also https://stackoverflow.com/questions/39414043/workaround-of-disabling-depth-testing-for-transparent-objects
      // and https://cs.wellesley.edu/~cs307/readings/transparencyNew.html.
      frontSideMaterial.depthTest = false;
      backSideOpacity = 0;
    } else {
      frontSideMaterial.side = FrontSide;
      frontSideMaterial.depthTest = true;

      backSideOpacity = backSideMeshOpacity;
    }

    if (this.backsideMesh?.material.opacity != undefined) {
      this.backsideMesh.material.opacity = backSideOpacity;
    }
  }

  _getAlpha(transparent) {
    let alpha;
    if (transparent) {
      alpha = frontSideMeshOpacity;
    } else {
      alpha = 1;
    }
    return alpha;
  }

  _getRgbaFeas(transparent, vertexValue) {
    var r, g, b, alpha;
    if (vertexValue) {
      r = 0.5137; // equals 131/255
      g = 0.7098; // equals 181/255
      b = 0.3725; // equals 95/255
      alpha = this._getAlpha(transparent);
    } else {
      r = 1;
      g = 0;
      b = 0;
      alpha = 1;
    }
    return [r, g, b, alpha];
  }
}

export class ConvexHullColorer extends MeshColorer {
  constructor(convexHullMesh) {
    super(convexHullMesh, null);
  }

  showMesh(show) {
    this.mesh.visible = show;
    this.facePickColoring(null); // initialize with default coloring
  }

  facePickColoring(face) {
    const transparent = true;
    const rgbaEvalFunc = (transparent, mapVal) => this._getRgbaPickFace(mapVal);

    let map = new Array(this.mesh.geometry.attributes.position.count).fill(false);
    if (face !== null) {
      face.toArray().forEach(vertexInd => {
        map[vertexInd] = true;
      });
    }

    this._setVertexColors(map, transparent, rgbaEvalFunc);
  }

  _getRgbaPickFace(vertexValue) {
    var r, g, b, alpha;
    if (vertexValue) {
      r = 0.2;
      g = 0.22;
      b = 0.23;
      alpha = 1;
    } else {
      r = 1;
      g = 1;
      b = 1;
      alpha = hullMeshOpacity;
    }
    return [r, g, b, alpha];
  }
}
