<template>
  <div class="flex-container w-full">
    <teleport v-if="isMounted" to="#sub-navigation__action-buttons">
      <div class="flex align-middle justify-center gap-8">
        <LibraryFilter @filter="getFilteredMaterials($event)" />
        <div class="mt-4">
          <DropdownMenu
            :list-options="tableDropdownOptions"
            title-icon="fas fa-ellipsis-h"
            close-click-style="always"
            class="border border-solid border-gray-10 rounded-8 pl-4"
            @update-option="tableAction($event)"
          />
        </div>
      </div>
    </teleport>
    <div class="flex-container flex-row table-title">
      <CustomMaterial :material-data="materialData" :type="type" @add-to-favourites="addToFavourites($event)" />
    </div>
    <table
      v-if="materials.length > 0"
      id="resizable-table"
      class="spark-table"
      style="width: 100%"
      :title="lockedForUser ? lockedTitle : ''"
    >
      <thead>
        <tr style="padding-left: 5px">
          <th />
          <th />
          <th
            v-for="col in selectedMaterialLibraryColumns"
            :id="col[0]"
            :key="col.index"
            @click="sort(col.sorting)"
            @mouseover="hover[col.title] = true"
            @mouseleave="hover[col.title] = false"
          >
            <div v-if="hover[col.title] || currentOrder === col.sorting">
              <Sorting :enabled="currentOrder === col.sorting" :reverse="currentOrderReverse" />
            </div>
            <span>{{ col.title }}</span>
          </th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody class="text-start" style="margin-top: 0px; padding-top: 0px; cursor: pointer">
        <TransitionGroup name="fadening">
          <!-- Iterate over parts -->
          <tr
            v-for="material in materials"
            :key="material"
            :style="selectedMatId === material.mat_id ? 'background-color: var(--light-color); font-weight: bold' : ''"
            :class="{ 'highlighted-material': highlightedMaterials.includes(material.mat_id) }"
          >
            <td v-if="material.favorite" key="" style="text-align: right" @click.stop="removeFromFavorites(material)">
              <i class="fas fa-star" title="Unset Favorite" />
            </td>
            <td v-else class="favourite" style="text-align: right" @click.stop="addToFavourites(material)">
              <i class="far fa-star" title="Set Favorite" />
            </td>
            <td v-if="material.inhouse" @click.stop="removeFromInHouse(material)">
              <img src="@/assets/icons/in_house_filled.svg" style="width: 16px; height: 16px" title="Unset In-House" />
            </td>
            <td v-else @click.stop="addToInHouse(material)">
              <img src="@/assets/icons/in_house.svg" style="width: 16px; height: 16px" title="Set In-House" />
            </td>
            <td v-for="col in selectedMaterialLibraryColumns" :key="col">
              {{ material[col['database']] }}
              <span v-if="material[col['database']] != null && col.unit" style="color: #9ba1a8">{{ col.unit }} </span>
            </td>
            <td class="favourite">
              <DropdownMenu
                :list-options="dropdownOptions[material.mat_id]"
                title-icon="fas fa-ellipsis-h"
                close-click-style="always"
                @update-option="materialEvent($event, material)"
              />
            </td>
          </tr>
        </TransitionGroup>
      </tbody>
    </table>
    <img
      v-else-if="materials.length === 0 && loading === true"
      src="@/assets/img/loading.gif"
      style="width: 50px; margin-left: auto; margin-right: auto; margin-top: auto; margin-bottom: auto"
    />
    <div v-else>
      <p>No materials found.</p>
    </div>
    <TablePagination :current-page="currentPage" :max-page-number="numberOfPages" @update-current-page="updateTable" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import LibraryFilter from '../../components/LibraryFilter.vue';

import CustomMaterial from './CustomMaterial.vue';

import DropdownMenu from '@/components/Reusable/DropdownMenu.vue';
import Sorting from '@/components/Reusable/Sorting.vue';
import TablePagination from '@/components/Reusable/TablePagination.vue';

export default {
  name: 'MaterialTable',

  components: {
    TablePagination,
    Sorting,
    CustomMaterial,
    DropdownMenu,
    LibraryFilter,
  },

  emits: ['mat-id', 'materialChangeNotAllowed'],

  data() {
    return {
      isMounted: false,
      materials: [],
      currentPage: undefined,
      numberOfPages: 1,
      filterParams: {},
      currentOrder: '',
      currentOrderReverse: true,
      materialData: {},
      highlightedMaterials: [],
      type: 'view',
      defaultDropdownOptions: [
        { text: 'View Details', value: 'view', icon: 'fas fa-glasses', disabled: false },
        { text: 'Add Similar Material', value: 'add', icon: 'fas fa-plus', disabled: false },
        { text: 'Edit Material', value: 'edit', icon: 'fas fa-pen', disabled: true },
        { text: 'Delete Material', value: 'delete', icon: 'fas fa-trash', disabled: true },
      ],

      ownDropdownOptions: [
        { text: 'View Details', value: 'view', icon: 'fas fa-glasses', disabled: false },
        { text: 'Add Similar Material', value: 'add', icon: 'fas fa-plus', disabled: false },
        { text: 'Edit Material', value: 'edit', icon: 'fas fa-pen', disabled: false },
        { text: 'Delete Material', value: 'delete', icon: 'fas fa-trash', disabled: false },
      ],

      dropdownOptions: {},

      tableDropdownOptions: [{ text: 'Edit Columns', value: 'editColumns', icon: '', disabled: false }],
      hover: {},
      loading: false,
    };
  },

  computed: {
    ...mapState(['part', 'user', 'investigationDetails', 'popup']),
    ...mapState('application', ['lockedTitle', 'axiosInstance']),
    ...mapGetters(['lockedForUser']),

    username() {
      return this.user.username;
    },

    activeInvestigationDetails() {
      return this.investigationDetails;
    },

    processChains() {
      return this.part.process_chains;
    },

    selectedMatId() {
      return this.processChains[this.activeInvestigationDetails.uid]?.mat_id;
    },

    materialLibraryColumns: {
      get() {
        let cols = this.user.user_settings.material_library_columns;
        // if (this.user.is_external === true) {
        //   cols = Object.fromEntries(Object.entries(cols).filter(([key, value]) => value.external === true));
        // }
        return cols;
      },

      set(columns) {
        this.setMaterialUserSettings();
        this.updateMaterialUserSettings(columns);
      },
    },

    // 2. get selected Part Library Columns
    selectedMaterialLibraryColumns() {
      let shown = [];
      for (let col in this.materialLibraryColumns) {
        if (this.materialLibraryColumns[col].selected === true) {
          shown.push(this.materialLibraryColumns[col]);
        }
      }
      // sort selected Columns
      shown.sort((a, b) => {
        return a.index - b.index;
      });
      return shown;
    },
  },

  watch: {
    popup(popup) {
      if (popup?.key === this.$options.name) {
        if (popup?.clicked === 'ok') {
          this.triggerPopup('');
          this.setMaterialUserSettings();
          this.updateMaterialUserSettings(this.materialLibraryColumns);
        } else if (popup?.clicked === 'cancel' || popup?.clicked === 'close') {
          this.triggerPopup('');
        }
      }
    },
  },

  mounted() {
    this.isMounted = true;
    this.getMaterials();
  },

  methods: {
    ...mapMutations(['updateMaterialUserSettings', 'triggerPopup']),

    addToFavourites(material) {
      this.axiosInstance
        .post(`api/v1/user-favorite-material/${material.mat_id}/`)
        .then(() => {
          material.favorite = true;
          this.getMaterials();
        })
        .catch(error => {
          if (error.response) {
            console.log(JSON.stringify(error));
          }
        });
    },

    removeFromFavorites(material) {
      this.axiosInstance
        .delete(`api/v1/user-favorite-material/${material.mat_id}/`)
        .then(() => {
          material.favorite = false;
        })
        .catch(error => {
          if (error.response) {
            console.log(JSON.stringify(error));
          }
        });
    },

    addToInHouse(material) {
      if (this.user.user_role !== 'manager') {
        this.$root.notify('warning', 'Only managers can edit the in-house status.', 3000);
        return;
      }
      this.axiosInstance
        .post(`api/v1/organization-inhouse-material/${material.mat_id}/`)
        .then(() => {
          material.inhouse = true;
          this.getMaterials();
        })
        .catch(error => {
          if (error.response) {
            console.log(JSON.stringify(error));
          }
        });
    },

    removeFromInHouse(material) {
      if (this.user.user_role !== 'manager') {
        this.$root.notify('warning', 'Only managers can edit the in-house status.', 3000);
        return;
      }
      this.axiosInstance
        .delete(`api/v1/organization-inhouse-material/${material.mat_id}/`)
        .then(() => {
          material.inhouse = false;
        })
        .catch(error => {
          if (error.response) {
            console.log(JSON.stringify(error));
          }
        });
    },

    highlightNewMaterial(mat_id) {
      this.highlightedMaterials.push(mat_id);
      setTimeout(() => {
        const index = this.highlightedMaterials.indexOf(mat_id);
        if (index > -1) {
          this.highlightedMaterials.splice(index, 1);
        }
      }, 60000); // Remove highlight after 1 minute
    },

    tableAction() {
      this.triggerPopup({
        key: this.$options.name,
        show: true,
        title: 'Edit Columns',
        selectOptions: this.materialLibraryColumns,
        type: 'multi',
        buttons: true,
        buttonContent: [
          { text: 'Cancel', type: 'outlined', value: 'cancel' },
          { text: 'Ok', type: 'secondary', value: 'ok' },
        ],
      });
    },

    calculateDropownOptions() {
      this.materials.forEach(mat => {
        if (mat.owner_username && (mat.owner_username === this.username || this.user.user_role === 'manager')) {
          this.dropdownOptions[mat.mat_id] = this.ownDropdownOptions;
        } else {
          this.dropdownOptions[mat.mat_id] = this.defaultDropdownOptions;
        }
      });
    },

    deleteMaterial(material) {
      this.axiosInstance
        .delete(`api/v1/material/${material.mat_id}/`)
        .then(response => {
          this.getMaterials();
          this.$root.notify('success', response.data, 3000);
        })
        .catch(error => {
          if (error.response) {
            this.$root.notify('warning', error.response.data.error_message, 3000);
          }
        });
    },

    getMaterial(material) {
      this.axiosInstance
        .get(`api/v1/material/${material.mat_id}`)
        .then(response => {
          this.materialData = {
            ...response.data,
            ...response.data.properties_am,
            ...response.data.properties_milling,
            ...response.data.properties_casting,
            ...response.data.properties_fire_and_smoke,
          };
        })
        .catch(error => {
          console.log(error);
        });
    },

    updateTable(pageNumber) {
      this.currentPage = pageNumber;
      this.getMaterials();
    },

    sort(str) {
      this.currentOrder = str;
      this.currentOrderReverse = !this.currentOrderReverse;
      this.filterParams['order_by'] = this.currentOrder;
      this.filterParams['order_reverse'] = this.currentOrderReverse;
      this.getMaterials();
    },

    getFilteredMaterials(event) {
      this.filterParams['filter_value'] = event;
      this.getMaterials();
    },

    addPrcAcronym(obj) {
      Object.keys(obj).forEach(key => {
        let prc_uid = obj[key]['prc_uid'];
        obj[key]['prc_acronym'] = this.user.organization.process_synonyms[prc_uid].prc_acronym;
      });
    },

    getMaterials() {
      let url = 'api/v1/material-library/';
      if (this.currentPage !== undefined) {
        url = `api/v1/material-library/?page=${this.currentPage}`;
      } else if (this.selectedMatId !== undefined) {
        url = `api/v1/material-library/${this.selectedMatId}/`;
      }
      // get paginated materials and not all via a prop
      this.loading = true;
      this.axiosInstance
        .get(url, {
          params: this.filterParams,
        })
        .then(response => {
          this.materials = response.data.results;
          this.addPrcAcronym(this.materials);
          this.numberOfPages = response.data.num_pages;
          this.currentPage = Number(response.data.page_number);
          this.calculateDropownOptions();
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
    },

    materialEvent(event, material) {
      this.type = event.value;
      if (event.value === 'add') {
        this.getMaterial(material);
      }
      if (event.value === 'delete') {
        if (material.owner_username != this.username && this.user.user_role !== 'manager') {
          this.$root.notify(
            'warning',
            'This Material cannot be deletetd. You can only delete your own material.',
            3000
          );
        } else {
          this.deleteMaterial(material);
        }
      }
      if (event.value === 'edit') {
        if (material.owner_username != this.username && this.user.user_role !== 'manager') {
          this.$root.notify('warning', 'This Material cannot be edited. You can only delete your own material.', 3000);
        } else {
          this.getMaterial(material);
        }
      }
      if (event.value === 'view') {
        this.getMaterial(material);
      }
    },

    async setMaterialUserSettings() {
      this.axiosInstance.put('/api/v1/user-settings/', {
        material_library_columns: this.materialLibraryColumns,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.favorite {
  visibility: hidden;
}

tr:hover td.favorite {
  visibility: visible;
}

th {
  height: 55px;
}

.star {
  color: #9ba1a8;
}

.star-favorite {
  color: var(--accent-color);
}

.checkbox-input {
  cursor: pointer !important;
  width: 13px;
  height: 13px;
  padding-left: 0;
}

.spark-table tr td {
  padding-right: 16px;
}

.highlighted-material {
  background-color: rgb(232, 255, 230);
  font-weight: bold;
}

.container {
  display: flex;
  flex-direction: column;
  font-size: var(--12px);
  justify-content: start;
}
</style>
