<template>
  <div class="container">
    <h5>Audit Log</h5>
    <DateFilter
      @selected="handleDateSelection"   
    />
    <input
      v-model="filterValue"
      type="text"
      placeholder="Filter logs..."
      class="spark-new-input-clean"
      @keydown.enter="updateFilter"
    />
    <hr />
    <table class="spark-table">
      <tr>
        <th @click="sortTable('date')" @mouseover="hover['date'] = true" @mouseleave="hover['date'] = false">
          Date
          <Sorting
            v-show="hover['date'] || currentOrder === 'date'"
            :enabled="currentOrder === 'date'"
            :reverse="currentOrderReverse"
          />
        </th>
        <th @click="sortTable('user')" @mouseover="hover['user'] = true" @mouseleave="hover['user'] = false">
          User
          <Sorting
            v-show="hover['user'] || currentOrder === 'user'"
            :enabled="currentOrder === 'user'"
            :reverse="currentOrderReverse"
          />
        </th>
        <th @click="sortTable('action')" @mouseover="hover['action'] = true" @mouseleave="hover['action'] = false">
          Action
          <Sorting
            v-show="hover['action'] || currentOrder === 'action'"
            :enabled="currentOrder === 'action'"
            :reverse="currentOrderReverse"
          />
        </th>
        <th @click="sortTable('model')" @mouseover="hover['model'] = true" @mouseleave="hover['model'] = false">
          Model
          <Sorting
            v-show="hover['model'] || currentOrder === 'model'"
            :enabled="currentOrder === 'model'"
            :reverse="currentOrderReverse"
          />
        </th>
        <th @click="sortTable('details')" @mouseover="hover['details'] = true" @mouseleave="hover['details'] = false">
          Details
          <Sorting
            v-show="hover['details'] || currentOrder === 'details'"
            :enabled="currentOrder === 'details'"
            :reverse="currentOrderReverse"
          />
        </th>
      </tr>
      <tr v-for="log in auditLogs" :key="log.id">
        <td>{{ formatDate(log.created) }}</td>
        <td>{{ log.data?.user?.username }}</td>
        <td>{{ log.action }}</td>
        <td>{{ log.model_name }}</td>
        <td>{{ log.data?.object_info }}</td>
      </tr>
    </table>
    <TablePagination :current-page="currentPage" :max-page-number="numberOfPages" @update-current-page="updateTable" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import DateFilter from '@/components/Reusable/Filter/DateFilter.vue';
import Sorting from '@/components/Reusable/Sorting.vue';
import TablePagination from '@/components/Reusable/TablePagination.vue';

export default {
  name: 'AuditLog',

  components: {
    DateFilter,
    TablePagination,
    Sorting,
  },

  data() {
    return {
      auditLogs: [],
      currentPage: undefined,
      numberOfPages: 1,
      currentOrder: '',
      currentOrderReverse: false,
      hover: {},
      filterValue: '',
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
  },

  mounted() {
    this.getAuditLogs();
  },

  methods: {
    // Fetch Audit Logs
    async getAuditLogs() {
      let params = {
        page: this.currentPage,
        filter_value: this.filterValue,
        start_date: this.startDate,
        end_date: this.endDate,
      };

      if (this.currentOrder) {
        params.sortKey = this.currentOrder;
      }
      if (this.currentOrderReverse) {
        params.sortOrder = 'asc';
      } else {
        params.sortOrder = 'desc';
      }
      this.axiosInstance
        .get('api/v1/audit-log/', { params })
        .then(response => {
          this.auditLogs = response.data.results;
          this.currentPage = Number(response.data.page_number);
          this.numberOfPages = response.data.num_pages;
        })
        .catch(error => {
          console.error('Error fetching audit logs:', error);
        });
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    },

    updateTable(pageNumber) {
      this.currentPage = pageNumber;
      this.getAuditLogs();
    },

    updateFilter() {
      this.currentPage = 1;
      this.getAuditLogs();
    },

    sortTable(column) {
      this.currentOrder = column;
      this.currentOrderReverse = !this.currentOrderReverse;
      this.getAuditLogs();
    },
    handleDateSelection(selectedDates) {
      if ( selectedDates.start_date) {
        this.startDate =  selectedDates.start_date;
      } else if (selectedDates.end_date) {
        this.endDate =  selectedDates.end_date;
      }
      this.getAuditLogs(); 
    }
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
}

th,
td {
  // border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  width: 20%;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.spark-new-input-clean {
  width: 270px;
}

.container {
  margin: var(--grid-outer-whitespace);
}
</style>
