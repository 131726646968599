<template>
  <fieldset class="flex p-16 rounded-8 border border-gray-100 overflow-hidden">
    <legend>
      {{ title }}
      <button
        :class="showButton ? 'visible' : 'invisible'"
        class="spark-btn secondary"
        type="button"
        @click="$emit('add', title)"
      >
        Add Step
      </button>
    </legend>
    <div class="flex flex-wrap max-w-full">
      <draggable class="grid grid-cols-4 gap-16" tag="div" :list="stepList" item-key="order" @end="reindexSteps">
        <template #item="{ element }">
          <div class="flex flex-col items-center">
            <div v-if="stepList.length > 1" class="mr-10 hover:cursor-grab">&#x2630;</div>
            <ProcessStep :step="Object.values(element)[0]" :level="level" :color="color" />
          </div>
        </template>
      </draggable>
    </div>
  </fieldset>
</template>

<script>
import draggable from 'vuedraggable';
import { mapState } from 'vuex';

import ProcessStep from '@/components/PartWorkflow/ProcessChain/ProcessStep.vue';

export default {
  name: 'ProcessStepGroup',

  components: { ProcessStep, draggable },

  props: {
    title: { type: String, default: '' },
    color: { type: String, default: '' },
    steps: { type: Object, default: () => {} },
    level: { type: Object, default: () => {} },
    showButton: { type: Boolean },
  },

  emits: ['add'],

  computed: {
    ...mapState('application', ['axiosInstance']),

    stepList() {
      if (this.steps) {
        let steps = Object.values(this.steps).map(step => {
          return { [step.process_step_id]: step };
        });
        const sortedSteps = steps.sort((a, b) => {
          const orderA = Object.values(a)[0].order;
          const orderB = Object.values(b)[0].order;
          return orderA - orderB;
        });

        return sortedSteps;
      }

      return [];
    },
  },

  methods: {
    reindexSteps() {
      let formData = {
        process_step_order: {},
      };
      this.stepList.forEach((step, index) => {
        Object.values(step)[0].order = index;
        formData.process_step_order[index] = Object.values(step)[0].process_step_id;
      });
      this.axiosInstance.put(`api/v1/process-step-list/`, formData).then(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
}

legend {
  font-size: var(--12px);
}

.secondary {
  background-color: white;
  color: var(--spark-darkgrey);
  &:hover {
    cursor: pointer;
  }
}
</style>
