import calibrationActions from './actions';
import calibrationGetters from './getters';
import calibrationMutations from './mutations';
import calibrationState from './state';
const calibrationModule = {
  state: calibrationState,
  getters: calibrationGetters,
  mutations: calibrationMutations,
  actions: calibrationActions,
  namespaced: true,
};
export default calibrationModule;
