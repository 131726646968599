<template>
  <div>
    <h5>Categories with fixed options <i class="fas fa-plus-square" @click="showDialogNewComboCategory()" /></h5>
    <div
      v-for="(category, id) in comboCategories"
      :key="category.id"
      class="flex text-11 items-center p-4"
      :class="categoryEditMode === id ? 'show-highlight' : ''"
    >
      <input
        id="category"
        v-model="category.label"
        :class="categoryEditMode === id ? 'input-edit-mode' : 'input-view-mode'"
        class="name-input"
        type="text"
        name="name"
        :disabled="categoryEditMode !== id"
        placeholder="Category Name"
      />
      
      <div v-if="id === categoryEditMode" class="flex-row">
        <SparkCheckbox v-model="category.required" name="required">
          <div class="text-13" v-text="'Required'" />
        </SparkCheckbox>
      </div>

      <div v-else class="flex-row">
        <div class="flex-row">
          <i v-if="category.required" class="fas fa-check category-checked" />
          <i v-else class="fas fa-xmark category-unchecked" />
          <span>required</span>
        </div>
      </div>


      <div class="items">
        <template v-for="(itemuid, item) in sortComboCategoryItems(category.items)" :key="item">
          <div class="item">
            <input v-if="id === categoryEditMode" v-model.lazy="category.items[itemuid]" class="item-input" />
            <span v-if="id !== categoryEditMode">{{ item }}</span>
            <i
              v-if="id === categoryEditMode"
              class="fas fa-times hover-pointer"
              @click="deleteComboCategoryItemCheck(itemuid, item, category.label)"
            />
          </div>
        </template>
        <div v-if="id === categoryEditMode">
          <input id="item" v-model="newItem" class="name-input" type="text" name="name" placeholder="New Item" />
          <i class="fas fa-plus icon-button" @click="addItemToCategory(id)" />
        </div>
        <span v-if="Object.keys(category.items).length == 0" class="item warning">Please add options</span>
      </div>

      <div v-if="id != categoryEditMode" class="flex-row icons-end">
        <i class="far fa-edit icon-button" @click="categoryInEditMode(id)" />
        <i class="far fa-trash-alt icon-button" @click="deleteCategoryCheck(id, category.label)" />
      </div>
      <div v-else class="flex-row icons-end">
        <i class="fas fa-check icon-button" @click="updateComboCategory(id, category, category.items)" />
      </div>
    </div>
    <div v-if="newCategoryEditMode" class="flex text-11 items-center p-4 show-highlight">
      <input
        id="category"
        v-model="newCategory.category_label"
        class="name-input input-edit-mode rounded-4"
        :class="markRedBorder ? 'red-border' : ''"
        type="text"
        name="name"
        placeholder="Category Name"
      />


      <input
        id="item"
        v-model="newItem"
        class="name-input input-edit-mode rounded-4"
        type="text"
        name="name"
        placeholder="New Item"
      />
      <i class="fas fa-plus icon-button" @click="addItemAndCheckCategory()" />
      <div class="flex-row icons-end">
        <i class="fas fa-check icon-button" @click="addNewComboCategory()" />
        <i class="fas fa-times icon-button" @click="closeNewCategory()" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import SparkCheckbox from '@/components/SparkComponents/SparkCheckbox.vue';
export default {
  name: 'PRPComboCategories',

  components: { SparkCheckbox },

  data() {
    return {
      categoryEditMode: '',
      newItem: '',
      newCategoryEditMode: false,
      newCategory: {
        category_label: '',
        required: false,
      },

      markRedBorder: false,
    };
  },

  computed: {
    ...mapState(['user', 'popup']),
    ...mapState('application', ['axiosInstance']),

    comboCategories() {
      return this.user.organization.prp_combo_categories;
    },
  },

  watch: {
    popup(popup) {
      if (popup?.clicked == 'ok') {
        if (popup?.key == this.$options.name + 'DeleteItem') {
          this.deleteComboCategoryItem(popup.additionalData);
          this.triggerPopup('');
        } else if (popup?.key == this.$options.name + 'DeleteCategory') {
          this.deleteComboCategory(popup.additionalData);
          this.triggerPopup('');
        }
      } else if (popup?.clicked == 'cancel' || popup?.clicked == 'close') {
        this.triggerPopup('');
      }
    },
  },

  methods: {
    ...mapMutations(['triggerPopup']),

    categoryInEditMode(uid) {
      this.categoryEditMode = uid;
    },

    showDialogNewComboCategory() {
      this.newCategoryEditMode = true;
    },

    addNewComboCategory() {
      if (this.newItem != '') {
        this.newCategory = { ...this.newCategory, ...{ item: this.newItem } };
      }
      this.newCategoryEditMode = false;
      this.axiosInstance.post('/api/v1/prp-combo-category-item/', this.newCategory).then(() => {
        this.$getUser(this.$keycloak, this.axiosInstance, this.$store);
        this.newCategory = {
          category_label: '',
          required: false,
        };
        this.markRedBorder = false;
      });
    },

    addItemAndCheckCategory() {
      if (this.newCategory?.category_label === undefined) {
        this.markRedBorder = true;
      } else {
        let formData = { ...this.newCategory, ...{ item: this.newItem } };
        this.axiosInstance.post('/api/v1/prp-combo-category-item/', formData).then(() => {
          this.$getUser(this.$keycloak, this.axiosInstance, this.$store);
          this.newCategoryEditMode = false;
          this.newCategory = {
            category_label: '',
          };
          this.newItem = '';
          this.markRedBorder = false;
        });
      }
    },

    closeNewCategory() {
      this.newCategory = {
        category_label: '',
      };
      this.newCategoryEditMode = false;
    },

    addItemToCategory(category) {
      let formData = {
        category_uid: category,
        item: this.newItem,
      };
      this.axiosInstance.post('/api/v1/prp-combo-category-item/', formData).then(() => {
        this.$getUser(this.$keycloak, this.axiosInstance, this.$store);
        this.newItem = '';
      });
    },

    updateComboCategory(categoryUid, category, items) {
      let formData = {
        category_label: category.label,
        required: category.required,
      };
      this.categoryEditMode = '';

      this.axiosInstance.put(`/api/v1/prp-combo-category/${categoryUid}/`, formData).then(() => {
        this.$getUser(this.$keycloak, this.axiosInstance, this.$store);
      });

      for (const [uid, value] of Object.entries(items)) {
        this.axiosInstance.put(`/api/v1/prp-combo-category-item/${uid}/`, { value: value });
      }
    },

    deleteCategoryCheck(categoryUid, categoryLabel) {
      this.triggerPopup({
        key: this.$options.name + 'DeleteCategory',
        show: true,
        title: 'Delete a category',
        message:
          'Do you really want to delete ' +
          categoryLabel +
          ' including all items? If there is data related to a part. THis will be also deleted.',
        additionalData: categoryUid,
        buttons: true,
        buttonContent: [
          { text: 'Cancel', type: 'outlined', value: 'cancel' },
          { text: 'Ok', type: 'secondary', value: 'ok' },
        ],
      });
    },

    deleteComboCategory(comboCategoryUid) {
      this.axiosInstance.delete(`/api/v1/prp-combo-category/${comboCategoryUid}`).then(() => {
        this.$getUser(this.$keycloak, this.axiosInstance, this.$store);
      });
    },

    deleteComboCategoryItemCheck(itemUid, itemLabel, categoryLabel) {
      this.triggerPopup({
        key: this.$options.name + 'DeleteItem',
        show: true,
        title: 'Delete a category item',
        message: 'Do you really want to delete ' + itemLabel + ' from category ' + categoryLabel + '?',
        additionalData: itemUid,
        buttons: true,
        buttonContent: [
          { text: 'Cancel', type: 'outlined', value: 'cancel' },
          { text: 'Ok', type: 'secondary', value: 'ok' },
        ],
      });
    },

    deleteComboCategoryItem(itemuid) {
      this.axiosInstance.delete(`/api/v1/prp-combo-category-item/${itemuid}`).then(() => {
        this.$getUser(this.$keycloak, this.axiosInstance, this.$store);
      });
    },

    sortComboCategoryItems(items) {
      let itemValues = Object.values(items);
      itemValues.sort((a, b) => a.localeCompare(b));
      itemValues = itemValues.reduce(
        (o, itemString) => ({ ...o, [itemString]: Object.keys(items).find(uid => items[uid] === itemString) }),
        {}
      );
      return itemValues;
    },
  },
};
</script>

<style lang="scss" scoped>
span {
  cursor: default;
}

.show-highlight {
  padding: 4px;
  border: 1px solid var(--accent-color);
  border-radius: 5px;
}

input {
  border: 1px solid var(--light-color);
  width: 20%;
  height: 2em;
  margin: 5px 3px 5px 0px;
}

.hover-pointer:hover {
  cursor: pointer;
}

.input-edit-mode {
  border: 1px solid var(--light-color);
}

.input-view-mode {
  border: 1px solid white;
  background-color: white;
  font-weight: bold;
}

.flex-row {
  display: flex;
  flex-wrap: nowrap;
}

.items {
  display: flex;
  flex-wrap: wrap;
  margin-left: 5px;
}

.name-input {
  min-width: 100px;
}

.item {
  display: flex;
  margin: 2px;
  background-color: #dbd3d8;
  border-radius: 10px;
  padding: 0px 5px;
  white-space: nowrap;
  align-items: center;
  width: auto;
}

.category-checked {
  color: var(--accent-color);
  padding-left: 10px;
}

.category-unchecked {
  color: red;
  padding-left: 10px;
}

.checkbox-item {
  display: flex;
  font-size: var(--10px);
  align-items: center;
  margin-right: 5px;
}

input[type='checkbox'] {
  height: 15px;
  &:hover {
    cursor: pointer;
  }
}

i {
  margin: 0px 5px;
}

.icons-end {
  justify-self: end;
  margin-left: auto;
  margin-right: 0;
}

.red-border {
  border: 1px solid red;
}

.warning {
  background-color: var(--spark-delete);
}

.item-input {
  flex-grow: 1;
}
</style>
