<template>
  <div class="flex-container">
    <AddBatchButton />
    <BatchTable />
  </div>
</template>

<script>
import AddBatchButton from './components/AddBatchButton.vue';
import BatchTable from './components/BatchTable.vue';

export default {
  name: 'Batch',

  components: {
    BatchTable,
    AddBatchButton,
  },

  data() {
    return {};
  },

  computed: {},

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.flex-container {
  display: flex;
  margin: var(--grid-outer-whitespace);
  margin-top: 0px;
  padding-bottom: 8px;
}
</style>
