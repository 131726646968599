<template>
  <div class="separator"></div>
</template>
<script>
export default {
  name: 'Separator',
};
</script>
<style lang="scss" scoped>
.separator {
  // border-bottom: 1px solid var(--light-color);
  // margin: 3px 15px var(--separator-whitespace) 15px;
  margin-bottom: 15px;
  max-width: 100%;
}

// .flex-container .separator {
// margin: 3px 0px 15px 0px;
// }
</style>
