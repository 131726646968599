import useNumberFormatting from '../composables/numberFormatting';
import usePriceFormatting from '../composables/priceFormatting';
import validateUrl from '../composables/validateUrl';

export default {
  install: app => {
    app.config.globalProperties.$formatPrice = key => usePriceFormatting(key).price;

    app.config.globalProperties.$formatThreeDecimalPlaces = key => useNumberFormatting(key, 3).number;
    app.config.globalProperties.$formatTwoDecimalPlaces = key => useNumberFormatting(key, 2).number;
    app.config.globalProperties.$formatOneDecimalPlace = key => useNumberFormatting(key, 1).number;
    app.config.globalProperties.$formatZeroDecimalPlaces = key => useNumberFormatting(key, 0).number;

    app.config.globalProperties.$validateUrl = key => validateUrl(key).validateBool;
  },
};
