<template>
  <apexchart :options="options" :series="series" />
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'DeviationChart',

  data: function () {
    return {
      options: {
        chart: {
          type: 'scatter',
          height: 'auto',
          zoom: {
            enabled: true,
            type: 'xy',
          },

          fontFamily: 'Poppins, Arial, sans-serif',
        },

        dataLabels: {
          enabled: false,
        },

        legend: {
          show: true,
          poistion: 'right',
        },

        xaxis: {
          title: { text: '' },
          labels: {
            formatter: function (val) {
              return parseFloat(val).toFixed(2);
            },
          },

          min: 0,
        },

        yaxis: {
          title: { text: '' },

          labels: {
            formatter: function (val) {
              return parseFloat(val).toFixed(2);
            },
          },

          min: 0,
        },
      },
    };
  },

  computed: {
    ...mapState('calibration', ['calibrationInstancesResult', 'selectedMode']),

    series() {
      let s = Object.keys(this.calibrationInstancesResult).map(key => ({
        name: this.calibrationInstancesResult[key].part_name,
        data: [
          [
            this.calibrationInstancesResult[key].value,
            this.calibrationInstancesResult[key].value + this.calibrationInstancesResult[key].deviation,
          ],
        ],
      }));
      return s;
    },
  },

  mounted() {
    this.getAxisLabel();
  },

  methods: {
    getAxisLabel() {
      if (this.selectedMode === 'machine') {
        this.options['yaxis']['title']['text'] = 'Predicted Build Time in h';
        this.options['xaxis']['title']['text'] = 'Build Time in h';
      } else {
        this.options['yaxis']['title']['text'] = 'Predicted Price in €';
        this.options['xaxis']['title']['text'] = 'Price in €';
      }
    },
  },
};
</script>
