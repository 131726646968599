<template>
  <div class="filter-element">
    <div class="filter-label">Own Parts</div>
    <div class="filtering">
      <Toggle :possible-classes="levels" :selected-class="selectedLevel" @selected="selectLevel($event)" />
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

import Toggle from '../Toggle.vue';

export default {
  name: 'OwnFilter',

  components: { Toggle },

  props: {
    reset: { type: Number, required: true },
    component: { type: String, required: true },
    label: { type: String, required: true },
    parent: { type: String, required: true },
  },

  emits: ['selected'],

  data() {
    return {
      levels: {
        organization: 'All',
        user: 'Own',
      },

      selectedLevel: {
        organization: 'All',
      },
    };
  },

  watch: {
    reset: {
      handler() {
        if (this.reset) {
          this.remove();
          this.selectedLevel = {
            organization: 'All',
          };
        }
      },
    },
  },

  mounted() {
    if (this.parent === 'library') {
      const filter = JSON.parse(sessionStorage.getItem('libraryFilter'));
      const owner = filter?.query_type;
      const partOwner = {};
      if (this.levels[owner]) {
        partOwner[owner] = this.levels[owner];
        this.selectedLevel = partOwner;
      } else {
        this.selectLevel(this.selectedLevel);
      }
    } else {
      this.selectLevel(this.selectedLevel);
    }
  },

  methods: {
    ...mapMutations(['disablePartLibraryFilter', 'resetFilter']),

    selectLevel(event) {
      this.selectedLevel = event;
      let query_type = Object.keys(this.selectedLevel)[0];
      this.$emit('selected', { query_type: query_type });
    },

    remove() {
      this.disablePartLibraryFilter({
        [this.label]: this.component,
      });
      this.resetFilter({ query_type: 'query_type' });
    },
  },
};
</script>

<style lang="scss" scoped>
.filtering {
  padding: 5px 0px;
}
</style>
