<template>
  <div class="file-upload flex flex-col gap-12">
    <Upload />
    <FileUploadModal :show="showFileUploadModal" @close="closeFileUploadModal" />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

import FileUploadModal from './modals/FileUploadModal.vue';
import Upload from './Upload.vue';

export default {
  name: 'FileUpload',

  components: { FileUploadModal, Upload },

  data() {
    return {
      showFileUploadModal: false,
    };
  },

  methods: {
    ...mapMutations('application', ['togglePrpSidebar']),

    ...mapMutations({
      resetPartToDefault: 'resetPart',
    }),

    closeFileUploadModal() {
      this.showFileUploadModal = false;
    },
  },
};
</script>
