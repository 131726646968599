<template>
  <div class="container">
    <h3>Additional Filters</h3>
    <div class="flex">
      <input
        v-model="newFieldLabel"
        required=""
        type="text"
        name="text"
        autocomplete="off"
        class="spark-new-input-clean input-small margin-left"
        placeholder="Type for new field"
        :disabled="false"
        maxlength="100"
      />
      <i class="fas fa-check icon-button" @click="addNewDescriptionLabel()" />
      <i class="fas fa-times icon-button" @click="discard()" />
    </div>
    <hr />
    <draggable :list="descriptionList" item-key="order" @end="reindexDescriptionLabels">
      {{ descriptionLabels }}
      <template #item="{ element }">
        <div class="draggable-item flex">
          <div class="drag-icon">&#x2630;</div>
          <input
            v-model.lazy="element.label"
            required=""
            type="text"
            name="text"
            autocomplete="off"
            class="spark-new-input-clean input-small"
            :disabled="false"
            maxlength="100"
            @blur="editDescriptionLabel(element.uid, element.label)"
          />
          <i
            v-if="element.label === 'Material' || element.label === 'Technology'"
            class="fas fa-fill icon-button"
            title="Auto-Fill"
            @click="autoFillDescriptionField(element.label)"
          />
          <i class="fas fa-trash icon-button" title="Delete Description" @click="deleteDescriptionLabel(element.uid)" />
        </div>
      </template>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapState } from 'vuex';

export default {
  name: 'TemplateDescription',

  components: { draggable },

  emits: ['changed-description'],

  data() {
    return {
      descriptionLabels: {},
      newFieldLabel: '',
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),

    descriptionList() {
      let descriptions = Object.values(this.descriptionLabels);
      return descriptions.sort((a, b) => a.order - b.order);
    },
  },

  mounted() {
    this.getDescriptionLabels();
  },

  methods: {
    async addNewDescriptionLabel() {
      let formData = {
        label: this.newFieldLabel,
      };
      await this.axiosInstance.post('/api/v1/description/', formData).then(() => {
        this.newFieldLabel = '';
        this.getDescriptionLabels();
        this.$emit('changed-description');
      });
    },

    async deleteDescriptionLabel(uid) {
      await this.axiosInstance.delete(`/api/v1/description/${uid}/`).then(() => {
        this.getDescriptionLabels();
        this.$emit('changed-description');
      });
    },

    async editDescriptionLabel(uid, label) {
      let formData = {
        label: label,
      };
      await this.axiosInstance.put(`/api/v1/description/${uid}/`, formData).then(() => {
        this.getDescriptionLabels();
        this.$emit('changed-description');
      });
    },

    async getDescriptionLabels() {
      await this.axiosInstance.get('/api/v1/description-list/').then(response => {
        this.descriptionLabels = response.data;
      });
    },

    async autoFillDescriptionField(field) {
      await this.axiosInstance.post(`/api/v1/prp-fill-description-values/`, { field_to_fill: field }).then(() => {
        this.getDescriptionLabels();
        this.$emit('changed-description');
      });
    },

    reindexDescriptionLabels() {
      let formData = {
        template_order: {},
      };
      this.descriptionList.forEach((description, index) => {
        description.order = index;
        formData.template_order[index] = description.uid;
      });
      this.axiosInstance.put(`api/v1/description-list/`, formData).then(() => {
        this.$emit('changed-description');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 30%;
  //   font-size: var(--10px)
}

.flex {
  display: flex;
  align-items: center;
}

.draggable-item {
  display: flex;
  align-items: center; /* Align icon and template details horizontally */
}

.drag-icon {
  margin-right: 10px; /* Add some spacing between icon and template details */
  &:hover {
    cursor: grab;
  }
}

i {
  font-size: var(--12px);
  margin: 0px 10px;
}

.input-small {
  font-size: var(--11px);
  max-width: 200px;
  margin: 3px;
  padding: 5px;
}

.margin-left {
  margin-left: 25px;
}
</style>
