<template>
  <div style="margin: 10px">
    <div 
      class="spark-new-input-group"
      title="The active Energy Mix Settings for CO&#8322; Estimation. New settings can be created by the manager of your organization via the organizational settings."
    >
      <select v-model="defaultConfig" class="spark-new-select-input" :disabled="disabledSetting" :title="title">
        <option v-for="(setting, key) in energyConfigs" :key="key" :value="key">
          {{ setting.name }}
        </option>
      </select>
      <label class="spark-new-select-label">Energy Mix (CO&#8322; Equivalent)</label>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'CarbonEquivalentSettings',

  props: {
    chain: Object,
    disabledSetting: Boolean,
    title: String,
  },

  computed: {
    ...mapState(['user']),
    ...mapState('application', ['axiosInstance']),

    energyConfigs() {
      return this.user.organization?.energy_configs;
    },

    defaultConfig: {
      get() {
        if (this.chain == undefined) {
          return this.user.organization.energy_config.uid;
        } else {
          return this.chain?.energy_config_id;
        }
      },

      set(value) {
        let formData = { energy_config_id: value };
        this.axiosInstance
          .put(`api/v1/process-chain/${this.chain.process_chain_id}/`, formData)
          .then(response => {
            this.updateSingleProcessChain(response.data);
          })
          .catch(error => {
            console.log('Save Process Chain Error', JSON.stringify(error));
          });
      },
    },
  },

  methods: {
    ...mapMutations(['updateSingleProcessChain']),
  },
};
</script>

<style lang="scss" scoped></style>
