<template>
  <details open>
    <summary>Manufacturing Options</summary>
    <table class="spark-table">
      <tr>
        <td colspan="5"><hr /></td>
      </tr>
      <tr>
        <th>Option</th>
        <th>Material</th>
        <th class="align-right">Price per Part</th>
        <th v-if="part.lot_size > 1" class="align-right">Price per Lot</th>
        <th class="align-right">Feasibility</th>
        <tbody v-for="field in additionalFieldsToTemplate" :key="field">
          <th />
        </tbody>
      </tr>
      <tr
        v-for="chain in sortedChains"
        :key="chain"
        class="row"
        :class="part.primary_process_chain_id === chain.process_chain_id && 'selected'"
        @click="selectChain(chain.process_chain_id)"
      >
        <td>{{ chain.name.split('|')[0] }}</td>
        <td>{{ chain.material_name }}</td>
        <td class="align-right">{{ $formatPrice(marketPricePerPart(chain)) }}</td>
        <td v-if="part.lot_size > 1" class="align-right">{{ $formatPrice(marketPricePerLot(chain)) }}</td>
        <td class="align-right"><ExternalFeasibility :process-chain="chain" /></td>
        <tbody v-for="field in additionalFieldsToTemplate" :key="field">
          <td>{{ field.label }}: {{ chain.description_items[field.label] }}</td>
        </tbody>
      </tr>
      <tr>
        <td colspan="5"><hr /></td>
      </tr>
    </table>
  </details>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import ExternalFeasibility from './ExternalFeasibility.vue';

export default {
  name: 'ExternalTemplateSelection',

  components: { ExternalFeasibility },

  data() {
    return {
      additionalFieldsToTemplate: {},
    };
  },

  computed: {
    ...mapState(['part']),
    ...mapState('application', ['axiosInstance']),
    ...mapGetters(['fileConversionFinished']),
    sortedChains() {
      let chains = Object.values(this.part.process_chains);
      return chains.sort((a, b) => a.order_index - b.order_index);
    },
  },

  mounted() {
    this.getTemplateDescriptionLabels();
  },

  methods: {
    selectChain(uid) {
      this.$store.commit('changeInvestigationDetails', { uid, content: '' });
      let formData = {
        primary_process_chain_id: uid,
        benchmark_process_chain_id: '',
      };
      this.axiosInstance.put(`/api/v1/part/${this.part.part_id}/`, formData).then(response => {
        this.$store.commit('changeProcessChainUids', formData);
        this.$store.commit('updatePart', response.data.part);
      });
    },

    marketPricePerLot(chain) {
      if (chain?.market_price.market_price_pl.calculated === undefined) return '-';
      return chain?.market_price.market_price_pl.calculated;
    },

    marketPricePerPart(chain) {
      if (chain?.market_price.market_price_pp.calculated === undefined) return '-';
      return chain?.market_price.market_price_pp.calculated;
    },

    async getTemplateDescriptionLabels() {
      await this.axiosInstance.get('/api/v1/description-list/').then(response => {
        this.additionalFieldsToTemplate = Object.values(response.data).sort((a, b) => a.order - b.order);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.selected {
  background-color: var(--lighter-color);
  border-radius: 3px;
  font-weight: bold;
  // border: 1px solid grey;
}

.row:hover {
  cursor: pointer;
}

.spark-table {
  width: 100%;
}

.align-right {
  text-align: right;
}

details {
  margin: 20px 0px 20px 10px;
  border: 1px solid var(--lighter-color);
  border-radius: 5px 5px 0px 0px;
}

summary {
  background-color: var(--lighter-color);
  padding: 10px;
  border-radius: 5px 5px 0px 0px;
}
</style>
