<template>
  <div class="flex flex-col bg-white border border-gray-100 rounded-8">
    <div class="mt-8">
      <div class="ml-20">Limits for machine and material parameters</div>
      <div class="text-gray-500 text-13 ml-20 mb-20">
        Prefilled through selected material and machine of the process chain template
      </div>
      <hr />
    </div>
    <div class="p-20">
      <div class="flex flex-row">
        <div class="w-1/2">
          <div class="text-13">Layer Height [mm]</div>
          <SparkSlider
            :min="selectedParameters.layer_height.abs_min"
            :max="selectedParameters.layer_height.abs_max"
            :step="0.01"
            type="layer_height"
            :default-min="selectedParameters.layer_height.min"
            :default-max="selectedParameters.layer_height.max"
            @update:model-value="handleUpdate"
          />
        </div>
        <div>
          <div class="text-13">Wall Thickness [mm]</div>
          <SparkSlider
            :min="selectedParameters.wall_thickness.abs_min"
            :max="selectedParameters.wall_thickness.abs_max"
            :step="0.01"
            type="wall_thickness"
            :default-min="selectedParameters.wall_thickness.min"
            :default-max="selectedParameters.wall_thickness.max"
            @update:model-value="handleUpdate"
          />
        </div>
      </div>
      <div class="flex flex-row">
        <div class="w-1/2">
          <div class="text-13">Infill Density Ratio</div>
          <SparkSlider
            :min="selectedParameters.infill_phi.abs_min"
            :max="selectedParameters.infill_phi.abs_max"
            :step="0.01"
            type="infill_phi"
            :default-min="selectedParameters.infill_phi.min"
            :default-max="selectedParameters.infill_phi.max"
            @update:model-value="handleUpdate"
          />
        </div>
        <div>
          <div class="text-13">Support Density Ratio</div>
          <SparkSlider
            :min="selectedParameters.support_density_ratio.abs_min"
            :max="selectedParameters.support_density_ratio.abs_max"
            :step="0.01"
            type="support_density_ratio"
            :default-min="selectedParameters.support_density_ratio.min"
            :default-max="selectedParameters.support_density_ratio.max"
            @update:model-value="handleUpdate"
          />
        </div>
      </div>

      <div class="flex flex-row">
        <div class="w-1/2">
          <div class="text-13">Core Build Rate [cm³/h]</div>
          <SparkSlider
            :min="selectedParameters.volumetric_build_rate_infill.abs_min"
            :max="selectedParameters.volumetric_build_rate_infill.abs_max"
            :step="0.01"
            type="volumetric_build_rate_infill"
            :default-min="selectedParameters.volumetric_build_rate_infill.min"
            :default-max="selectedParameters.volumetric_build_rate_infill.max"
            @update:model-value="handleUpdate"
          />
        </div>
        <div>
          <div class="text-13">Contour Build Rate [cm³/h]</div>
          <SparkSlider
            :min="selectedParameters.volumetric_build_rate_contour.abs_min"
            :max="selectedParameters.volumetric_build_rate_contour.abs_max"
            :step="0.01"
            type="volumetric_build_rate_contour"
            :default-min="selectedParameters.volumetric_build_rate_contour.min"
            :default-max="selectedParameters.volumetric_build_rate_contour.max"
            @update:model-value="handleUpdate"
          />
        </div>
      </div>

      <div class="flex flex-row">
        <div class="w-1/2">
          <div class="text-13">Time per Layer [s]</div>
          <SparkSlider
            :min="selectedParameters.time_per_layer.abs_min"
            :max="selectedParameters.time_per_layer.abs_max"
            :step="0.01"
            type="time_per_layer"
            :default-min="selectedParameters.time_per_layer.min"
            :default-max="selectedParameters.time_per_layer.max"
            @update:model-value="handleUpdate"
          />
        </div>
        <div>
          <div class="text-13">Fixed Machine Time [h]</div>
          <SparkSlider
            :min="selectedParameters.fixed_machine_time.abs_min"
            :max="selectedParameters.fixed_machine_time.abs_max"
            :step="0.01"
            type="fixed_machine_time"
            :default-min="selectedParameters.fixed_machine_time.min"
            :default-max="selectedParameters.fixed_machine_time.max"
            @update:model-value="handleUpdate"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import SparkSlider from '@/components/SparkComponents/SparkSlider.vue';

export default {
  name: 'MachineMaterialLimits',

  components: { SparkSlider },

  data() {
    return { finalValues: {} };
  },

  computed: {
    ...mapState('calibration', ['calibrationResult', 'selectedParameters']),

    selectedParameters: {
      get() {
        return this.calibrationResult.parameters;
      },

      set(value) {
        this.setSelectedParameters(value);
      },
    },
  },

  mounted() {
    this.finalValues = JSON.parse(JSON.stringify(this.selectedParameters));
  },

  unmounted() {
    this.setSelectedParameters(this.finalValues);
  },

  methods: {
    ...mapMutations('calibration', ['setSelectedParameters']),

    handleUpdate(newRange) {
      this.finalValues[newRange[0]]['min'] = newRange[1];
      this.finalValues[newRange[0]]['max'] = newRange[2];
    },
  },
};
</script>
