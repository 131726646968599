<template>
  <footer
    v-if="show"
    id="footer-content"
    class="footer-bar bg-white sticky bottom-0 z-10 grid h-64 flex-shrink-0 grid-cols-3 items-center border-t border-gray-50 px-20 py-12"
  />
</template>

<script>
export default {
  name: 'FooterBar',

  computed: {
    show() {
      return this.$route.meta.showFooter || false;
    },
  },
};
</script>
