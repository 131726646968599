<template>
  <div v-if="numberOfSelectedParts" class="flex gap-12 items-center">
    <div class="text-11 text-gray-500" v-text="`${numberOfSelectedParts} ${partSelectedText}`" />
    <SparkButton custom="!text-11 !p-0 !m-0" small variant="primary" @click="setProperties">
      <div v-text="'Set Properties'" />
    </SparkButton>
  </div>
</template>

<script>
import { mapState } from 'vuex/dist/vuex.cjs';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';

export default {
  name: 'PrpPartAction',

  components: { SparkButton },

  props: {
    partIds: { type: Array, default: () => [] },
  },

  emits: ['set-properties'],

  computed: {
    ...mapState('prp', ['selectedPrpParts']),

    partSelectedText() {
      return this.partIds.length > 1 ? 'Parts Selected' : 'Part Selected';
    },

    numberOfSelectedParts() {
      return Object.keys(this.selectedPrpParts).filter(key => this.selectedPrpParts[key]).length;
    },
  },

  methods: {
    setProperties() {
      this.$emit('set-properties');
    },
  },
};
</script>
