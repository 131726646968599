<template>
  <div>
    <div v-if="Object.keys(externalMarketPrices).length != 0">
      <div style="font-size: var(--12px)">Prices requested on {{ requestDate }}</div>
      <table class="spark-table">
        <thead>
          <tr>
            <th>Material</th>
            <th>Process</th>
            <th style="text-align: right">Price</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="price in externalMarketPrices.prices" :key="price">
            <td>{{ price.name }}</td>
            <td>{{ price.prc }}</td>
            <td style="text-align: right">{{ $formatTwoDecimalPlaces(price.price) }} {{ price.unit }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import FeedbackValue from '@/components/Reusable/FeedbackValues/FeedbackValue.vue';
import { floatFormat } from '@/helpers.js';

export default {
  name: 'ExternalMarketPrice',

  components: { FeedbackValue },

  data() {
    return {
      currentMargin: 20,
    };
  },

  computed: {
    ...mapState(['user', 'actualMarketPriceRequest', 'investigationDetails']),
    ...mapState('application', ['axiosInstance']),
    ...mapState({ storePart: 'part' }),
    ...mapGetters(['processChainsInitializationFinished', 'computationRunning']),
    requestDate() {
      let date = this.externalMarketPrices?.date;
      if (date === undefined) {
        return undefined;
      } else {
        return this.$dayjs(this.externalMarketPrices?.date).local().format('DD.MM.YYYY HH:mm');
      }
    },

    externalMarketPrices() {
      return this.part.market_prices_api;
    },

    part: {
      get() {
        return this.storePart;
      },

      set(value) {
        this.updatePart(value);
      },
    },

    boolProcessChainExists() {
      if (this.activeProcessChain == null) {
        return false;
      }
      if (this.activeProcessChain.cost != null) {
        return true;
      } else {
        return false;
      }
    },

    marketPricePerPart() {
      if (this.boolProcessChainExists) {
        return this.activeProcessChain.market_price.market_price_pp;
      } else return {};
    },

    amPartsPerJob() {
      if (this.boolProcessChainExists) {
        return this.activeProcessChain.parts_pj;
      }
    },

    activeInvestigationDetails() {
      return this.investigationDetails;
    },

    activeProcessChain() {
      if (this.activeInvestigationDetails != '') {
        let allChains = this.part.process_chains;
        return allChains[this.activeInvestigationDetails.uid];
      }
    },

    activeProcessChainIsAM() {
      return this.activeProcessChain?.tech == 'additive_manufacturing';
    },

    market() {
      return {};
    },

    costingConfig() {
      let costingConfig = {};
      if (this.user.organization !== undefined) {
        for (const [key, value] of Object.entries(this.user.organization.costing_configs)) {
          if (this.activeProcessChain !== undefined) {
            if (value.uid === this.activeProcessChain.costing_config_id) {
              costingConfig = value;
              if (this.activeProcessChain.margin_user_value !== null) {
                this.currentMargin = this.roundValue(this.activeProcessChain.margin_user_value * 100, 0);
              } else {
                this.currentMargin = this.roundValue(costingConfig.margin_default * 100, 0);
              }
            }
          }
        }
        // ! correct handling of costingConfig has to be checked in the template section !
      }
      return costingConfig;
    },
  },

  watch: {
    actualMarketPriceRequest: {
      handler(newVal) {
        // market Price Request for actual Part
        if (newVal === this.part.part_id) {
          this.requestMarketPrice();
        }
      },
    },
  },

  methods: {
    ...mapMutations([
      'updateSingleProcessChain',
      'updatePart',
      'updateMarketPricePerPartUserValue',
      'updateExternalMarketPrice',
      'setActualMarketPriceRequest',
    ]),

    roundValue(number, digit = 2) {
      return parseFloat(floatFormat(number, digit)).toFixed(digit);
    },

    setMargin(margin) {
      // sets the margin for the process chain
      let formData = {
        margin_user_value: margin / 100,
      };

      this.axiosInstance
        .put('api/v1/process-chain/' + this.activeProcessChain.process_chain_id + '/', formData)
        .then(response => {});
    },

    setMarketPricePerPartUserValue(user_value) {
      this.updateMarketPricePerPartUserValue({ uid: this.chain.process_chain_id, value: user_value });
      this.savePart();
    },

    savePart() {
      // This function will parse the current 'part' instance to the database.
      let formData = {
        market_price_pp: this.activeProcessChain.market_price.market_price_pp,
      };
      this.axiosInstance
        .put(`/api/v1/process-chain/${this.activeProcessChain.process_chain_id}/`, formData)
        .then(response => {
          this.updateSingleProcessChain(response);
        })
        .catch(error => {
          console.log(JSON.stringify(error));
        });
    },

    clearActualMarketPriceRequest() {
      if (this.actualMarketPriceRequest === this.part.part_id) {
        this.setActualMarketPriceRequest('');
      }
    },

    async requestMarketPrice() {
      // if (this.actualMarketPriceRequest === "") {
      // this.$store.commit("setActualMarketPriceRequest", this.part.part_id);
      this.updateExternalMarketPrice({});
      this.axiosInstance
        .get(`/api/v1/market-price/${this.part.part_id}`)
        .then(response => {
          // console.log(response.data);
        })
        .then(response => {
          // set Timeout, maybe websocket is killed
          setTimeout(this.clearActualMarketPriceRequest, 300000);
        })
        .catch(error => {
          console.log(JSON.stringify(error));
          this.setActualMarketPriceRequest('');
          // here notify??
        });
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.cost-table {
  display: inline-block;
  width: 60%;
}

.price-header {
  font-weight: bold;
}

.right-aligned {
  text-align: right;
}

.price-breakdown {
  font-size: var(--12px);
}

input[type='range'] {
  z-index: 5;
  position: relative;
  -webkit-appearance: none;
  background-color: #bdc3c7;
  width: 150px;
  height: 5px;
  border-radius: 5px;
  margin: 0 auto;
  outline: 0;
  opacity: 0.15;

  &:hover {
    height: 5px;
    opacity: 1;
  }

  &:before {
    opacity: 0;
  }
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: var(--spark-darkgrey);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid white;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:active {
    transform: scale(1.15);
  }
}
​ input[type='range']::-webkit-slider-thumb:hover {
  background-color: rgb(255, 0, 0);
  border: 2px solid #e74c3c;
}
</style>
