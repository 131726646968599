<template>
  <div class="container">
    <h5>Error Log</h5>
    <DateFilter
      @selected="handleDateSelection"   
    />
    <input
      v-model="filterValue"
      type="text"
      placeholder="Filter logs..."
      class="spark-new-input-clean"
      @keydown.enter="updateFilter"
    />
    <hr />
    <table class="spark-table">
      <tr>
        <th @click="sortTable('date')" @mouseover="hover['date'] = true" @mouseleave="hover['date'] = false">
          Date
          <Sorting
            v-show="hover['date'] || currentOrder === 'date'"
            :enabled="currentOrder === 'date'"
            :reverse="currentOrderReverse"
          />
        </th>
        <th @click="sortTable('user')" @mouseover="hover['user'] = true" @mouseleave="hover['user'] = false">
          User
          <Sorting
            v-show="hover['user'] || currentOrder === 'user'"
            :enabled="currentOrder === 'user'"
            :reverse="currentOrderReverse"
          />
        </th>
        <th @click="sortTable('api_endpoint')" @mouseover="hover['api_endpoint'] = true" @mouseleave="hover['api_endpoint'] = false">
          API Endpoint / Task
          <Sorting
            v-show="hover['api_endpoint'] || currentOrder === 'api_endpoint'"
            :enabled="currentOrder === 'api_endpoint'"
            :reverse="currentOrderReverse"
          />
        </th>
        <th @click="sortTable('type')" @mouseover="hover['type'] = true" @mouseleave="hover['type'] = false">
          Type
          <Sorting
            v-show="hover['type'] || currentOrder === 'type'"
            :enabled="currentOrder === 'type'"
            :reverse="currentOrderReverse"
          />
        </th>
        <th @click="sortTable('message')" @mouseover="hover['message'] = true" @mouseleave="hover['message'] = false">
          Message
          <Sorting
            v-show="hover['message'] || currentOrder === 'message'"
            :enabled="currentOrder === 'message'"
            :reverse="currentOrderReverse"
          />
        </th>
        <th @click="sortTable('traceback')" @mouseover="hover['traceback'] = true" @mouseleave="hover['traceback'] = false">
          Traceback
          <Sorting
            v-show="hover['traceback'] || currentOrder === 'traceback'"
            :enabled="currentOrder === 'traceback'"
            :reverse="currentOrderReverse"
          />
        </th>
      </tr>
      <tr v-for="log in ErrorLogs" :key="log.id">
        <td>{{ formatDate(log.created) }}</td>
        <td>{{ log.username || "Unknown User" }}</td>
        <td>{{ log.data?.api_endpoint || log.data?.task_name }}</td>
        <td>{{ log.error_type }}</td>
        <td>{{ log.message }}</td>
        <td>
          <button class='spark-btn' @click="toggleTraceback(log.id)">Show/Hide</button>
          <div v-if="visibleTracebacks.includes(log.id)">
            {{ log.data?.traceback || "No traceback available" }}
          </div>
        </td>
      </tr>
    </table>
    <TablePagination :current-page="currentPage" :max-page-number="numberOfPages" @update-current-page="updateTable" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import DateFilter from '@/components/Reusable/Filter/DateFilter.vue';
import Sorting from '@/components/Reusable/Sorting.vue';
import TablePagination from '@/components/Reusable/TablePagination.vue';

export default {
  name: 'ErrorLog',

  components: {
    DateFilter,
    TablePagination,
    Sorting,
  },

  data() {
    return {
      ErrorLogs: [],
      currentPage: undefined,
      numberOfPages: 1,
      currentOrder: '',
      currentOrderReverse: false,
      hover: {},
      filterValue: '',
      visibleTracebacks: [],
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
  },

  mounted() {
    this.getErrorLogs();
  },

  methods: {
    // Fetch Error Logs
    async getErrorLogs() {
      let params = {
        page: this.currentPage,
        filter_value: this.filterValue,
        start_date: this.startDate,
        end_date: this.endDate,
      };

      if (this.currentOrder) {
        params.sortKey = this.currentOrder;
      }
      if (this.currentOrderReverse) {
        params.sortOrder = 'asc';
      } else {
        params.sortOrder = 'desc';
      }
      this.axiosInstance
        .get('api/v1/error-log/', { params })
        .then(response => {
          this.ErrorLogs = response.data.results;
          this.currentPage = Number(response.data.page_number);
          this.numberOfPages = response.data.num_pages;
        })
        .catch(error => {
          console.error('Error fetching error logs:', error);
        });
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    },

    updateTable(pageNumber) {
      this.currentPage = pageNumber;
      this.getErrorLogs();
    },

    updateFilter() {
      this.currentPage = 1;
      this.getErrorLogs();
    },

    sortTable(column) {
      this.currentOrder = column;
      this.currentOrderReverse = !this.currentOrderReverse;
      this.getErrorLogs();
    },

    handleDateSelection(selectedDates) {
      if ( selectedDates.start_date) {
        this.startDate =  selectedDates.start_date;
      } else if (selectedDates.end_date) {
        this.endDate =  selectedDates.end_date;
      }
      this.getErrorLogs(); 
    },

    toggleTraceback(id) {
      const index = this.visibleTracebacks.indexOf(id);
      if (index > -1) {
        this.visibleTracebacks.splice(index, 1);
      } else {
        this.visibleTracebacks.push(id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
}

th,
td {
  // border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  width: 20%;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.spark-new-input-clean {
  width: 270px;
}

.container {
  margin: var(--grid-outer-whitespace);
}
</style>
