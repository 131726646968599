<template>
  <SparkModal custom="!min-w-[1200px]" :model-value="show" @update:model-value="close">
    <template #header>
      <div class="text-19 font-bold" v-text="'Order Details'" />
    </template>

    <template #default>
      <div
        v-for="(part, index) in orderDetails.prp_parts"
        :key="index"
        class="part mb-16 relative bg-white border rounded-8 p-24 border-x border-gray-200 h-[12.5rem] w-full flex justify-between"
      >
        <div class="flex gap-12">
          <PartImage v-if="part" :part="part" />
          <div class="flex flex-col gap-12">
            <div class="font-bold" v-text="part.name" />

            <div v-if="part.partDescription?.length">
              <table>
                <tr v-for="(descItem, descIndex) in part.partDescription" :key="descIndex">
                  <td class="text-gray-800 pr-8" v-text="descItem.descriptionLabel" />
                  <td class="text-gray-500" v-text="descItem.value" />
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="text-gray-800">
          <div class="flex justify-between gap-120 mb-12">
            <div class="flex gap-12 items-center">
              <div v-text="'Quantity'" />
              <SparkInput v-model="part.lot_size" readonly name="quantity" custom="text-right" class="w-60" />
            </div>
            <div class="text-primary-500 font-bold" v-text="part.priceRangePerLot" />
          </div>
        </div>
      </div>
      <OrderSummary class="mb-16" :cost-items="orderDetails.summary" />
      <CustomerInfo is-expert-view :combo-categories="comboCategories" :text-categories="textCategories" />
    </template>
  </SparkModal>
</template>

<script>
import { mapState } from 'vuex';

import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';
import CustomerInfo from '@/views/PartRequestPortal/components/CustomerInfo.vue';
import OrderSummary from '@/views/PartRequestPortal/components/OrderSummary.vue';
import PartImage from '@/views/PartRequestPortal/components/PartImage.vue';

export default {
  name: 'OrderDetailsModal',

  components: { SparkModal, SparkInput, PartImage, OrderSummary, CustomerInfo },

  props: {
    show: { type: Boolean, required: true },
    orderDetails: { type: Object, required: true },
    comboCategories: { type: Object, default: () => {} },
    textCategories: { type: Object, default: () => {} },
  },

  emits: ['close'],

  computed: {
    ...mapState('application', ['axiosInstance']),
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
