<template>
  <div>
    <div>Calibration mode</div>
    <div class="text-11">What do you want to calibrate</div>
    <div
      class="flex border border-gray-100 p-16 rounded-8 hover:cursor-pointer"
      :class="mode === 'price' ? 'bg-primary-50' : ''"
      @click="mode = 'price'"
    >
      <SparkRadio value="price" name="price" :model-value="mode" />
      <div>
        <div class="text-11">Target pricing</div>
        <div class="text-9">Calibration of a pricing based on the prices for different parts</div>
      </div>
    </div>
    <div
      class="flex border border-gray-100 p-16 mt-16 rounded-8 hover:cursor-pointer"
      :class="mode === 'machine' ? 'bg-primary-50' : ''"
      @click="mode = 'machine'"
    >
      <SparkRadio :model-value="mode" value="machine" name="machine" />
      <div>
        <div class="text-11">Machine and Material</div>
        <div class="text-9">Calibration of a machine based on the build times for different parts</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import SparkRadio from '../../../components/SparkComponents/SparkRadio.vue';

export default {
  name: 'CalibrationMode',

  components: {
    SparkRadio,
  },

  computed: {
    ...mapState('calibration', ['selectedMode']),

    mode: {
      get() {
        return this.selectedMode;
      },

      set(value) {
        this.setSelectedMode(value);
      },
    },
  },

  methods: {
    ...mapMutations('calibration', ['setSelectedMode']),
  },
};
</script>
