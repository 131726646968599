<template>
    <ul v-if="showSuggestions && filteredMaterials.length" class="suggestions-dropdown">
      <li
        v-for="(material, index) in filteredMaterials"
        :key="material"
        :class="{ active: index === selectedIndex }"
        @mousedown.prevent="selectSuggestion(material)"
      >
        {{ material }}
      </li>
    </ul>
  </template>
  
  <script>
  export default {
    name: 'MaterialSuggestions',

    props: {
      showSuggestions: {
        type: Boolean,
        required: true
      },

      filteredMaterials: {
        type: Array,
        required: true
      },

      selectedIndex: {
        type: Number,
        required: true
      }
    },

    emits: ['select-suggestion'],

    methods: {
      selectSuggestion(material) {
        this.$emit('select-suggestion', material);
      }
    }
  }
  </script>
  
  <style scoped>
  .suggestions-dropdown {
    border: 1px solid #ccc;
    max-height: 300px;
    overflow-y: auto;
    position: absolute;
    width: 200px;
    background-color: white;
    z-index: 10;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .suggestions-dropdown li {
    padding: 10px;
    cursor: pointer;
  }
  
  .suggestions-dropdown li.active {
    background-color: #f0f0f0;
  }
  </style>