<template>
  <div class="filter-element">
    <div class="filter-label" v-text="label" />
    <div class="filtering">
      <VueMultiselect
        v-model="selectedCategories"
        :options="categoryOptions"
        multiple
        :close-on-select="false"
        :limit="2"
        :limit-text="limitText"
        placeholder="Pick some"
        :disabled="isLoading"
      />
    </div>
    <div
      :style="{ visibility: selectedCategories.length != 0 ? 'visible' : 'hidden' }"
      class="clear-filtering optional"
      @click="clear()"
    >
      Clear
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import VueMultiselect from 'vue-multiselect';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'ComboCategoryFilter',

  components: {
    VueMultiselect,
  },

  props: {
    reset: { type: Number, required: true },
    label: { type: String, required: true },
    component: { type: String, required: true },
    parent: { type: String, required: true },
  },

  emits: ['selected'],

  data() {
    return {
      selectedCategories: [],
      categoryOptions: [],
      isLoading: false, // controsl the disabled state of the VueMultiselect component
    };
  },

  computed: {
    ...mapState(['user', 'partLibraryFilters']),

    comboCategories() {
      return this.user.organization.combo_categories;
    },
  },

  watch: {
    selectedCategories: {
      handler(newVal) {
        // Use a debounced function to prevent overwhelming the backend with requests
        this.debouncedOnSelectedCategoriesChange(newVal);
      },

      deep: true,
    },

    reset: {
      handler() {
        if (this.reset) {
          this.remove();
        }
      },
    },
  },

  mounted() {
    this.getAllItems();
    if (this.parent === 'library') {
      const filter = JSON.parse(sessionStorage.getItem('libraryFilter'));
      const categories = filter.combo_categories;
      if (Object.keys(categories).length) {
        const selected = categories[this.getCategoryUid(this.label)];
        if (selected != undefined) {
          this.selectedCategories = this.getItemLabels(selected);
        }
      }
    }
    // Debounced function to handle the selectedCategories change event, wihch is just called after some debounce time. in this case, 300 milliseconds.
    this.debouncedOnSelectedCategoriesChange = _.debounce(this.onSelectedCategoriesChange, 300);
  },

  methods: {
    ...mapMutations(['disablePartLibraryFilter', 'resetFilter']),

    remove() {
      this.disablePartLibraryFilter({
        [this.label]: this.component,
      });
      this.clear();
    },

    clear() {
      this.selectedCategories = [];
      this.resetFilter({
        combo_categories: this.getCategoryUid(this.label),
      });
    },

    getAllItems() {
      Object.values(this.comboCategories).forEach(cat => {
        if (cat.label === this.label) {
          this.categoryOptions = Object.values(cat.items);
        }
      });
      this.categoryOptions[this.categoryOptions.length] = 'No Category';
      // sort optiona a - z
      this.categoryOptions = this.categoryOptions.sort((a, b) => a.localeCompare(b));
    },

    getItemUids(itemLabels) {
      let uids = [];
      Object.values(this.comboCategories).forEach(cat => {
        if (cat.label === this.label) {
          for (let item of Object.values(itemLabels)) {
            uids.push(Object.keys(cat.items).find(key => cat.items[key] === item));
          }
        }
      });
      return uids;
    },

    getItemLabels(itemUids) {
      let labels = [];
      Object.values(this.comboCategories).forEach(cat => {
        if (cat.label === this.label) {
          for (let item of Object.values(itemUids)) {
            labels.push(cat.items[item]);
          }
        }
      });
      return labels;
    },

    getCategoryUid(categoryName) {
      let categoryUid = '';
      Object.keys(this.comboCategories).forEach(uid => {
        if (this.comboCategories[uid].label === categoryName) {
          categoryUid = uid;
        }
      });
      return categoryUid;
    },

    limitText() {
      return `...`;
    },

    // method to handle the selectedCategories change event. Basically what we had before inside watch I brought it here but with the isLoading = true
    onSelectedCategoriesChange(newVal) {
      this.isLoading = true;
      let uid = this.getCategoryUid(this.label);
      this.$emit('selected', {
        combo_categories: { [uid]: this.getItemUids(newVal) },
      });
      if (newVal.length === 0) {
        this.resetFilter({
          combo_categories: this.getCategoryUid(this.label),
        });
      }
      // Simulate an API call to get the filtered results.
      setTimeout(() => {
        this.isLoading = false; // Set isLoading to false to re-enable the VueMultiselect component once filtered results are received
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
.multiselect {
  font-size: var(--12px);
  width: 400px;
}

.multiselect__tags {
  padding: 5px 40px 0px 3px;
}

.multiselect__tag {
  padding: 0px;
  font-size: var(--9px);
}

.multiselect__input {
  font-size: var(--9px);
}
</style>
