<template>
  <div class="container">
    <h3>
      PRP Profile <Badge v-if="profile && profile.restriction == 'external'" title="External" selected-style="purple" />
    </h3>

    {{ profile?.name }}

    <table class="spark-table my-20">
      <tr class="border-b">
        <th>Process Chain Name</th>
        <th>Process</th>
        <th>Machine</th>
        <th>Material</th>
        <th class="number-align border-right">Average Cost per Kg</th>
        <th>Filters</th>
      </tr>
      <tr v-for="template in profile?.process_chain_templates" :key="template" class="even:bg-gray-50">
        <td>{{ template.name }}</td>
        <td>{{ template.prc_name }}</td>
        <td>{{ template.machine }}</td>
        <td>{{ template.material_name }}</td>
        <td class="number-align border-right">{{ $formatPrice(template.avg_cost_per_kg) }}</td>
        <td>
          <table v-for="field in additionalFieldsToTemplate" :key="field">
            <tr>
              <th class="col-width">
                {{ field.label }}
              </th>
              <td>
                <input
                  v-model="template.process_chain_template_description.description_items[field.uid]"
                  required=""
                  type="text"
                  name="text"
                  autocomplete="off"
                  class="spark-new-input-clean"
                  :disabled="false"
                  maxlength="250"
                  @change="
                    setValueToDescriptionLabel(
                      template.uid,
                      template.process_chain_template_description.description_items[field.uid],
                      field.uid
                    )
                  "
                />
              </td>
            </tr>
          </table>
        </td>
        <td />
      </tr>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Badge from '@/components/Reusable/Badge.vue';

export default {
  name: 'ExternalProfile',

  components: { Badge },

  props: {
    reloadTemplates: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      profile: {},
      additionalFieldsToTemplate: {},
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
  },

  watch: {
    reloadTemplates(newVal) {
      this.getExternalProfile();
      this.getTemplateDescriptionLabels();
    },
  },

  mounted() {
    this.getExternalProfile();
    this.getTemplateDescriptionLabels();
  },

  methods: {
    async getExternalProfile() {
      this.axiosInstance.get(`/api/v1/analysis-profile-list/`).then(response => {
        let profiles = response.data;
        let externalProfileUid = Object.keys(profiles).find(uid => profiles[uid].restriction === 'external');
        this.profile = profiles[externalProfileUid];
      });
    },

    async getTemplateDescriptionLabels() {
      await this.axiosInstance.get('/api/v1/description-list/').then(response => {
        this.additionalFieldsToTemplate = Object.values(response.data).sort((a, b) => a.order - b.order);
      });
    },

    async setValueToDescriptionLabel(templateUid, value, labelUid) {
      let formData = { process_chain_template_id: templateUid, description_id: labelUid, value: value };
      await this.axiosInstance.post(`/api/v1/template-description/`, formData).then(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.border-right {
  border-right: 1px solid var(--light-color);
}

.spark-table {
  width: 100%;
}

.number-align {
  text-align: right;
}

th {
  padding: 0px 10px;
}

table {
  padding: 10px;
}

input {
  margin: unset;
  margin: 5px;
  padding: unset;
}

.col-width {
  width: 150px;
}
</style>
