<template>
  <div>
    <div class="flex gap-8 items-center">
      <VueMultiselect
        v-model="selectedExpressionOptions"
        :options="expressionOptions"
        multiple
        :close-on-select="false"
        :limit="10"
        :limit-text="limitText"
        placeholder="Select expression options"
        label="displayName"
        @remove="handleExpressionOptionRemoval"
      >
        <!-- Custom Option Template -->
        <template #option="props">
          <div class="w-full h-full">
            {{ props.option.displayName }}
          </div>
        </template>
      </VueMultiselect>
      <SparkButton variant="outlined" custom="min-w-[120px]" small @click="openAddScalarModal">
        <span class="font-bold" v-text="'Add scalar'" />
      </SparkButton>
      <SparkButton variant="outlined" small>
        <span class="font-bold"><i class="fas fa-check text-primary-500" /></span>
      </SparkButton>
    </div>

    <AddScalarModal
      type="expression"
      :show="showAddScalarModal"
      @close="showAddScalarModal = false"
      @value="addScalarValue"
    />
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import { operators, operands } from '@/expression';
import AddScalarModal from '@/views/Pricing/modals/AddScalarModal.vue';

export default {
  name: 'Expression',

  components: { VueMultiselect, SparkButton, AddScalarModal },

  props: {
    clearInputs: { type: Boolean },
  },

  emits: ['expression-input'],

  data() {
    return {
      selectedExpressionOptions: [],
      expressionOptions: [...operands.items, ...operators.items],
      showAddScalarModal: false,
    };
  },

  computed: {
    expressionReal() {
      return this.selectedExpressionOptions.map(option => option.variableName).join(' ');
    },
  },

  watch: {
    selectedExpressionOptions: {
      handler() {
        this.$emit('expression-input', this.expressionReal);
      },

      deep: true,
    },

    clearInputs: {
      handler() {
        this.selectedExpressionOptions = [];
      },
    },
  },

  methods: {
    handleExpressionOptionRemoval(removedOption) {
      const updatedOptions = this.selectedExpressionOptions.filter(
        option => option.variableName !== removedOption.variableName
      );

      this.selectedExpressionOptions = this.ensureUniqueOptions(updatedOptions);
    },

    limitText(count) {
      return `and ${count} more`;
    },

    openAddScalarModal() {
      this.showAddScalarModal = true;
    },

    addScalarValue(data) {
      if (data.type === 'expression') {
        const id = this.generateRandomId();
        this.expressionOptions.unshift({
          displayName: data.value,
          variableName: data.value,
          variableType: 'scalar',
          id,
        });
      }
    },

    generateRandomId() {
      const randomId = Math.random().toString(16).substring(2);
      return randomId;
    },

    ensureUniqueOptions(options) {
      const uniqueOptions = [];
      const seenNames = new Set();
      for (const option of options) {
        if (!seenNames.has(option.displayName)) {
          seenNames.add(option.displayName);
          uniqueOptions.push(option);
        }
      }

      return uniqueOptions;
    },
  },
};
</script>
