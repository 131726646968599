import { Matrix4, MathUtils } from 'three';

/**
 * Extract euler angles ('XYZ' order) in radians from transformation matrix.
 * @param matrix Transformation matrix (type: THREE.Matrix4).
 * @returns The Euler angles in radians.
 */
function eulerRadFromMatrix(matrix) {
  // Algorithm parameters
  let _EPS = 0.000000000000001;
  let i = 2;
  let j = 1;
  let k = 0;

  // Extract Rotation sub-matrix [:3][:3]
  let ma = matrix.toArray(); // Convert THREE.Matrix4 to array
  let M = [
    [ma[0], ma[1], ma[2]],
    [ma[4], ma[5], ma[6]],
    [ma[8], ma[9], ma[10]],
  ];

  let ax = null;
  let ay = null;
  let az = null;

  let cy = Math.sqrt(M[i][i] * M[i][i] + M[j][i] * M[j][i]);
  if (cy > _EPS) {
    ax = Math.atan2(M[k][j], M[k][k]);
    ay = Math.atan2(-M[k][i], cy);
    az = Math.atan2(M[j][i], M[i][i]);
  } else {
    ax = Math.atan2(-M[j][k], M[j][j]);
    ay = Math.atan2(-M[k][i], cy);
    az = 0.0;
  }

  // parity
  ax = -ax;
  ay = -ay;
  az = -az;

  // frame
  let ax_tmp = ax;
  ax = az;
  az = ax_tmp;

  return [ax, ay, az];
}

/**
 * Converts kardan angles to one of the euler angle sets.
 * @param kardanRotXRad Global rotation around the X axis in radians.
 * @param kardanRotYRad Global rotation around the Y axis in radians.
 * @param kardanRotZRad Global rotation around the Z axis in radians.
 * @returns Euler angles in radians.
 */
function kardanToEulerRad(kardanRotXRad, kardanRotYRad, kardanRotZRad) {
  let Rx = new Matrix4().makeRotationX(-kardanRotXRad);
  let Ry = new Matrix4().makeRotationY(-kardanRotYRad);
  let Rz = new Matrix4().makeRotationZ(-kardanRotZRad);

  let Ryx = new Matrix4().multiplyMatrices(Rx, Ry);
  let R = new Matrix4().multiplyMatrices(Ryx, Rz);

  return eulerRadFromMatrix(R);
}

/**
 * Converts kardan angles to one of the euler angle sets.
 * @param kardanRotXRad Global rotation around the X axis in degrees.
 * @param kardanRotYRad Global rotation around the Y axis in degrees.
 * @param kardanRotZRad Global rotation around the Z axis in degrees.
 * @returns Euler angles in degrees.
 */
function kardanToEulerDeg(kardanRotXDeg, kardanRotYDeg, kardanRotZDeg) {
  let kardanRotXRad = MathUtils.degToRad(kardanRotXDeg);
  let kardanRotYRad = MathUtils.degToRad(kardanRotYDeg);
  let kardanRotZRad = MathUtils.degToRad(kardanRotZDeg);

  let angles = kardanToEulerRad(kardanRotXRad, kardanRotYRad, kardanRotZRad);

  let eulerRotXDeg = MathUtils.radToDeg(angles[0]);
  let eulerRotYDeg = MathUtils.radToDeg(angles[1]);
  let eulerRotZDeg = MathUtils.radToDeg(angles[2]);

  return [eulerRotXDeg, eulerRotYDeg, eulerRotZDeg];
}

export { eulerRadFromMatrix, kardanToEulerRad, kardanToEulerDeg };
