<template>
  <div>
    <div v-if="maxPageNumber > 1" class="pagination-container">
      <button
        type="button"
        class="spark-btn previous-btn"
        :disabled="currentPage === 1"
        @click="updatePage(currentPage - 1)"
      >
        {{ previousText }}
      </button>
      <div v-for="page in pageArray" :key="page" style="display: inline-block">
        <button v-if="page == 'goto'" type="button" class="spark-btn">
          <input
            id="page"
            v-model="jumpedPage"
            class="pagination-input icon"
            type="text"
            placeholder="Go To"
            @blur="navigateToPage"
            @keyup.enter="navigateToPage"
          />
        </button>
        <button
          v-else
          type="button"
          class="spark-btn"
          :class="page === currentPage ? 'active' : ''"
          @click="updatePage(page)"
        >
          {{ page }}
        </button>
      </div>
      <button
        type="button"
        class="spark-btn next-btn"
        :disabled="currentPage === maxPageNumber"
        @click="updatePage(currentPage + 1)"
      >
        {{ nextText }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TablePagination',

  props: {
    maxPageNumber: { type: Number, default: 1 },
    currentPage: { type: Number, default: 1 },
  },

  emits: ['update-current-page'],

  data() {
    return {
      pageValue: '',
      pageInputValid: false,
      showPageInput: false,
      pageArray: [],
      previousText: '< Previous',
      nextText: 'Next >',
      jumpedPage: null,
    };
  },

  watch: {
    maxPageNumber() {
      this.getPageArray(this.currentPage);
    },

    currentPage: {
      handler(newVal) {
        this.getPageArray(this.currentPage);
      },
    },

    pageValue: {
      handler() {
        this.validatePageInput();
      },
    },
  },

  mounted() {
    this.getPageArray(this.currentPage);
  },

  methods: {
    getPageArray(newPage) {
      if (typeof newPage === 'number') {
        let naivePageArray = [1, newPage - 2, newPage - 1, newPage, newPage + 1, newPage + 2, this.maxPageNumber];
        let uniqueElements = naivePageArray.filter((val, ind, arr) => arr.indexOf(val) === ind);
        let removedBottomValues = uniqueElements.filter(item => item >= 1);
        let removedTopValues = removedBottomValues.filter(item => item <= this.maxPageNumber);

        let deltas = removedTopValues.map((element, index, array) => element - (array[index - 1] || 0));
        if (deltas.every(val => val === 1)) {
          this.pageArray = removedTopValues;
        } else {
          let foundValues = deltas.filter(element => element > 1);
          foundValues.forEach(value => {
            let indexOfBiggestDelta = deltas.findIndex(element => element == value);
            if (value > 2) {
              removedTopValues.splice(indexOfBiggestDelta, 0, '..');
              deltas.splice(indexOfBiggestDelta, 0, 1);
            }
            // // = 2
            else {
              let valueToInsert = removedTopValues[indexOfBiggestDelta - 1] + 1;
              removedTopValues.splice(indexOfBiggestDelta, 0, valueToInsert);
              deltas.splice(indexOfBiggestDelta, 0, 1);
            }
          });
          this.pageArray = removedTopValues;
          this.pageArray.push('goto');
        }
      }
    },

    updatePage(page) {
      if (page === '..') {
        this.showPageInput = !this.showPageInput;
        return;
      }

      this.getPageArray(this.currentPage);
      this.$emit('update-current-page', page);
    },

    validatePageInput() {
      const regex = /^\d+$/;
      const isValid = regex.test(this.pageValue);

      if (isValid && this.pageValue <= this.maxPageNumber && this.pageValue > 0) {
        this.pageInputValid = true;
      } else {
        this.pageInputValid = false;
      }
    },

    navigateToPage() {
      this.pageValue = this.jumpedPage;
      this.validatePageInput();
      if (this.pageInputValid) {
        this.updatePage(Number(this.pageValue));
        this.jumpedPage = null;
      }
      this.showPageInput = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.spark-btn {
  //color: var(--lighter-color);
  padding: 0;
  margin: 0;
  height: 2em;
  min-width: 2em;
  border-radius: 0;
  background-color: var(--lighter-color);
  color: var(--primary-color);
  border: var(--primary-color-light);

  &:hover {
    background-color: var(--primary-color-light);
    color: var(--primary-color);
  }

  &:disabled {
    &:hover {
      background-color: var(--light-grey);
    }
  }
}

.pagination-input {
  padding: 0;
  margin: 0;
  padding-left: 25px;
  padding-bottom: 0.05em;
  height: 2.2em;
  min-width: 2em;
  max-width: 8em;
  border-radius: 0;
  background-color: var(--lighter-color);
  color: var(--primary-color);
  border: var(--primary-color-light);
  font-size: 10px;

  &:hover {
    background-color: var(--light-grey);
    color: white;
  }

  // &:disabled {
  //   &:hover {
  //     background-color: var(--light-grey);
  //   }
  // }
  &:focus {
    border: 1px solid var(--spark-grey);
  }
}

.previous-btn {
  border-radius: 5px 0px 0px 5px;
  padding: 0px 2px;
}

.next-btn {
  border-radius: 0px 5px 5px 0px;
  padding: 0px 2px;
}

.active {
  background-color: var(--accent-color);
}

.pagination-container {
  display: block;
  text-align: center;
  margin: 20px;
  font-size: var(--11px);
}

.page-input__container {
  text-align: center;
  font-size: var(--11px);
}

.page-input {
  width: 100px;
  height: 24px !important;
}

.page-input__btn {
  width: 31px;
}
</style>
