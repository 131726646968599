<template>
  <div>
    <div v-if="meshChecksExisting" style="background-color: white">
      <div class="title">
        Mesh Checks
        <i class="icon-button fas fa-times" @click="hideMeshChecks()" />
      </div>
      <table class="spark-table" style="margin-left: 10px">
        <tbody>
          <tr v-if="uploadFileChecks">
            <th colspan="3" title="Checks performed on the uploaded CAD file">Upload File</th>
          </tr>
          <tr v-for="(key, check) in uploadFileChecks" :key="check">
            <td :title="key.tooltip_hover_text">{{ check }}</td>
            <td v-if="key.check_passed === true">
              <i class="fas fa-check" style="color: var(--spark-check-green)" />
            </td>
            <td v-else-if="key.check_passed === false">
              <i class="fas fa-exclamation" style="color: var(--spark-warning)" />
            </td>
            <td v-else>&nbsp;</td>
            <td>{{ key.user_message }}</td>
          </tr>
          <tr v-if="initialMeshActions">
            <th
              colspan="3"
              title="The uploaded CAD file is converted to a mesh on which the analysis is performed. &#010;The initial analysis mesh required the operations listed below."
            >
              Performed Actions on Upload File Mesh
            </th>
          </tr>
          <tr v-for="(key, check) in initialMeshActions" :key="check">
            <td :title="key.tooltip_hover_text">{{ check }}</td>
            <td v-if="key.check_passed === true">
              <i class="fas fa-check" style="color: var(--spark-check-green)" />
            </td>
            <td v-else-if="key.check_passed === false">
              <i class="fas fa-exclamation" style="color: var(--spark-warning)" />
            </td>
            <td v-else>&nbsp;</td>
            <td>{{ key.user_message }}</td>
          </tr>
          <tr v-if="analysisMeshChecks">
            <th colspan="3" title="Checks performed on the final analysis mesh.">Analysis Mesh</th>
          </tr>
          <tr v-for="(key, check) in analysisMeshChecks" :key="check">
            <td :title="key.tooltip_hover_text">{{ check }}</td>
            <td v-if="key.check_passed"><i class="fas fa-check" style="color: var(--spark-check-green)" /></td>
            <td v-else-if="key.check_passed === false">
              <i class="fas fa-exclamation" style="color: var(--spark-warning)" />
            </td>
            <td v-else>&nbsp;</td>
            <td>{{ key.user_message }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'MeshChecks',

  computed: {
    ...mapState(['part']),
    currentRouteName() {
      return this.$route.name;
    },

    meshChecksExisting() {
      let meshChecks = this.part.mesh_checks;
      if (meshChecks != null) {
        return Object.keys(meshChecks).length != 0;
      } else {
        return false;
      }
    },

    uploadFileChecks() {
      return this.part.mesh_checks?.upload_file;
    },

    initialMeshActions() {
      return this.part.mesh_checks?.actions_performed_on_initial_mesh;
    },

    analysisMeshChecks() {
      return this.part.mesh_checks?.analysis_mesh;
    },
  },

  methods: {
    ...mapMutations(['changeDisplayInfo']),
    hideMeshChecks() {
      this.changeDisplayInfo('');
    },
  },
};
</script>

<style lang="scss" scoped>
.spark-table {
  margin-left: 0px;
  margin-right: 40px;
  margin-top: 5px;
}
.title {
  margin-top: 3px;
  padding-left: 10px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
}
</style>
