<template>
  <div class="entry-point" />
</template>

<script setup>
import { onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const route = useRoute();

const redirect = () => {
  const redirectPath = route.query.r;
  router.push({
    name: 'login',
    query: redirectPath ? { r: redirectPath } : {}
  });
};
onMounted(() => redirect());
</script>
