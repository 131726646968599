<template>
  <div style="margin-left: 17px; font-size: var(--10px)">
    <div v-if="boolAssembly">
      <div style="display: inline-block; margin-right: 5px">Assembly detected.</div>
      <a class="link" :class="boolClicked ? 'disabled-link' : ''" @click="rippAssembly()"> Disassemble. </a>
    </div>
    <div v-if="!boolAssembly && boolNoAssembly">
      <div style="display: inline-block; margin-right: 5px">No Assembly detected.</div>
      <a class="link" :class="boolClicked ? 'disabled-link' : ''" @click="rippAssembly()"> Try to disassemble. </a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'AssemblyRipper',

  data() {
    return {
      boolClicked: false,
    };
  },

  computed: {
    ...mapState(['part']),
    ...mapState('application', ['axiosInstance']),

    boolAssembly() {
      return this.part.basename !== '' && this.part.is_assembly === true;
    },

    boolNoAssembly() {
      return this.part.basename !== '' && this.part.is_assembly === false && this.part.parent_assembly === null;
    },
  },

  methods: {
    rippAssembly() {
      // The disassembling process should only be started once.
      if (this.boolClicked) {
        this.$root.notify('warning', 'Disassembling', 'The disassembling process has already been started.', 3000);
        return;
      }

      this.boolClicked = true;
      let formData = {
        part_id: this.part.part_id,
      };
      this.axiosInstance
        .post(`/api/v1/assembly/${this.part.part_id}/`, formData)
        .then(response => {
          this.$root.notify('success', 'Disassembling', 'The file was submitted to disassembling.', 3000);
        })
        .catch(error => {
          console.log(JSON.stringify(error));
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.status-representation {
  font-size: 0.8em;
  width: 100%;
}

.link {
  display: inline-block;
  opacity: 0.4;
  color: black;
  text-decoration: none;
  transition: all 0.3s ease-out;
  &:hover {
    cursor: pointer;
    opacity: 1;
    text-decoration: underline;
    transition: all 0.3s ease-out;
  }
}

.disabled-link {
  cursor: default;
  text-decoration: none;
  color: var(--accent-color);
  transition: all 0.3s ease-out;
}
</style>
