<template>
  <div>
    <h4>Energy Settings</h4>
    <table class="spark-table">
      <tbody>
        <tr>
          <td><b>Active Profile</b></td>
          <td>
            <b>{{ activeEnergyConfig?.name }}</b>
          </td>
        </tr>
        <tr>
          <td colspan="2"><hr /></td>
        </tr>
        <tr>
          <td>
            Available Profiles&nbsp;
            <i class="fas fa-plus-square" title="Add profile" @click="openDialog(null)" />
          </td>
          <td>
            <select v-model="displayedEnergyConfig" style="display: inline-block">
              <option v-for="(setting, key) in energyConfigs" :key="key" :value="setting">
                {{ setting.name }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <td colspan="2"><hr /></td>
        </tr>
      </tbody>
    </table>

    <div v-if="!showDialogSettings && displayedEnergyConfig != null" class="card">
      <div class="setting-title">
        {{ displayedEnergyConfig?.name }}
        <i
          v-if="displayedEnergyConfig.uid != activeEnergyConfig.uid"
          class="fas fa-wrench"
          title="Set to active"
          @click="setToActive(displayedEnergyConfig)"
        />
        <div v-else class="badge">Active</div>
        <div v-if="displayedEnergyConfig.uid != '2d4273d57c764b6ca4a98054c8f094d1'" style="display: inline-block">
          <i class="fas fa-pen" @click="openDialog(displayedEnergyConfig)" />
          <i
            v-if="displayedEnergyConfig.uid != activeEnergyConfig.uid"
            class="fas fa-trash"
            @click="deleteSetting(displayedEnergyConfig.uid)"
          />
        </div>
      </div>
      <table class="spark-table">
        <tbody>
          <tr>
            <td>Carbon Intensity of Electricity</td>
            <td>
              {{ displayedEnergyConfig?.co2eq_factor }}
            </td>
            <td>kgCO&#8322;-eq/kWh</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="showDialogSettings" class="card">
      <div class="setting-title">
        <input v-model="form.name" class="input-header" type="text" />
        <i class="fas fa-times dialog-settings" style="float: right" @click="closeDialog()" />
        <i class="fas fa-check dialog-settings" style="float: right" @click="makeSettings()" />
      </div>
      <table class="spark-table">
        <tbody>
          <tr>
            <td>Carbon Intensity of Electricity</td>
            <td>
              <input v-model="form.co2eq_factor" type="number" />
            </td>
            <td>kgCO&#8322;-eq/kWh</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'EnergySettings',

  data() {
    return {
      energyConfigs: {},
      displayedEnergyConfig: {},
      activeEnergyConfig: {},
      showDialogSettings: false,
      form: {
        uid: null,
        name: 'My Settings',
        co2eq_factor: null,
      },

      defaultForm: {
        uid: null,
        name: 'My Settings',
        co2eq_factor: null,
      },

      sparkConfigUid: 'a2bb84f5f9f24332884c50614e9c377f',
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
  },

  mounted() {
    this.getActiveEnergyConfig();
    this.getAllEnergyConfigs();
  },

  methods: {
    openDialog(config) {
      if (config != null) {
        this.form['uid'] = config.uid;
        this.form['name'] = config.name;
        this.form['co2eq_factor'] = config.co2eq_factor;
      } else {
        this.form = this.defaultForm;
      }
      this.showDialogSettings = true;
    },

    makeSettings() {
      if (this.form.uid == null) {
        this.createSetting();
      } else {
        this.editSetting();
      }
      this.closeDialog();
    },

    editSetting() {
      if (this.form.uid != this.sparkConfigUid) {
        this.axiosInstance
          .put(`api/v1/energy-config/${this.form.uid}/`, this.form)
          .then(response => {
            // this.setToActive(this.displayedEnergyConfig);
            this.getAllEnergyConfigs();
            this.displayedEnergyConfig = response.data;
            this.$root.notify('success', 'Energy profile', 'Energy profile successfully updated.', 3000);
          })
          .catch(error => {
            console.log(error.response.data);
          });
      } else {
        this.$root.notify('warning', 'Energy Profile', '3D SPark Profile cannot be edited', 3000);
      }
    },

    createSetting() {
      this.axiosInstance
        .post(`api/v1/energy-config/`, this.form)
        .then(response => {
          this.setToActive(response.data);
          this.displayedEnergyConfig = response.data;
          this.$root.notify('success', 'Energy profile', 'Energy profile successfully created.', 3000);
        })
        .catch(error => {});
    },

    closeDialog() {
      this.showDialogSettings = false;
    },

    setToActive(config) {
      this.getAllEnergyConfigs();

      this.axiosInstance
        .post(`api/v1/default-energy-config/${config.uid}/`)
        .then(response => {
          this.activeEnergyConfig = config;
        })
        .catch(error => {
          console.log(error.response);
        });
    },

    deleteSetting(uid) {
      if (uid != this.sparkConfigUid) {
        this.axiosInstance
          .delete(`api/v1/energy-config/${uid}/`)
          .then(response => {
            this.displayedEnergyConfig = null;
            this.getAllEnergyConfigs();
            this.$root.notify('success', 'Energy profile', 'Energy profile successfully deleted.', 3000);
          })
          .catch(error => {
            console.log(error.response);
            this.$root.notify('warning', 'Energy profile', error.response.data.error_message, 3000);
          });
      } else {
        this.$root.notify('warning', 'Energy Profile', '3D SPark Profile cannot be deleted', 3000);
      }
    },

    getAllEnergyConfigs() {
      this.axiosInstance
        .get(`api/v1/all-energy-configs/`)
        .then(response => {
          this.energyConfigs = response.data;
        })
        .catch(error => {
          console.log(error.response);
        });
    },

    getActiveEnergyConfig() {
      this.axiosInstance
        .get(`api/v1/default-energy-config/`)
        .then(response => {
          this.displayedEnergyConfig = this.activeEnergyConfig = response.data;
        })
        .catch(error => {
          console.log(error.response);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.setting-title {
  font-weight: bold;
  font-size: var(--13px);
}

.badge {
  display: inline-block;
  background-color: var(--spark-darkgrey);
  border-radius: 5px;
  padding: 0px 3px;
  color: white;
}

.card-styling {
  border: 1px solid darkgrey;
  border-radius: 3px;
  padding: 5px;
  margin-bottom: 5px;
}

input {
  font-size: var(--10px);
}

.input-header {
  width: 50%;
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: textfield;

  background-color: white;
  border: solid 1px var(--light-color);

  &:hover {
    border: solid 1px var(--primary-color-light);
    transition: all 0.3s ease-in;
    &:disabled {
      border: solid 1px white;
    }
  }
}

.subtable-right {
  text-align: right;
  display: inline-block;
  margin-left: 10px;
}

.subtable-left {
  width: 100%;
}

.subtable-units {
  width: 20%;
}

.dialog-settings {
  display: inline-flex;
  margin: 2px;
}

i {
  cursor: pointer;
  font-size: var(--14px);
  padding: 0px 5px;
}
</style>
