<template>
  <div class="main-container">
    <div class="left-container">
      <details v-for="process in orderedProcesses" :key="process" class="step-container flex">
        <summary
          class="flex items-center hover:text-primary-500 hover:cursor-pointer hover:bg-gray-50 p-2 text-13 rounded-4"
        >
          {{ process[1].text }}
          <SparkBadge class="ml-8 mb-4" variant="outlined">{{ numberOfStepsPerProcess[process[0]] }}</SparkBadge>
        </summary>
        <div class="flex flex-wrap mb-8 mr-8">
          <div v-for="step in processSteps" :key="step.uid" class="my-4">
            <div
              v-if="step.prc_acronym === process[1].value && step.step_type !== 'main'"
              class="mx-4 p-4 border border-solid border-gray-50 rounded-4 w-[300px]"
            >
              <div class="flex items-center">
                <div :title="step.name" class="text-13 mr-4 truncate" v-text="step.name" />
                <SparkBadge variant="outlined">{{ step.step_type }}</SparkBadge>

                <i
                  v-if="notRestricted(step.restriction)"
                  class="icon-button fas fa-trash-alt"
                  @click="deleteProcessStepTemplate(step.uid)"
                />
                <i
                  v-if="notRestricted(step.restriction)"
                  class="icon-button fas fa-pen"
                  @click="editProcessStepTemplate(step.uid)"
                />
                <i
                  v-if="stepTemplateToEdit === step.uid"
                  class="icon-button fas fa-times"
                  @click="cancelEditingProcessStepTemplate()"
                />
              </div>
              <ProcessChainStep v-if="stepTemplateToEdit != step.uid" :step="step" />
              <ProcessChainStepForm
                v-else
                class="mr-8"
                :filled-form="step"
                mode="Edit"
                @created="
                  stepTemplateToEdit = '';
                  getProcessSteps();
                "
              />
            </div>
          </div>
        </div>
      </details>
    </div>
    <div class="right-container width">
      <SparkButton
        v-if="!showCreateForm"
        variant="secondary"
        class="w-100 m-8"
        @click="addNewProcessChainStepTemplate()"
      >
        <i class="fas fa-plus" /> Add
      </SparkButton>
      <i v-if="showCreateForm" class="icon-button fas fa-times create-button" @click="closeCreateForm()" />
      <ProcessChainStepForm
        v-if="showCreateForm"
        class="mr-8"
        mode="Create"
        @created="
          showCreateForm = false;
          getProcessSteps();
        "
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import SparkBadge from '../../../components/SparkComponents/SparkBadge.vue';
import SparkButton from '../../../components/SparkComponents/SparkButton.vue';

import ProcessChainStep from './components/ProcessChainStep.vue';
import ProcessChainStepForm from './components/ProcessChainStepForm.vue';

export default {
  name: 'ProcessChainSteps',

  components: {
    ProcessChainStepForm,
    ProcessChainStep,
    SparkButton,
    SparkBadge,
  },

  data() {
    return {
      processSteps: {},
      showCreateForm: false,
      stepTemplateToEdit: '',
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
    ...mapState(['user']),
    ...mapState({
      storeProcesses: 'processes',
    }),

    orderedProcesses() {
      let sortedEntries = [];
      if (this.storeProcesses != undefined) {
        sortedEntries = Object.entries(this.storeProcesses).sort(([, value1], [, value2]) => {
          const name1 = value1.text.toLowerCase();
          const name2 = value2.text.toLowerCase();
          return name1.localeCompare(name2);
        });
      }
      return sortedEntries;
    },

    numberOfStepsPerProcess() {
      let keys = Object.keys(this.storeProcesses);

      let numberObject = {};

      keys.forEach(key => {
        let processName = this.storeProcesses[key].value;
        const filteredKeys = Object.keys(this.processSteps).filter(
          key => this.processSteps[key]['prc_acronym'] === processName
        );
        numberObject[key] = Object.keys(filteredKeys).length;
      });
      return numberObject;
    },
  },

  mounted() {
    this.getProcessSteps();
  },

  methods: {
    addNewProcessChainStepTemplate() {
      this.showCreateForm = true;
    },

    closeCreateForm() {
      this.showCreateForm = false;
    },

    editProcessStepTemplate(uid) {
      this.stepTemplateToEdit = uid;
    },

    async getProcessSteps() {
      this.axiosInstance
        .get(`/api/v1/process-step-templates-all/`)
        .then(response => {
          this.processSteps = response.data;
        })
        .catch(error => {
          console.log(JSON.stringify(error));
        });
    },

    async deleteProcessStepTemplate(uid) {
      await this.axiosInstance.delete(`/api/v1/process-step-template/${uid}`).then(() => {
        this.getProcessSteps();
        this.$root.notify('success', 'Process Step Deleted');
      });
    },

    notRestricted(restriction) {
      if (restriction === 'owner' || (restriction === 'organization' && this.user.user_role === 'manager')) {
        return true;
      } else {
        return false;
      }
    },

    async cancelEditingProcessStepTemplate() {
      this.stepTemplateToEdit = '';
      this.getProcessSteps();
    },
  },
};
</script>

<style lang="scss" scoped>
.right-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 30%;
  border: 1px solid var(--light-color);
  border-radius: 5px;
}

.main-container {
  flex-direction: row;
  display: flex;
  background-color: var(--lighter-color);
}

.left-container {
  background-color: white;
  padding: var(--grid-outer-whitespace);
  margin-right: 10px;
  border: 1px solid var(--light-color);
  border-radius: 5px;
  width: 70%;
}

.create-button {
  width: 10%;
  align-self: flex-end;
}

.icon-button {
  margin: 7px;
  justify-content: center;
  display: flex;
}

.step-title {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-self: center;
  font-size: var(--13px);
  background-color: var(--lighter-color);
  width: 100%;
  padding: 5px 0px 5px 8px;
}

.step {
  border: 1px solid var(--light-color);
  width: 40%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.step-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
</style>
