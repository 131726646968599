<template>
  <div id="card-height">
    <div><CarbonEquivalent /></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import CarbonEquivalent from '@/components/PartWorkflow/CarbonFootprint/CarbonEquivalent.vue';

export default {
  name: 'CarbonFootprint',

  components: {
    CarbonEquivalent,
  },

  computed: {
    ...mapState(['part', 'investigationDetails']),
  },
};
</script>
