<template>
  <div class="flex-container w-full">
    <teleport v-if="isMounted" to="#sub-navigation__action-buttons">
      <div class="flex align-middle justify-center gap-8">
        <LibraryFilter @filter="getFilteredMachines($event)" />
        <div class="mt-4">
          <DropdownMenu
            :list-options="tableDropdownOptions"
            title-icon="fas fa-ellipsis-h"
            close-click-style="always"
            class="border border-solid border-gray-10 rounded-8 pl-4"
            @update-option="tableAction($event)"
          />
        </div>
      </div>
    </teleport>
    <table v-if="machines.length > 0" id="resizable-table" class="spark-table" style="width: 100%">
      <thead>
        <tr style="padding-left: 5px">
          <th />
          <th
            v-for="col in selectedMachineLibraryColumns"
            :id="col[0]"
            :key="col.index"
            @click="sort(col.sorting)"
            @mouseover="hover[col.title] = true"
            @mouseleave="hover[col.title] = false"
          >
            <div v-if="hover[col.title] || currentOrder === col.sorting">
              <Sorting :enabled="currentOrder === col.sorting" :reverse="currentOrderReverse" />
            </div>
            <span>{{ col.title }}</span>
          </th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody class="text-start" style="margin-top: 0px; padding-top: 0px; cursor: pointer">
        <TransitionGroup name="fadening">
          <!-- Iterate over parts -->
          <tr v-for="machine in machines" :key="machine">
            <td
              v-if="machine.inhouse"
              style="text-align: right"
              title="Unset In-House"
              @click.stop="removeFromInHouse(machine)"
            >
              <img src="@/assets/icons/in_house_filled.svg" style="width: 16px; height: 16px" />
            </td>
            <td v-else style="text-align: right" title="Set In-House" @click.stop="addToInHouse(machine)">
              <img src="@/assets/icons/in_house.svg" style="width: 16px; height: 16px" />
            </td>
            <td v-for="col in selectedMachineLibraryColumns" :key="col">
              {{ machine[col['database']] }}
              <span v-if="machine[col['database']] != null && col.unit" style="color: #9ba1a8">{{ col.unit }} </span>
            </td>
            <td class="favourite">
              <DropdownMenu
                :list-options="dropdownOptions[machine.mac_id]"
                title-icon="fas fa-ellipsis-h"
                close-click-style="always"
                @update-option="machineEvent($event, machine)"
              />
            </td>
          </tr>
        </TransitionGroup>
      </tbody>
    </table>

    <img
      v-else-if="machines.length === 0 && loading === true"
      src="@/assets/img/loading.gif"
      style="width: 50px; margin-left: auto; margin-right: auto; margin-top: auto; margin-bottom: auto"
    />
    <div v-else>
      <p>No machines found.</p>
    </div>
    <TablePagination :current-page="currentPage" :max-page-number="numberOfPages" @update-current-page="updateTable" />
    <CustomMachine :machinedata="machineData" :type="type" @get-machines="getMachines" />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import LibraryFilter from '../../components/LibraryFilter.vue';

import CustomMachine from './CustomMachine.vue';

import DropdownMenu from '@/components/Reusable/DropdownMenu.vue';
import Sorting from '@/components/Reusable/Sorting.vue';
import TablePagination from '@/components/Reusable/TablePagination.vue';

export default {
  name: 'MachineTable',

  components: {
    TablePagination,
    Sorting,
    DropdownMenu,
    CustomMachine,
    LibraryFilter,
  },

  data() {
    return {
      machines: [],
      currentPage: 1,
      numberOfPages: 1,
      loading: true,
      filterParams: {},
      currentOrder: '',
      isMounted: false,
      currentOrderReverse: true,
      type: 'view',
      machineData: {},
      defaultDropdownOptions: [
        { text: 'View Details', value: 'view', icon: 'fas fa-glasses', disabled: false },
        { text: 'Add Similar Machine', value: 'add', icon: 'fas fa-plus', disabled: false },
        { text: 'Edit Machine', value: 'edit', icon: 'fas fa-pen', disabled: true },
        { text: 'Delete Machine', value: 'delete', icon: 'fas fa-trash', disabled: true },
      ],

      ownDropdownOptions: [
        { text: 'View Details', value: 'view', icon: 'fas fa-glasses', disabled: false },
        { text: 'Add Similar Machine', value: 'add', icon: 'fas fa-plus', disabled: false },
        { text: 'Edit Machine', value: 'edit', icon: 'fas fa-pen', disabled: false },
        { text: 'Delete Machine', value: 'delete', icon: 'fas fa-trash', disabled: false },
      ],

      dropdownOptions: {},

      tableDropdownOptions: [{ text: 'Edit Columns', value: 'editColumns', icon: '', disabled: false }],
      hover: {},
    };
  },

  computed: {
    ...mapState(['user', 'processes', 'investigationDetails', 'part', 'popup']),
    ...mapState('application', ['axiosInstance']),

    username() {
      return this.user.username;
    },

    machineLibraryColumns: {
      get() {
        let cols = this.user.user_settings.machine_library_columns;
        return cols;
      },

      set(columns) {
        this.setMachineUserSettings();
        this.updateMachineUserSettings(columns);
      },
    },

    // 2. get selected Part Library Columns
    selectedMachineLibraryColumns() {
      let shown = [];
      for (let col in this.machineLibraryColumns) {
        if (this.machineLibraryColumns[col].selected === true) {
          shown.push(this.machineLibraryColumns[col]);
        }
      }
      // sort selected Columns
      shown.sort((a, b) => {
        return a.index - b.index;
      });
      return shown;
    },
  },

  watch: {
    popup(popup) {
      if (popup?.key === this.$options.name) {
        if (popup?.clicked === 'ok') {
          this.triggerPopup('');
          this.setMachineUserSettings();
          this.updateMachineUserSettings(this.machineLibraryColumns);
        } else if (popup?.clicked === 'cancel' || popup?.clicked === 'close') {
          this.triggerPopup('');
        }
      }
    },
  },

  mounted() {
    this.getMachines();
    this.isMounted = true;
  },

  methods: {
    ...mapMutations(['updateAllMachines', 'updateMachineUserSettings', 'updateFilteredMachines', 'triggerPopup']),

    tableAction() {
      this.triggerPopup({
        key: this.$options.name,
        show: true,
        title: 'Edit Columns',
        selectOptions: this.machineLibraryColumns,
        type: 'multi',
        buttons: true,
        buttonContent: [
          { text: 'Cancel', type: 'outlined', value: 'cancel' },
          { text: 'Ok', type: 'secondary', value: 'ok' },
        ],
      });
    },

    addToInHouse(machine) {
      if (this.user.user_role !== 'manager') {
        this.$root.notify('warning', 'Only managers can edit the in-house status.', 3000);
        return;
      }
      this.axiosInstance
        .post(`api/v1/organization-inhouse-machine/${machine.mac_id}/`)
        .then(() => {
          machine.inhouse = true;
          this.getMaterials();
        })
        .catch(error => {
          if (error.response) {
            console.log(JSON.stringify(error));
          }
        });
    },

    removeFromInHouse(machine) {
      if (this.user.user_role !== 'manager') {
        this.$root.notify('warning', 'Only managers can edit the in-house status.', 3000);
        return;
      }
      this.axiosInstance
        .delete(`api/v1/organization-inhouse-machine/${machine.mac_id}/`)
        .then(() => {
          machine.inhouse = false;
        })
        .catch(error => {
          if (error.response) {
            console.log(JSON.stringify(error));
          }
        });
    },

    calculateDropownOptions() {
      this.machines.forEach(machine => {
        if (machine.owner_username && (machine.owner_username === this.username || this.user.user_role === 'manager')) {
          this.dropdownOptions[machine.mac_id] = this.ownDropdownOptions;
        } else {
          this.dropdownOptions[machine.mac_id] = this.defaultDropdownOptions;
        }
      });
    },

    getMachines() {
      let url = `/api/v1/machine-list/?page=${this.currentPage}`;
      this.loading = true;
      this.axiosInstance
        .get(url, {
          params: this.filterParams,
        })
        .then(response => {
          this.machines = response.data.results;
          this.addPrcAcronym(this.machines);
          this.currentPage = Number(response.data.page_number);
          this.calculateDropownOptions();
          this.numberOfPages = response.data.num_pages;
          this.loading = false;

          // load also machines for machine table new
          this.getAllMachines();
        });
    },

    addPrcAcronym(obj) {
      Object.keys(obj).forEach(key => {
        let prc_uid = obj[key]['prc_uid'];
        obj[key]['prc_acronym'] = this.user.organization.process_synonyms[prc_uid].prc_acronym;
      });
    },

    getAllMachines() {
      this.axiosInstance.get('api/v1/machines-all/').then(response => {
        let allMachines = response.data;
        let filteredMachines = Object.keys(allMachines).map(key => {
          return {
            key: Number(key),
            value: allMachines[key].mac_id,
            text: allMachines[key].mac_name + ' | ' + allMachines[key].oem_name,
            prc_uid: allMachines[key].prc_uid,
          };
        });
        filteredMachines = { ...filteredMachines };
        this.updateAllMachines(filteredMachines);
        this.updateFilteredMachines(filteredMachines);
      });
    },

    getMachine(machine) {
      this.axiosInstance.get(`/api/v1/machine/${machine.mac_id}`).then(response => {
        this.machineData = { ...response.data };
        this.machineData['prc_acronym'] =
          this.user.organization.process_synonyms[this.machineData['prc_uid']].prc_acronym;
      });
    },

    deleteMachine(machine) {
      this.axiosInstance
        .delete(`api/v1/machine/${machine.mac_id}/`)
        .then(response => {
          this.getMachines();
          this.$root.notify('success', response.data, 3000);
        })
        .catch(error => {
          if (error.response) {
            this.$root.notify('warning', error.response.data.error_message, 3000);
          }
        });
    },

    machineEvent(event, machine) {
      this.type = event.value;
      if (event.value === 'add') {
        this.getMachine(machine);
      }
      if (event.value === 'delete') {
        if (machine.owner_username != this.username && this.user.user_role !== 'manager') {
          this.$root.notify('warning', 'This Machine cannot be deletetd. You can only delete your own machine.', 3000);
        } else {
          this.deleteMachine(machine);
        }
      }
      if (event.value === 'edit') {
        if (machine.owner_username != this.username && this.user.user_role !== 'manager') {
          this.$root.notify('warning', 'This Machine cannot be edited. You can only delete your own machine.', 3000);
        } else {
          this.getMachine(machine);
        }
      }
      if (event.value === 'view') {
        this.getMachine(machine);
      }
    },

    updateTable(pageNumber) {
      this.currentPage = pageNumber;
      this.getMachines();
    },

    sort(str) {
      this.currentOrder = str;
      this.currentOrderReverse = !this.currentOrderReverse;
      this.filterParams['order_by'] = this.currentOrder;
      this.filterParams['order_reverse'] = this.currentOrderReverse;
      this.getMachines();
    },

    async setMachineUserSettings() {
      this.axiosInstance.put('/api/v1/user-settings/', {
        machine_library_columns: this.machineLibraryColumns,
      });
    },

    getFilteredMachines(event) {
      this.filterParams['filter_value'] = event;
      this.getMachines();
    },
  },
};
</script>

<style lang="scss" scoped>
th {
  height: 55px;
}

.star {
  color: #9ba1a8;
}

.star-filled {
  color: var(--accent-color);
}

.checkbox-input {
  cursor: pointer !important;
  width: 13px;
  height: 13px;
  padding-left: 0;
}

.spark-table tr td {
  padding-right: 16px;
}

.container {
  display: flex;
  flex-direction: column;
  font-size: var(--12px);
  justify-content: start;
}
</style>
