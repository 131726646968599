<template>
  <div v-if="part.basename !== ''" class="radio-row" :class="!cadUploadFinished ? 'grey' : ''">
    <div class="flex-column">
      <div class="unit-title">CAD Units</div>
      <small v-show="!cadUploadFinished">Processing...</small>
    </div>

    <div class="flex-column">
      <input v-model="uploadFileUnits" type="radio" value="mm" :disabled="!cadUploadFinished" />
      <label for="mm">mm</label>
    </div>
    <div class="flex-column">
      <input v-model="uploadFileUnits" type="radio" value="inch" :disabled="!cadUploadFinished" />
      <label for="inch">inch</label>
    </div>
  </div>
  <div v-else>
    <td style="height: 30px" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
export default {
  name: 'Units',

  emits: ['change-unit'],

  data() {
    return {};
  },

  computed: {
    ...mapState(['part']),
    ...mapGetters(['cadStatus', 'fileGeometricPropertiesFinished', 'fileConversionFinished']),

    cadUploadFinished() {
      return (
        this.fileGeometricPropertiesFinished == true &&
        this.fileConversionFinished == true &&
        this.cadStatus != 'Scaling CAD file'
      );
    },

    title() {
      if (this.cadUploadFinished) {
        return '';
      } else {
        return 'disabled while uploading CAD file';
      }
    },

    uploadFileUnits: {
      get() {
        return this.part.upload_file_units;
      },

      set(value) {
        this.$emit('change-unit', {
          upload_file_units: value,
        });
      },
    },
  },

  methods: {},
};
</script>

<style lang="scss">
.radio-row {
  display: flex;
  font-size: var(--10px);
  margin-left: 15px;
}

.unit-title {
  font-size: var(--12px);
  color: var(--spark-lightgrey);
  padding-right: 10px;
}

label {
  clear: none;
  display: block;
  text-align: center;
}

.grey {
  color: grey;
}

.flex-column {
  display: flex;
  flex-direction: column;
  padding-right: 10px;
}
</style>
