<template>
  <div class="filter-element">
    <div class="filter-label">Materials</div>
    <div class="filtering">
      <VueMultiselect
        v-model="selectedMaterials"
        :options="materialOptions"
        multiple
        :close-on-select="false"
        :limit="10"
        :limit-text="limitText"
        placeholder="Pick some"
        label="name"
        @remove="handleMaterialRemoval"
      >
        <!-- Custom Option Template -->
        <template #option="props">
          <div>
            <span v-if="props.option.favorite">
              <i class="fas fa-star" />
            </span>

            {{ props.option.name }}
          </div>
        </template>
      </VueMultiselect>
    </div>
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'MaterialFilter',

  components: {
    VueMultiselect,
  },

  props: {
    reset: { type: Number, required: true },
    parent: { type: String, required: true },
  },

  emits: ['selected'],

  data() {
    return {
      selectedMaterials: [],
      materialOptions: [],
    };
  },

  computed: {
    ...mapState(['partLibraryFilters']),
    ...mapState('application', ['axiosInstance']),
  },

  watch: {
    selectedMaterials: {
      handler(newVal) {
        const selectedNames = newVal.map(material => material.name);
        this.$emit('selected', { materials: selectedNames });
      },

      deep: true,
    },

    reset: {
      handler() {
        if (this.reset) {
          this.clear();
        }
      },

      immediate: true,
    },
  },

  async mounted() {
    await this.getMaterials();
    const filter = JSON.parse(sessionStorage.getItem('libraryFilter'));
    if (this.parent === 'library') {
      const selected = filter?.materials;
      if (selected?.length) {
        const selectedMaterials = this.materialOptions.filter(material => selected.includes(material.name));
        if (selectedMaterials && selectedMaterials.length) {
          this.selectedMaterials = this.ensureUniqueMaterials(selectedMaterials);
        }
      }
    }
  },

  methods: {
    ...mapMutations(['resetFilter']),

    async getMaterials() {
      await this.axiosInstance
        .get('/api/v1/materials-dropdown/')
        .then(response => {
          const materialMap = new Map();
          response.data.forEach(entry => {
            if (!materialMap.has(entry.mat_name)) {
              materialMap.set(entry.mat_name, {
                mat_id: entry.mat_id,
                name: entry.mat_name,
                favorite: entry.favorite,
              });
            }
          });
          this.materialOptions = Array.from(materialMap.values());
          this.materialOptions.push({ name: 'No Material', favorite: false });
        })
        .catch(error => {
          console.log(error);
        });
    },

    handleMaterialRemoval(removedMaterial) {
      const updatedMaterials = this.selectedMaterials.filter(material => material.name !== removedMaterial.name);
      this.selectedMaterials = this.ensureUniqueMaterials(updatedMaterials);
    },

    ensureUniqueMaterials(materials) {
      const uniqueMaterials = [];
      const seenNames = new Set();
      for (const material of materials) {
        if (!seenNames.has(material.name)) {
          seenNames.add(material.name);
          uniqueMaterials.push(material);
        }
      }

      return uniqueMaterials;
    },

    clear() {
      this.resetFilter({ materials: 'materials' });
      this.selectedMaterials = [];
    },

    limitText(count) {
      return `and ${count} more`;
    },
  },
};
</script>

<style scoped lang="scss">
.multiselect {
  font-size: var(--12px);
}

.multiselect__tags {
  padding: 5px 40px 0px 3px;
}

.multiselect__tag {
  padding: 0px;
  font-size: var(--9px);
}

.multiselect__input {
  font-size: var(--9px);
}
</style>
