<template>
  <div
    v-if="part"
    class="bg-gray-50 w-150 h-150 justify-center flex items-center rounded-4"
    :class="{ [custom]: !!custom }"
  >
    <img :src="part?.thumbnail || defaultImage" alt="Part Thumbnail" width="120" height="120" />
  </div>
</template>

<script>
import DefaultImage from '../../../assets/img/Logo_Transparent.png';
export default {
  name: 'PartImage',

  props: {
    part: { type: Object, required: true },
    custom: { type: String, default: '' },
  },

  computed: {
    defaultImage() {
      return DefaultImage;
    },
  },
};
</script>
