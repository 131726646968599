<template>
  <div class="m-8 text-13 pb-10">
    <teleport v-if="isMounted" to="#sub-navigation__action-buttons">
      <div class="flex flex-row">
        <SparkInput
          v-model="searchQuery"
          name="filter"
          custom="m-0"
          label=""
          placeholder="Search Users..."
          class="pr-8"
          @change="searchUsers()"
        />
        <ChangeRoles
          :disabled="Object.keys(selectedIds)?.length === 0"
          class="mr-8"
          :trigger-update-role="triggerUpdateRole"
          :change-form-data="changeFormData"
          @reload="getUsers()"
        />
        <InviteUser />
      </div>
    </teleport>
    <div v-if="!loading">
      <table class="w-full bg-white rounded-8 border border-gray-50">
        <thead class="h-56 rounded-8 border border-b-gray-50">
          <th class="pl-8" @click="toggleMultipleIdToSelectedIds()">
            <i v-if="allSelected" class="far fa-check-square text-15" />
            <i v-else class="far fa-square text-15" />
          </th>

          <th @click="sort('name')">
            Name<Sorting :enabled="'name' === currentOrder" :reverse="currentOrderReverse" />
          </th>
          <th @click="sort('username')">
            Username<Sorting :enabled="'username' === currentOrder" :reverse="currentOrderReverse" />
          </th>
          <th @click="sort('role')">
            Role<Sorting :enabled="'role' === currentOrder" :reverse="currentOrderReverse" />
          </th>
          <th @click="sort('last_active')">
            Last Active<Sorting :enabled="'last_active' === currentOrder" :reverse="currentOrderReverse" />
          </th>
          <th>&nbsp;</th>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user" class="h-56 hover:bg-gray-50 hover:cursor-pointer">
            <td class="pl-8" @click="toggleIdToSelectedIds(user.id)">
              <i v-if="selectedIds[user.id]" class="far fa-check-square text-15" />
              <i v-else class="far fa-square text-15" />
            </td>
            <td>
              <div class="flex flex-col">
                <div class="font-bold">{{ user.firstName }} {{ user.lastName }}</div>
                <div class="text-gray-500">
                  {{ user.email }}
                </div>
              </div>
            </td>
            <td>{{ user.username }}</td>
            <td>{{ roles[user.role] }}</td>
            <td v-if="user.last_active === dateIfInvited">
              <SparkBadge variant="success" custom="w-fit">Invited</SparkBadge>
            </td>
            <td v-else>{{ formatLastSeen(user.last_active) }}</td>
            <td>
              <DropdownMenu
                :list-options="dropdownOptions"
                title-icon="fas fa-ellipsis-h"
                close-click-style="always"
                :title-style="{ 'font-size': 'var(--15px)', padding: '1px 0px 0px 0px' }"
                @update-option="show($event, user)"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div class="flex justify-between">
        <div class="flex self-center text-gray-500">
          Viewing <span class="text-black mx-2"> {{ countObjectsFrom }} - {{ countObjectsTo }} </span> of
          <span class="text-black mx-2">{{ sumOfObjects }}</span> Users
        </div>

        <TablePagination
          class="flex"
          :current-page="currentPage"
          :max-page-number="numberOfPages"
          @update-current-page="updateTable"
        />
      </div>
    </div>
    <div v-else><img src="@/assets/img/loading.gif" class="ml-auto mr-auto w-32 h-32 m-16" /></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import DropdownMenu from '../../../components/Reusable/DropdownMenu.vue';
import Sorting from '../../../components/Reusable/Sorting.vue';
import TablePagination from '../../../components/Reusable/TablePagination.vue';
import SparkBadge from '../../../components/SparkComponents/SparkBadge.vue';
import SparkInput from '../../../components/SparkComponents/SparkInput.vue';
import ChangeRoles from '../components/ChangeRoles.vue';
import InviteUser from '../components/InviteUser.vue';

export default {
  name: 'Team',

  components: {
    DropdownMenu,
    SparkInput,
    InviteUser,
    ChangeRoles,
    SparkBadge,
    Sorting,
    TablePagination,
  },

  data() {
    return {
      users: [],
      isMounted: false,
      searchQuery: '',
      selectedIds: {},
      allSelected: false,
      triggerUpdateRole: 0,
      changeFormData: {},
      currentOrder: '',
      currentOrderReverse: true,
      filterParams: {},
      currentPage: 1,
      numberOfPages: 1,
      loading: true,
      dateIfInvited: '1970-01-01T00:00:00Z',
      objectsPerPage: 1,
      sumOfObjects: 1,
    };
  },

  computed: {
    ...mapState('application', ['websocketDisconnect', 'axiosInstance']),
    ...mapState(['roles']),
    dropdownOptions() {
      let options = [{ text: 'Change Role', value: 'change-role', icon: 'fas fa-exchange-alt', disabled: false }];
      return options;
    },

    countObjectsFrom() {
      return (this.currentPage - 1) * this.objectsPerPage + 1;
    },

    countObjectsTo() {
      let count = this.currentPage * this.objectsPerPage;
      if (this.numberOfPages === this.currentPage) {
        count = (this.currentPage - 1) * this.objectsPerPage + (this.sumOfObjects % this.objectsPerPage);
      }
      return count;
    },
  },

  mounted() {
    this.getUsers();
    this.isMounted = true;
  },

  methods: {
    getUsers() {
      this.loading = true;
      this.axiosInstance
        .get(`api/v1/user-management/get-users-by-group/?page=${this.currentPage}`, { params: this.filterParams })
        .then(response => {
          this.users = response.data.results;
          this.selectedIds = [];
          this.changeFormData = {};
          this.loading = false;
          this.numberOfPages = response.data.num_pages;
          this.currentPage = response.data.page_number;
          this.objectsPerPage = response.data.per_page;
          this.sumOfObjects = response.data.count;
        });
    },

    updateTable(pageNumber) {
      this.currentPage = pageNumber;
      this.getUsers();
    },

    searchUsers() {
      if (this.searchQuery === '') {
        this.getUsers();
      } else {
        this.loading = true;
        this.axiosInstance
          .get('api/v1/user-management/search-users/', { params: { search: this.searchQuery } })
          .then(response => {
            this.users = response.data.users;
            this.selectedIds = [];
            this.changeFormData = {};
            this.loading = false;
          });
      }
    },

    toggleIdToSelectedIds(userUid) {
      if (this.selectedIds[userUid]) {
        delete this.selectedIds[userUid];
      } else {
        this.selectedIds[userUid] = true;
      }
      this.changeFormData['userId'] = Object.keys(this.selectedIds);
    },

    toggleMultipleIdToSelectedIds() {
      this.allSelected = !this.allSelected;
      if (this.allSelected) {
        this.users.map(user => {
          this.selectedIds[user.id] = true;
        });
      } else {
        this.selectedIds = {};
      }
      this.changeFormData['userId'] = Object.keys(this.selectedIds);
    },

    show(event, user) {
      this.changeFormData['userId'] = [user.id];
      if (event.value === 'change-role') {
        this.triggerUpdateRole += 1;
        this.changeFormData['oldRole'] = user.role;
      }
    },

    sort(str) {
      this.currentOrder = str;
      this.currentOrderReverse = !this.currentOrderReverse;
      this.filterParams['sort_by'] = this.currentOrder;
      this.filterParams['order_reverse'] = this.currentOrderReverse;
      this.getUsers();
    },

    formatLastSeen(lastSeen) {
      const now = this.$dayjs();
      const lastSeenTime = this.$dayjs(lastSeen);
      const diffInDays = now.diff(lastSeenTime, 'day');
      const diffInMonths = now.diff(lastSeenTime, 'month');
      const diffInYears = now.diff(lastSeenTime, 'year');

      if (diffInDays === 0) {
        return 'Today';
      } else if (diffInDays < 30) {
        return `${diffInDays} days ago`;
      } else if (diffInMonths < 12) {
        return `${diffInMonths} months ago`;
      } else {
        return diffInYears > 1 ? `More than a year ago` : `${diffInYears} years ago`;
      }
    },
  },
};
</script>
