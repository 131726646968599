<template>
  <SparkInput
    v-model.lazy="lotSize"
    name="text"
    autocomplete="off"
    :disabled="chain.process_chain_id == '0' || lockedForUser"
    :title="lockedForUser ? lockedTitle : ''"
    label=""
    class="px-4"
    @click="setToActive()"
    @change="updateLotSize"
  />
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

import SparkInput from '../../../../SparkComponents/SparkInput.vue';

export default {
  name: 'LotSize',

  components: {
    SparkInput,
  },

  props: {
    chain: { type: Object, required: true },
  },

  data() {
    return {
      lotSize: 0,
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance', 'lockedTitle']),
    ...mapGetters(['lockedForUser']),
  },

  watch: {
    lotSize: {
      handler(value) {
        if (value === '') {
          this.lotSize = 1;
          return;
        }

        value = parseInt(value);
        this.lotSize = Math.ceil(value);
        this.lotSize === 0 ? (this.lotSize = 1) : '';
        if (this.lotSize > 100000) {
          this.lotSize = 100000;
          this.$root.notify(
            'warning',
            'Quantity limit reached',
            'You have reached the quantity limit of 1.000.000.',
            3000
          );
        }
      },

      deep: true,
    },
  },

  mounted() {
    this.lotSize = this.chain.lot_size;
  },

  methods: {
    ...mapMutations(['changeInvestigationDetails']),

    updateLotSize() {
      let formData = { lot_size: this.lotSize };
      this.axiosInstance.put(`api/v1/process-chain-feedback/${this.chain.process_chain_id}/`, formData).catch(error => {
        console.log('Save Process Chain Error', JSON.stringify(error));
      });
    },

    setToActive() {
      this.changeInvestigationDetails({ uid: this.chain.process_chain_id });
    },
  },
};
</script>
