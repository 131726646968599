<template>
  <SparkModalBase
    v-model="value"
    :dismissable="dismissable"
    :class="{ [custom]: !!custom }"
    class="py-safe m-0 max-h-screen w-full max-w-[47.75rem] bg-transparent sm:m-auto sm:p-32"
  >
    <div class="text-13 sm:rounded-8 min-h-screen overflow-hidden bg-white text-black sm:min-h-0">
      <slot name="custom">
        <div class="relative flex" :class="{ 'p-20': !compactContent }">
          <div
            v-if="$slots.header"
            class="text-19 absolute inset-0 flex h-64 items-center border-b border-gray-50 p-20 font-semibold"
          >
            <slot name="header" />
          </div>
          <div class="absolute right-20 top-16 flex gap-16">
            <SparkButtonIcon v-if="dismissable" icon="fas fa-xmark" @click="closeModal" />
          </div>
          <div
            class="scrollbar-thin scrollbar-thumb-black/50 scrollbar-thumb-rounded-full w-full overflow-y-auto overflow-x-hidden"
            :class="{ 'mt-[4rem]': $slots.header }"
          >
            <slot />
          </div>
        </div>
      </slot>
    </div>
  </SparkModalBase>
</template>

<script setup>
import { computed, watch } from 'vue';

import SparkButtonIcon from './SparkButtonIcon.vue';
import SparkModalBase from './SparkModalBase.vue';

const props = defineProps({
  modelValue: Boolean,
  dismissable: Boolean,
  custom: { type: String, default: '' },
  compactContent: Boolean,
});

const emits = defineEmits(['update:modelValue', 'close']);

const closeModal = () => {
  emits('update:modelValue', false);
  emits('close');
  value.value = false;
};

const value = computed({
  get: () => props.modelValue,
  set: value => emits('update:modelValue', value),
});

watch(
  () => value,
  v => {
    console.log(v, 'value');
  }
);
</script>
