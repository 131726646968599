<template>
  <SparkButton small variant="outlined" @click="reset">
    <SparkButtonIcon icon="fas fa-undo" />
  </SparkButton>
</template>

<script>
import SparkButton from '../../SparkComponents/SparkButton.vue';
import SparkButtonIcon from '../../SparkComponents/SparkButtonIcon.vue';

export default {
  name: 'Reset',

  components: { SparkButton, SparkButtonIcon },

  emits: ['resetting'],

  methods: {
    reset() {
      this.$emit('resetting');
    },
  },
};
</script>

<style lang="scss" scoped>
.filter {
  cursor: pointer;
  height: fit-content;
  display: flex;
  align-items: center;
}
</style>
