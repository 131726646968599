<template>
  <SparkHiddenInput
    v-model="inputField"
    :name="name"
    type="checkbox"
    class="transition-color block h-[1.5rem] w-[2.5rem] cursor-pointer rounded-full bg-gray-100 p-[0.125rem] peer-checked:bg-primary-500 peer-disabled:cursor-default peer-disabled:bg-gray-200 peer-checked:[&>div]:translate-x-[calc(100%-0.25rem)] peer-disabled:[&>div]:!bg-gray-100"
    :disabled="disabled"
    value
    :unchecked-value="false"
  >
    <div class="h-[1.25rem] w-[1.25rem] rounded-full bg-white transition-transform" />
  </SparkHiddenInput>
</template>

<script>
import { computed } from 'vue';

import SparkHiddenInput from './SparkHiddenInput.vue';

export default {
  name: 'SparkSwitch',

  components: {
    SparkHiddenInput,
  },

  props: {
    name: { type: String, required: true },
    modelValue: { type: Boolean, required: true },
    disabled: { type: Boolean },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const inputField = computed({
      get: () => props.modelValue,
      set: value => emit('update:modelValue', value),
    });

    return { inputField };
  },
};
</script>
