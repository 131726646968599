<template>
  <SparkModal :model-value="show" @update:model-value="close">
    <template #header>
      <div class="text-19 font-bold flex">
        <div>Save&nbsp;</div>
      </div>
    </template>

    <template #default>
      <div>
        <SparkInput v-model="calibrationName" label="Name" name="name" />
        <div class="flex gap-8 justify-end">
          <SparkButton variant="outlined" @click="close()"><div v-text="'Cancel'" /></SparkButton>
          <SparkButton variant="secondary" @click="save()"><div v-text="'Save'" /></SparkButton>
        </div>
      </div>
    </template>
  </SparkModal>
</template>

<script>
import { mapState } from 'vuex';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';

export default {
  name: 'SaveResultsModal',

  components: { SparkModal, SparkInput, SparkButton },

  props: {
    show: { type: Boolean },
  },

  emits: ['close'],

  data() {
    return {
      calibrationName: '',
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
    ...mapState('calibration', ['selectedProcessChain', 'selectedMode', 'calibrationConfigUid', 'selectedParameters']),
  },

  methods: {
    close() {
      this.$emit('close');
    },

    async save() {
      const data = {
        calibration_config_name: this.calibrationName,
      };

      await this.axiosInstance
        .put(`api/v1/calibration-config/${this.calibrationConfigUid}`, data)
        .then(() => {
          this.$emit('close');

          this.createCalibratedResult();
        })
        .catch(error => {
          console.error(error);
        });
    },

    async createCalibratedResult() {
      await this.axiosInstance
        .post(`api/v1/calibration-result/${this.calibrationConfigUid}`)
        .then(response => {
          if (this.selectedMode === 'machine') {
            let text =
              'Machine: ' + response.data.machine_name + '        ' + 'Material: ' + response.data.material_name;
            this.$root.notify('success', 'Machine and material combination created', text, 10000);
          } else {
            this.$root.notify('success', 'Pricing created', response.data.pricing_name,  10000);
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
  },
};
</script>
