<template>
  <div class="flex text-[17px] flex-col m-20">
    <div class="flex gap-16 justify-center px-20 py-20">
      <div>
        <h2>Your customer is sharing part order details with you via 3D Spark. You can download them below</h2>
        <br />

        <h2 class="font-bold leading-10">What is 3D Spark?</h2>
        <p class="">3D Spark is a part order management software.</p>
        <p>
          <b>Global companies</b> like
          <a
            href="https://www.metal-am.com/deutsche-bahn-implements-3d-spark-automated-solution-for-additive-manufacturing-costing/"
            >DB</a
          >, <a href="https://amchronicle.com/news/3d-spark-signs-three-year-breakthrough-deal-with-alstom/">Alstom</a>,
          <a href="https://www.voxelmatters.com/zf-implements-3d-spark-platform-to-democratize-am/"
            >ZF Friedrichshafen</a
          >
          and
          <a
            href="https://www.linkedin.com/posts/cagriuzum_3dprinting-digitalmanufacturing-futureofmanufacturing-activity-7184103130215665665-yHYL?utm_source=share&utm_medium=member_desktop"
            >Siemens Mobility</a
          >
          use 3D Spark for technology selection with 15+ AM and conventional technologies; precise costing, make or buy
          decision support, instant supplier target price feedback, and sustainable decisions with a CO2 footprint
          calculation.
        </p>
        <br />
        <p>
          <b>Service manufacturers</b> use the software to quickly answer RFQs with auto-quoting using instant
          manufacturability assurance, accurate costing based on their in-house production reality and a pricing
          configurator. Interested in how 3D Spark can help you respond to <b>RFQs 80% faster</b>? Check out these
          <a href="https://www.3dspark.de/3d-spark-customer-testimonials#msp-customer-stories">testimonials</a> and
          <a href="https://www.3dspark.de/blog-post-categories/case-study">case studies</a> from various suppliers.
        </p>

        <br />
        <p>Visit the <a href="https://www.3dspark.de/">3D Spark website</a> for more details.</p>
      </div>
    </div>
    <div class="flex text-center mb-150 gap-48 max-w-[50%] mx-auto mt-100">
      <div class="flex flex-col items-center">
        <div class="mb-16">
          <span class="font-bold">You already have a 3D Spark account?</span> <br />
          Perfect! You can reach the order details here:
        </div>

        <router-link :to="gotToDownload()"
          ><SparkButton small><div>Download</div> </SparkButton>
        </router-link>
      </div>

      <div class="flex flex-col items-center pl-40">
        <div class="mb-16">
          <span class="font-bold">No account yet?</span> <br />
          Please register here for a free account:
        </div>
        <router-link :to="gotToDownload()"
          ><SparkButton small><div>Register</div> </SparkButton>
        </router-link>
        <br />
      </div>
    </div>
    <div class="self-center">
      Do you have any questions? Please contact <a href="mailto:support@3dspark.de">3D Spark Support!</a>
    </div>
  </div>
  <GtcFooter />
</template>

<script>
import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import GtcFooter from '@/layouts/default/components/GtcFooter.vue';
export default {
  name: 'DownloadLinkCallToAction',

  components: { SparkButton, GtcFooter },

  methods: {
    gotToDownload() {
      return { path: `/download-link/${this.$route.params.download_link_uid}` };
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: underline;
  color: #007bff;
}

a:hover {
  color: #0056b3;
}
</style>
