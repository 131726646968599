<template>
  <SparkButtonIcon
    custom="hover:!bg-primary-100 mr-4"
    small
    icon=""
    title="Download File"
    :disabled="disabled"
    @click="downloadFile()"
  >
    <img src="@/assets/icons/download.svg" />
  </SparkButtonIcon>
</template>

<script>
import { saveAs } from 'file-saver';
import { mapState } from 'vuex';

import SparkButtonIcon from '../SparkComponents/SparkButtonIcon.vue';

const origin = !import.meta.env.PROD ? 'http://localhost' : document.location.origin;

export default {
  name: 'DownloadFile',

  components: { SparkButtonIcon },

  props: {
    partId: { type: String, default: '' },
    newFileName: { type: String, default: '' },
    dataType: { type: String, default: '' },
    disabled: { type: Boolean },
  },

  data() {
    return {};
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
  },

  methods: {
    async downloadFile() {
      if (this.dataType == 'Part') {
        await this.axiosInstance
          .get(origin + '/api/v1/part/' + this.partId + '/upload-file/', {
            responseType: 'blob',
          })
          .then(response => {
            saveAs(response.data, this.newFileName);
          })
          .catch(error => {
            this.$root.notifyError('Download CAD', error);
          });
      } else if (this.dataType == 'Asset') {
        await this.axiosInstance
          .get(`/api/v1/part/${this.partId}/assets/`, {
            responseType: 'blob',
            params: { basename: this.newFileName },
          })
          .then(response => {
            saveAs(response.data, this.newFileName);
          })
          .catch(error => {
            this.$root.notifyError('Download Asset', error);
          });
      }
    },
  },
};
</script>
