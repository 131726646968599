<template>
  <div>
    <error-page
      title="Cookie Error"
      description=" The 3D Spark Platform requires cookies to work properly. Some modern browsers block all cookies by default or have very strict 3rd party rules. Please enable cookies for this page."
      back-to="/start"
      back-to-title="Back home"
      :image-name="errorImage"
      image-description="Something Went Wrong"
    >
      <ul>
        <li>
          <a
            href="https://support.mozilla.org/en-US/kb/websites-say-cookies-are-blocked-unblock-them"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fab fa-firefox-browser" />
            <span>Firefox</span>
          </a>
        </li>
        <li>
          <a href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank" rel="noopener noreferrer">
            <i class="fab fa-chrome" />
            <span>Chrome</span>
          </a>
        </li>
        <li>
          <a
            href="https://support.microsoft.com/en-us/office/enable-cookies-6b018d22-1d24-43d9-8543-3d35ddb2cb52"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fab fa-edge" />
            <span>Edge</span>
          </a>
        </li>
        <li>
          <a
            href="https://support.apple.com/de-de/guide/safari/sfri11471/mac"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fab fa-safari" />
            <span>Safari</span>
          </a>
        </li>
      </ul>
    </error-page>
  </div>
</template>

<script>
import ErrorImage from '@/assets/img/error_pages/cookie-error.png';
import ErrorPage from '@/components/Reusable/ErrorPage.vue';

export default {
  name: 'SomethingWentWrong',

  components: { ErrorPage },

  computed: {
    errorImage() {
      return ErrorImage;
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
}
</style>
