<template>
  <div v-if="primaryOrEmptyProcessChain" class="flex flex-col w-full">
    <div class="mt-16 ml-8">
      <h5>Selected Machine</h5>

      <div>
        <div class="topic-field">{{ machineData.mac_name }} by {{ machineData.oem_name }}</div>
        <div style="display: grid; margin-left: 10px">
          <IconWithText
            description="Build Envelope"
            :value="machineData.bld_size_x + 'mm x ' + machineData.bld_size_y + 'mm x ' + machineData.bld_size_z + 'mm'"
            :icon="buildEnvelopeSvg"
          />

          <div class="grid-container" style="grid-auto-flow: column; grid-auto-columns: 1fr">
            <IconWithText description="Time per Layer" :value="machineData.mac_prc_t_plyr + 's'" :icon="layerTimeSvg" />
            <IconWithText description="Setup Time" :value="machineData.setup_time + 'h'" :icon="setupTimeSvg" />
          </div>
        </div>

        <div class="grid-container" style="grid-auto-flow: column; grid-auto-columns: 1fr; margin-left: 10px">
          <IconWithText description="Hourly Rate" :value="machineData.mac_cost_ph + '€/h'" :icon="hourlyRateSvg" />
          <IconWithText
            description="Unloading Time"
            :value="machineData.unloading_time + 'h'"
            :icon="unloadingTimeSvg"
          />
        </div>

        <div class="grid-container" style="grid-auto-flow: column; grid-auto-columns: 1fr; margin-left: 10px">
          <IconWithText description="Fixed Cost" :value="machineData.fix_mac_cost_pj + '€'" :icon="fixedCostSvg" />
          <IconWithText
            description="Supervised Time"
            :value="machineData.staff_time_factor + '%'"
            :icon="supervisedTimeSvg"
          />
        </div>

        <div class="grid-container" style="grid-auto-flow: column; grid-auto-columns: 1fr; margin-left: 10px">
          <IconWithText
            description="Power Consumption"
            :value="machineData.power_consumption + 'kW'"
            :icon="powerConsumptionSvg"
          />
        </div>
      </div>
    </div>
    <div class="flex mt-16">
      <MachineTable class="w-full" />
    </div>
  </div>
  <div v-else class="container">Standard Machine for this process is selected.</div>
</template>

<script>
import { mapState } from 'vuex';

import IconWithText from '../../../components/IconWithText.vue';

import BuildEnvelope from '@/assets/icons/comparison/build_envelope.svg';
import FixedCost from '@/assets/icons/comparison/fixed_cost.svg';
import HourlyRate from '@/assets/icons/comparison/hourly_rate.svg';
import LayerTime from '@/assets/icons/comparison/layer_time.svg';
import PowerConsumption from '@/assets/icons/comparison/power_consumption.svg';
import SetupTime from '@/assets/icons/comparison/setup_time.svg';
import SupervisedTime from '@/assets/icons/comparison/supervised_time.svg';
import UnloadingTime from '@/assets/icons/comparison/unloading_time.svg';
import MachineTable from '@/components/PartWorkflow/Machine/MachineTable.vue';

export default {
  name: 'MachineCard',

  components: {
    MachineTable,
    IconWithText,
  },

  data() {
    return {
      machineData: {},
    };
  },

  computed: {
    ...mapState(['investigationDetails', 'part']),
    ...mapState('application', ['axiosInstance']),

    powerConsumptionSvg() {
      return PowerConsumption;
    },

    unloadingTimeSvg() {
      return UnloadingTime;
    },

    supervisedTimeSvg() {
      return SupervisedTime;
    },

    buildEnvelopeSvg() {
      return BuildEnvelope;
    },

    fixedCostSvg() {
      return FixedCost;
    },

    hourlyRateSvg() {
      return HourlyRate;
    },

    layerTimeSvg() {
      return LayerTime;
    },

    setupTimeSvg() {
      return SetupTime;
    },

    processChains() {
      return this.part.process_chains;
    },

    activeInvestigationDetails() {
      return this.investigationDetails;
    },

    activeProcessChain() {
      if (this.activeInvestigationDetails != '') {
        return this.processChains[this.activeInvestigationDetails.uid];
      }
      return {};
    },

    activeProcessChainIsAM() {
      return this.activeProcessChain?.tech == 'additive_manufacturing';
    },

    machineUid() {
      let actualProcessUid = this.activeInvestigationDetails.uid;
      let actualProcessChain = this.processChains[actualProcessUid];
      return actualProcessChain?.mac_id;
    },

    primaryOrEmptyProcessChain() {
      return this.activeProcessChainIsAM || this.activeInvestigationDetails.uid == 0;
    },
  },

  mounted() {
    this.getMachine(this.machineUid);
  },

  methods: {
    getMachine(uid) {
      this.axiosInstance.get(`/api/v1/machine/${uid}`).then(response => {
        this.machineData = { ...response.data };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  font-size: var(--12px);
  justify-content: start;
}

.right {
  text-align: right;
}

.background {
  background-color: gray;
  opacity: 0.5;
  padding: 10px;
  border-radius: 3px;
}

.topic-field {
  display: inline-block;
  width: fit-content;
  padding: 5px;
  border-radius: 10px;
  margin: 0px 10px;
  border: 1px solid gray;
  display: inline-block;
  font-size: var(--12px);
}

.machine-warning {
  font-size: var(--10px);
  margin: 5px 0px 20px 10px;
  color: grey;
}
</style>
