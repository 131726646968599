<template>
  <div class="bg-white border border-gray-100 mb-200 relative rounded-l-8 min-w-[1100px]">
    <table class="w-full border-collapse">
      <thead class="h-56 text-15">
        <th />
        <InvestigationTitle
          title="Name"
          :active-sorting="activeSorting['name']"
          sortby="name"
          @sort="sorting($event)"
        />
        <InvestigationTitle
          title="Lot Size"
          :active-sorting="activeSorting['lot_size']"
          sortby="lot_size"
          @sort="sorting($event)"
        />
        <InvestigationTitle
          title="Feasibility"
          :active-sorting="activeSorting['']"
          sortby="float_suitabilities.overall"
          @sort="sorting($event)"
        />
        <InvestigationTitle
          title="Cost"
          hover-title="Cost per Lot"
          :active-sorting="activeSorting['cost.cost_pp']"
          sortby="cost.cost_pp"
          class="w-150"
          @sort="sorting($event)"
        />
        <InvestigationTitle
          title="Lead Time"
          hover-title="Lead Time per Lot"
          :active-sorting="activeSorting['leadtime']"
          sortby="leadtime"
          class="w-150"
          @sort="sorting($event)"
        />
        <InvestigationTitle
          title="Carbon"
          :active-sorting="activeSorting['co2.co2_pp']"
          sortby="co2.co2_pp"
          class="w-150"
          @sort="sorting($event)"
        />
        <InvestigationTitle title=" " class="sticky right-0 bg-white" sortby="" @sort="sorting($event)" />
      </thead>

      <tbody class="text-13 relative">
        <tr v-for="(key, index) in sortedChainIds" :key="key" class="h-56">
          <td v-if="processChains[key] === undefined" colspan="8"><div class="ml-16 text-gray-500">Deleted</div></td>
          <template v-else>
            <td
              class="w-16 border border-gray-50 hover:bg-gray-50 hover:cursor-pointer"
              :class="activeInvestigationDetails?.uid === key ? 'bg-primary-600' : 'bg-white'"
              @click="selectProcessChain(key)"
            />
            <td
              class="w-[300px] border border-gray-50 hover:bg-gray-50 hover:cursor-pointer"
              @mouseenter="mouseHover[key] = 'name'"
              @mouseleave="mouseHover[key] = ''"
            >
              <ProcessChainName :chain="processChains[key]" :mouse-hover="mouseHover[key]" />
            </td>
            <td class="border border-gray-50 w-80"><LotSize :chain="processChains[key]" /></td>
            <td
              class="border border-gray-50 hover:bg-gray-50 hover:cursor-pointer"
              @mouseenter="mouseHover[key] = 'feasibility'"
              @mouseleave="mouseHover[key] = ''"
            >
              <Feasibility :chain="processChains[key]" :mouse-hover="mouseHover[key]" />
            </td>
            <td
              class="border border-gray-50 hover:bg-gray-50 hover:cursor-pointer"
              @mouseenter="mouseHover[key] = 'cost'"
              @mouseleave="mouseHover[key] = ''"
            >
              <Cost :chain="processChains[key]" :chains="processChains" :mouse-hover="mouseHover[key]" />
            </td>
            <td
              class="border border-gray-50 hover:bg-gray-50 hover:cursor-pointer"
              @mouseenter="mouseHover[key] = 'leadtime'"
              @mouseleave="mouseHover[key] = ''"
            >
              <Leadtime :chain="processChains[key]" :chains="processChains" :mouse-hover="mouseHover[key]" />
            </td>
            <td
              class="border border-gray-50"
              :class="analysisFinished(key) ? ' hover:bg-gray-50 hover:cursor-pointer' : ''"
              @mouseenter="mouseHover[key] = 'carbon'"
              @mouseleave="mouseHover[key] = ''"
            >
              <CarbonFootprint :chain="processChains[key]" :chains="processChains" :mouse-hover="mouseHover[key]" />
            </td>
            <td class="border border-gray-50 right-0 sticky bg-white" :style="{ 'z-index': 20 - index }">
              <Checkout :chain="processChains[key]" />
            </td>
          </template>
        </tr>
        <tr>
          <td colspan="7">
            <div
              v-if="sortedChainIds.length === 0"
              class="font-bold p-12 hover:cursor-pointer hover:text-primary-600 w-fit"
              @click="showCreateProcessChainPopup()"
            >
              <SparkButton variant="secondary"> <i class="fas fa-plus" />Add production option </SparkButton>
            </div>
            <div
              v-else
              class="font-bold p-12 hover:cursor-pointer hover:text-primary-600 w-fit"
              @click="showCreateProcessChainPopup()"
            >
              <i class="fas fa-plus" /> Add production option
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <CreateProcessChainPopup :trigger-create-process-chain-popup="triggerCreateProcessChainPopup" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

import SparkButton from '../../../SparkComponents/SparkButton.vue';

import CreateProcessChainPopup from './CreateProcessChainPopup.vue';
import InvestigationTitle from './InvestigationTitle.vue';

import Checkout from '@/components/PartWorkflow/Comparison/tablecells/actions/Checkout.vue';
import CarbonFootprint from '@/components/PartWorkflow/Comparison/tablecells/carbon/CarbonFootprint.vue';
import Cost from '@/components/PartWorkflow/Comparison/tablecells/cost/Cost.vue';
import Feasibility from '@/components/PartWorkflow/Comparison/tablecells/feasibility/Feasibility.vue';
import Leadtime from '@/components/PartWorkflow/Comparison/tablecells/leadtime/Leadtime.vue';
import LotSize from '@/components/PartWorkflow/Comparison/tablecells/lotsize/LotSize.vue';
import ProcessChainName from '@/components/PartWorkflow/Comparison/tablecells/name/ProcessChainName.vue';
import { analysisFinished } from '@/composables/processChainHandling.js';
import { getValueOfNestedObject, compareValues } from '@/helpers.js';

export default {
  name: 'InvestigationTable',

  components: {
    ProcessChainName,
    LotSize,
    Feasibility,
    Cost,
    Leadtime,
    CarbonFootprint,
    Checkout,
    InvestigationTitle,
    CreateProcessChainPopup,
    SparkButton,
  },

  data() {
    return {
      activeSorting: {},
      activeSortBy: {},
      sortedChainIds: [],
      mouseHover: {},

      triggerCreateProcessChainPopup: 0,
    };
  },

  computed: {
    ...mapState(['part', 'investigationDetails']),
    ...mapState('application', ['lockedTitle']),
    ...mapGetters(['lockedForUser']),

    previousPageWasOverview() {
      let previousRoute = this.$router.options.history.state.back;
      let prevWasOverview = false;
      if (previousRoute != null) {
        let prev = previousRoute.toString();
        prevWasOverview = prev.includes('/ov/');
      }
      return prevWasOverview;
    },

    primary_process_chain_id() {
      return this.part?.primary_process_chain_id;
    },

    benchmark_process_chain_id() {
      return this.part?.benchmark_process_chain_id;
    },

    processChains() {
      return this.part.process_chains;
    },

    activeInvestigationDetails() {
      return this.investigationDetails;
    },
  },

  watch: {
    processChains: {
      handler(oldVal, newVal) {
        this.sortedChainIds = Object.keys(newVal);
        let numberOfOldProcessChains = Object.keys(oldVal).length;
        let numberOfNewProcessChains = Object.keys(newVal).length;
        if (numberOfOldProcessChains != numberOfNewProcessChains) {
          if (this.activeSortBy != '' && Object.keys(this.activeSortBy) != 0) {
            this.sorting(this.activeSortBy, true);
          } else {
            this.sortByMainBenchmark();
          }
        }
      },

      deep: true,
    },
  },

  mounted() {
    this.sortByMainBenchmark();
  },

  beforeMount() {
    if (!this.previousPageWasOverview) {
      this.$store.commit('changeInvestigationDetails', '');
    }
  },

  methods: {
    ...mapMutations(['changeInvestigationDetails']),

    selectProcessChain(uid) {
      this.changeInvestigationDetails({ uid: uid });
    },

    showCreateProcessChainPopup() {
      this.changeInvestigationDetails({ uid: '' });
      this.triggerCreateProcessChainPopup += 1;
    },

    analysisFinished(uid) {
      return analysisFinished(uid);
    },

    sorting(newSortBy, fixedOrder) {
      if (fixedOrder != null) {
        this.sortByHeader(this.activeSorting[newSortBy].reverse);
      } else if (newSortBy != '') {
        if (this.activeSortBy != newSortBy) {
          this.activeSorting = {};
          this.activeSorting = { [newSortBy]: {} };
          this.activeSorting[newSortBy].enabled = true;
          this.activeSorting[newSortBy].reverse = false;
          this.activeSortBy = newSortBy;
        } else if (this.activeSortBy == newSortBy && this.activeSorting[newSortBy].reverse == false) {
          this.activeSorting[newSortBy].enabled = true;
          this.activeSorting[newSortBy].reverse = true;
        } else if (this.activeSortBy == newSortBy && this.activeSorting[newSortBy].reverse == true) {
          this.activeSorting = {};
          this.activeSortBy = '';
        }

        if (Object.keys(this.activeSorting).length != 0) {
          this.sortByHeader(this.activeSorting[newSortBy].reverse);
        } else {
          this.sortByMainBenchmark();
        }
      }
    },

    sortByHeader(reverse) {
      this.sortedChainIds = [];
      this.changeInvestigationDetails('');
      if (!this.previousPageWasOverview) {
        this.changeInvestigationDetails('');
      }

      let loopUpValueUid = {};

      let process_chain_ids = Object.keys(this.part.process_chains);

      for (const [key, value] of Object.entries(this.processChains)) {
        if (process_chain_ids.includes(key)) {
          let v = getValueOfNestedObject(value, this.activeSortBy);
          loopUpValueUid[key] = v;
        }
      }

      let sortedEntries = [];
      const entries = Object.entries(loopUpValueUid);
      if (!reverse) {
        sortedEntries = entries.sort((a, b) => compareValues(a[1], b[1]));
      } else if (reverse) {
        sortedEntries = entries.sort((b, a) => compareValues(a[1], b[1]));
      }
      sortedEntries.forEach(entry => {
        this.sortedChainIds.push(entry[0]);
      });
    },

    sortByMainBenchmark() {
      this.changeInvestigationDetails('');
      if (!this.previousPageWasOverview) {
        this.changeInvestigationDetails('');
      }

      let process_chain_ids = Object.keys(this.part.process_chains);

      let sorted_ids = [];
      if (
        this.primary_process_chain_id != null &&
        this.primary_process_chain_id != '' &&
        process_chain_ids.includes(this.primary_process_chain_id)
      ) {
        sorted_ids = [this.primary_process_chain_id];
      }
      if (
        this.benchmark_process_chain_id != null &&
        this.benchmark_process_chain_id != '' &&
        process_chain_ids.includes(this.benchmark_process_chain_id)
      ) {
        sorted_ids = sorted_ids.concat(this.benchmark_process_chain_id);
      }
      const other_ids = process_chain_ids.filter(element => !sorted_ids.includes(element));

      let all_sorted_ids = sorted_ids.concat(other_ids);

      this.sortedChainIds = all_sorted_ids;
    },
  },
};
</script>
