import defaultImage from '@/assets/img/Logo_Transparent.png';

const prpActions = {
  setPrpParts({ state }, data) {
    state.prpParts = data;
  },

  async setPrpPartsData({ rootState, commit, dispatch }, partsEvent) {
    const parts = partsEvent.results ?? [];

    const newParts = parts.map(part => {
      const comboCategories = rootState.user.organization.combo_categories;
      const textCategories = rootState.user.organization.text_categories;

      const categories = combineCategories(
        { combo_categories: comboCategories, text_categories: textCategories },
        part.categories
      );

      return { ...part, categories };
    });

    function combineCategories(organizationCategories, partCategories) {
      let mergedCategories = { combo_categories: {}, text_categories: {} };

      // Combine combo_categories
      for (let key in organizationCategories.combo_categories) {
        let uid = key;
        if (partCategories.combo_categories && partCategories.combo_categories[uid]) {
          mergedCategories.combo_categories[uid] = {
            ...organizationCategories.combo_categories[uid],
            ...partCategories.combo_categories[uid],
          };
          mergedCategories.combo_categories[uid].uid = Object.keys(mergedCategories.combo_categories[uid].items).find(
            key => mergedCategories.combo_categories[uid].items[key] === mergedCategories.combo_categories[uid].value
          );
        }
      }

      // Combine text_categories
      for (let key in organizationCategories.text_categories) {
        let uid = key;
        if (partCategories.text_categories && partCategories.text_categories[uid]) {
          mergedCategories.text_categories[uid] = {
            ...organizationCategories.text_categories[uid],
            ...partCategories.text_categories[uid],
          };
        }
      }

      return mergedCategories;
    }

    commit('setPrpParts', newParts);

    parts.map(part => {
      if (!part.thumbnail) {
        dispatch('fetchThumbnail', part.part_id);
      }
    });
  },

  async fetchThumbnail({ commit, rootState, state }, partId) {
    const updateThumbnail = (partId, thumbnail) => {
      const updatedParts = state.prpParts.map(part => {
        if (part?.part_id === partId) {
          const updatedPart = { ...part, thumbnail };
          return updatedPart;
        }
        return part;
      });

      commit('setPrpParts', updatedParts);
    };

    // Check if the thumbnail is already cached
    if (rootState.application.cachedThumbnails[partId]) {
      updateThumbnail(partId, rootState.application.cachedThumbnails[partId]);
      return;
    } else {
      updateThumbnail(partId, defaultImage);
    }

    try {
      const response = await rootState.application.axiosInstance.get(`api/v1/part-thumbnail/${partId}/`, {
        responseType: 'blob',
      });
      const fileReader = new FileReader();
      fileReader.readAsDataURL(response.data);
      fileReader.onload = e => {
        const thumbnail = e.target.result || defaultImage;

        // Cache the thumbnail in the store
        commit('application/cacheThumbnail', { part_id: partId, thumbnail }, { root: true });
        updateThumbnail(partId, thumbnail);
      };
    } catch (error) {
      updateThumbnail(partId, defaultImage);
    }
  },

  async fetchPrpPartLibraryData({ rootState, dispatch, commit }, formData) {
    const response = await rootState.application.axiosInstance.post('api/v1/prp-part-library/', formData);
    if (response.data) {
      commit('setPrpPartLibraryData', response.data);
      await dispatch('setPrpPartsData', response.data);
      commit('setInitialPrpSelectedParts');
    }
  },

  async fetchPrpOrderData({ rootState, commit }) {
    const response = await rootState.application.axiosInstance.get(`api/v1/prp-order/`);
    commit('setPrpOrderCategories', response.data);
  },
};
export default prpActions;
