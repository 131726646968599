<template>
  <SparkModal dismissable :model-value="show" @update:model-value="close">
    <template #header>
      <div>Edit Columns</div>
    </template>
    <template #default>
      <div class="flex flex-row mb-16 flex-wrap">
        <div v-for="header in headers" :key="header" class="p-2">
          <div
            :class="header.key === clickedHeader ? 'font-bold' : ' text-gray-700 bg-gray-50'"
            class="hover:cursor-pointer hover:bg-gray-100 rounded-8"
            @click="clickedHeader = header.key"
          >
            <div class="p-4">{{ header.label }}</div>
            <div class="px-8 text-9 text-gray-500">{{ numberOfSelections[header.key] }} selected</div>
          </div>
        </div>
      </div>
      <div v-for="option in options" :key="option">
        <div v-if="clickedHeader === option.tab || clickedHeader === 'all'">
          <SparkCheckbox v-model="option.selected" :name="option.title" />{{ option.title }}
        </div>
      </div>
      <div class="flex justify-end gap-16">
        <SparkButton variant="outlined" @click="close">
          <div v-text="'Ok'" />
        </SparkButton>
      </div>
    </template>
  </SparkModal>
</template>

<script>
import SparkButton from '../SparkComponents/SparkButton.vue';
import SparkCheckbox from '../SparkComponents/SparkCheckbox.vue';

import SparkModal from '@/components/SparkComponents/SparkModal.vue';

export default {
  name: 'SelectColumnsModal',

  components: { SparkModal, SparkCheckbox, SparkButton },

  props: {
    show: { type: Boolean },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  emits: ['close', 'save'],

  data() {
    return {
      clickedHeader: 'part_properties',
      headers: [
        { key: 'all', label: 'All Columns' },
        { key: 'part_properties', label: 'Part Properties' },
        { key: 'geometry', label: 'Geometry' },
        { key: 'manufacturing', label: 'Manufacturing' },
        { key: 'feasibility', label: 'Feasibility' },
        { key: 'suitability', label: 'Suitability' },
        { key: 'cost', label: 'Cost' },
        { key: 'market_price', label: 'Market Price' },
        { key: 'carbon', label: 'Carbon' },
        { key: 'lead_time', label: 'Lead Time' },
        { key: 'categories', label: 'Organisation Categories' },
        { key: 'prp_categories', label: 'PRP Categories' },
        { key: 'state', label: 'State' },
      ],
    };
  },

  computed: {
    numberOfSelections() {
      let selections = {};
      let selectableSum = 0;
      let selectedSum = 0;
      this.headers.forEach(header => {
        let selectable = 0;
        let selected = 0;
        Object.keys(this.options).forEach(key => {
          if (this.options[key].tab === header.key) {
            selectable += 1;
            selectableSum += 1;
          }
          if (this.options[key].tab === header.key && this.options[key].selected === true) {
            selected += 1;
            selectedSum += 1;
          }
        });
        selections[header.key] = selected + '/' + selectable;
      });
      selections['all'] = selectedSum + '/' + selectableSum;
      return selections;
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
