<template>
  <div class="max-w-[750px] rounded-8 flex flex-col gap-20 min-w-[750px] p-20 my-48 mx-auto border border-gray-100">
    <Teleport v-if="isMounted && selectedPricingOptionUid" to="#footer-content">
      <div class="flex justify-self-start">
        <div class="flex gap-24 cursor-pointer">
          <div class="flex flex-col">
            <div class="font-bold text-15" v-text="'Calculated Price'" />
            <span class="text-primary-500 font-semibold">{{ $formatTwoDecimalPlaces(calculatedPrice) + ' €' }}</span>
          </div>
          <div class="flex flex-col">
            <div class="font-bold text-15" v-text="'Price Range'" />
            <span class="text-primary-500 font-semibold">{{
              $formatTwoDecimalPlaces(lowerPrice) + ' - ' + $formatTwoDecimalPlaces(upperPrice) + ' €'
            }}</span>
          </div>
        </div>
      </div>
      <div class="flex justify-self-center">
        <div />
      </div>
      <div class="flex justify-self-end gap-16">
        <div />
      </div>
    </Teleport>
    <div class="font-bold" v-text="'Pricing Configuration'" />
    <SparkSelect
      v-model="selectedPricingOptionUid"
      :options="pricingOptionList"
      label="Pricing option"
      animate-label
      name="selection"
      @change="getPricingList"
    />
    <SparkInput
      v-model="selectedPricingOptionName"
      name="selectedPricingOptionName"
      title="Please assign a descriptive name for the pricing configuration."
      :disabled="selectedPricingOptionName === 'combinedConfig'"
      label="Pricing Configuration Name"
      custom="spark-input h-40 focus:h-40 text-13 rounded-4"
      @change="savePricingConfig"
    />
    <SparkInput
      v-model="minPrice"
      type="number"
      name="minPrice"
      title="Please assign a minimum price for the pricing configuration."
      label="Minimum Price per Part [€]"
      custom="spark-input h-40 focus:h-40 text-13 rounded-4"
      @blur="savePricingConfig"
    />
    <SparkInput
      v-model="lowerPriceDeviation"
      type="number"
      name="lowerPriceDeviation"
      title="You can assign a lower deviation for the pricing configuration."
      label="Lower Price Deviation [%]"
      custom="spark-input h-40 focus:h-40 text-13 rounded-4"
      @blur="savePricingConfig"
    />
    <SparkInput
      v-model="upperPriceDeviation"
      type="number"
      name="upperPriceDeviation"
      title="You can assign an upper deviation for the pricing configuration."
      label="Upper Price Deviation [%]"
      custom="spark-input h-40 focus:h-40 text-13 rounded-4"
      @blur="savePricingConfig"
    />

    <div v-if="selectedPricingOptionUid === 'combinedConfig'" class="mt-10 flex flex-col">
      <h2>Create a Combined Pricing Configuration</h2>
      <div v-for="pricing in pricingOptionList" :key="pricing.uid" class="mt-4 mr-20">
        <label v-if="pricing.combined_configs?.length === 0">
          <input v-model="selectedPricingOptions" class="ml-20" type="checkbox" :value="pricing.uid" />
          {{ pricing.name }}
        </label>
      </div>

      <SparkButton
        class="self-end"
        title="Create a Combined Pricing Configuration"
        custom="w-40 h-40"
        variant="outlined"
        small
        @click="addPricing"
      >
        <div class="action-icon"><i class="fas fa-plus" /></div>
      </SparkButton>
    </div>

    <div v-if="combinedConfigUid && combinedConfigList.length !== 0 && selectedPricingOptionUid" class="mt-10">
      <h2>You have selected a Combined Pricing Configuration.</h2>
      <summary>It combines the following configurations: {{ combinedConfigList.join(', ') }}</summary>
    </div>

    <div v-if="selectedPricingOptionUid && selectedPricingOptionUid !== 'combinedConfig'" class="ml-auto flex">
      <h2 class="mt-10" v-text="'Delete this Pricing Configuration'" />
      <SparkButton class="ml-10" variant="outlined" @click="deleteConfig(selectedPricingOptionUid)">
        <div v-text="'✕'" />
      </SparkButton>
    </div>

    <div v-if="!combinedConfigUid && selectedPricingOptionUid !== 'combinedConfig'" class="flex justify-between">
      <div class="w-3/4">
        <span class="font-bold" v-text="'Expressions'" />

        <div
          class="text-gray-400"
          v-text="
            'Use operands (Lots size, Margin, Cost per part, Overhead) and operators (+, -, /, *, ()) to create expressions'
          "
        />
      </div>
      <SparkButton variant="outlined" @click="showExpressionBuilder = !showExpressionBuilder">
        <span class="font-bold"><i class="fas fa-plus" /> Add expression </span>
      </SparkButton>
    </div>
    <Expression
      v-if="!combinedConfigUid && showExpressionBuilder && selectedPricingOptionUid !== 'combinedConfig'"
      :clear-inputs="clearInputs"
      @expression-input="assignExpression"
    />
    <ExpressionContainer
      v-if="!combinedConfigUid && pricingList.length > 0 && selectedPricingOptionUid !== 'combinedConfig'"
      :pricing-list="pricingList"
      :selected-pricing-option-uid="selectedPricingOptionUid"
      @delete-pricing="deletePricing"
    />

    <div v-if="!combinedConfigUid && selectedPricingOptionUid !== 'combinedConfig'" class="flex justify-between mt-20">
      <span class="font-bold" v-text="'Rules'" />

      <SparkButton variant="outlined" @click="showRuleBuilder = !showRuleBuilder">
        <span class="font-bold"><i class="fas fa-plus" /> Add rule </span>
      </SparkButton>
    </div>

    <Rule
      v-if="!combinedConfigUid && showRuleBuilder && selectedPricingOptionUid !== 'combinedConfig'"
      :clear-inputs="clearInputs"
      @rule-input="assignRule"
    />
    <RuleContainer
      v-if="!combinedConfigUid && pricingList.length > 0 && selectedPricingOptionUid !== 'combinedConfig'"
      :pricing-list="pricingList"
      :selected-pricing-option-uid="selectedPricingOptionUid"
      @delete-pricing="deletePricing"
    />
    <div
      v-if="!combinedConfigUid && (expression || rule) && selectedPricingOptionUid !== 'combinedConfig'"
      class="ml-auto flex"
      title="Add Expression (and rule) to Pricing Configuration"
    >
      <SparkButton class="ml-10" variant="outlined" @click="addPricing">
        <i class="fas fa-plus" />
      </SparkButton>
    </div>

    <div
      v-if="selectedPricingOptionUid && !combinedConfigUid && selectedPricingOptionUid !== 'combinedConfig'"
      class="border border-gray-10 rounded-8 p-16 mt-16"
    >
      <h2>Test this Pricing Configuration.</h2>
      <SparkSelect
        v-model="selectedPartId"
        :options="partList"
        label="Select a Part"
        class="my-16"
        animate-label
        name="part"
        @change="getProcessChainList"
      />

      <SparkSelect
        v-if="selectedPartId"
        v-model="selectedProcessChainId"
        :options="processChainList"
        label="Select a Process Chain"
        animate-label
        name="process-chain"
      />

      <!-- Evaluate  -->
      <SparkButton variant="outlined" title="Evaluate Pricing Configuration" class="mt-16" @click="evaluatePricing">
        <span class="cursor-pointer" v-text="'Evaluate'" />
      </SparkButton>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Expression from './components/Expression.vue';
import ExpressionContainer from './components/ExpressionContainer.vue';
import Rule from './components/Rule.vue';
import RuleContainer from './components/RuleContainer.vue';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import SparkSelect from '@/components/SparkComponents/SparkSelect.vue';

export default {
  name: 'PricingConfig',

  components: {
    SparkSelect,
    SparkInput,
    SparkButton,
    Expression,
    Rule,
    ExpressionContainer,
    RuleContainer,
  },

  data() {
    return {
      isMounted: false,
      pricingOptionList: [
        { label: 'Create a new pricing configuration', value: '' },
        { label: 'Create a combined configuration', value: 'combinedConfig' },
      ],

      pricingList: [],
      partList: [],
      processChainList: [],
      lowerPrice: null,
      upperPrice: null,

      selectedPricingOptionName: '',
      selectedPricingOptionUid: '',
      activePricing: null,
      minPrice: null,
      showExpressionBuilder: false,
      showRuleBuilder: false,
      lowerPriceDeviation: null,
      calculatedPrice: null,
      upperPriceDeviation: null,
      combinedConfigUid: '',
      expression: '',
      rule: '',
      clearInputs: false,
      selectedProcessChainId: null,
      selectedPartId: null,
      selectedPricingOptions: [],
      combinedConfigList: [],
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
  },

  watch: {
    selectedPricingOptionUid() {
      if (this.selectedPricingOptionUid === 'combinedConfig' || this.selectedPricingOptionUid === '') {
        this.selectedPricingOptionName = '';
        this.minPrice = 0;
        this.lowerPriceDeviation = 0;
        this.upperPriceDeviation = 0;
      }
    },
  },

  mounted() {
    this.isMounted = true;
    this.getPricingOptionList();
    this.getPricingList();
    this.getPartList();
  },

  methods: {
    async savePricingConfig() {
      if (this.selectedPricingOptionUid && this.selectedPricingOptionUid !== 'combinedConfig') {
        if (!this.minPrice || this.minPrice === null || this.minPrice === undefined || this.minPrice < 0) {
          this.minPrice = 0;
        } else if (this.minPrice > 10000000) {
          this.$root.notify('error', 'Error', 'Please lower the minimum price.');
          return;
        }

        if (!this.lowerPriceDeviation || this.lowerPriceDeviation === null || this.lowerPriceDeviation === undefined) {
          this.lowerPriceDeviation = 0;
        }

        if (!this.upperPriceDeviation || this.upperPriceDeviation === null || this.upperPriceDeviation === undefined) {
          this.upperPriceDeviation = 0;
        }

        let formData = {
          pricing_config_name: this.selectedPricingOptionName,
          min_price: this.minPrice,
          lower_deviation: this.lowerPriceDeviation / 100,
          upper_deviation: this.upperPriceDeviation / 100,
        };

        try {
          await this.axiosInstance.put(`api/v1/pricing-config/${this.selectedPricingOptionUid}/`, formData);
          await this.getPricingOptionList();
          this.$root.notify('success', '', 'Price Configuration updated.', 3000);
        } catch (error) {
          if (error.response.status === 400) {
            this.$root.notify('error', 'Error', 'Pricing Configuration could not be updated.');
          }
          return;
        }
      }
    },

    async getPricingList() {
      if (this.selectedPricingOptionUid) {
        const selectedPricingConfig = this.pricingOptionList.find(item => item.uid === this.selectedPricingOptionUid);
        this.selectedPricingOptionName = selectedPricingConfig ? selectedPricingConfig.name : null;

        this.minPrice = selectedPricingConfig ? selectedPricingConfig.min_price : null;
        this.lowerPriceDeviation = selectedPricingConfig ? selectedPricingConfig.lower_deviation * 100 : null;
        this.upperPriceDeviation = selectedPricingConfig ? selectedPricingConfig.upper_deviation * 100 : null;
        this.axiosInstance.get(`api/v1/pricing-list/${this.selectedPricingOptionUid}/`).then(response => {
          this.pricingList = response.data;

          this.numberOfPricings = this.pricingList.length;
          this.checkCombinedConfigs();
        });
      } else {
        this.pricingList = [];
        this.numberOfPricings = 0;
      }
    },

    async getPartList() {
      await this.axiosInstance.get('api/v1/part-list/').then(response => {
        this.partList = response.data;
        this.partList.forEach(part => {
          part.value = part.part_id;
          part.label = part.name;
        });
      });
    },

    getProcessChainList() {
      if (this.selectedPartId) {
        this.axiosInstance.get(`api/v1/process-chain-list/${this.selectedPartId}/`).then(response => {
          this.processChainList = response.data;

          this.processChainList.forEach(processChain => {
            processChain.value = processChain.process_chain_id;
            processChain.label = processChain.name;
          });
        });
      }
    },

    checkCombinedConfigs() {
      const selectedPricing = this.pricingOptionList.find(pricing => pricing.uid === this.selectedPricingOptionUid);
      if (selectedPricing && selectedPricing.combined_configs.length > 0) {
        this.combinedConfigUid = this.selectedPricingOptionUid;
        this.combinedConfigList = selectedPricing.combined_configs.map(uid => {
          const combinedConfig = this.pricingOptionList.find(pricing => pricing.uid === uid);
          return combinedConfig ? combinedConfig.name : null;
        });
      } else {
        this.combinedConfigUid = '';
      }
    },

    async getPricingOptionList() {
      try {
        const response = await this.axiosInstance.get(`api/v1/pricing-config-list/`);
        const pricingOptionList = response.data.filter(pricing => pricing.uid !== 'cc3d0e78623d4ad58f66b3a61d4d2d59'); // filter out the default option, it should not be ediable in the cofigurator
        for (const pricingOption of pricingOptionList) {
          this.pricingOptionList.push({
            label: pricingOption.name,
            value: pricingOption.uid,
            combined_configs: pricingOption.combined_configs,
            lower_deviation: pricingOption.lower_deviation,
            upper_deviation: pricingOption.upper_deviation,
            organization: pricingOption.organization,
            uid: pricingOption.uid,
            min_price: pricingOption.min_price,
            name: pricingOption.name,
          });
        }

        this.pricingOptionList = this.ensureUniqueOptions(this.pricingOptionList);

        this.activePricing = null;
      } catch (error) {
        console.error('Error fetching pricing options:', error);
      }
    },

    async evaluatePricing() {
      if (!this.selectedProcessChainId) {
        this.$root.notify('error', 'Error', 'Fill out all fields');
        return;
      }

      if (this.selectedPricingOptionUid) {
        try {
          this.getPricingOptionList();
          let formData = new FormData();
          formData.append('min_price', this.minPrice);
          formData.append('lower_deviation', this.lowerPriceDeviation / 100);
          formData.append('upper_deviation', this.upperPriceDeviation / 100);
          await this.axiosInstance
            .post(`api/v1/pricing-config/${this.selectedProcessChainId}/${this.selectedPricingOptionUid}/`, formData)
            .then(response => {
              this.calculatedPrice = response.data.result; // Bind the calculated price
              this.lowerPrice = response.data.lower_price || this.calculatedPrice;
              this.upperPrice = response.data.upper_price || this.calculatedPrice;
              this.activePricing = response.data.active_pricing_idx; // Order index of the active pricing
              this.variablesObject = response.data;
              this.expressionObject.forEach(item => {
                if (item.variableType === 'variable') {
                  // Set the value from the dataObject based on variableName
                  item.value = this.variablesObject[item.variableName];
                }
              });
            })
            .catch(error => {
              console.log(error.response);
            });
        } catch (error) {
          console.error(error);
          return;
        }
      }
    },

    deleteConfig(uid) {
      this.axiosInstance.delete(`api/v1/pricing-config/${uid}/`).then(async () => {
        this.$root.notify('success', 'Success', 'Pricing configuration deleted');
        this.pricingOptionList = this.pricingOptionList.filter(pricing => pricing.uid !== uid);
        await this.getPricingOptionList();
        await this.getPricingList();
        this.selectedPricingOptionUid = '';
        this.selectedPricingOptionName = '';
        this.combinedConfigUid = '';
      });
    },

    assignExpression(expression) {
      this.expression = expression;
    },

    assignRule(rule) {
      this.rule = rule;
    },

    async addPricing() {
      if (!this.expression && this.selectedPricingOptionUid !== 'combinedConfig') {
        this.$root.notify('error', 'Error', 'Please add an expression');
        return;
      }

      if (!this.minPrice || this.minPrice < 0) {
        this.minPrice = 0;
      } else if (this.minPrice > 10000000) {
        this.$root.notify('error', 'Error', 'Please lower the minimum price.');
        return;
      }

      if (!this.lowerPriceDeviation) {
        this.lowerPriceDeviation = 0;
      }

      if (!this.upperPriceDeviation) {
        this.upperPriceDeviation = 0;
      }

      if (!this.selectedPricingOptionUid || this.selectedPricingOptionUid === 'combinedConfig') {
        try {
          let formData = new FormData();
          formData.append('pricing_config_name', this.selectedPricingOptionName);
          formData.append('min_price', this.minPrice);
          formData.append('lower_deviation', this.lowerPriceDeviation / 100);
          formData.append('upper_deviation', this.upperPriceDeviation / 100);
          formData.append('combined_config', this.selectedPricingOptions);

          const response = await this.axiosInstance.post(`api/v1/pricing-config/`, formData);
          await this.getPricingList(); // Update the pricing list
          await this.getPricingOptionList();
          if (this.selectedPricingOptionUid === 'combinedConfig') {
            this.combinedConfigUid = response.data.uid;
          }

          this.selectedPricingOptionUid = response.data.uid;
        } catch (error) {
          console.error(error);
          return;
        }
      }

      if (this.selectedPricingOptionUid !== this.combinedConfigUid) {
        let formData = new FormData();
        formData.append('pricing_config_uid', this.selectedPricingOptionUid);
        formData.append('expression', this.expression);
        formData.append('rule', this.rule);
        formData.append('min_price', this.minPrice);
        formData.append('lower_deviation', this.lowerPriceDeviation / 100);
        formData.append('upper_deviation', this.upperPriceDeviation / 100);

        try {
          await this.axiosInstance.post(`api/v1/pricing-list/${this.selectedPricingOptionUid}/`, formData);
          this.$root.notify('success', 'Success', 'Pricing added');
          await this.getPricingList(); // Update the pricing list
          await this.getPricingOptionList();
        } catch (error) {
          if (error.response.status === 400) {
            this.$root.notify('error', 'Error', 'Bad expression or rule.');
          }
          return;
        }
      } else {
        this.selectedPricingOptionUid = '';
        this.selectedPricingOptionName = '';
        this.combinedConfigUid = '';
        this.getPricingOptionList();
      }

      this.clearInputs = !this.clearInputs;
    },

    ensureUniqueOptions(options) {
      const uniqueOptions = [];
      const seenNames = new Set();
      for (const option of options) {
        if (!seenNames.has(option.value)) {
          seenNames.add(option.value);
          uniqueOptions.push(option);
        }
      }

      return uniqueOptions;
    },

    deletePricing(uid) {
      this.axiosInstance.delete(`api/v1/pricing/${uid}/`).then(() => {
        this.$root.notify('success', 'Success', 'Pricing deleted');
        this.getPricingList();
        this.getPricingOptionList();
      });
    },
  },
};
</script>
