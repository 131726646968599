<template>
  <SparkModal dismissable :model-value="show" @update:model-value="close">
    <template #header>
      <div class="flex flex-col">
        <div>Duplicate Part</div>
        <div class="text-11 text-gray-400 font-normal">Change inputs</div>
      </div>
    </template>
    <template #default>
      <div>
        <SparkInput v-model="name" name="name" label="Name" />
        <SparkInput v-model="lot_size" name="lotsize" label="Lotsize" />
        <div v-if="selectedMode === 'machine'">
          <div class="text-gray-500">Build Time</div>
          <FeedbackValueTimespan :feedback-value="value" editable @feedback-value-changed="setTime($event)" />
        </div>
        <div v-if="selectedMode === 'price'">
          <SparkInput v-model="value" name="price" label="Price" />
        </div>
      </div>
      <div class="flex gap-8 my-8 justify-end">
        <SparkButton variant="outlined" @click="close()"><div v-text="'Cancel'" /></SparkButton>
        <SparkButton variant="secondary" @click="save()"><div v-text="'Save'" /></SparkButton>
      </div>
    </template>
  </SparkModal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import FeedbackValueTimespan from '@/components/Reusable/FeedbackValues/FeedbackValueTimespan.vue';
import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';

export default {
  name: 'DuplicatePartModal',

  components: {
    SparkModal,
    SparkButton,
    SparkInput,
    FeedbackValueTimespan,
  },

  props: {
    show: { type: Boolean },
    partToDuplicate: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  emits: ['close', 'select'],

  data() {
    return {
      name: '',
      lot_size: 0,
      value: 0,
    };
  },

  computed: {
    ...mapState('calibration', ['selectedMode', 'transformedPartsData']),
  },

  watch: {
    partToDuplicate(newVal) {
      this.name = newVal.basename;
      this.lot_size = newVal.lot_size;
      if (newVal.value != null) {
        this.value = newVal.value;
      }
    },
  },

  methods: {
    ...mapMutations('calibration', ['duplicatePartFromTransformedPartData']),
    close() {
      this.$emit('close');
    },

    save() {
      let numberOfParts = Object.keys(this.transformedPartsData).length;
      let part = {
        basename: this.name,
        lot_size: this.lot_size,
        value: this.value,
        uid: this.partToDuplicate.uid,
        key: 'duplicated' + numberOfParts,
      };
      this.duplicatePartFromTransformedPartData(part);
      this.$emit('close');
    },

    setTime(event) {
      this.value = event;
    },
  },
};
</script>
