<template>
  <div v-if="canvas.showGrid" class="relative flex">
    <div class="grid-lines" :style="gridStyle" />
    <div class="absolute pointer-events-none text-13 top-[50px] translate-x-1/2" :style="labelStyle">
      {{ formatNumberForGridLabel(gridSpacingInMM) }} mm
    </div>
    <div class="dimension-line" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

const numberGridLinesInCanvasWidth = 5;

export default {
  name: 'CanvasGrid',

  props: {
    zoom: Number(0),
    clientWidth: Number(0),
    clientHeight: Number(0),
    sceneWidthInMM: Number(0),
    viewScaleFactor: Number(0),
  },

  data() {
    return {
      gridSpacingInMM: 0,
    };
  },

  computed: {
    ...mapState('canvas', ['canvas']),

    gridStyle() {
      const gridSpacing = this.clientWidth / numberGridLinesInCanvasWidth;
      const styleDict = {
        backgroundSize: `${gridSpacing}px ${gridSpacing}px`,
        height: `${this.clientHeight}px`,
      };
      return styleDict;
    },

    labelStyle() {
      return { right: `${100 / numberGridLinesInCanvasWidth / 2}%` };
    },
  },

  watch: {
    zoom(newVal) {
      const widthInMM = this.sceneWidthInMM / this.viewScaleFactor / newVal;
      this.gridSpacingInMM = widthInMM / numberGridLinesInCanvasWidth;
    },
  },

  methods: {
    formatNumberForGridLabel(value) {
      const precision = 2;
      const scientificNotationTransition = Math.pow(10, precision);
      if (value >= scientificNotationTransition) {
        return value.toFixed(0);
      } else {
        return value.toPrecision(precision);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-lines {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.2) 1px, transparent 0px),
    linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 1px, transparent 0px);
}

.dimension-line {
  pointer-events: none;
  position: absolute;
  top: 70px;
  right: 0%;
  width: 20%; // equals 1/numberGridLinesInCanvasWidth
  height: 2px; // boldness of line
  background-color: black;
}
.dimension-line::before,
.dimension-line::after {
  content: '';
  position: absolute;
  // move arrowheads vertically to align with dimension line
  top: 50%;
  transform: translateY(-50%);
}
.dimension-line::before {
  // left arrow head
  border-width: 5px 15px 5px 0; // shape of head
  border-color: transparent black transparent transparent;
}
.dimension-line::after {
  // right arrow head
  right: 0%;
  border-width: 5px 0 5px 15px; // shape of head
  border-color: transparent transparent transparent black;
}
</style>
