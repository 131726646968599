import { MathUtils, Box3, Vector3, BufferAttribute } from 'three';

import {
  setupRotationMatrixFromKardan,
  applyRotToVectorArray,
  centerOfBB,
} from '@/assets/js/3d_viewer/SparkModules/MeshRotation.js';

function intersection(...boolMaps) {
  let intersectArray = new Array(boolMaps[0].length).fill(false);
  for (let indVertex = 0; indVertex < intersectArray.length; indVertex++) {
    let falseEntryFound = false;
    for (let indMaps = 0; indMaps < boolMaps.length; indMaps++) {
      if (!boolMaps[indMaps][indVertex]) {
        falseEntryFound = true;
        break;
      }
    }
    intersectArray[indVertex] = !falseEntryFound;
  }
  return intersectArray;
}

function union(...boolMaps) {
  let unionArray = new Array(boolMaps[0].length).fill(false);
  for (let indVertex = 0; indVertex < unionArray.length; indVertex++) {
    for (let indMaps = 0; indMaps < boolMaps.length; indMaps++) {
      if (boolMaps[indMaps][indVertex]) {
        unionArray[indVertex] = true;
        break;
      }
    }
  }
  return unionArray;
}

function any(boolMap) {
  return boolMap.some(element => element);
}

export class FeasibilityEvaluater {
  constructor(mesh) {
    this.mesh = mesh;
  }

  prepareMachineBB(machineEnvelope, physicalScale, orientedBB) {
    // it is computationally faster to inverse scale the machine than
    // to apply the scale to every vertex of the mesh

    let physicalScaleVec = new Vector3(...physicalScale);
    let scaledExtend = machineEnvelope.clone();
    scaledExtend.divide(physicalScaleVec); // inverse scale

    let scaledHalfExtend = scaledExtend.clone().divideScalar(2);

    let center = new Vector3(
      centerOfBB(orientedBB.min.x, orientedBB.max.x),
      centerOfBB(orientedBB.min.y, orientedBB.max.y),
      orientedBB.min.z + scaledHalfExtend.z
    );
    center.z -= 0.0001; // as part is always flush with plate, the vertices at plate would otherwise allways be shown as protruding

    let machineBB = new Box3(center.clone().sub(scaledHalfExtend), center.clone().add(scaledHalfExtend));
    return machineBB;
  }

  computeMandatoryFeasibilityMaps(feasibility, rotVerticesPos, preparedMachineEnvelope) {
    let limSupportDeg = feasibility?.support?.min?.lim;
    if (limSupportDeg !== undefined && this.mesh?.geometry?.attributes?._occlusion !== undefined) {
      this.boolMapSupportedVertices = this._getSupportedVertices(limSupportDeg); // quite time consuming --> reuse later

      let limOcclusion = feasibility.support_occluded.min.lim;
      let occlusionMap = this.mesh.geometry.attributes._occlusion.array;
      this.boolMapOccludedVertices = this._compareMapToScalarLim(occlusionMap, this._smallerThan, limOcclusion);

      this.boolMapOccludedSupportedVertices = intersection(this.boolMapOccludedVertices, this.boolMapSupportedVertices);
    }

    this.boolMapVerticesProtrudingFromMachine = this._verticesProtrudingFromMachine(
      rotVerticesPos,
      preparedMachineEnvelope
    );
  }

  _getSupportedVertices(limSupportDeg) {
    let degToRad = x => MathUtils.degToRad(x);
    let limit_angle_rad = degToRad(limSupportDeg);
    let rot_x = this.mesh.rotation.x;
    let rot_y = this.mesh.rotation.y;
    let rot_z = this.mesh.rotation.z;

    let rotMat = setupRotationMatrixFromKardan(rot_x, rot_y, rot_z);

    let origFaceNormals = new BufferAttribute(new Float32Array(this.mesh.userData.faceNormals), 3);
    let rotatedNormals = applyRotToVectorArray(rotMat, origFaceNormals);

    let numberFaces = origFaceNormals.array.length;
    let numberVertices = this.mesh.geometry.attributes.position.count;
    let vertexNeedsSupport = new Array(numberVertices).fill(false);
    for (let cntFace = 0; cntFace < numberFaces; cntFace += 3) {
      let rotNormalZ = rotatedNormals[cntFace + 2];
      const faceNeedsSupport = Math.PI - Math.acos(rotNormalZ) < limit_angle_rad;
      if (faceNeedsSupport) {
        this._setAllVerticesOfFaceToTrue(vertexNeedsSupport, cntFace);
      }
    }
    return vertexNeedsSupport;
  }

  _setAllVerticesOfFaceToTrue(vertexNeedsSupport, faceInd) {
    const indices = this.mesh.geometry.index.array;
    const indicesVerticesOfFace = [indices[faceInd + 0], indices[faceInd + 1], indices[faceInd + 2]];
    indicesVerticesOfFace.forEach(vertexInd => {
      vertexNeedsSupport[vertexInd] = true;
    });
  }

  _compareMapToScalarLim(map, passing_comparator, lim) {
    let numberVertices = map.length;
    let boolArray = new Array(numberVertices);
    for (let indVertex = 0; indVertex < numberVertices; indVertex++) {
      boolArray[indVertex] = passing_comparator(map[indVertex], lim);
    }
    return boolArray;
  }

  _greaterThan(a, b) {
    return a > b;
  }

  _smallerThan(a, b) {
    return a < b;
  }

  _verticesProtrudingFromMachine(rotVerticesPos, preparedMachineEnvelope) {
    let macEnvelMin = preparedMachineEnvelope.min.toArray();
    let macEnvelMax = preparedMachineEnvelope.max.toArray();
    const numberVertices = rotVerticesPos.length / 3;
    const xzyInd = [0, 1, 2];
    let boolMapProtruding = new Array(numberVertices).fill(false);

    for (let indVertex = 0; indVertex < boolMapProtruding.length; indVertex++) {
      let startInd = indVertex * 3;
      for (let indXYZ = 0; indXYZ < xzyInd.length; indXYZ++) {
        const vertexPosInCoord = rotVerticesPos[startInd + indXYZ];
        if (vertexPosInCoord >= macEnvelMax[indXYZ] || vertexPosInCoord <= macEnvelMin[indXYZ]) {
          boolMapProtruding[indVertex] = true;
          break;
        }
      }
    }
    return boolMapProtruding;
  }

  evalFeasibilityChecks() {
    let checkOccludedSupports;
    if (this.boolMapOccludedSupportedVertices === undefined) {
      checkOccludedSupports = null;
    } else {
      checkOccludedSupports = !any(this.boolMapOccludedSupportedVertices);
    }

    let checkFitsMachine = !any(this.boolMapVerticesProtrudingFromMachine);
    return [checkOccludedSupports, checkFitsMachine];
  }

  computeFeasibilityMapToPlot(attribute, feasibility) {
    const geometry = this.mesh.geometry;
    const negateArray = array => array.map(value => !value);

    let feasMap;
    switch (attribute) {
      case '_fits_machine':
        feasMap = negateArray(this.boolMapVerticesProtrudingFromMachine);
        break;
      case '_support':
        feasMap = negateArray(this.boolMapSupportedVertices);
        break;
      case '_support_removability':
        feasMap = negateArray(this.boolMapOccludedSupportedVertices);
        break;
      case '_thickness_min':
        feasMap = this._compareMapToScalarLim(
          geometry.attributes._thickness.array,
          this._greaterThan,
          feasibility.thickness.min.lim
        );
        break;
      case '_thickness_max':
        feasMap = this._compareMapToScalarLim(
          geometry.attributes._thickness.array,
          this._smallerThan,
          feasibility.thickness.max.lim
        );
        break;
      case '_gap_size':
        feasMap = this._compareMapToScalarLim(
          geometry.attributes._gap_size.array,
          this._greaterThan,
          feasibility.gap_size.min.lim
        );
        break;
      case '_radii':
        feasMap = this._compareMapToScalarLim(
          geometry.attributes._radii.array,
          this._greaterThan,
          feasibility.radii.min.lim
        );
        break;
    }
    return feasMap;
  }
}
