<template>
  <div class="filter-element">
    <div class="filter-label">Pipeline Status</div>
    <div class="filtering">
      <VueMultiselect
        v-model="selectedPipelineStatus"
        :options="pipelineStatusOptions"
        multiple
        :close-on-select="false"
        :limit="10"
        :limit-text="limitText"
        placeholder="Pick some"
        label="name"
        @remove="handlePipelineStatusRemoval"
      >
        <!-- Custom Option Template -->
        <template #option="props">
          <div>
            <span v-if="props.option.favorite">
              <i class="fas fa-star" />
            </span>

            {{ props.option.name }}
          </div>
        </template>
      </VueMultiselect>
    </div>
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'PipelineStatusFilter',

  components: {
    VueMultiselect,
  },

  props: {
    reset: { type: Number, required: true },
    parent: { type: String, required: true },
  },

  emits: ['selected'],

  data() {
    return {
      selectedPipelineStatus: [],
      pipelineStatusOptions: [],
    };
  },

  computed: {
    ...mapState(['partLibraryFilters', 'pipeline']),
    ...mapState('application', ['axiosInstance']),
  },

  watch: {
    selectedPipelineStatus: {
      handler(newVal) {
        const selectedIds = newVal.map(status => status.uid);
        this.$emit('selected', { pipeline_status: selectedIds });
      },
    },

    pipeline: {
      handler() {
        if (this.pipeline) {
          this.pipelineStatusOptions = this.pipeline.map(entry => ({
            name: entry.name,
            uid: entry.uid,
            orderIndex: entry.order_index,
          }));
        }
      },

      immediate: true,
    },

    reset: {
      handler() {
        if (this.reset) {
          this.clear();
        }
      },
    },
  },

  async mounted() {
    const filter = JSON.parse(sessionStorage.getItem('libraryFilter'));
    if (this.parent === 'library') {
      const selected = filter?.pipeline_status;
      if (selected?.length) {
        const selectedPipelineStatus = this.pipelineStatusOptions.filter(status => selected.includes(status.uid));
        if (selectedPipelineStatus && selectedPipelineStatus.length) {
          this.selectedPipelineStatus = this.ensureUniquePipelineStatus(selectedPipelineStatus);
        }
      }
    }
  },

  methods: {
    ...mapMutations(['resetFilter']),

    handlePipelineStatusRemoval(removedPipelineStatus) {
      const updatedPipelineStatus = this.selectedPipelineStatus.filter(
        status => status.uid !== removedPipelineStatus.uid
      );
      this.selectedPipelineStatus = this.ensureUniquePipelineStatus(updatedPipelineStatus);
    },

    ensureUniquePipelineStatus(statuses) {
      const uniquePipelineStatuses = [];
      const seenIds = new Set();

      for (const status of statuses) {
        if (!seenIds.has(status.mat_id)) {
          seenIds.add(status.mat_id);
          uniquePipelineStatuses.push(status);
        }
      }

      return uniquePipelineStatuses;
    },

    clear() {
      this.resetFilter({ pipelineStatus: 'pipelineStatus' });
      this.selectedPipelineStatus = [];
    },

    limitText() {
      return `...`;
    },
  },
};
</script>

<style scoped lang="scss">
.multiselect {
  font-size: var(--12px);
}

.multiselect__tags {
  padding: 5px 40px 0px 3px;
}

.multiselect__tag {
  padding: 0px;
  font-size: var(--9px);
}

.multiselect__input {
  font-size: var(--9px);
}
</style>
