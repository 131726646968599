<template>
  <div class="align-top mr-8">
    <div class="flex items-center" @click="showNotification()">
      <!-- Analysis Button -->
      <SparkButton
        v-if="!processChainsAnalysisRunning"
        small
        variant="secondary"
        :class="{ 'cursor-pointer': !analysisButtonDisabled, 'cursor-default': analysisButtonDisabled }"
        :style="!analysisButtonDisabled ? 'cursor: pointer' : ''"
        class="w-200"
        :disabled="analysisButtonDisabled"
        :title="lockedForUser ? lockedTitle : '(Re-) Analyze All Process Chains'"
        @click="analyze"
      >
        <div>
          <i v-if="processChainsAnalysisRunning" class="fa-solid fa-sync fa-spin" />
          <i v-else class="fas fa-redo" />
          Analyze All
        </div>
      </SparkButton>

      <SparkButton v-else class="w-200" variant="outlined" small @click="cancelAnalysis"
        ><i class="fa fa-ban pr-2" aria-hidden="true" /> Cancel Analysis
      </SparkButton>
    </div>

    <!-- Cancel Button -->
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import SparkButton from '../../../SparkComponents/SparkButton.vue';

export default {
  name: 'AnalysisButton',

  components: { SparkButton },

  data() {
    return {
      cancelClicked: false,
      boolDoubleClickPrevention: false,
    };
  },

  computed: {
    ...mapState(['part']),
    ...mapState('application', ['axiosInstance', 'websocketDisconnect', 'lockedTitle']),
    ...mapGetters(['lockedForUser', 'processChainsAnalysisRunning']),

    analysisButtonDisabled() {
      return (
        (!this.cadUploaded && !this.part.is_cadless) ||
        !this.allMaterialsSelected ||
        !this.processChainExisting ||
        this.processChainsAnalysisRunning ||
        this.boolDoubleClickPrevention ||
        this.lockedForUser
      );
    },

    cadUploaded() {
      return this.basename != '';
    },

    allMaterialsSelected() {
      Object.keys(this.part.process_chains).forEach(key => {
        if (this.part.process_chains[key].mat_id == null) {
          return false;
        }
      });
      return true;
    },

    processChainExisting() {
      return Object.keys(this.part.process_chains).length != 0;
    },

    partId() {
      return this.part.part_id;
    },

    basename() {
      return this.part.basename;
    },
  },

  watch: {
    websocketDisconnect: {
      handler() {
        // if the websocket disconnects just before the analysis button is hit, the button should be activated again, so that the user can try again.
        this.setDoubleClickPrevention(false);
      },
    },

    partId: {
      handler() {
        this.setDoubleClickPrevention(false);
      },

      immediate: true,
    },

    processChainsAnalysisRunning(newVal) {
      if (newVal == false) {
        this.setDoubleClickPrevention(false);
      }
    },
  },

  methods: {
    analyze() {
      this.runAnalysis();
      this.setDoubleClickPrevention(true);
    },

    setDoubleClickPrevention(newBool) {
      this.boolDoubleClickPrevention = newBool;
    },

    showNotification() {
      if (this.analysisButtonDisabled) {
        if (!this.cadUploaded && !this.part.is_cadless) {
          this.$root.notify('warning', 'CAD missing', 'No CAD file uploaded.', 6000);
        } else if (!this.allMaterialsSelected) {
          this.$root.notify('warning', 'Material missing', 'No material specified.', 6000);
        }
      }
    },

    errorCatcher(error) {
      if (error.response && error.response.status === 403 || error.message.includes('Invalid token specified')) {
        this.$root.notify('error', 'Authorization Error', 'Please try to logout and login again.', 6000);
      } else if (error.response && error.response.status === 422) {
        const errorMessage = error.response.data.error_message || 'An error occurred while analyzing process chains.';
        this.$root.notify('error', 'Analysis Error', errorMessage, 6000);
        this.resetAnalysisButton();
      } else {
        const errorMessage = error.response?.data?.message || error.message || 'An unknown error occurred.';
        this.$root.notify('error', 'Error', errorMessage, 6000);
      }
      console.log('Error details:', error);
    },

    resetAnalysisButton() {
      this.setDoubleClickPrevention(false);
      this.$store.commit('setProcessChainsAnalysisRunning', false);
    },

    async cancelAnalysis() {
      await this.axiosInstance
        .post(`/api/v1/cancel/${this.part.part_id}/`)
        .then(() => {
          this.$root.notify('success', 'Canceled', 'Part analysis was canceled.', 3000);
          this.cancelClicked = true;
          this.setDoubleClickPrevention(false);
        })
        .catch(error => {
          this.errorCatcher(error);
        });
    },

    async runAnalysis() {
      this.$store.commit('changeDisplayInfo', '');
      this.cancelClicked = false;
      await this.axiosInstance
        .post(`/api/v1/analysis/${this.part.part_id}/`)
        .then(() => {
          this.$root.notify('success', 'Analysis running', 'Part analysis started.', 3000);
        })
        .catch(error => {
          this.errorCatcher(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.cancel {
  display: flex;
  cursor: pointer;
  margin: 2px;
  padding: 2px;
  font-size: var(--12px);
  border: 1px solid var(--light-color);
  border-radius: 3px;
  margin-right: auto;
  margin-left: auto;

  &:hover {
    border: 1px solid black;
    transition: all 0.3s ease-in;
  }
}
</style>
