<template>
  <div class="spark-window">
    <div class="spark-window-titlebar spark-window-header">
      <div style="display: inline-block; font-size: 1.5em; padding-left: 5px">Create Newsfeed Entry</div>
      <div class="spark-window-close" @click="back()">
        <i class="fas fa-times" />
      </div>
    </div>
    <div class="spark-window-body" style="position: relative">
      <form>
        <div>
          <label for="title">Title</label>
          <input id="title" v-model="form.title" type="text" />
        </div>
        <div>
          <label for="content">Content</label>
          <textarea id="content" v-model="form.content" />
        </div>
        <div>
          <label for="ulr">URL</label>
          <input id="url" v-model="form.url" type="text" />
        </div>
        <div>
          <label for="topic">Topic</label>
          <select v-model="form.topic">
            <option value="general">General</option>
            <option value="release_note">Release Note</option>
            <option value="service">Service</option>
            <option value="important_change">Important Change</option>
            <option value="user">User</option>
            <option value="feature">Feature</option>
            <option value="bugfix">Bugfix</option>
            <option value="blog_entry">Blog Entry</option>
            <option value="press_release">Press Release</option>
            <option value="social_post">Social Post</option>
          </select>
        </div>
        <div
          title="Valid values: 'all', 'topic_subscribers', 'organization__<organization.name>', 'user__<user.username>'"
        >
          <label for="subscription_key">Subscription Key</label>
          <input id="subscription_key" v-model="form.subscription_key" type="text" />
        </div>
        <div>
          <label for="image">Image</label>
          <input type="file" name="image" accept=".png, .jpg, .jpeg, .gif, .svg" @input="UploadImages" />
        </div>
        <button @click.prevent="createNewsfeedEntry">Create News</button>
      </form>
    </div>
  </div>
</template>

<script>
import FormData from 'form-data';
import { mapState } from 'vuex';

export default {
  name: 'NewsManagement',

  data() {
    return {
      form: {
        title: '',
        content: '',
        topic: 'General',
        subscription_key: 'all',
        url: '',
        image: null,
      },

      isLoading: false,
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
  },

  methods: {
    async createNewsfeedEntry() {
      this.isLoading = true;
      let formData = new FormData();
      formData.append('title', this.form.title);
      formData.append('content', this.form.content);
      formData.append('topic', this.form.topic);
      formData.append('subscription_key', this.form.subscription_key);
      formData.append('url', this.form.url);
      formData.append('image', this.form.image);
      this.axiosInstance
        .post('/api/v1/news/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          this.$root.notify('success', 'News Created', this.form.title, 3000);
          this.form = {
            title: '',
            content: '',
            topic: 'General',
            subscription_key: 'all',
            url: '',
            image: null,
          };
        })
        .catch(error => {
          this.$root.notify('warning', error.message, error.response.data.error_message, 6000);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    UploadImages(event) {
      const file = event.target.files[0];
      if (!file) {
        this.form.image = null;
        return;
      }

      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'];
      if (!validImageTypes.includes(file.type)) {
        this.$root.notify('warning', 'Invalid file type', 'Please upload an image file (JPEG, PNG, GIF or SVG).', 6000);
        event.target.value = null;
        this.form.image = null;
      } else {
        this.form.image = file;
      }
    },
  },
};
</script>

<style>
label {
  float: left;
}
</style>
