<script>
import { saveAs } from 'file-saver';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'DownloadManager',

  computed: {
    ...mapState(['fetchOptimalOrientation', 'fetchAnalysisResultsXLS', 'fetchCustomReportDOCX', 'part']),
    ...mapState('application', ['axiosInstance']),

    chain_id() {
      return this.$route.params.process_chain_id;
    },
  },

  watch: {
    fetchOptimalOrientation: {
      handler(newVal) {
        if (newVal) {
          this.fetchOrientedCAD();
        }
      },

      immediate: true
    },

    fetchAnalysisResultsXLS: {
      handler(newVal) {
        if (newVal) {
          this.fetchExcelResultsFile();
        }
      },

      immediate: true
    },

    fetchCustomReportDOCX: {
      handler(newVal) {
        if (newVal !== '') {
          this.fetchCustomReportFile(newVal);
        }
      },

      immediate: true
    }
  },

  methods: {
    ...mapMutations(['updateFetchOptimalOrientation', 'updateFetchAnalysisResultsXLS', 'updateFetchCustomReportDOCX']),

    async fetchOrientedCAD() {
      try {
        const response = await this.axiosInstance.get(`/api/v1/part/${this.part.part_id}/fetch-optimal-orientation-file/`, {
          params: { part_id: this.part.part_id },
          responseType: 'blob',
        });
        const fileName = 'Optimally_oriented_' + this.part.basename.replace(/\s/g, '_');
        saveAs(response.data, fileName);
      } catch (error) {
        this.$root.notifyError('Downloading oriented CAD', error);
      } finally {
        this.updateFetchOptimalOrientation(false);
      }
    },

    async fetchExcelResultsFile() {
      try {
        const response = await this.axiosInstance.get(`/api/v1/part/${this.part.part_id}/fetch-analysis-results-file/${this.chain_id}/`, {
          responseType: 'blob',
        });
        const fileName = 'Results_' + this.part.name.replace(/\s/g, '_') + '_' + this.chain_id + '.xlsx';
        saveAs(response.data, fileName);
      } catch (error) {
        this.$root.notifyError('Downloading Excel Report', error);
      } finally {
        this.updateFetchAnalysisResultsXLS(false);
      }
    },

    async fetchCustomReportFile(task_string) {
      if (task_string === '') return;
      
      try {
        const task = JSON.parse(task_string);
        const response = await this.axiosInstance.get(`api/v1/fetch-doc-generated/${task.part_id}/${task.template_uid}/`, {
          responseType: 'blob',
        });
        saveAs(response.data, `${task.part_name}__${task.default_file_name}`);
      } catch (error) {
        this.$root.notifyError('Downloading PDF Report', error);
      } finally {
        this.updateFetchCustomReportDOCX('');
      }
    },
  },

  render() {
    return null;
  },
};
</script>
