<template>
  <div class="flex mx-8">
    <div v-if="boolFeasibility == 'false'" class="flex self-baseline">
      <img src="@/assets/icons/feasibility/alert.svg" class="mr-4" />
      <div class="text-orange-400">Warning</div>
    </div>
    <div v-if="boolFeasibility == 'true'" class="flex">
      <img class="mr-4" src="@/assets/icons/feasibility/check.svg" />
      <div class="text-primary-500">Passed</div>
    </div>
    <div v-if="boolFeasibility == undefined" title="No Feasibility Checks for this process calculated,">-</div>
  </div>
</template>

<script>
export default {
  name: 'FeasibilitySymbol',

  props: { boolFeasibility: { type: String, default: undefined } },
};
</script>
