<template>
  <div class="flex flex-col flex-wrap items-start">
    <div v-for="(cat, id) in allComboCategories" :key="id" class="w-full">
      <div class="spark-new-input-group !px-0">
        <select
          v-model="cat.uid"
          class="spark-new-select-input !ring-0 hover:border-primary-500 focus:border-primary-500 mx-0 w-full"
          :class="!cat.uid && cat.required && highlightCategories ? 'highlight-red-border' : ''"
          @change="saveComboCategory(id, cat)"
        >
          <option :value="null" />
          <option v-for="(item, itemid) in cat.items" :key="itemid" :value="itemid">
            {{ item }}
          </option>
        </select>
        <label class="spark-new-select-label crop">{{ cat.label }}<span v-if="cat.required">*</span></label>
      </div>
    </div>

    <div v-for="(textcat, id) in allTextCategories" :key="id" class="flex w-full">
      <div class="spark-new-input-group w-full !px-0">
        <input
          v-model="textcat.value"
          type="text"
          name="text_category"
          autocomplete="off"
          class="spark-new-input-clean !ring-0 hover:border-primary-500 focus:border-primary-500 border-gray-100 rounded-4 text-13 mx-0 w-full"
          :class="!textcat.value && textcat.required && highlightCategories ? 'highlight-red-border' : ''"
          @change="saveTextCategory(id, textcat)"
        />
        <label class="spark-new-input-label crop"> {{ textcat.label }}<span v-if="textcat.required">*</span></label>
      </div>
      <div
        v-if="validateThisUrl(textcat.value)"
        title="Go to"
        class="external-link-icon"
        @click="goToUrl(textcat.value)"
      >
        <i class="fas fa-external-link-alt" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import validateUrl from '@/composables/validateUrl.js';
export default {
  name: 'PrpCategoryInput',

  emits: ['close'],

  computed: {
    ...mapState('prp', ['prpOrderCategories']),
    ...mapState(['user', 'highlightCategories', 'partLibraryFilters']),
    ...mapState('application', ['axiosInstance']),

    allComboCategories() {
      return this.prpOrderCategories.prp_combo_categories;
    },

    allTextCategories() {
      return this.prpOrderCategories.prp_text_categories;
    },
  },

  methods: {
    ...mapActions('prp', ['fetchPrpOrderData']),

    validateThisUrl(url) {
      return validateUrl(url).validateBool;
    },

    goToUrl(url) {
      let prefix = 'https://';
      if (!url.includes(prefix) && !url.includes('http://')) {
        url = prefix + url;
      }
      window.open(url, '_blank');
    },

    saveTextCategory(id, textcat) {
      let cat = {};
      cat[id] = {
        value: textcat.value,
      };
      this.updatePartNewPart({ prp_text_categories: cat });
    },

    saveComboCategory(id, combocat) {
      let cat = {};
      if (combocat.uid != '') {
        cat[id] = {
          uid: combocat.uid,
          value: combocat.items[combocat.uid],
        };
      } else {
        cat[id] = {
          uid: '',
          value: '',
        };
      }
      this.updatePartNewPart({ prp_combo_categories: cat });
    },

    updatePartNewPart(data) {
      if (Object.prototype.hasOwnProperty.call(data, 'prp_combo_categories')) {
        // change data structure for backend
        for (const [key, value] of Object.entries(data['prp_combo_categories'])) {
          data['prp_combo_categories'][key] = value.uid;
        }
      }

      this.savePart(data);
    },

    async savePart(formData) {
      await this.axiosInstance
        .put(`/api/v1/prp-order/`, formData)
        .then(async () => {
          await this.fetchPrpOrderData();
        })
        .catch(error => {
          console.log(JSON.stringify(error));
        });
    },
  },
};
</script>
