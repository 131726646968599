<template>
  <div class="flex flex-col mx-4" @click="trigger()">
    <EstimationAndBenchmark
      type="cost"
      :mouse-hover="mouseHover === 'cost'"
      :chain="chain"
      :process-chains="chains"
      unit="€"
      @click="trigger()"
    />
    <OpenDetails :show="mouseHover === 'cost'" />
  </div>
</template>

<script>
import { markRaw } from 'vue';
import { mapMutations } from 'vuex';

import EstimationAndBenchmark from '../../components/EstimationAndBenchmark.vue';
import OpenDetails from '../../components/OpenDetails.vue';

import CostPopup from './components/CostPopup.vue';

import { analysisFinished } from '@/composables/processChainHandling.js';

export default {
  name: 'Cost',

  components: {
    EstimationAndBenchmark,
    OpenDetails,
  },

  props: {
    chain: {
      type: Object,
      default() {
        return {};
      },
    },

    chains: {
      type: Object,
      default() {
        return {};
      },
    },

    mouseHover: { type: String, default: '' },
  },

  computed: {
    analysisFinished() {
      return analysisFinished(this.chain.process_chain_id);
    },
  },

  methods: {
    ...mapMutations(['triggerPopup', 'changeInvestigationDetails']),

    trigger() {
      this.changeInvestigationDetails({ uid: this.chain.process_chain_id });
      this.triggerPopup({
        key: this.$options.name,
        show: true,
        title: 'Cost',
        type: 'component',
        buttons: true,
        buttonContent: [{ text: 'Close', type: 'outlined', value: 'cancel' }],
        data: markRaw(CostPopup),
      });
    },
  },
};
</script>
