<template>
  <input
    v-model.lazy="storedValue"
    class="border-white text-15 hover:border-gray-300 hover rounded-8 focus:border-gray-600 active:border-gray-600 font-bold p-4 mb-2 text-right"
    type="number"
    name="cost"
    placeholder="0"
    :title="lockedForUser ? lockedTitle : ''"
    :disabled="lockedForUser"
    @click.stop
    @change="setNewFeedbackValue()"
    @focus="inputFocusActive(true)"
    @blur="inputFocusActive(false)"
  />
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'FeedbackValueInput',

  props: {
    feedbackValue: [Object, String, Number],
  },

  emits: ['feedback-value-changed', 'input-focus-active'],

  data() {
    return {
      storedValue: '',
      unitFieldStyle: '',
    };
  },

  computed: {
    ...mapState('application', ['lockedTitle']),
    ...mapGetters(['lockedForUser']),
  },

  watch: {
    feedbackValue: {
      handler(feedbackValue) {
        this.setProppedFeedbackValue(feedbackValue);
      },

      immediate: true,
    },

    fieldStyle: {
      handler(fieldStyle) {
        this.setUnitFieldStyle(fieldStyle);
      },
    },
  },

  methods: {
    setProppedFeedbackValue(feedbackValue) {
      if (feedbackValue != undefined) {
        this.storedValue = feedbackValue;
      }
    },

    setUnitFieldStyle(fieldStyle) {
      if (fieldStyle.includes('font-weight: bold')) {
        this.unitFieldStyle = 'font-weight: bold';
      }
    },

    setNewFeedbackValue() {
      this.$emit('feedback-value-changed', this.storedValue);
    },

    inputFocusActive(value) {
      this.$emit('input-focus-active', value);
    },
  },
};
</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
